import React from 'react';
import { Text, Select, Input, Flex } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

export function PageOptions({
    ViewSelect,
    ViewSelectChange,
    ShowNumberChange,
    ActivePageNo,
    SelectedTestPageValue,
    KeywordChange,
  }:any) {

  const AllCreateTestPagesoptions = [
    { value: '0', label: 'All Pages' },
    { value: '1', label: 'Pages with No Tests' },
    { value: '2', label: 'Pages with "Draft Tests"' }
  ];

  const handleSelectView = (selectedViewOption) => {
    ViewSelectChange(selectedViewOption);
  };

  const handleSelectAllCreateTestPagesValueChange = (selectedAllCreateTestPageOption) => {
    SelectedTestPageValue(selectedAllCreateTestPageOption);
    ActivePageNo(1);
  };

  const handleShowNumberChange = (selectedShowNumberOption) => {
    ShowNumberChange(selectedShowNumberOption);
    ActivePageNo(1);
  };

  const handleKeywordChange = (e) => {
    KeywordChange(e.target.value);
    ActivePageNo(1);
  };

  return (
    <>
      <div style={{ display: 'flex'}}>
        <Text mt={5} mr={5}>Configured pages:</Text>
        <Select 
          w={200}
          defaultValue="0"
          data={AllCreateTestPagesoptions}
          onChange={(selectedAllCreateTestPageOption) => handleSelectAllCreateTestPagesValueChange(selectedAllCreateTestPageOption)}
          searchable
          nothingFound="No options"
          maxDropdownHeight={300}
          dropdownPosition="bottom"
        />
        <Text mt={5} ml={20} mr={5}>View:</Text>
        <Select
          onChange={handleSelectView}
          defaultValue="grid"
          value={ViewSelect}
          data={[
            { value: 'grid', label: 'Grid' },
            { value: 'list', label: 'List' }
          ]}
          w={80}
        />
        <Text mt={5} ml={20} mr={5}>Show: </Text>
        <Select
          onChange={handleShowNumberChange}
          defaultValue="25"
          data={[
            { value: '10', label: '10' },
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
          ]}
          w={80}
        />
        <Flex
          style={{marginLeft:"auto"}}
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input icon={<IconSearch size={16} />} placeholder="Search"
            maxLength={50}
            autoFocus
            onChange={handleKeywordChange}
           />
        </Flex>
      </div>
    </>
  );
}