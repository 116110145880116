import React from 'react';
import { useNavigate } from "react-router-dom";
import { Text, Button, TextInput, Modal } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import InsightsApi from "../../api/InsightsApi";

export function AssetsUrl({
  isAssetsUrlModalOpen,
  setIsAssetsUrlModalOpen,
  handleAssetsUrlModalClose,
  assetsUrlValue,
  setAssetsUrlValue,
  pageId,
  assetsredirecturl
  }:any) {

  const navigate = useNavigate();

  const handleSaveAssetsUrl = () => {
    let encodedUrl = btoa(assetsUrlValue);
    const dataObject = {
      page_id: pageId,
      assets_url: encodedUrl
    };
    InsightsApi.saveupdateassetsurl(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setIsAssetsUrlModalOpen(false);
          let decodedUrl = atob(data.data.url);
          setAssetsUrlValue(decodedUrl);
          assetsredirecturl();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  }

  return (
    <>
      <Modal.Root opened={isAssetsUrlModalOpen} onClose={handleAssetsUrlModalClose} size="40%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>DEFINE ASSETS URL <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Text style={{ fontSize: '14px' }}>Note:</Text>
            <Text style={{ marginTop: '10px', fontSize: '14px' }}>If you do not see styles showing in the editor than it means that your website assets are located somewhere other than your core domain. Assets include images, css files, etc.</Text>
            <Text style={{ marginTop: '10px', fontSize: '14px' }}>For example:</Text>
            <Text style={{ marginTop: '10px', fontSize: '14px' }}>Mysite.com would usually have all assets located at my site.com but if your developer moved them to a file like mysite.com/subfolder/assets thanwe have to direct Visiopt to that folder.</Text>
            <Text style={{ marginTop: '10px', fontSize: '14px' }}>Enter the location of your site assets below. Check with your web developer first.</Text>
            <Text style={{ marginTop: '10px', fontSize: '14px' }}>Please enter Assets Directory Link:</Text>
            <TextInput value={assetsUrlValue} onChange={(e) => setAssetsUrlValue(e.currentTarget.value)}></TextInput>
            <div style={{ marginTop: '20px', display: 'flex', gap: '5px', justifyContent: 'flex-end' }}>
              <Button color="red" onClick={handleAssetsUrlModalClose}>Cancel</Button>
              <Button onClick={handleSaveAssetsUrl}>Save</Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}