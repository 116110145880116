import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Container, Skeleton, Card, Group, Text, Space, Grid, Pagination, Flex } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import '../Funnel/Funnel.css';
import { VisiLoader, ErrorModal, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import { UnsetWinner } from "../../components/ReviewTest/UnsetWinner";
import { ReviewPageList } from "../../components/ReviewTest/ReviewPageList";
import { ReviewPageGridList } from "../../components/ReviewTest/ReviewPageGridList";
import { ReviewPageOptions } from "../../components/ReviewTest/ReviewPageOptions";
import { AddToFunnel } from "../../components/ReviewTest/AddToFunnel";
import TestsApi from "../../api/TestsApi";

export function ReviewTests() {

	const navigate = useNavigate();
	
	useDocumentTitle("Visiopt - Review Tests");

	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};
	const reviewTestRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [SystemUsageLoader, setSystemUsageLoader] = useState(false);
	const [errorModelOpen, seterrorCloseModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showErrorHeader, setShowErrorHeader] = useState(false);
	const [showMessageHeader, setShowMessageHeader] = useState(false);
	const [selectedAllTestPageValue, setSelectedAllTestPageValue] = useState('0');
	const [activePageNo, setActivePageNo] = useState(1);
	const [keyword, setKeyword] = useState('');
	const [selectPageTests, setSelectPageTests] = useState([]);
	const [totalPages, setTotalPages] = useState(10);
	const [totalPaginationNo, setTotalPaginationNo] = useState(1);
	const [selectedThumbnail, setSelectedThumbnail] = useState(null);
	const [selectedTaguchiTestId, setSelectedTaguchiTestId] = useState(null);
	const [selectedFunnelId, setSelectedFunnelId] = useState(null);
	const [selectedTestsId, setSelectedTestsId] = useState(null);
	const [isThumbnailSelected, setIsThumbnailSelected] = useState(false);
  const [viewSelect, setViewSelect] = useState('grid');
  const [showNumber, setShowNumber] = useState(25);
  const [marksalestime, setMarksalestime] = useState(0);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });



  useEffect(() => {
  	if(selectedMemberWebsite !== ''){
	    setLoading(true);
	    seterrorCloseModal(false);
	    TestsApi.getpageslist({
	      system_website_id: selectedMemberWebsite,
	      filter: selectedAllTestPageValue,
	      layout: viewSelect,
	      number: showNumber,
	      page: activePageNo,
	      keyword: keyword,
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          setSystemUsageLoader(false);
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	        if (data.data.test_pages_list) {
	          setSelectPageTests(data.data.test_pages_list);
	          if (data.data.active_page_no !== activePageNo) {
	            setActivePageNo(data.data.active_page_no);
	            setActivePageNo(1);
	          }
	          if (data.data.total_pages !== totalPages) {
	            setTotalPages(data.data.total_pages);
	          }
	          if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
	            setTotalPaginationNo(data.data.total_pagination_no);
	          }
	        } else{
	          console.log("No data received");
	          setSelectPageTests([]);
	        }
	      }else{
	        setSelectedAllTestPageValue('0');
	        setErrorMessage(data.message);
	        seterrorCloseModal(true);
	      }
	      setLoading(false);
	    })
	    .catch(error => {
	      console.error("API Error:", error);
	      setLoading(false);
	    });
	  }
  }, [selectedAllTestPageValue, activePageNo, selectedMemberWebsite, viewSelect, showNumber, keyword, marksalestime]);




  const handlePageChange = (newPage) => {
    setActivePageNo(newPage);
  };




  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (reviewTestRef.current) {
      const { top, left, width } = reviewTestRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

	return (
	  <>
	  <VisiLoader VisiLoaderVisible={SystemUsageLoader}/>
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
	  {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
	  <Container size="xl" mb={10}>
	  	<Card shadow="sm" padding="sm" radius="md" withBorder>
	  		<Group position="apart" mb="xs">
	        <Text weight={500}>REVIEW TESTS <IconHelp ref={reviewTestRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(120)}/></Text>
	      </Group>
  	    <ReviewPageOptions
  	    	ViewSelect={viewSelect}
			    ViewSelectChange={setViewSelect}
			    ShowNumberChange={setShowNumber}
			    ActivePageNo={setActivePageNo}
			    SelectedTestPageValue={setSelectedAllTestPageValue}
			    KeywordChange={setKeyword}
  	    />
  	    <div style={{flex: "1 1 auto",overflowX: "hidden",marginTop:10}}>
  	    	<div style={{ display: viewSelect === "grid" ? "block" : "none"}}>
  	    		{loading ? (
				      <Flex justify="start" gap="sm" mt={10} wrap="wrap">
				        {Array.from({ length: 21 }).map((_, index) => (
				          <Skeleton key={index} height={170} width={152} />
				        ))}
				      </Flex>
				    ) : (
					  	<>
					  	<ReviewPageGridList
					  		SelectedPageTests={selectPageTests}
						    SelectedThumbnail={selectedThumbnail}
						    SelectedThumbnailChange={setSelectedThumbnail}
						    IsThumbnailSelected={isThumbnailSelected}
						    IsThumbnailSelectedChange={setIsThumbnailSelected}
						    SelectedTaguchiIdChange={setSelectedTaguchiTestId}
						    SelectedFunnelIdChange={setSelectedFunnelId}
						    SelectedTestIdChange={setSelectedTestsId}
						    SelectedMemberWebsite={selectedMemberWebsite}
						    ErrorMessageChange={setErrorMessage}
						    ErrorCloseModal={seterrorCloseModal}
						    ShowMessageHeader={setShowMessageHeader}
					  	/>
					  	{selectPageTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
						  <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
						    <Group spacing={5} position="center" mb={20}>
						      <Pagination.First />
						      <Pagination.Previous />
						      <Pagination.Items />
						      <Pagination.Next />
						      <Pagination.Last />
						    </Group>
						  </Pagination.Root>
						  )}
						  </>
					  )}
				  </div>

					  <div style={{ display: viewSelect === "list" ? "block" : "none"}}>
			  	    {selectPageTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
			  	    <>
					  	<ReviewPageList
					  		SelectedPageTests={selectPageTests}
						    SelectedThumbnail={selectedThumbnail}
						    SelectedThumbnailChange={setSelectedThumbnail}
						    IsThumbnailSelected={isThumbnailSelected}
						    IsThumbnailSelectedChange={setIsThumbnailSelected}
						    SelectedTaguchiIdChange={setSelectedTaguchiTestId}
						    SelectedFunnelIdChange={setSelectedFunnelId}
						    SelectedTestIdChange={setSelectedTestsId}
					  	/>
			  	    <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
			  	      <Group spacing={5} position="center" mb={20}>
			  	        <Pagination.First />
			  	        <Pagination.Previous />
			  	        <Pagination.Items />
			  	        <Pagination.Next />
			  	        <Pagination.Last />
			  	      </Group>
			  	    </Pagination.Root>
			  	    </>
			  	    )}
					  </div>
			  </div>
			  <div className="legend-holder">
				  <Grid>
		        <Grid.Col span={6} mt={15}>
		        	<Group spacing="xs" m={0}>
		        	  <span className="legend selected" />
		        	  <Text size="sm" ml={0} fz={12} className="legend-title">Selected</Text>
		        	  <span className="legend active" />
		        	  <Text size="sm" ml={0} fz={12} className="legend-title">Active Test</Text>
		        	  <span className="legend winner" />
		        	  <Text size="sm" ml={0} fz={12} className="legend-title">Winner Set</Text>
		        	</Group>
		        </Grid.Col>
		        <Grid.Col span={3} offset={3}>
		        	<div style={{ display: viewSelect === "grid" ? "block" : "none"}}>
			        	<div style={{ display: 'flex'}}>
		              <Space w="xs" />
		              {selectedThumbnail && selectPageTests.find(item => item.page_id === selectedThumbnail && item.pageClass === 'page winner') ? (
									  <>
									  	<UnsetWinner
									  		SelectedThumbnail={selectedThumbnail}
												SystemUsageLoader={setSystemUsageLoader}
												SelectedMemberWebsite={selectedMemberWebsite}
												MarkSalesTime={setMarksalestime}
												ErrorMessageSet={setErrorMessage}
												ErrorCloseModal={seterrorCloseModal}
												ShowErrorHeader={setShowErrorHeader}
									  	/>
									  </>
									) : (
									  <>
									    <Space w="xs" />
									    <AddToFunnel
									    	SelectedThumbnail={selectedThumbnail}
												SelectedTaguchiId={selectedTaguchiTestId}
												SelectedMemberWebsite={selectedMemberWebsite}
												SelectedFunnelId={selectedFunnelId}
												SelectedTestId={selectedTestsId}
												ErrorMessage={setErrorMessage}
												ErrorCloseModal={seterrorCloseModal}
												ShowMessageHeader={setShowMessageHeader}
									    />
									  </>
									)}
		            </div>
		          </div>
		          <div style={{ display: viewSelect === "list" ? "block" : "none"}}>
		          	<div style={{ display: 'flex'}}>
		          		<Space w="xs" />
		          		{selectedThumbnail && selectPageTests.find(item => item.page_id === selectedThumbnail && item.type === 'Winner') ? (
									  <>
									  	<UnsetWinner
									  		SelectedThumbnail={selectedThumbnail}
												SystemUsageLoader={setSystemUsageLoader}
												SelectedMemberWebsite={selectedMemberWebsite}
												MarkSalesTime={setMarksalestime}
												ErrorMessageSet={setErrorMessage}
												ErrorCloseModal={seterrorCloseModal}
												ShowErrorHeader={setShowErrorHeader}
									  	/>
									  </>
									) : (
									  <>
									    <Space w="xs" />
									    <AddToFunnel
									    	SelectedThumbnail={selectedThumbnail}
												SelectedTaguchiId={selectedTaguchiTestId}
												SelectedMemberWebsite={selectedMemberWebsite}
												SelectedFunnelId={selectedFunnelId}
												SelectedTestId={selectedTestsId}
												ErrorMessage={setErrorMessage}
												ErrorCloseModal={seterrorCloseModal}
												ShowMessageHeader={setShowMessageHeader}
									    />
									  </>
									)}
		          	</div>
		          </div>
		        </Grid.Col>
		      </Grid>
			  </div>
			  {/*Error Model*/}
		    <ErrorModal errorModelOpen={errorModelOpen} errorMessage={errorMessage} showErrorHeader={showErrorHeader} showMessageHeader={showMessageHeader} onClose={handlerrorCloseModal} />
			</Card>
	  </Container>
	  </>
	);
}