import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button, Input, Group, Text, Select, Table, Pagination } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import CampaignReport from "../../api/CampaignReport";
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function CustomerJourney({ emailValue, setEmailValue, selectedAttributionModal, reportTimeValue, selectedCustomTagData, selectedTrackingTagData, conversionGoalOptions, selectedSourceData, campaignId, campaignGoalId, campaignGoalType, customerJourneyCampaignSessionIds, customerJourneyType, isCustomerJourneyModalOpen, onCustomerJourneyClose}:any) {
  const navigate = useNavigate();

  const [selectedCustomerJourneyGoalValue, setSelectedConversionGoalValue] = useState('');

  const [customerVisitors, setCustomerVisitors] = useState(null);
  const [updatedTime, setUpdatedTime] = useState('');

  const [allGoalPageIds, setAllGoalPageIds] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [conversionGoalDetails, setConversionGoalDetails] = useState([]);
  const [customerJourneyData, setCustomerJourneyData] = useState([]);
  const [activePageNo, setActivePageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [totalPaginationNo, setTotalPaginationNo] = useState(1);

  const [isCustomerJourneyViewModalOpen, setIsCustomerJourneyViewModalOpen] = useState(false);
  const [journeyViewData, setJourneyViewData] = useState([]);
  const [journeyType, setJourneyType] = useState('');

  const journeyIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleCustomerJourneyGoalChange = (value:any) => {
    setSelectedConversionGoalValue(value);
  };

  useEffect(() => {    
    if(customerJourneyType === "traffic" && customerJourneyCampaignSessionIds){
      setJourneyType('traffic');
      CustomerJourneyList('',activePageNo,'traffic');
    }
  }, [customerJourneyType, customerJourneyCampaignSessionIds]);

  useEffect(() => {
    if(customerJourneyType === "goal"){
      CampaignGoalJourney();
    }
    if (!isCustomerJourneyModalOpen) {
      setActivePageNo(1);
    }
  }, [isCustomerJourneyModalOpen]);

  const CampaignGoalJourney = () => {
    const formattedCustomTags = selectedCustomTagData.reduce((acc, data) => {
      const { campaignid, arrayId, customTagValues } = data;
      if (Array.isArray(customTagValues) && customTagValues.length > 0) {
        if (!acc[campaignid]) {
          acc[campaignid] = {};
        }
        acc[campaignid][arrayId] = customTagValues;
      }
      return acc;
    }, {});

    const formattedTrackingNetwork = selectedTrackingTagData.reduce((acc, data) => {
      const { campaignid, arrayId, trackingTagValues } = data;
      if (Array.isArray(trackingTagValues) && trackingTagValues.length > 0) {
        if (!acc[campaignid]) {
          acc[campaignid] = {};
        }
        acc[campaignid][arrayId] = trackingTagValues;
      }
      return acc;
    }, {});

    const testVariationCampaigns = conversionGoalOptions.filter(options => options.test_variation === 'show');
    const testVariationData = testVariationCampaigns.reduce((acc, options) => {
      acc[options.campaign_id] = options.test_variation_value;
      return acc;
    }, {});

    const formattedSource = selectedSourceData.reduce((acc, data) => {
      const { campaignid, sourceValues } = data;
      if (Array.isArray(sourceValues) && sourceValues.length > 0) {
        if (!acc[campaignid]) {
          acc[campaignid] = {};
        }
        acc[campaignid] = sourceValues;
      }
      return acc;
    }, {});

    CampaignReport.getcampaigngoaljourney({
      attribution_name: selectedAttributionModal,
      ads_custom_network: formattedCustomTags,
      ads_tracking_network: formattedTrackingNetwork,
      source: formattedSource,      
      campaign_id: campaignId,
      campaign_goal: campaignGoalId,
      campaign_goal_type: campaignGoalType,
      report_time: reportTimeValue,
      test_variations_data: testVariationData,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setCustomerVisitors(data.data.customer_visitors);
        setUpdatedTime(data.data.updated_time);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  useEffect(() => {
    if(customerVisitors){
      setJourneyType('goal');
      CustomerJourneyList('',activePageNo,'goal');
    }
  }, [customerVisitors,updatedTime]);

  const CustomerJourneyList = (value:any,activePageNo:any,type:any='') => {
    CampaignReport.getcustomerjourneylist({
      campaign_id: campaignId,
      campaign_goal: campaignGoalId,
      session_ids: type === 'goal' ? customerVisitors : customerJourneyCampaignSessionIds,
      page:activePageNo
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setAllGoalPageIds(data.data.all_goal_page_ids);
        setCampaignName(data.data.campaign_name);
        setConversionGoalDetails(data.data.conversion_goal_details);
        setCustomerJourneyData(data.data.customer_journey_data);
        if (data.data.active_page_no !== activePageNo) {
          setActivePageNo(data.data.active_page_no);
          setActivePageNo(1);
        }
        if (data.data.total_pages !== totalPages) {
          setTotalPages(data.data.total_pages);
        }
        if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
          setTotalPaginationNo(data.data.total_pagination_no);
        }
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const goalListData = [
    ...conversionGoalDetails.map((GoalListData) => ({
      value: GoalListData.goal_page_id,
      label: GoalListData.goal_name,
    })),
  ];

  useEffect(() => {
    const selectedCampaign = conversionGoalDetails.find((GoalDetails) => GoalDetails.selected === "selected");
    if (selectedCampaign) {
      setSelectedConversionGoalValue(selectedCampaign.goal_page_id);
    }
  }, [conversionGoalDetails]);

  const handleCustomerJourneyFilter = (e) => {
    e.preventDefault();
    setAllGoalPageIds('');
    setCampaignName('');
    setCustomerJourneyData([]);
    CampaignReport.getcustomerjourneylist({
      campaign_id: campaignId,
      campaign_goal: selectedCustomerJourneyGoalValue,
      session_ids: journeyType === 'goal' ? customerVisitors : customerJourneyCampaignSessionIds,
      customer_email: emailValue,
      all_goal_page_ids: allGoalPageIds,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setAllGoalPageIds(data.data.all_goal_page_ids);
        setCampaignName(data.data.campaign_name);
        setConversionGoalDetails(data.data.conversion_goal_details);
        if(emailValue !== ''){
          Object.entries(data.data.customer_journey_data).forEach(([journeyIndex, journeyData]) => {
            setCustomerJourneyData([journeyData]);
          })
        }else{
          setCustomerJourneyData(data.data.customer_journey_data);
        }
        if (data.data.active_page_no !== activePageNo) {
          setActivePageNo(data.data.active_page_no);
          setActivePageNo(1);
        }
        if (data.data.total_pages !== totalPages) {
          setTotalPages(data.data.total_pages);
        }
        if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
          setTotalPaginationNo(data.data.total_pagination_no);
        }
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleCustomerJourneyView = (visitorsession:any) => {
    setIsCustomerJourneyViewModalOpen(true);
    CampaignReport.getcustomerjourneyviewdetail({
      visitor_session_id: visitorsession
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setJourneyViewData(data.data.journey_data);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleCustomerJourneyViewCloseModal = () => {
    setIsCustomerJourneyViewModalOpen(false);
  };

  const handlePageChange = (newPage) => {
    CustomerJourneyList('',newPage,journeyType);
    setActivePageNo(newPage);
  };

  const listRows = customerJourneyData.length > 0 ? (
    customerJourneyData.map((item, index) => (
      <tr key={index}>
        <td><Text>{item.date}</Text></td>
        <td><Text>{item.sales_amount}</Text></td>
        <td><Text>{item.email}</Text></td>
        <td style={{ color: 'green', cursor: 'pointer' }} onClick={() => handleCustomerJourneyView(item.visitor_session)}><Text>{"View"}</Text></td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={4} style={{ textAlign: "center" }}><Text>No data found</Text></td>
    </tr>
  );

  const JourneyViewList = journeyViewData.length > 0 ? (
    journeyViewData.map((item, index) => (
      <tr key={index}>
        <td><Text>{item.date}</Text></td>
        <td>
          <Text>{item.page_details.page_url}</Text>
          <Text>{"Page Title : "}{item.page_details.page_title}</Text>
        </td>
        <td><Text>{item.customer_email}</Text></td>
        <td><Text>{item.traffic_source}</Text></td>
        <td><Text>{item.sales_amount}</Text></td>
        {item.attribution ? (
          <td><Text>{"Attribution : "}{item.attribution}</Text></td>
        ) : (
          <td></td>
        )}
        <td>
            {item.behavioral_details.utm_campaign ? (
              <Text>{"UTM Campaign : "}{item.behavioral_details.utm_campaign}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.utm_content ? (
              <Text>{"UTM Content : "}{item.behavioral_details.utm_content}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.utm_id ? (
              <Text>{"UTM Id : "}{item.behavioral_details.utm_id}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.utm_medium ? (
              <Text>{"UTM Medium : "}{item.behavioral_details.utm_medium}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.utm_source ? (
              <Text>{"UTM Source : "}{item.behavioral_details.utm_source}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.utm_term ? (
              <Text>{"UTM Term : "}{item.behavioral_details.utm_term}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.ad_id ? (
              <Text>{"Ad Id : "}{item.behavioral_details.ad_id}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.ad_name ? (
              <Text>{"Ad Name : "}{item.behavioral_details.ad_name}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.ads_network_type ? (
              <Text>{"Ads Network_type : "}{item.behavioral_details.ads_network_type}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.adset_id ? (
              <Text>{"Adset Id : "}{item.behavioral_details.adset_id}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.adset_name ? (
              <Text>{"Adset Name : "}{item.behavioral_details.adset_name}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.campaign_id ? (
              <Text>{"Campaign Id : "}{item.behavioral_details.campaign_id}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.campaign_name ? (
              <Text>{"Campaign Name : "}{item.behavioral_details.campaign_name}</Text>
            ) : (
              <Text></Text>
            )}
            {item.behavioral_details.fbclid ? (
              <Text>{"Fbclid : "}{item.behavioral_details.fbclid}</Text>
            ) : (
              <Text></Text>
            )}
        </td>
        {item.device ? (
          <td><Text>{"Device : "}{item.device}</Text></td>
        ) : (
          <td></td>
        )}
        <td><Text>{item.referral_source}</Text></td>
        <td><Text>{item.v_no}</Text></td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={10} style={{ textAlign: "center" }}><Text>No data found</Text></td>
    </tr>
  );

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (journeyIconRef.current) {
      const { top, left, width } = journeyIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      <Modal.Root opened={isCustomerJourneyModalOpen} onClose={onCustomerJourneyClose} size="80%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>{"Customer Journey - "}{campaignName} <IconHelp ref={journeyIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(317)}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body h={480}>
            <form onSubmit={handleCustomerJourneyFilter}>
              <Group mb="xs">  
                <Text mt={4} mr={10}>Email: </Text>              
                <Input value={emailValue} onChange={(e) => setEmailValue(e.target.value)} />
                <Text mt={4} mr={10}>Select Conversion Goal: </Text>
                <Select
                  data={goalListData}
                  value={selectedCustomerJourneyGoalValue}
                  maxDropdownHeight={280}
                  onChange={handleCustomerJourneyGoalChange}
                />
                <Button type="submit" size="sm" w={80}>
                  Filter
                </Button>
              </Group>
            </form>
            <Table striped highlightOnHover withBorder my={5} fontSize="xs" key={"goalIndex"}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Sales Amount</th>
                  <th>Email</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>{listRows}</tbody>
            </Table>
            {totalPages > 1 ? (
              <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange}>
                <Group spacing={5} position="center">
                  <Pagination.First />
                  <Pagination.Previous />
                  <Pagination.Items />
                  <Pagination.Next />
                  <Pagination.Last />
                </Group>
              </Pagination.Root>
            ) : ''}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      <Modal.Root opened={isCustomerJourneyViewModalOpen} onClose={handleCustomerJourneyViewCloseModal} size="80%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>{"Customer Journey - "}{campaignName} <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body h={480}>
            <Group mb="xs" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text mt={4}>Customer Journey Report</Text>
              <Button type="submit" size="sm" w={80} onClick={handleCustomerJourneyViewCloseModal}>
                Back
              </Button>
            </Group>

            <Table striped highlightOnHover withBorder my={5} fontSize="xs" key={"goalIndex"} withColumnBorders>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Page Details</th>
                  <th>Customer Email</th>
                  <th>Traffic Source</th>
                  <th>Sales Amount</th>
                  <th>Attribution</th>
                  <th>Behavioral Details</th>
                  <th>Device</th>
                  <th>Referral Source</th>
                  <th>V.No.</th>
                </tr>
              </thead>
              <tbody>{JourneyViewList}</tbody>
            </Table>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}