import { Group, Text, Button, Flex, Container, Card, Input, Select, Modal, Textarea, CopyButton } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { useNavigate, useLocation } from "react-router-dom";
import { VisiLoader, ErrorModal, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import InsightsApi from "../../api/InsightsApi";

export function CreateScrollmap() {
  useDocumentTitle("Visiopt - Insights");
  const navigate = useNavigate();
  const location = useLocation();
  const ScrollmapId = location.state?.scroll_map_id;
  const [editedScrollmapId, setEditedScrollmapId] = useState(ScrollmapId);
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [scrollMapName, setScrollMapName] = useState('');
  const [selectedFunnel, setSelectedFunnel] = useState('');
  const [selectedPage, setSelectedPage] = useState('');
  const [funnelList, setFunnelList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [isVisismartScrollmapCodeModalOpen, setIsVisismartScrollmapCodeModalOpen] = useState(false);
  const [visiSmartCodePageURL, setVisiSmartCodePageURL] = useState('');
  const [visiSmartCode, setVisiSmartCode] = useState('');
  const [isVisiLoaderVisible, setIsVisiLoaderVisible] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const createScrollIconRef = useRef(null);
  const visiSmartIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const fetchFunnelList = () => {
    if(selectedMemberWebsite !== '') {
      setIsVisiLoaderVisible(true);
      InsightsApi.getfunnellist({
        system_website_id: selectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setFunnelList(data.data);
        }
        setIsVisiLoaderVisible(false);
      })
      .catch(error => {
        console.error("API Error:", error);
        setIsVisiLoaderVisible(false);
      });
    }    
  };

  const fetchPageList = (Value:any) => {
    if(Value !== '') {
      setIsVisiLoaderVisible(true);
      InsightsApi.getpagelistbyfunnelid({
        funnel_id: Value
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setPageList(data.data);
          setIsVisiLoaderVisible(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setIsVisiLoaderVisible(false);
      });
    }    
  };

  const handleSelectedFunnel = (Value:any) => {
    setSelectedFunnel(Value);
    fetchPageList(Value);
  };

  const handleSelectedPage = (value:any) => {
    setSelectedPage(value);
    if(value !== '') {
      InsightsApi.scrollmappagecheck({
        scrollmap_page_id: value
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 0){
          setIsErrorModalOpen(true);
          setErrorMessage(data.message);
          setShowErrorHeader(false);
          setSelectedPage('');
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  };

  const handleSaveScrollMap = (value:any) => {
    let dataObject = {};
    if(value === 'save') {
      dataObject = {
        scrollmap_name: scrollMapName,
        system_website_id: selectedMemberWebsite,
        funnel_id: selectedFunnel,
        page_id: selectedPage
      };
    }else if(value === 'update') {
      dataObject = {
        scrollmap_name: scrollMapName,
        system_website_id: selectedMemberWebsite,
        scrollmap_id: editedScrollmapId,
        page_id: selectedPage
      };
    } 
    console.log(dataObject);
    setIsVisiLoaderVisible(true);
    InsightsApi.saveupdatescrollmap(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1) {
          setVisiSmartCode(data.data.visissmart_code);
          setVisiSmartCodePageURL(data.data.page_url);
          setIsVisiLoaderVisible(false);
          if(data.data.smart_script_exits === 'no'){
            setIsVisismartScrollmapCodeModalOpen(true);
          }
          if(data.data.smart_script_exits === 'yes'){
            navigate('/scroll-maps');
          }
        }else{
          setIsVisiLoaderVisible(false);
          setIsErrorModalOpen(true);
          setErrorMessage(data.message);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setIsVisiLoaderVisible(false);
      });
  };

  const handleCancel = () => {
    navigate('/scroll-maps');
    setEditedScrollmapId('');
    setScrollMapName('');
    setSelectedFunnel('');
    setSelectedPage('');
  };

  const onVisismartScrollmapCodeClose = () => {
    setIsVisismartScrollmapCodeModalOpen(false);
    navigate('/scroll-maps');
  };

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };

  useEffect(() => {
    fetchFunnelList();
  }, [selectedMemberWebsite]);

  useEffect(() => {
    if(editedScrollmapId && editedScrollmapId !== '') {
      setIsVisiLoaderVisible(true);
      InsightsApi.getsinglescrollmapdetail({
        scrollmap_id: editedScrollmapId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1) {
          setScrollMapName(data.data.scrollmap_name);
          setSelectedFunnel(data.data.funnel_id);
          fetchPageList(data.data.funnel_id);
          setSelectedPage(data.data.page_id);
          setIsVisiLoaderVisible(false);
        }else{
          setIsVisiLoaderVisible(false);
          setIsErrorModalOpen(true);
          setErrorMessage(data.message);
          setShowErrorHeader(true);
        }        
      })
      .catch(error => {
        console.error("API Error:", error);
        setIsVisiLoaderVisible(false);
      });
    }
  }, [editedScrollmapId]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    let iconRef = id === 254 ? createScrollIconRef : visiSmartIconRef;
    if (iconRef.current) {
      const { top, left, width } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: { top: top + scrollOffset, left: left + width + 10 },
      });
    }
  };

  return (
    <>
      <VisiLoader VisiLoaderVisible={isVisiLoaderVisible}/>
      {/*START ERROR MODAL*/}
      {isErrorModalOpen &&
        <ErrorModal
          errorModelOpen={isErrorModalOpen}
          errorMessage={errorMessage}
          showErrorHeader={showErrorHeader}
          onClose={handleErrorModalClose}
        />
      }
      {/*END ERROR MODAL*/}
      {showHelpModal && Object.keys(tooltipPosition).map((key) => (
        <SelectTooltip
          isVisible={showHelpModal}
          setIsVisible={setShowHelpModal}
          id={helpTargetId}
          tooltipPosition={tooltipPosition[key]}
        />
      ))}
      {/*START VISISMARTCODE MODAL*/}
      <Modal.Root opened={isVisismartScrollmapCodeModalOpen} onClose={onVisismartScrollmapCodeClose} size="35%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>VISISMART SCROLLMAP CODE <IconHelp ref={visiSmartIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(200)}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Text weight={500}>Please add the following code into the &lt;head&gt; tag of the following page:</Text>
            <Text weight={500}>{visiSmartCodePageURL}</Text>
            <Textarea mt={10} size="xs" autosize value={visiSmartCode} />
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <CopyButton timeout={5000} value={visiSmartCode}>
                {({ copied, copy }) => (
                  <Button color={copied ? 'teal' : 'blue'} onClick={copy} size="sm">
                    {copied ? 'Copied' : ' Copy'}
                  </Button>
                )}
              </CopyButton>
              <Button ml={430} color="red" onClick={onVisismartScrollmapCodeClose}>Close</Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      {/*END VISISMARTCODE MODAL*/}
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      <Container size="xl">
        <Card shadow="sm" padding="sm" radius="md" withBorder>
          <Group position="apart" mb="xs">
            <Text weight={500}>SCROLL MAPS <IconHelp ref={createScrollIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(254)}/></Text>
          </Group>
          <Flex
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Text w={130} mt={3}>Scroll Map Name:</Text>
            <Input 
              w={250} 
              ml={0}
              mt={5}
              value={scrollMapName} 
              onChange={(e) => setScrollMapName(e.target.value)} 
            />
          </Flex>
          <Flex
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Text w={130} mt={3}>Select Funnel:</Text>
            <Select
              data={[
                { value: '', label: 'Select Funnel', disabled: true }, 
                ...funnelList.map((funnel, index) => ({
                  value: funnel.funnel_id,
                  label: funnel.funnel_name
                }))
              ]}
              mt={5}
              dropdownPosition="bottom"
              withinPortal 
              value={selectedFunnel}
              onChange={handleSelectedFunnel}
              w={250}
              disabled={!!editedScrollmapId}
            />
          </Flex>
          <Flex
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Text w={130} mt={3}>Select Page:</Text>
            <Select
              data={[
                { value: '', label: 'Select Page', disabled: true }, 
                ...pageList.map((page, index) => ({
                  value: page.page_id,
                  label: page.page_name
                }))
              ]}
              mt={5}
              dropdownPosition="bottom"
              withinPortal 
              value={selectedPage}
              onChange={handleSelectedPage}
              w={250}
              disabled={!!editedScrollmapId}
            />
          </Flex>
          <Flex
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            {editedScrollmapId && editedScrollmapId !== '' ? (
                <Button size="sm" w={100} mt={10} ml={5} onClick={() => handleSaveScrollMap('update')}>Update</Button>
              ) : (
                <Button size="sm" w={70} mt={10} ml={5} onClick={() => handleSaveScrollMap('save')}>Save</Button>
            )}
            <Button size="sm" w={80} color="red" mt={10} ml={5} onClick={handleCancel}>
               Cancel
            </Button>
          </Flex>
        </Card>
      </Container>
    </>
  );
}