import { Group, Text, TextInput, Button, Box, Space } from '@mantine/core';
import { IconHelp, IconX, IconCheck } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { VisiLoader, SelectTooltip } from "../../GeneralComponent/GeneralComponent";
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import './EmailIntegration.css';
import SettingsApi from "../../../api/SettingsApi";

export function EmailIntegration() {

	const navigate = useNavigate();

	const [activeEmail, setActiveEmail] = useState("email-integration");
	const [activeId, setActiveId] = useState(null);
	const [activeEIClass, setActiveEIClass] = useState([]);
	const [activecampaign_status, setactivecampaign_status] = useState('connect');
	const [infusionsoft_status, setinfusionsoft_status] = useState('connect');
	const [getresponse_status, setgetresponse_status] = useState('connect');
	const [mailchimp_status, setmailchimp_status] = useState('connect');
	const [drip_status, setdrip_status] = useState('connect');
	const [icontact_status, seticontact_status] = useState('connect');
	const [ontraport_status, setontraport_status] = useState('connect');

	const [IntegrationLoader, setIntegrationLoader] = useState(false);
  const emailIconref = useRef(null);
  const activeCampaignIconRef = useRef(null);
  const infusionSoftIconRef = useRef(null);
  const ontraportIconRef = useRef(null);
  const getresponseIconRef = useRef(null);
  const mailchimpIconRef = useRef(null);
  const dripIconRef = useRef(null);
  const icontactIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});

	const handleEmailIntegrationAction = (id:any) => {
		if(id === 'cancel'){
			setActiveId(id);
			setActiveEmail("email-integration");
		} else {
			setActiveId(id);
			setActiveEmail('');
		}
	};

	const [emailIntegrationData, setEmailIntegrationData] = useState([
	  { id: 1, action_name: 'activecampaign' ,img: 'img/email-provider-logos/active-campaign.png', name:'Active Campaign' },
	  { id: 2, action_name: 'infusionsoft' ,img: 'img/email-provider-logos/infusion-soft.png', name:'InfusionSoft' },
	  { id: 3, action_name: 'ontraport' ,img: 'img/email-provider-logos/ontraport.png', name:'Ontraport' },
	  { id: 4, action_name: 'getresponse' ,img: 'img/email-provider-logos/getresponse.png', name:'GetResponse' },
	  { id: 5, action_name: 'mailchimp' ,img: 'img/email-provider-logos/mailchimp.png', name:'MailChimp' },
	  { id: 6, action_name: 'drip' ,img: 'img/email-provider-logos/drip.png', name:'Drip' },
	  { id: 7, action_name: 'icontact' ,img: 'img/email-provider-logos/icontact.png', name:'iContact' },
	]);

	useEffect(() => {
    setIntegrationLoader(true);
    SettingsApi.getemailintegrationconnectedlist()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	let activeIntegrations = [];
      	if(data.data.activecampaign && data.data.activecampaign === 'connected'){
      		activeIntegrations.push('activecampaign');
      	}
      	if(data.data.infusionsoft && data.data.infusionsoft === 'connected'){
      		activeIntegrations.push('infusionsoft');
      	}
      	if(data.data.getresponse && data.data.getresponse === 'connected'){
      		activeIntegrations.push('getresponse');
      	}
      	if(data.data.mailchimp && data.data.mailchimp === 'connected'){
      		activeIntegrations.push('mailchimp');
      	}
      	if(data.data.drip && data.data.drip === 'connected'){
      		activeIntegrations.push('drip');
      	}
      	if(data.data.icontact && data.data.icontact === 'connected'){
      		activeIntegrations.push('icontact');
      	}
      	if(data.data.ontraport && data.data.ontraport === 'connected'){
      		activeIntegrations.push('ontraport');
      	}
      	setActiveEIClass(activeIntegrations);
        setIntegrationLoader(false);
      }
      setIntegrationLoader(false);
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
    setIntegrationLoader(true);
    SettingsApi.getandsaveemailintegrationsettings({
    	email_integration_type : "activecampaign",
    	email_action : "get_details",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.api_key){
      		activecampaign_form.setFieldValue('api_key', data.data.api_key);
      	}
      	if(data.data.api_url){
      		activecampaign_form.setFieldValue('api_url', data.data.api_url);
      	}
      	if(data.data.status){
      		setactivecampaign_status(data.data.status);
      	}
      }
      setIntegrationLoader(false);
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
    setIntegrationLoader(true);
    SettingsApi.getandsaveemailintegrationsettings({
    	email_integration_type : "infusionsoft",
    	email_action : "get_details",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.api_key){
      		infusionsoft_form.setFieldValue('api_key', data.data.api_key);
      	}
      	if(data.data.account_name){
      		infusionsoft_form.setFieldValue('account_name', data.data.account_name);
      	}
      	if(data.data.status){
      		setinfusionsoft_status(data.data.status);
      	}
      }
      setIntegrationLoader(false);
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
    setIntegrationLoader(true);
    SettingsApi.getandsaveemailintegrationsettings({
    	email_integration_type : "getresponse",
    	email_action : "get_details",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.api_key){
      		getresponse_form.setFieldValue('api_key', data.data.api_key);
      	}
      	if(data.data.status){
      		setgetresponse_status(data.data.status);
      	}
      }
      setIntegrationLoader(false);
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
    setIntegrationLoader(true);
    SettingsApi.getandsaveemailintegrationsettings({
    	email_integration_type : "mailchimp",
    	email_action : "get_details",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.api_key){
      		mailchimp_form.setFieldValue('api_key', data.data.api_key);
      	}
      	if(data.data.status){
      		setmailchimp_status(data.data.status);
      	}
      }
      setIntegrationLoader(false);
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
    setIntegrationLoader(true);
    SettingsApi.getandsaveemailintegrationsettings({
    	email_integration_type : "drip",
    	email_action : "get_details",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.api_token){
      		drip_form.setFieldValue('api_token', data.data.api_token);
      	}
      	if(data.data.account_id){
      		drip_form.setFieldValue('account_id', data.data.account_id);
      	}
      	if(data.data.status){
      		setdrip_status(data.data.status);
      	}
      }
      setIntegrationLoader(false);
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
    setIntegrationLoader(true);
    SettingsApi.getandsaveemailintegrationsettings({
    	email_integration_type : "icontact",
    	email_action : "get_details",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.username){
      		icontact_form.setFieldValue('username', data.data.username);
      	}
      	if(data.data.api_id){
      		icontact_form.setFieldValue('api_id', data.data.api_id);
      	}
      	if(data.data.api_password){
      		icontact_form.setFieldValue('api_password', data.data.api_password);
      	}
      	if(data.data.status){
      		seticontact_status(data.data.status);
      	}
      }
      setIntegrationLoader(false);
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
    setIntegrationLoader(true);
    SettingsApi.getandsaveemailintegrationsettings({
    	email_integration_type : "ontraport",
    	email_action : "get_details",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.app_id){
      		ontraport_form.setFieldValue('app_id', data.data.app_id);
      	}
      	if(data.data.api_key){
      		ontraport_form.setFieldValue('api_key', data.data.api_key);
      	}
      	if(data.data.status){
      		setontraport_status(data.data.status);
      	}
      }
      setIntegrationLoader(false);
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
  }, []);

	const activecampaign_form = useForm({
    initialValues: { api_url: '', api_key: '' },
    validate: {
      api_url: (val) => (val === '' ? 'API URL is Required' : null),
      api_key: (val) => (val === '' ? 'API Key is Required' : null),
    },
  });

	const infusionsoft_form = useForm({
    initialValues: { account_name: '', api_key: '' },
    validate: {
      account_name: (val) => (val === '' ? 'Account Name is Required' : null),
      api_key: (val) => (val === '' ? 'API Key is Required' : null),
    },
  });

  const getresponse_form = useForm({
    initialValues: { api_key: '' },
    validate: {
      api_key: (val) => (val === '' ? 'API Key is Required' : null),
    },
  });

  const mailchimp_form = useForm({
    initialValues: { api_key: '' },
    validate: {
      api_key: (val) => (val === '' ? 'API Key is Required' : null),
    },
  });

  const drip_form = useForm({
    initialValues: { api_token: '', account_id: '' },
    validate: {
      api_token: (val) => (val === '' ? 'API Key is Required' : null),
      account_id: (val) => (val === '' ? 'Account Id is Required' : null),
    },
  });

  const icontact_form = useForm({
    initialValues: { username: '', api_id: '', api_password: '' },
    validate: {
      username: (val) => (val === '' ? 'API Key is Required' : null),
      api_id: (val) => (val === '' ? 'Account Id is Required' : null),
      api_password: (val) => (val === '' ? 'Account Id is Required' : null),
    },
  });

  const ontraport_form = useForm({
    initialValues: { app_id: '', api_key: '' },
    validate: {
      app_id: (val) => (val === '' ? 'API Key is Required' : null),
      api_key: (val) => (val === '' ? 'Account Id is Required' : null),
    },
  });

	const handleActivecampaignSubmit = async (formData:any) => {
		setIntegrationLoader(true);
		SettingsApi.getandsaveemailintegrationsettings({
      email_integration_type : 'activecampaign',
      email_action : 'save_details',
      status : activecampaign_status,
      api_url : formData.api_url,
      api_key : formData.api_key,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setIntegrationLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActiveEmail("email-integration");
        if(activecampaign_status === 'connect'){
        	if(!activeEIClass.includes('activecampaign')){
        		setActiveEIClass(oldArray => [...oldArray, 'activecampaign']);
        		setactivecampaign_status('disconnect');
        	}
        }else if(activecampaign_status === 'disconnect'){
        	if(activeEIClass.includes('activecampaign')){
        		setActiveEIClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'activecampaign');
				    });
				    setactivecampaign_status('connect');
				    activecampaign_form.setFieldValue('api_key', "");
				    activecampaign_form.setFieldValue('api_url', "");
        	}
        }
      }else{
        setIntegrationLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
	}

	const handleinfusionsoftSubmit = async (formData:any) => {
		setIntegrationLoader(true);
		SettingsApi.getandsaveemailintegrationsettings({
      email_integration_type : 'infusionsoft',
      email_action : 'save_details',
      status : infusionsoft_status,
      account_name : formData.account_name,
      api_key : formData.api_key,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setIntegrationLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActiveEmail("email-integration");
        if(infusionsoft_status === 'connect'){
        	if(!activeEIClass.includes('infusionsoft')){
        		setActiveEIClass(oldArray => [...oldArray, 'infusionsoft']);
        		setinfusionsoft_status('disconnect');
        	}
        }else if(infusionsoft_status === 'disconnect'){
        	if(activeEIClass.includes('infusionsoft')){
        		setActiveEIClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'infusionsoft');
				    });
				    setinfusionsoft_status('connect');
				    infusionsoft_form.setFieldValue('api_key', "");
				    infusionsoft_form.setFieldValue('account_name', "");
        	}
        }
      }else{
        setIntegrationLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
	}

	const handlegetresponseSubmit = async (formData:any) => {
		setIntegrationLoader(true);
		SettingsApi.getandsaveemailintegrationsettings({
      email_integration_type : 'getresponse',
      email_action : 'save_details',
      status : getresponse_status,
      api_key : formData.api_key,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setIntegrationLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActiveEmail("email-integration");
        if(getresponse_status === 'connect'){
        	if(!activeEIClass.includes('getresponse')){
        		setActiveEIClass(oldArray => [...oldArray, 'getresponse']);
        		setgetresponse_status('disconnect');
        	}
        }else if(getresponse_status === 'disconnect'){
        	if(activeEIClass.includes('getresponse')){
        		setActiveEIClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'getresponse');
				    });
				    setgetresponse_status('connect');
				    getresponse_form.setFieldValue('api_key', "");
        	}
        }
      }else{
        setIntegrationLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
	}

	const handlemailchimpSubmit = async (formData:any) => {
		setIntegrationLoader(true);
		SettingsApi.getandsaveemailintegrationsettings({
      email_integration_type : 'mailchimp',
      email_action : 'save_details',
      status : mailchimp_status,
      api_key : formData.api_key,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setIntegrationLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActiveEmail("email-integration");
        if(mailchimp_status === 'connect'){
        	if(!activeEIClass.includes('mailchimp')){
        		setActiveEIClass(oldArray => [...oldArray, 'mailchimp']);
        		setmailchimp_status('disconnect');
        	}
        }else if(mailchimp_status === 'disconnect'){
        	if(activeEIClass.includes('mailchimp')){
        		setActiveEIClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'mailchimp');
				    });
				    setmailchimp_status('connect');
				    mailchimp_form.setFieldValue('api_key', "");
        	}
        }
      }else{
        setIntegrationLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
	}

	const handledripSubmit = async (formData:any) => {
		setIntegrationLoader(true);
		SettingsApi.getandsaveemailintegrationsettings({
      email_integration_type : 'drip',
      email_action : 'save_details',
      status : drip_status,
      api_token : formData.api_token,
      account_id : formData.account_id,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setIntegrationLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActiveEmail("email-integration");
        if(drip_status === 'connect'){
        	if(!activeEIClass.includes('drip')){
        		setActiveEIClass(oldArray => [...oldArray, 'drip']);
        		setdrip_status('disconnect');
        	}
        }else if(drip_status === 'disconnect'){
        	if(activeEIClass.includes('drip')){
        		setActiveEIClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'drip');
				    });
				    setdrip_status('connect');
				    drip_form.setFieldValue('api_token', "");
				    drip_form.setFieldValue('account_id', "");
        	}
        }
      }else{
        setIntegrationLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
	}

	const handleicontactSubmit = async (formData:any) => {
		setIntegrationLoader(true);
		SettingsApi.getandsaveemailintegrationsettings({
      email_integration_type : 'icontact',
      email_action : 'save_details',
      status : icontact_status,
      username : formData.username,
      api_id : formData.api_id,
      api_password : formData.api_password,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setIntegrationLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActiveEmail("email-integration");
        if(icontact_status === 'connect'){
        	if(!activeEIClass.includes('icontact')){
        		setActiveEIClass(oldArray => [...oldArray, 'icontact']);
        		seticontact_status('disconnect');
        	}
        }else if(icontact_status === 'disconnect'){
        	if(activeEIClass.includes('icontact')){
        		setActiveEIClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'icontact');
				    });
				    seticontact_status('connect');
				    icontact_form.setFieldValue('username', "");
				    icontact_form.setFieldValue('api_id', "");
				    icontact_form.setFieldValue('api_password', "");
        	}
        }
      }else{
        setIntegrationLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
	}

	const handleontraportSubmit = async (formData:any) => {
		setIntegrationLoader(true);
		SettingsApi.getandsaveemailintegrationsettings({
      email_integration_type : 'ontraport',
      email_action : 'save_details',
      status : ontraport_status,
      app_id : formData.app_id,
      api_key : formData.api_key,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setIntegrationLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setActiveId("cancel");
        setActiveEmail("email-integration");
        if(ontraport_status === 'connect'){
        	if(!activeEIClass.includes('ontraport')){
        		setActiveEIClass(oldArray => [...oldArray, 'ontraport']);
        		setontraport_status('disconnect');
        	}
        }else if(ontraport_status === 'disconnect'){
        	if(activeEIClass.includes('ontraport')){
        		setActiveEIClass(oldValues => {
				      return oldValues.filter(Value => Value !== 'ontraport');
				    });
				    setontraport_status('connect');
				    ontraport_form.setFieldValue('app_id', "");
				    ontraport_form.setFieldValue('api_key', "");
        	}
        }
      }else{
        setIntegrationLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setIntegrationLoader(false);
    });
	}

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRefMapping = {
      169: emailIconref,
      235: activeCampaignIconRef,
      236: infusionSoftIconRef,
      241: ontraportIconRef,
      237: getresponseIconRef,
      238: mailchimpIconRef,
      239: dripIconRef,
      240: icontactIconRef
    };
    let iconRef = iconRefMapping[id];
    if (iconRef.current) {
      const { top, left, width } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: { top: top + scrollOffset, left: left + width + 10 },
      });
    }
  };

  return (
    <>
    	<VisiLoader VisiLoaderVisible={IntegrationLoader} />
      {showHelpModal &&
        ReactDOM.createPortal(
          <div>
            {Object.keys(tooltipPosition).map((key) => (
              <SelectTooltip
                isVisible={showHelpModal}
                setIsVisible={setShowHelpModal}
                id={helpTargetId}
                tooltipPosition={tooltipPosition[key]} 
              />
            ))}
          </div>,
        document.body
      )}
    	<div style={{ display: activeEmail === "email-integration" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500}>EMAIL INTEGRATION <IconHelp ref={emailIconref} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(169)}/></Text>
	      </Group>
	      <Text mb={15}>Click on the desired email provider to see the connection options.</Text>
	      <Group spacing="xs">
        	{emailIntegrationData.map((item,index) => (
	  	      <div key={index} className={activeEIClass.includes(item.action_name) ? "email-thumb active-email" : "email-thumb"} onClick={() => handleEmailIntegrationAction(item.action_name)}>
	  	      	<div className="thumb-holder">
	  	      		<img src={item.img} />
	  	      	</div>
	  	      	<span>{item.name}</span>
	  	      </div>
  	      ))}
	      </Group>
	    </div>
      <div style={{ display: activeId === "activecampaign" ? "block" : "none"}}>
      	<form onSubmit={activecampaign_form.onSubmit((formData) => { handleActivecampaignSubmit(formData); })}>
		      <Group position="apart" mb="xs">
		        <Text weight={500}>ACTIVE CAMPAIGN <IconHelp ref={activeCampaignIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(235)}/></Text>
		      </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>API URL:</Text>
	  	      <TextInput w={250} ml={0} {...activecampaign_form.getInputProps('api_url')} value={activecampaign_form.values.api_url} onChange={(event) => activecampaign_form.setFieldValue('api_url', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>API Key:</Text>
	  	      <TextInput w={250} ml={0} {...activecampaign_form.getInputProps('api_key')} value={activecampaign_form.values.api_key} onChange={(event) => activecampaign_form.setFieldValue('api_key', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Button type="submit" size="sm" mt={10}>
		  	    {activecampaign_status === "connect" ? "Connect" : "Disconnect"}
		  	    </Button>
		  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" mt={10} onClick={() => handleEmailIntegrationAction('cancel')}>
		  	      Cancel
		  	    </Button>
	  	    </Group>
	  	    <Group mb={10}>
	  	      <Box 
	  	      	mt={10}
	  	     		sx={(theme) => ({
	              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
	              padding: "10px",
	              borderRadius: "4px"
	            })}
	          >
		  	      <Text weight={500}>Instructions</Text>
		  	      <Space h="xs" />
		  	      <Text>In ActiveCampaign, under "My Settings", click on "Developer" Option.</Text>
		  	      <Space h="xs" />
		  	      <Text>Under the "API access" section find the API Key and API URL.</Text>
		  	      <Space h="xs" />
		  	      <Text>copy and paste them into the areas above.</Text>
	  	    	</Box>
	        </Group>
        </form>
	    </div>
      <div style={{ display: activeId === "infusionsoft" ? "block" : "none"}}>
      	<form onSubmit={infusionsoft_form.onSubmit((formData) => { handleinfusionsoftSubmit(formData); })}>
		      <Group position="apart" mb="xs">
		        <Text weight={500}>INFUSIONSOFT <IconHelp ref={infusionSoftIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(236)}/></Text>
		      </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Account Name:</Text>
	  	      <TextInput w={250} ml={0} {...infusionsoft_form.getInputProps('account_name')} value={infusionsoft_form.values.account_name} onChange={(event) => infusionsoft_form.setFieldValue('account_name', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>API Key:</Text>
	  	    	<TextInput w={250} ml={0} {...infusionsoft_form.getInputProps('api_key')} value={infusionsoft_form.values.api_key} onChange={(event) => infusionsoft_form.setFieldValue('api_key', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Button type="submit" size="sm" mt={10}>
		  	    	{infusionsoft_status === "connect" ? "Connect" : "Disconnect"}
		  	    </Button>
		  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" mt={10} onClick={() => handleEmailIntegrationAction('cancel')}>
		  	      Cancel
		  	    </Button>
	  	    </Group>
  	    </form>
	    </div>
      <div style={{ display: activeId === "ontraport" ? "block" : "none"}}>
      	<form onSubmit={ontraport_form.onSubmit((formData) => { handleontraportSubmit(formData); })}>
		      <Group position="apart" mb="xs">
		        <Text weight={500}>ONTRAPORT <IconHelp ref={ontraportIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(241)}/></Text>
		      </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>API ID:</Text>
	  	    	<TextInput w={250} ml={0} {...ontraport_form.getInputProps('app_id')} value={ontraport_form.values.app_id} onChange={(event) => ontraport_form.setFieldValue('app_id', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>API Key:</Text>
	  	      <TextInput w={250} ml={0} {...ontraport_form.getInputProps('api_key')} value={ontraport_form.values.api_key} onChange={(event) => ontraport_form.setFieldValue('api_key', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Button type="submit" size="sm" mt={10}>
		  	    	{ontraport_status === "connect" ? "Connect" : "Disconnect"}
		  	    </Button>
		  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" mt={10} onClick={() => handleEmailIntegrationAction('cancel')}>
		  	      Cancel
		  	    </Button>
	  	    </Group>
  	    </form>
	    </div>
      <div style={{ display: activeId === "getresponse" ? "block" : "none"}}>
      	<form onSubmit={getresponse_form.onSubmit((formData) => { handlegetresponseSubmit(formData); })}>
		      <Group position="apart" mb="xs">
		        <Text weight={500}>GETRESPONSE <IconHelp ref={getresponseIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(237)}/></Text>
		      </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>API Key:</Text>
	  	      <TextInput w={250} ml={0} {...getresponse_form.getInputProps('api_key')} value={getresponse_form.values.api_key} onChange={(event) => getresponse_form.setFieldValue('api_key', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Button type="submit" size="sm" mt={10}>
		  	    	{getresponse_status === "connect" ? "Connect" : "Disconnect"}
		  	    </Button>
		  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" mt={10} onClick={() => handleEmailIntegrationAction('cancel')}>
		  	      Cancel
		  	    </Button>
	  	    </Group>
	  	    <Group mb={10}>
	  	      <Box 
	  	      	mt={10}
	  	     		sx={(theme) => ({
	              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
	              padding: "10px",
	              borderRadius: "4px"
	            })}
	          >
	  	      <Text weight={500}>Instructions</Text>
	  	      <Space h="xs" />
	  	      <Text>In GetResponse, navigateto the "Account" tab and select "Integrations".</Text>
	  	      <Space h="xs" />
	  	      <Text>On the Integration screen, choose the "API & Oauth" tab.</Text>
	  	      <Space h="xs" />
	  	      <Text>Copy your default API Key and paste it in the field above.</Text>
	  	    	</Box>
	        </Group>
        </form>
	    </div>
      <div style={{ display: activeId === "mailchimp" ? "block" : "none"}}>
      	<form onSubmit={mailchimp_form.onSubmit((formData) => { handlemailchimpSubmit(formData); })}>
		      <Group position="apart" mb="xs">
		        <Text weight={500}>MAILCHIMP <IconHelp ref={mailchimpIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(238)}/></Text>
		      </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>API Key:</Text>
	  	      <TextInput w={250} ml={0} {...mailchimp_form.getInputProps('api_key')} value={mailchimp_form.values.api_key} onChange={(event) => mailchimp_form.setFieldValue('api_key', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Button type="submit" size="sm" mt={10}>
		  	    	{mailchimp_status === "connect" ? "Connect" : "Disconnect"}
		  	    </Button>
		  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" mt={10} onClick={() => handleEmailIntegrationAction('cancel')}>
		  	      Cancel
		  	    </Button>
	  	    </Group>
	  	    <Group mb={10}>
	  	      <Box 
	  	      	mt={10}
	  	     		sx={(theme) => ({
	              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
	              padding: "10px",
	              borderRadius: "4px"
	            })}
	          >
		  	      <Text weight={500}>Instructions</Text>
		  	      <Space h="xs" />
		  	      <Text>In MailChimp, click your profile name to expand the "Account Panel", and choose "Account".</Text>
		  	      <Space h="xs" />
		  	      <Text>Click the "Extras" drop-down menu and choose API keys.</Text>
		  	      <Space h="xs" />
		  	      <Text>Copy and existing API key or click the "Create a Key" button.</Text>
	  	    	</Box>
	        </Group>
        </form>
	    </div>
      <div style={{ display: activeId === "drip" ? "block" : "none"}}>
      	<form onSubmit={drip_form.onSubmit((formData) => { handledripSubmit(formData); })}>
		      <Group position="apart" mb="xs">
		        <Text weight={500}>DRIP <IconHelp ref={dripIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(239)}/></Text>
		      </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>API Token:</Text>
	  	      <TextInput w={250} ml={0} {...drip_form.getInputProps('api_token')} value={drip_form.values.api_token} onChange={(event) => drip_form.setFieldValue('api_token', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Account ID:</Text>
	  	      <TextInput w={250} ml={0} {...drip_form.getInputProps('account_id')} value={drip_form.values.account_id} onChange={(event) => drip_form.setFieldValue('account_id', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Button type="submit" size="sm" mt={10}>
		  	    	{drip_status === "connect" ? "Connect" : "Disconnect"}
		  	    </Button>
		  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" mt={10} onClick={() => handleEmailIntegrationAction('cancel')}>
		  	      Cancel
		  	    </Button>
	  	    </Group>
  	    </form>
	    </div>
      <div style={{ display: activeId === "icontact" ? "block" : "none"}}>
      	<form onSubmit={icontact_form.onSubmit((formData) => { handleicontactSubmit(formData); })}>
		      <Group position="apart" mb="xs">
		        <Text weight={500}>ICONTACT <IconHelp ref={icontactIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(240)}/></Text>
		      </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Username:</Text>
	  	      <TextInput w={250} ml={0} {...icontact_form.getInputProps('username')} value={icontact_form.values.username} onChange={(event) => icontact_form.setFieldValue('username', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>API ID:</Text>
	  	      <TextInput w={250} ml={0} {...icontact_form.getInputProps('api_id')} value={icontact_form.values.api_id} onChange={(event) => icontact_form.setFieldValue('api_id', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
	  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>API Password:</Text>
	  	      <TextInput w={250} ml={0} {...icontact_form.getInputProps('api_password')} value={icontact_form.values.api_password} onChange={(event) => icontact_form.setFieldValue('api_password', event.currentTarget.value)}/>
	  	    </Group>
	  	    <Group spacing="xs" mb={10}>
		  	    <Button type="submit" size="sm" mt={10}>
		  	    	{icontact_status === "connect" ? "Connect" : "Disconnect"}
		  	    </Button>
		  	    <Button type="button" style={{backgroundColor: "red"}} size="sm" mt={10} onClick={() => handleEmailIntegrationAction('cancel')}>
		  	      Cancel
		  	    </Button>
	  	    </Group>
  	    </form>
	    </div>
    </>
  );
}