import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Select, Button, Group, Text, Accordion, Input, Space } from '@mantine/core';
import { IconX, IconHelp } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { SelectTooltip, SelectFunnelList } from "../../components/GeneralComponent/GeneralComponent";
import CampaignApi from "../../api/CampaignApi";
import classes from '../../pages/DashBoard/DashBoard.module.css';

export function Step1({ selectedMemberWebsite, campaignId, setCampaignId, campaign_id, SelectedFunnelValue, setSelectedFunnelValue, campaignLPPageValue, setCampaignLPPage, campaignName, setCampaignName, step1Disabled, step1FieldDisabled, setStep2Disabled, setStep3Disabled, setStep4Disabled, setStepValue, campaignLoader }:any) {
  const navigate = useNavigate();
  const [campaignLPPageList, setCampaignLPPageList] = useState([]);
  const step1IconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleSelectFunnelListComponentChange = (Value:any) => {
    if(Value !== ""){
      campaignLoader(true);
      CampaignApi.checkexistingcampaign({
        funnel_id : Value,
        c_id : campaignId || campaign_id
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            campaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setSelectedFunnelValue(Value);
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
        campaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        campaignLoader(false);
      });
    }
  };

  useEffect(() => {
    if(SelectedFunnelValue !== "" && selectedMemberWebsite !== ""){
      campaignLoader(true);
      CampaignApi.getcampaignpagebyfunnelid({
        funnel_id : SelectedFunnelValue,
        system_website_id : selectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            campaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1 && data.data && data.data.length > 0){
          setCampaignLPPageList(data.data);
          // setCampaignLPPage('');
        }else{
          setCampaignLPPageList([]);
        }
        campaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        campaignLoader(false);
      });
    }
  }, [SelectedFunnelValue]);

  const handleCampaignLPPageChange = (value:any) => {
    if(value !== ""){
      campaignLoader(true);
      CampaignApi.checkexistingcampaignpage({
        campaign_page_id : value,
        c_id : campaignId || campaign_id
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            campaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setCampaignLPPage(value);
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
        campaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        campaignLoader(false);
      });
    }
  }

  const submitStep1 = () => {
    if(SelectedFunnelValue === "" || SelectedFunnelValue === null){
      notifications.show({
        color: 'red',
        icon: <IconX size="1.1rem" />,
        title: 'Error',
        message: "Please Select a Funnel",
      });
    }else if(campaignName === "" || campaignName === null){
      notifications.show({
        color: 'red',
        icon: <IconX size="1.1rem" />,
        title: 'Error',
        message: "Please Enter Campaign Name",
      });
    }else if(campaignLPPageValue === "" || campaignLPPageValue === null){
      notifications.show({
        color: 'red',
        icon: <IconX size="1.1rem" />,
        title: 'Error',
        message: "Please Select Campaign Page",
      });
    }else{
      campaignLoader(true);
      CampaignApi.addcampaignstepfirst({
        funnel_id : SelectedFunnelValue,
        campaign_name : campaignName,
        campaign_page_id : campaignLPPageValue,
        system_website_id : selectedMemberWebsite,
        c_id : campaign_id
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            campaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setStep2Disabled(false);
          setStep3Disabled(false);
          setStep4Disabled(false);
          setStepValue('step-2');
          setCampaignId(data.data.campaign_id);
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
        campaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        campaignLoader(false);
      });
    }
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (step1IconRef.current) {
      const { top, left, width } = step1IconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
    {showHelpModal &&
      ReactDOM.createPortal(
        <div style={{ zIndex: 9999 }}>
          <SelectTooltip
            isVisible={showHelpModal}
            setIsVisible={setShowHelpModal}
            id={helpTargetId}
            tooltipPosition={tooltipPosition}
          />
        </div>,
      document.body
    )}
      <Accordion.Item className={classes.item} value="step-1">
        <Accordion.Control disabled={step1Disabled}><Text component="span" color="red" weight={500}>Step 1</Text><Text component="span" weight={500}> - Select Campaign Page </Text><IconHelp ref={step1IconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(313)}/></Accordion.Control>
        <Accordion.Panel>
          <Group spacing="xs" mb={5}>
            <Text mr={20} weight={500}>Select a Funnel:</Text>
            {step1FieldDisabled === true ? (
              <SelectFunnelList 
                updated_time={0} 
                Value={SelectedFunnelValue} 
                OnChange={handleSelectFunnelListComponentChange} 
                MemberWebsiteId={selectedMemberWebsite} 
                Width={250} 
                IsSelected={false}
                IsFieldDisabled={true}
              />
            ) : ( 
              <SelectFunnelList 
                updated_time={0} 
                Value={SelectedFunnelValue} 
                OnChange={handleSelectFunnelListComponentChange} 
                MemberWebsiteId={selectedMemberWebsite} 
                Width={250} 
                IsSelected={false}
                IsFieldDisabled={false}
              />
            )}
          </Group>
          <Group spacing="xs" mb={5}>
            <Text mr={10} weight={500}>Campaign Name:</Text>
            <Input w={250} value={campaignName} onChange={(e) => setCampaignName(e.target.value)}/>
          </Group>
          <Group spacing="xs" mb={5}>
            <Text mr={17} weight={500}>Campaign Page:</Text>
            {step1FieldDisabled === true ? (
              <Select
                data={campaignLPPageList.map(page => ({
                  value: page.page_id,
                  label: page.title
                }))}
                value={campaignLPPageValue}
                name="landing_page"
                onChange={handleCampaignLPPageChange}
                w={250}
                placeholder="Select Campaign Page"
                disabled={true}
              />
            ) : (
              <Select
                data={campaignLPPageList.map(page => ({
                  value: page.page_id,
                  label: page.title
                }))}
                value={campaignLPPageValue}
                name="landing_page"
                onChange={handleCampaignLPPageChange}
                w={250}
                placeholder="Select Campaign Page"
              />
            )}
          </Group>
          <Text weight={500}>All goal calculations will be based upon unique visits to this page.</Text>
          <Space h="md" />
          <Text weight={500}>For example: Conversion rates are calculated by dividing the number of conversions (actions) by the total number of uniques. Conversions / Uniques</Text>
          <Space h="md" />
          <Text weight={500}>The most common choice is the first page in your funnel but there are times when you want to choose a different page in your funnel for these calculations.</Text>
          <Button type="button" mt={10} onClick={() => submitStep1()}>Continue</Button>
        </Accordion.Panel>
      </Accordion.Item>
    </>
  );
}