import React from 'react';
import { Image } from '@mantine/core';

export function GridPage({
  SelectedPageCreateTests,
  SelectedThumbnail,
  SelectedThumbnailChange,
  SelectedDraftThumbnail,
  SelectedDraftThumbnailChange,
  DraftThumbnailIsSelected,
  DraftThumbnailIsSelectedChange,
  ThumbnailIsSelected,
  ThumbnailIsSelectedChange,
  SelectedDraftPageIdChange,
  SelectedDraftTaguchiIdChange
  }:any) {

  const handleDraftThumbnailClick = (pageId,taguchiTestId,funnelId,testsId) => {
    if (DraftThumbnailIsSelected && SelectedDraftThumbnail === pageId) {
      SelectedDraftThumbnailChange(null);
      DraftThumbnailIsSelectedChange(false);
    } else {
      SelectedThumbnailChange(null);  
      SelectedDraftThumbnailChange(pageId);
      DraftThumbnailIsSelectedChange(true);
      SelectedDraftPageIdChange(pageId);
      SelectedDraftTaguchiIdChange(taguchiTestId);
    }
  };

  const handleThumbnailClick = (pageId,taguchiTestId,funnelId,testsId) => {
    if (ThumbnailIsSelected && SelectedThumbnail === pageId) {
      SelectedThumbnailChange(null);
      ThumbnailIsSelectedChange(false);
    } else {
      SelectedThumbnailChange(pageId);
      ThumbnailIsSelectedChange(true);
      SelectedDraftThumbnailChange(null);  
    }
  };

  return (
    <>    
      {SelectedPageCreateTests.map((item, index) => (
        <div className="visi-page" key={index} style={{marginRight:10}} >
          <div className={item.pageClass}
                style={{ cursor: 'pointer', backgroundColor: (item.selected || SelectedThumbnail === item.page_id || SelectedDraftThumbnail === item.page_id) ? '#00b975' : '' }}
                onClick={item.selected ? undefined : () => {
                  if (item.pageClass === "page draft") {
                      handleDraftThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id);
                  } else {
                      handleThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id);
                  }
                }}
              >
            <span title={item.page_url}>
              <span className="page-url">{item.page_title}</span>
            </span>
            <div className="thumbnail" style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.img_url})` }} title={item.page_url}>
              {(item.marge_img !== '' && (
                  <Image
                  id="merge_img"
                  src={item.marge_img}
                  title="This Page is merged with other links"
                />
                ))
              }
              {(item.track_check !== '' && (
                <Image
                  id="track_img"
                  src={item.track_check}
                  title={item.track_check_title}
                />
                ))
              }
            </div>
            <span className="page-title">{item.custom_title}</span>
          </div>
        </div>
      ))} 
    </>
  );
}