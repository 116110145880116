import { ServerAPI } from '../GeneralFunction';

export default {
	login: (data) => {
		let url = "login";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	logout: () => {
		let url = "logout";
		const requestOptions = ServerAPI(url, 'GET');
		  const data = {
             'action': 'remove'
            };
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = process.env.REACT_APP_OLD_VISIOPT_BASE_URL+'php-proxy.php'; 
            form.target = 'myIframe';
            for (const key in data) {
              const input = document.createElement('input');
              input.type = 'hidden';
              input.name = key;
              input.value = data[key];
              form.appendChild(input);
            }
            const iframe = document.createElement('iframe');
            iframe.name = 'myIframe'; 
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            document.body.appendChild(form);
            form.submit();

		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	resetpassword: (data) => {
		let url = "resetpassword";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getmemberlist: (data) => {
		let url = "get_member_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	forgotpassword: (data) => {
		let url = "forgot_password";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
};