import { Modal, Select, Group, Button } from '@mantine/core';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import { useEffect, useState, useRef } from 'react';
import { useForm } from '@mantine/form';
import FunnelApi from "../../api/FunnelApi";
import { notifications } from '@mantine/notifications';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function AddButtonToFunnel({handleAddbuttonFunnelOpenModalProp, selectedMemberWebsite, setUpdatedtime, setSelectedPageId, selectedPageId, sethandleAddbuttonFunnelOpenModalProp}){

	const [addButtonFunnelModalOpen, setAddbuttonFunnelModalOpen] = useState(false);
	const [selectedButtonId, setSelectedButtonId] = useState(null);
	const [buttoninAddButtonSection, setButtoninAddButtonSection] = useState([]);
	const [pageinAddButtonSection, setPageinAddButtonSection] = useState([]);
	const buttonIconRef = useRef(null);
	const [showHelpModal, setShowHelpModal] = useState(false);
	const [helpTargetId, setHelpTargetId] = useState(0);
	const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

	useEffect(() => {
		if(handleAddbuttonFunnelOpenModalProp){
			setAddbuttonFunnelModalOpen(handleAddbuttonFunnelOpenModalProp);
		    FunnelApi.getpageinaddbuttonsection({
		      system_website_id: selectedMemberWebsite
		    })
		    .then(response => {
		      if (response.ok) {
		          return response.json();
		      }
		    })
		    .then(data => {
		      if (data) {
		        setPageinAddButtonSection(data.data);
		      }
		    })
		    .catch(error => {
		        console.error("API Error:", error);
		    });
		}
	}, [handleAddbuttonFunnelOpenModalProp]);

	/*Use All Funnel Form Name*/
  	const form = useForm();

	const handleAddbuttonFunnelCloseModal = () => {
		form.reset();
	    setAddbuttonFunnelModalOpen(false);
	    setSelectedPageId(null);
	    setSelectedButtonId(null);
	    setButtoninAddButtonSection([]);
	    sethandleAddbuttonFunnelOpenModalProp(false);
  	};

  	/*Add Button to the Funnel*/
  	const handleAddButtonFunnelSubmit = async (formData:any) => {
	    try {
	      const APIResponse = await FunnelApi.addbuttontofunnel({
	        system_website_id: selectedMemberWebsite,
	        button_id: selectedButtonId
	      });
	      const APIResultJSON = await APIResponse.json();
	      if (APIResultJSON.status === 1) {
	        notifications.show({
	          color: 'green',
	          icon: <IconCheck size="1.1rem" />,
	          title: 'Success',
	          message: APIResultJSON.message,
	        });
	        setUpdatedtime(APIResultJSON.data.updated_time);
	        handleAddbuttonFunnelCloseModal();
	        setSelectedButtonId('');
	      } else {
	      	form.setErrors({ button_id: APIResultJSON.message });
	      }
	    } catch (error) {
	      console.log('error', error);
	    }
  	}

  	/*Get Button in Add Button To The Funnel*/
  	const handlePageSelect = async (selectedPageId) => {
	    setSelectedPageId(selectedPageId);
	    try {
	      const response = await FunnelApi.getbuttoninaddbuttonsection({
	        page_id: selectedPageId
	      });
	      if (response.ok) {
	        const data = await response.json();
	        if (data && data.data.length > 0) {
	          setButtoninAddButtonSection(data.data);
	        } else {
	          setButtoninAddButtonSection([]);
	          setSelectedButtonId('');
	        }
	      }
	    } catch (error) {
	      console.error("API Error:", error);
	    }
  	};

  	const handleHelpClick = (id:any) => {
	    setShowHelpModal(true);
	    setHelpTargetId(id);
	    if (buttonIconRef.current) {
	      const { top, left, width } = buttonIconRef.current.getBoundingClientRect();
	      const scrollOffset = window.scrollY;
	      setTooltipPosition({
	        top: top + scrollOffset,
	        left: left + width + 10, 
	      });
	    }
	};

	return (
		<>
		{showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
		{/*Add Button to the Funnel*/}
	    <Modal.Root opened={addButtonFunnelModalOpen} onClose={handleAddbuttonFunnelCloseModal}>
	      <Modal.Overlay />
	      <Modal.Content>
	        <Modal.Header>
	          <Modal.Title>ADD BUTTON(S) <IconHelp ref={buttonIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(207)}/></Modal.Title>
	          <Modal.CloseButton />
	        </Modal.Header>
	        <Modal.Body>
	    	  <form onSubmit={form.onSubmit((formData) => { handleAddButtonFunnelSubmit(formData); })}>
	          <>
	            <Select
	              label="Pages"
	              placeholder="Select Page"
	              data={pageinAddButtonSection.map(pageinAdd => ({
	                value: pageinAdd.page_id,
	                label: pageinAdd.page_title
	              }))}
	              searchable
	              nothingFound="No options"
	              maxDropdownHeight={120}
	              size="sm"
	              dropdownPosition="bottom"
	              mb={15}
	              withinPortal
	              onChange={(selectedPageId) => {
	                handlePageSelect(selectedPageId);
	              }}
	            />
	            {selectedPageId && (
	            <Select
	              label="Buttons"
	              placeholder="Select Button"
	              data={buttoninAddButtonSection.map(buttoninAdd => ({
	                value: buttoninAdd.button_id,
	                label: buttoninAdd.button_name
	              }))}
	              searchable
	              nothingFound="No options"
	              maxDropdownHeight={120}
	              size="sm"
	              withinPortal
	              dropdownPosition="top"
	              value={selectedButtonId}
	              onChange={(selectedButtonId) => setSelectedButtonId(selectedButtonId)}
	              error={form.errors.button_id}
	            />
	            )}
	            </>
	            <Group position="right">
	              <Button type="submit" size="sm" w={80} mt={25}>
	                Save
	              </Button>
	            </Group>
	          </form>
	        </Modal.Body>
	      </Modal.Content>
	    </Modal.Root>
		</>
	);
}