import React from 'react';
import { Text, Table } from '@mantine/core';

export function OptimizeReport({
  SelectedFilteredOptions,
  TestVariationsData
  }:any) {

    const getBackgroundColor = (highlight) => {
      if (highlight === 'red') {
        return '#ffd9d9';
      }
      if (highlight === 'yellow') {
        return '#fff7c6';
      }
      return 'transparent';
    };

  return (
    <>
      <Table striped highlightOnHover withBorder my={15} fontSize="xs">
        <thead>
          <tr>
            <th>ID</th>
            <th>Variations</th>
             {SelectedFilteredOptions.includes('Uniques') && <th>Uniques</th>}
             {SelectedFilteredOptions.includes('Visits') && <th>Visits</th>}
             {SelectedFilteredOptions.includes('Conv. # to page') && <th>Conv. # to page</th>}
             {SelectedFilteredOptions.includes('Conv. % to page') && <th>Conv. % to page</th>}
             {SelectedFilteredOptions.includes('Percentage Improvement') &&<th>Percentage Improvement</th>}
             {SelectedFilteredOptions.includes('Statistical Significance') &&<th>Statistical Significance</th>}
             {SelectedFilteredOptions.includes('Total Revenue') && <th>Total Revenue</th>}
             {SelectedFilteredOptions.includes('AOV') && <th>AOV</th>}
             {SelectedFilteredOptions.includes('Avg. Revenue per unique') && <th>Avg. Revenue per unique</th>}
          </tr>
        </thead>
        <tbody>
            {TestVariationsData.map((element,index) => (
              <tr key={index}
                  style={{ backgroundColor: getBackgroundColor(element.highlight_row) }}
                  onMouseEnter={(e) => {
                    if(element.highlight_row !== 'red'){
                      e.currentTarget.style.backgroundColor = '#fff7c6';
                    }
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = getBackgroundColor(element.highlight_row);
                  }}
                >
                <td>{element.variation_number}</td>
                <td>
                  <div style={{ display: 'flex'}}>
                    <Text mt={6} w={80}>{element.variations}</Text>
                    {element.is_winner !== '' ? <Text mt={6} w={80} fz={12} weight={800} c="red">! winner</Text> : "" }
                  </div>
                </td>
                {SelectedFilteredOptions.includes('Uniques') && <td>{element.uniques}</td>}
                {SelectedFilteredOptions.includes('Visits') && <td>{element.visits}</td>}
                {SelectedFilteredOptions.includes('Conv. # to page') && <td>{element.convert_to_page}</td>}
                {SelectedFilteredOptions.includes('Conv. % to page') && <td>{element.conversion_to_page_percent}</td>}
                {SelectedFilteredOptions.includes('Percentage Improvement') && 
                <td>
                  {element.pi_per === 'Not Enough Data' ? (
                    <Text c="red" fw={500}>{element.pi_per}</Text>
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <Text fw={500} w={40}>{Math.round(element.pi_per)}%</Text>
                      <div className="pi_graph pi_cf">
                        <div className={`pi_ext ${element.pi_class}`}>
                          <div style={{ width: `${Math.min(Math.round(element.pi_per_value), 100)}%` }} className={`pi_bar ${element.pi_class}`}></div>
                        </div>
                      </div>
                    </div>
                  )}
                </td>
                }
                {SelectedFilteredOptions.includes('Statistical Significance') &&
                <>
                  {(element.ss_class !== '' || element.ss_per !== 0) ? (
                  <td><div style={{ display: 'flex' }}><Text fw={500} style={{width:"5.5rem"}}>{element.ss_per}%</Text>{element.ss_per !== ''?<progress value={element.ss_per} style={{width:"208%",marginTop:"3px"}} className={element.ss_class} max="100"></progress>:''}</div></td>
                  ) : (
                    <td></td>
                  )}
                </>
                }
                {SelectedFilteredOptions.includes('Total Revenue') && <td>{element.revenue}</td>}
                {SelectedFilteredOptions.includes('AOV') && <td>{element.aov}</td>}
                {SelectedFilteredOptions.includes('Avg. Revenue per unique') && <td>{element.arpu}</td>}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}