import React, { useState } from 'react';
import { IconHelp } from "@tabler/icons-react";
import { Grid, Checkbox, Text, Modal, Group, Button } from '@mantine/core';

export function SelectMetrics({
    generalColumns,
    setGeneralColumns,
    goalColumns,
    setGoalColumns,
    trafficSourceColumns,
    setTrafficSourceColumns,
    organicTrafficMetricsColumns,
    setOrganicTrafficMetricsColumns,
    sourceMetricsColumns,
    setSourceMetricsColumns,
    conversionGoalOptions,
    setOrganicTrafficColumns,
    setSourceColumns,
    setCustomTagColumns,
    setTrackingTagColumns,
    setCampaignColumns
  }:any) {

  const [selectMetricsModalOpen, setSelectMetricsModalOpen] = useState(false);

  const handleSelectMetricsOpenModal = (value:any) => {
    setSelectMetricsModalOpen(true);
  };

  const handleSelectMetricsCloseModal = () => {
    setSelectMetricsModalOpen(false);
  };

  const handleGeneralMetricsColumns = (key, value) => {
    const updatedGeneralColumns = generalColumns.map((data) => {
      if (data.key === key) {
        return { ...data, show: !data.show };
      }
      return data;
    });
    setGeneralColumns(updatedGeneralColumns);
  };

  const handleGoalColumnsMetrics = (key, value) => {
    const updatedGoalColumns = goalColumns.map((data) => {
      if (data.key === key) {
        return { ...data, show: !data.show };
      }
      return data;
    });
    setGoalColumns(updatedGoalColumns);
  };

  const handleTrafficSourceColumnsMetrics = (key, value) => {
    /*SET TRAFFIC SOURCE COLUMNS*/
    const updatedTrafficSourceColumns = trafficSourceColumns.map((data) => {
      if (data.key === key) {
        return { ...data, show: !data.show };
      }
      return data;
    });
    setTrafficSourceColumns(updatedTrafficSourceColumns);

    /*SET ORGANIC TRAFFIC COLUMNS*/
    const updatedOrganicTrafficColumns = Object.entries(organicTrafficMetricsColumns).map(
      ([index, data]) => {
        const column = data as { key: string; value: string; show: boolean };
        if (column.key === key) {
          return { ...column, show: !column.show };
        }
        return column;
      }
    );
    setOrganicTrafficMetricsColumns(updatedOrganicTrafficColumns);

    /*SET SOURCE COLUMNS*/
    const updatedSourceMetricsColumns = Object.entries(sourceMetricsColumns).map(
      ([index, data]) => {
        const column = data as { key: string; value: string; show: boolean };
        if (column.key === key) {
          return { ...column, show: !column.show };
        }
        return column;
      }
    );
    setSourceMetricsColumns(updatedSourceMetricsColumns);

    /*SET TRACKING TAG AND CUSTOM TAG COLUMN*/
    conversionGoalOptions.forEach((currentCampaign) => {
      const campaignId = currentCampaign.campaign_id;

      setTrackingTagColumns((prev) => {
        const existingColumns = prev[campaignId] || [];
        const updatedTrackingTagColumns = existingColumns.map((existingColumn) => {
          const matchingSourceMetric = Object.values(updatedSourceMetricsColumns).find(
            (data) => data.key === existingColumn.key
          );

          return {
            ...existingColumn,
            ...(matchingSourceMetric ? { show: matchingSourceMetric.show } : {}),
          };
        });

        return {
          ...prev,
          [campaignId]: updatedTrackingTagColumns,
        };
      });

        if (currentCampaign.customTagData) {
          Object.entries(currentCampaign.customTagData).forEach(([customTagName, columns]) => {
            setCustomTagColumns((prev) => {
              const updatedPrev = prev || {}; 
              const campaignColumns = updatedPrev[campaignId] || {};
              const existingColumns = campaignColumns[customTagName];
              const updatedCampaignColumns = existingColumns && existingColumns.map((column) => {
                const matchingSourceMetric = Object.values(updatedSourceMetricsColumns).find(
                  (data) => data.key === column.key
                );

                return {
                  ...column,
                  ...(matchingSourceMetric ? { show: matchingSourceMetric.show } : {}),
                };
              });
              return {
                ...updatedPrev,
                [campaignId]: {
                  ...campaignColumns,
                  [customTagName]: updatedCampaignColumns,
                },
              };
            });
          });
        }
    });
  };

  const handleSaveMetrics = (event) => {
    event.preventDefault();
    const visibleTrafficSourceColumns = trafficSourceColumns.filter(column => column.show);
    const visibleOrganicTrafficColumns = organicTrafficMetricsColumns.filter(column => column.show);
    const visibleSourceColumns = sourceMetricsColumns.filter(column => column.show);
    if(conversionGoalOptions.length > 0){
      conversionGoalOptions.forEach((currentCampaign, currentCampaignIndex) => {
        setOrganicTrafficColumns((prev) => ({
          ...prev,
          [currentCampaign.campaign_id]: visibleOrganicTrafficColumns,
        }));
        setSourceColumns((prev) => ({
          ...prev,
          [currentCampaign.campaign_id]: visibleSourceColumns,
        }));
        const trafficSourceNames = ['facebook', 'google', 'microsoft', 'tiktok', 'youtube'];
        const trafficSourceAdsetNames = ['facebookAdset', 'googleAdset', 'microsoftAdset', 'tiktokAdset', 'youtubeAdset'];
        const trafficSourceAdsNames = ['facebookAds', 'googleAds', 'microsoftAds', 'tiktokAds', 'youtubeAds'];
        setCampaignColumns((prev) => {
          const updatedCampaigns = {};

          trafficSourceNames.forEach((traffic) => {
            updatedCampaigns[traffic] = visibleTrafficSourceColumns.flatMap((col) => {
              if (col.key === 'traffic_source') {
                return [
                  [col.key, col.value],
                  ['checkbox', false],
                ];
              }
              return [[col.key, col.value]];
            });
          });

          trafficSourceAdsetNames.forEach((traffic) => {
            updatedCampaigns[traffic] = visibleTrafficSourceColumns.flatMap((col) => {
              if (col.key === 'traffic_source') {
                return [
                  [col.key, col.value],
                  ['checkbox', false],
                ];
              }
              if (col.key === 'campaign_name') {
                return [
                  [col.key, col.value],
                  ['adset_name', 'Ad Set / Group Name'],
                ];
              }
              return [[col.key, col.value]];
            });
          });

          trafficSourceAdsNames.forEach((traffic) => {
            updatedCampaigns[traffic] = visibleTrafficSourceColumns.flatMap((col) => {
              if (col.key === 'traffic_source') {
                return [
                  [col.key, col.value],
                  ['checkbox', false],
                ];
              }
              if (col.key === 'campaign_name') {
                return [
                  [col.key, col.value],
                  ['adset_name', 'Ad Set / Group Name'],
                  ['ad_name', 'Ad'],
                ];
              }
              return [[col.key, col.value]];
            });
          });

          return {
            ...prev,
            ...updatedCampaigns,
          };
        });
      });
    }
    setSelectMetricsModalOpen(false);
  };

  return (
    <>
      <Modal.Root opened={selectMetricsModalOpen} onClose={handleSelectMetricsCloseModal} size="70%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>SELECT METRICS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body style={{ overflow: 'hidden' }}>
            <Text>Select metrics to be displayed in the report table: </Text>
            <form onSubmit={handleSaveMetrics}>
              <Grid gutter={5} gutterXs="md" gutterMd="xl" gutterXl={50}>
                <Grid.Col span={4}>
                  <Text weight={500} mt={10} mb={5}>General Metrics</Text>
                  {generalColumns.map((data, index) => (
                      <Checkbox
                        key={index}
                        mb={3}
                        checked={data.show}
                        onChange={() => handleGeneralMetricsColumns(data.key, data.value)}
                        label={data.value}
                      />
                  ))}
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text weight={500} mt={10} mb={5}>Goal Metrics</Text>
                  {goalColumns.map((data, index) => (
                      <Checkbox
                        key={index}
                        mb={3}
                        checked={data.show}
                        onChange={() => handleGoalColumnsMetrics(data.key, data.value)}
                        label={data.value}
                      />
                  ))}
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text weight={500} mt={10} mb={5}>Traffic Metrics</Text>
                  {trafficSourceColumns.map((data, index) => (
                      <Checkbox
                        key={index}
                        mb={3}
                        checked={data.show}
                        onChange={() => handleTrafficSourceColumnsMetrics(data.key, data.value)}
                        label={data.value}
                      />
                  ))}
                </Grid.Col>
              </Grid>
              <Button type="submit" size="sm" w={80}>
                Save
              </Button>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root> 

      <Group position="left">
        <Button color="teal" onClick={() => handleSelectMetricsOpenModal(1)}>
          Select Metrics
        </Button>
      </Group>
    </>
  );
}