import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button, Input, Group, Slider, Grid, Text } from '@mantine/core';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function TrafficControl({ selectedMemberWebsite, pageId, isTrafficControlModalOpen, onTrafficControlClose, setMarksalestime, TestOptimizeReport, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [valueTrafficControl, setValueTrafficControl] = useState(10);
  const [trafficControlValues, setTrafficControlValues] = useState([]);

  const trafficControlRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isTrafficControlModalOpen) {
      if (!pageId) {
        navigate('/review-tests');
        return;
      }
      TestsApi.gettrafficcontrol({
        page_id:pageId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          const transformedData = data.data.test_row_data.map(test => ({
            variableId: test.test_id,
            value: parseFloat(test.test_value),
          }));
          const controlValue = Math.max(10, Math.min(90, parseFloat(data.data.control)));
          setValueTrafficControl(controlValue);
          setTrafficControlValues(transformedData);
        } else {
          errorMessage(data.message);
          errorModelOpen(true);
          showErrorHeader(true);
          onTrafficControlClose();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  }, [isTrafficControlModalOpen, pageId]);

  const handleTrafficControlResult = useCallback(() => {
    const remainingTraffic = 100 - valueTrafficControl;
    const newValues = trafficControlValues.map((item) => ({
      ...item,
      value: parseFloat((remainingTraffic / trafficControlValues.length).toFixed(2)),
    }));
    setTrafficControlValues(newValues);
  }, [valueTrafficControl, trafficControlValues.length,trafficControlValues ]);

  useEffect(() => {
    const updateValuesOnMouseUp = () => {
      handleTrafficControlResult();
    };
    window.addEventListener('mouseup', updateValuesOnMouseUp);
    return () => {
      window.removeEventListener('mouseup', updateValuesOnMouseUp);
    };
  }, [handleTrafficControlResult]);

  const handleSaveTrafficControl = (e) => {
    e.preventDefault();
    TestsApi.savetrafficcontrol({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      control:valueTrafficControl
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        onTrafficControlClose();
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onTrafficControlClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (trafficControlRef.current) {
      const { top, left, width } = trafficControlRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      <Modal.Root opened={isTrafficControlModalOpen} onClose={onTrafficControlClose} size="70%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>TRAFFIC CONTROL <IconHelp ref={trafficControlRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(206)}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSaveTrafficControl}>
              <Grid mt={20}>
                <Grid.Col span={3}></Grid.Col>
                <Grid.Col span={1}>All Tests</Grid.Col>
                <Grid.Col span={4} mt={6}>
                  <Slider
                    value={valueTrafficControl}
                    onChange={(value) => {
                      setValueTrafficControl(value);
                    }}
                    min={10}
                    max={90}
                  />
                </Grid.Col>
                <Grid.Col span={1}>Control</Grid.Col>
                <Grid.Col span={3}></Grid.Col>
              </Grid>
              <Text mt={10} mb={20}>Percentage of traffic routed to:</Text>
              <Group>
                <Text style={{ display: 'flex'}}>Control:<Input w={80} size="xs" value={valueTrafficControl} mr={5} ml={5} disabled/>%</Text>
                {trafficControlValues.map((item, index) => (
                  <Text key={index} style={{ display: 'flex' }} ml={10} w={parseInt(item.variableId) >= 10 ? 160 : 150}>
                    Test {item.variableId}:
                    <Input w={80} size="xs" value={item.value} mr={5} ml={5} disabled />%
                  </Text>
                ))}
              </Group>
              <Group position="right">
                <Button type="submit" size="sm" w={80} mt={25}>
                  Save
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}