import { ServerAPI } from '../GeneralFunction';

export default {
	getfunnellist: (data) => {
		let url = "get_funnel_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getpagelistbyfunnelid: (data) => {
		let url = "get_page_list_by_funnel_id";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	saveupdateassetsurl: (data) => {
		let url = "save_update_assets_url";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	/*START HEATMAP API*/
	getheatmaplist: (data) => {
		let url = "get_heatmap_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},	
	deleteheatmap: (data) => {
		let url = "delete_heatmap";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	heatmappagecheck: (data) => {
		let url = "heatmap_page_check";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	saveupdateheatmap: (data) => {
		let url = "save_update_heat_map";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getsingleheatmapdetail: (data) => {
		let url = "get_single_heatmap_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	updatehighaccuracy: (data) => {
		let url = "update_high_accuracy";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	resumeheatmap: (data) => {
		let url = "resume_heatmap";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getheatmapdetails: (data) => {
		let url = "get_heatmap_details";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	resetheatmapdata: (data) => {
		let url = "reset_heatmap_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	/*END HEATMAP API*/

	/*START SCROLLMAP API*/
	getscrollmaplist: (data) => {
		let url = "get_scrollmap_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	scrollmappagecheck: (data) => {
		let url = "scrollmap_page_check";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	saveupdatescrollmap: (data) => {
		let url = "save_update_scroll_map";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getsinglescrollmapdetail: (data) => {
		let url = "get_single_scrollmap_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	resumescrollmap: (data) => {
		let url = "resume_scrollmap";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	deletescrollmap: (data) => {
		let url = "delete_scrollmap";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getscrollmapdetails: (data) => {
		let url = "get_scrollmap_details";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	resetscrollmapdata: (data) => {
		let url = "reset_scrollmap_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	/*END SCROLLMAP API*/

	/*START RECORDING API*/
	getrecordingfunnelslist: (data) => {
		let url = "get_recording_funnels_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getrecordingsettingdetail: (data) => {
		let url = "get_recording_setting_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	saverecordingsettingchanges: (data) => {
		let url = "save_recording_setting_changes";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	resumerecordings: (data) => {
		let url = "resume_recordings";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getrecordingslist: (data) => {
		let url = "get_recordings_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	deleterecording: (data) => {
		let url = "delete_recording";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getrecordingplaydetails: (data) => {
		let url = "get_recording_play_details";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savetrackpage: (data) => {
		let url = "save_track_page";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	clearrecordinglist: (data) => {
		let url = "clear_recording_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	}
	/*END RECORDING API*/
};