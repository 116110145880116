import { ServerAPI } from '../GeneralFunction';

const TestsAPI = {
	//Start ReviewTests
		getpageslist: (data) => {
			let url = "get_pages_list";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		checkpageinfunnelvalidation: (data) => {
			let url = "check_page_in_funnel_validation";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		unsetwinner: (data) => {
			let url = "unset_winner";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End ReviewTests

	//Start ReviewingTests
		getaudiancesize: (data) => {
			let url = "get_audiance_size";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		saveaudiancesizenum: (data) => {
			let url = "save_audiance_size_num";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		gettrafficcontrol: (data) => {
			let url = "get_traffic_control";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savetrafficcontrol: (data) => {
			let url = "save_traffic_control";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		gettestnote: (data) => {
			let url = "get_test_note";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savetestsnote: (data) => {
			let url = "save_tests_note";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		testresetstats: (data) => {
			let url = "test_reset_stats";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		gettestinfo: (data) => {
			let url = "get_test_info";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getconnectshoppingcartandprocessor: (data) => {
			let url = "get_connect_shopping_cart_and_processor";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getwebhookurl: (data) => {
			let url = "get_webhook_url";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		addpaymentprocessorcart: (data) => {
			let url = "add_payment_processor_cart";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getsinglewebhookprocessordetail: (data) => {
			let url = "get_single_webhook_processor_detail";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		validatedeletepaymentprocessorcart: (data) => {
			let url = "validate_delete_payment_processor_cart";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		deletepaymentprocessorcart: (data) => {
			let url = "delete_payment_processor_cart";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getgoogleanalytics: (data) => {
			let url = "get_google_analytics";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savegoogleanalytics: (data) => {
			let url = "save_google_analytics";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getpauseresumetest: (data) => {
			let url = "get_pause_resume_test";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		pauseresumetest: (data) => {
			let url = "pause_resume_test";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savetestmode: (data) => {
			let url = "save_test_mode";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getattributionmodel: (data) => {
			let url = "get_attribution_model";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		saveattributionmodel: (data) => {
			let url = "save_attribution_model";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getvisismarttestingcode: (data) => {
			let url = "get_visismart_testing_code";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		discardtest: (data) => {
			let url = "discard_test";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getconversionsdropdown: (data) => {
			let url = "get_conversions_dropdown";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getconversionlogdetails: (data) => {
			let url = "get_conversion_log_details";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getconversionlogfilter: (data) => {
			let url = "get_conversion_log_filter";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getbehavioraldimensions: (data) => {
			let url = "get_behavioral_dimensions";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		behavioraldimensionsaddfilterfirstdropdown: (data) => {
			let url = "behavioral_dimensions_add_filter_first_dropdown";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		behavioraldimensionsaddfilterseconddropdown: (data) => {
			let url = "behavioral_dimensions_add_filter_second_dropdown";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		behavioraldimensionsaddfilterthirddropdown: (data) => {
			let url = "behavioral_dimensions_add_filter_third_dropdown";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savebehavioraldimensions: (data) => {
			let url = "save_behavioral_dimensions";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		resetfilterbehavioraldimensions: (data) => {
			let url = "reset_filter_behavioral_dimensions";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getvariablestab: (data) => {
			let url = "get_variables_tab";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getbehavioraldimensionfilterdata: (data) => {
			let url = "get_behavioral_dimension_filter_data";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getnetworkhierarchicaldata: (data) => {
			let url = "get_network_hierarchical_data";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		setwinnerpopupget: (data) => {
			let url = "set_winner_popup_get";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savecustomtitleintest: (data) => {
			let url = "save_custom_title_in_test";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		pauseresumevariation: (data) => {
			let url = "pause_resume_variation";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		testoptimizereport: (data) => {
			let url = "test_optimize_report";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End ReviewingTests

	//Start CreateTest
		getcreatetestpagelist: (data) => {
			let url = "get_create_test_page_list";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getfunnelinrotation: (data) => {
			let url = "get_funnel_in_rotation";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		saveclassicabrotationpage: (data) => {
			let url = "save_classic_ab_rotation_page";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		getsmartrelationallist: () => {
			let url = "get_smart_relational_list";
			const requestOptions = ServerAPI(url, 'GET');
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		discarddraft: (data) => {
			let url = "discard_draft";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End CreateTest

	//Start TestHistory & TestHistoryReport
		gettesthistorylist: (data) => {
			let url = "get_test_history_list";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		checktesthistorydata: (data) => {
			let url = "check_test_history_data";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		gettesthistoryvariabletab: (data) => {
			let url = "get_test_history_variable_tab";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		gettesthistoryconversionsdropdown: (data) => {
			let url = "get_test_history_conversions_dropdown";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		testhistoryoptimizereport: (data) => {
			let url = "test_history_optimize_report";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End TestHistory & TestHistoryReport

	//Start TestEditorVisual & TestEditorVisualModern
		addassetsurlforstyleimagenotloaded: (data) => {
			let url = "add_assets_url_for_style_image_not_loaded";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		testeditorprerendercacheclear: (data) => {
			let url = "testeditor_prerender_cache_clear";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		gettesteditordata: (data) => {
			let url = "get_test_editor_data";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		savetestsetup: (data) => {
			let url = "save_test_setup";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
		testeditorresetfilterbehavioraldimensions: (data) => {
			let url = "testeditor_reset_filter_behavioral_dimensions";
			const requestOptions = ServerAPI(url, 'POST', data);
			return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
		},
	//End TestEditorVisual & TestEditorVisualModern
};

export default TestsAPI;