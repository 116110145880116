import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Select, Button, Group, Text, Accordion, Checkbox, Input, Tabs, Table, MultiSelect, LoadingOverlay, ActionIcon } from '@mantine/core';
import { IconCheck, IconCircleMinus, IconPlus, IconHelp } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import CampaignApi from "../../api/CampaignApi";
import classes from '../../pages/DashBoard/DashBoard.module.css';
import { modals } from '@mantine/modals';

export function Step5({ selectedMemberWebsite, campaign_id, campaignId, glConvCustomerId, glConvSubCustomerId, ytAdAccountId, ytConvCustomerId, ytConvSubCustomerId, tbAdAccountId, step5Disabled, 
                        campaignLoader, setStep5Show, step5Show, convAttributionModel, handleConvAttributionModelChange, sendAllConversionFb, isFacebookChecked,
                        isGoogleChecked, isMicrosoftChecked, isTiktokChecked, isYoutubeChecked, isTaboolaChecked, isOutbrainChecked, obadaccountid, msadaccountid, conversionApiType
                      }:any) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Facebook');
  const [tabLoader, settabLoader] = useState(false);

  const step5IconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const [isFbSendAllConversionChecked, setIsFbSendAllConversionChecked] = useState(sendAllConversionFb === true);
  const [fbConvMessageRow, setFbConvMessageRow] = useState([]);
  const [fbConversionGoal, setFbConversionGoal] = useState([]);
  const [fbConversionEvent, setFbConversionEvent] = useState([]);
  const [fbConversionCurrency, setFbConversionCurrency] = useState([]);
  const [fbPixelIdValue, setFbPixelIdValue] = useState({});
  const [fbAccessTokenValue, setFbAccessTokenValue] = useState({});
  const [fbAccountIdValue] = useState({});
  const [fbTotalRowShow, setFbTotalRowShow] = useState(0);

  const [fbConversionEventMP, setFbConversionEventMP] = useState([]);
  const [fbConversionEventValueMP, setFbConversionEventValueMP] = useState('');
  const [fbConversionCurrencyMP, setFbConversionCurrencyMP] = useState([]);
  const [fbConversionCurrencyValueMP, setFbConversionCurrencyValueMP] = useState('');
  const [facebookConversionPageview, setFacebookConversionPageview] = useState(false);
  const [goalGroups, setGoalGroups] = useState([]);
  const [fbAdAccountDetails, setFbAdAccountDetails] = useState([]);

  const [glTotalRowShow, setGlTotalRowShow] = useState(0);
  const [glCustomer, setGlCustomer] = useState([]);
  const [glCustomerValue, setGlCustomerValue] = useState('');
  const [glSubCustomer, setGlSubCustomer] = useState([]);
  const [glSubCustomerValue, setGlSubCustomerValue] = useState('');
  const [glConvMessageRow, setGlConvMessageRow] = useState([]);
  const [glConversionActionMP, setGlConversionActionMP] = useState([]);
  const [useglConversionActionMPData, setUseglConversionActionMPData] = useState(false);
  const [glConvAction, setGlConvAction] = useState([]);
  const [glConvActionValue, setGlConvActionValue] = useState('');
  const [glConvPageDetail, setGlConvPageDetail] = useState([]);
  const [googleConversionPageview, setGoogleConversionPageview] = useState(false);
  const [glgoalGroups, setGlGoalGroups] = useState([]);

  const [ytTotalRowShow, setYtTotalRowShow] = useState(0);
  const [ytCustomer, setYtCustomer] = useState([]);
  const [ytCustomerValue, setYtCustomerValue] = useState('');
  const [ytSubCustomer, setYtSubCustomer] = useState([]);
  const [ytSubCustomerValue, setYtSubCustomerValue] = useState('');
  const [ytConversionActionMP, setYtConversionActionMP] = useState([]);
  const [ytConvAction, setYtConvAction] = useState([]);
  const [ytConvMessageRow, setYtConvMessageRow] = useState([]);
  const [useytConversionActionMPData, setUseytConversionActionMPData] = useState(false);
  const [ytConvPageDetail, setYtConvPageDetail] = useState([]);
  const [ytConvActionValue, setYtConvActionValue] = useState('');
  const [youtubeConversionPageview, setYoutubeConversionPageview] = useState(false);
  const [ytgoalGroups, setYtGoalGroups] = useState([]);

  const [tbConvPageDetail, setTbConvPageDetail] = useState([]);
  const [tbConversionAction, setTbConversionAction] = useState([]);
  const [tbConvActionValue, setTbConvActionValue] = useState('');
  const [useTbConversionActionMPData, setUseTbConversionActionMPData] = useState(false);
  const [tbAdAccountDetails, setTbAdAccountDetails] = useState([]);
  const [tbAdAccountDetailsValue, setTbAdAccountDetailsValue] = useState('');
  const [tbgoalGroups, setTbGoalGroups] = useState([]);
  const [tbConvMessageRow, setTbConvMessageRow] = useState([]);
  const [taboolaConversionPageview, setTaboolaConversionPageview] = useState(false);
  const [tbConversionActionMP, setTbConversionActionMP] = useState([]);

  const [tkAdvertiserAccountValue, setTkAdvertiserAccountValue] = useState('');
  const [tiktokConversionPageview, setTiktokConversionPageview] = useState(false);
  const [tkPixelMPValue, setTkPixelMPValue] = useState('');
  const [tkEventMPValue, setTkEventMPValue] = useState('');

  const [obAdAccountDetails, setObAdAccountDetails] = useState([]);
  const [obAdAccountDetailsValue, setObAdAccountDetailsValue] = useState('');
  const [obConversionAction, setObConversionAction] = useState([]);
  const [obConvPageDetail, setObConvPageDetail] = useState([]);
  const [obConvMessageRow, setObConvMessageRow] = useState([]);
  const [outbrainConversionPageview, setOutbrainConversionPageview] = useState(false);
  const [obConversionActionMP, setObConversionActionMP] = useState([]);
  const [obConvActionValue, setObConvActionValue] = useState('');
  const [useObConversionActionMPData, setUseObConversionActionMPData] = useState(false);
  const [obgoalGroups, setObGoalGroups] = useState([]);

  const [msAdAccountDetails, setMsAdAccountDetails] = useState([]);
  const [msAdAccountDetailsValue, setMsAdAccountDetailsValue] = useState('');
  const [msConversionAction, setMsConversionAction] = useState([]);
  const [msConvPageDetail, setMsConvPageDetail] = useState([]);
  const [msConvMessageRow, setMsConvMessageRow] = useState([]);
  const [microsoftConversionPageview, setMicrosoftConversionPageview] = useState(false);
  const [msConversionActionMP, setMsConversionActionMP] = useState([]);
  const [msConvActionValue, setMsConvActionValue] = useState('');
  const [useMsConversionActionMPData, setUseMsConversionActionMPData] = useState(false);
  const [msgoalGroups, setMsGoalGroups] = useState([]);

  const handleFbSendAllConversionChange = (event) => {
    setIsFbSendAllConversionChecked(event.target.checked);
  };

  useEffect(() => {
    setIsFbSendAllConversionChecked(sendAllConversionFb === true);
  }, [sendAllConversionFb]);

  /*start facebook step 5*/
    const getconversiondata = () => {
      settabLoader(true);
      CampaignApi.getconversiondata({
        campaign_id : campaign_id || campaignId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setFbConvMessageRow(data.data.message_row || []);

          setFbConversionGoal(data.data.fb_conversion_goal_event.fb_conversion_goal || []);
          setFbConversionEvent(data.data.fb_conversion_goal_event.fb_conversion_event || []);
          setFbConversionCurrency(data.data.fb_conversion_goal_event.fb_conversion_currency || []);
          if(facebookConversionPageview === true){
            setFacebookConversionPageview(true);
          } else {
            setFacebookConversionPageview(data.data.message_pageview.facebook_conversion_pageview);
          }
          setFbConversionCurrencyMP(data.data.message_pageview.fb_conversion_currency || []);
          setFbConversionEventMP(data.data.message_pageview.fb_conversion_event || []);
          settabLoader(false);
        }
      })
      .catch(error => {
        console.log(error);
        settabLoader(false);
      });
    };

    const getfbpixeldetails = () => {
      settabLoader(true);
      CampaignApi.getfbpixeldetails({
        campaign_id : campaign_id || campaignId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          if(data.data.adaccount_details === "No Data Found."){
            setFbAdAccountDetails(data.data.adaccount_details || {});  
          }
          if(data.data.adaccount_details !== "No Data Found."){
            const adAccountDetailsArray = Object.values(data.data.adaccount_details || {});
            setFbAdAccountDetails(adAccountDetailsArray);
          }
          settabLoader(false);
        }
      })
      .catch(error => {
        settabLoader(false);
        console.log(error);
      });
    };

    useEffect(() => {
      if(setStep5Show !== '0'){
        if (activeTab === 'Facebook') {
          getconversiondata();
          getfbpixeldetails();
        }
      }
    }, [activeTab,setStep5Show]);
  /*End facebook step 5*/

  /*start google step 5*/
    const getgoogleconvadcustomer = () => {
      CampaignApi.getgoogleconvadcustomer({
        campaign_id : campaign_id || campaignId,
        gl_conv_customer_id : glConvCustomerId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setGlCustomer(data.data.adaccount_details);
          const selectedglCustomer = data.data.adaccount_details.find((item) => item.selected === "selected");
          if (selectedglCustomer) {
            setGlCustomerValue(selectedglCustomer.key);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

    const getgoogleconvadsubcustomer = (value) => {
      CampaignApi.getgoogleconvadsubcustomer({
        customer_id : value || glCustomerValue,
        gl_conv_subcustomer_id : glConvSubCustomerId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setGlSubCustomer(data.data.adaccount_details);
          const selectedglSubCustomer = data.data.adaccount_details.find((item) => item.selected === "selected");
          if (selectedglSubCustomer) {
            setGlSubCustomerValue(selectedglSubCustomer.key);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

    const getgoogleconversiondata = () => {
      settabLoader(true);
      CampaignApi.getgoogleconversiondata({
        campaign_id : campaign_id || campaignId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          settabLoader(false);
          setGlConvMessageRow(data.data.message_row || []);
          if(googleConversionPageview === true){
            setGoogleConversionPageview(true);
          } else {
            setGoogleConversionPageview(data.data.message_pageview_gl.google_conversion_pageview);
          }
          setGlConversionActionMP(data.data.message_pageview_gl.gl_conv_action_resource_name_pageview || []);
        }
      })
      .catch(error => {
        settabLoader(false);
        console.log(error);
      });
    };

    const getgoogleconversionpagedetail = () => {
      settabLoader(true);
      CampaignApi.getgoogleconversionpagedetail({
        campaign_id : campaign_id || campaignId,
        customer_id : glCustomerValue,
        subcustomer_id : glSubCustomerValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          settabLoader(false);
          setGlConvAction(data.data.adaccount_details || []);
          setGlConvPageDetail(data.data.gl_conv_page_detail || []);
        }
      })
      .catch(error => {
        settabLoader(false);
        console.log(error);
      });
    };

    useEffect(() => {
      if (activeTab === 'Google') {
        getgoogleconvadcustomer();
        getgoogleconversiondata();
      };
    }, [activeTab]);

    useEffect(() => {
      if(glCustomerValue){
        setGlCustomerValue(glCustomerValue);
        setGlSubCustomerValue('');
        getgoogleconvadsubcustomer('');
      }
    }, [glCustomerValue]);

    useEffect(() => {
      if(glSubCustomerValue){
        setGlSubCustomerValue(glSubCustomerValue);
        getgoogleconversionpagedetail();
      }
    }, [glSubCustomerValue]);

    useEffect(() => {
      if(setStep5Show !== '0'){
        if (activeTab === 'Google') {
          getgoogleconversiondata();
          getgoogleconversionpagedetail();
        };
      };
    }, [activeTab,setStep5Show]);
  /*end google step 5*/

  /*start youtube step 5*/
    const getyoutubeconvadcustomer = () => {
      CampaignApi.getgoogleconvadcustomer({
        campaign_id : campaign_id || campaignId,
        yt_conv_customer_id : ytConvCustomerId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setYtCustomer(data.data.adaccount_details_yt || []);
          const selectedytCustomer = data.data.adaccount_details_yt.find((item) => item.selected === "selected");
          if (selectedytCustomer) {
            setYtCustomerValue(selectedytCustomer.key);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

    const getyoutubeconvadsubcustomer = (value) => {
      CampaignApi.getgoogleconvadsubcustomer({
        customer_id : value || ytCustomerValue,
        yt_conv_subcustomer_id : ytConvSubCustomerId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setYtSubCustomer(data.data.adaccount_details || []);
          const selectedytSubCustomer = data.data.adaccount_details.find((item) => item.selected === "selected");
          if (selectedytSubCustomer) {
            setYtSubCustomerValue(selectedytSubCustomer.key);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

    const getyoutubeconversiondata = () => {
      settabLoader(true);
      CampaignApi.getgoogleconversiondata({
        campaign_id : campaign_id || campaignId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          settabLoader(false);
          setYtConvMessageRow(data.data.message_yt_row || []);
          if(youtubeConversionPageview === true){
            setYoutubeConversionPageview(true);
          } else {
            setYoutubeConversionPageview(data.data.message_pageview_yt.youtube_conversion_pageview);
          }
          setYtConversionActionMP(data.data.message_pageview_yt.yt_conv_action_resource_name_pageview || []);
        }
      })
      .catch(error => {
        settabLoader(false);
        console.log(error);
      });
    };

    const getyoutubeconversionpagedetail = () => {
      settabLoader(true);
      CampaignApi.getgoogleconversionpagedetail({
        campaign_id : campaign_id || campaignId,
        customer_id : ytCustomerValue,
        subcustomer_id : ytSubCustomerValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setYtConvAction(data.data.adaccount_details || []);
          setYtConvPageDetail(data.data.gl_conv_page_detail || []);
          settabLoader(false);
        }
      })
      .catch(error => {
        settabLoader(false);
        console.log(error);
      });
    };

    useEffect(() => {
      if (activeTab === 'Youtube') {
        getyoutubeconvadcustomer();
        getyoutubeconversiondata();
      };
    }, [activeTab]);

    useEffect(() => {
      if(ytCustomerValue){
        setYtCustomerValue(ytCustomerValue);
        setYtSubCustomerValue('');
        getyoutubeconvadsubcustomer('');
      }
    }, [ytCustomerValue]);

    useEffect(() => {
      if(ytSubCustomerValue){
        setYtSubCustomerValue(ytSubCustomerValue);
        getyoutubeconversionpagedetail();
      }
    }, [ytSubCustomerValue]);

    useEffect(() => {
      if(setStep5Show !== '0'){
        if (activeTab === 'Youtube') {
          getyoutubeconversiondata();
          getyoutubeconversionpagedetail();
        };
      };
    }, [activeTab,setStep5Show]);
  /*end youtube step 5*/

  /*start microsoft step 5*/
    const getmicrosoftconvadsaccount = () => {
      settabLoader(true);
      CampaignApi.getmicrosoftconvadsaccount({
        campaign_id : campaign_id || campaignId,
        type : "microsoft_conversion"
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          settabLoader(false);
          setMsAdAccountDetails(data.data.adaccount_details || []);
        }
      })
      .catch(error => {
        settabLoader(false);
        console.log(error);
      });
    };
    
    const getmicrosoftconvgoalname = () => {
      CampaignApi.getmicrosoftconvgoalname({
        campaign_id : campaign_id || campaignId,
        ms_conv_ads_account_id : msadaccountid
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setMsConversionAction(data.data.adaccount_details || []);
          setMsConvPageDetail(data.data.ms_conv_page_detail || []);
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

    const getmicrosoftconversiondata = () => {
      CampaignApi.getmicrosoftconversiondata({
        campaign_id : campaign_id || campaignId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setMsConvMessageRow(data.data.message_row || []);
          if(microsoftConversionPageview === true){
            setMicrosoftConversionPageview(true);
          } else {
            setMicrosoftConversionPageview(data.data.message_pageview_ms.microsoft_conversion_pageview);
          }
          setMsConversionActionMP(data.data.message_pageview_ms.ms_conv_goal_name_pageview || []);
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

    useEffect(() => {
      if (activeTab === 'Microsoft') {
        getmicrosoftconvadsaccount();  
        getmicrosoftconvgoalname();
        getmicrosoftconversiondata();
      };
    }, [activeTab]);
  /*end microsoft step 5*/

  /*start taboola step 5*/
    const taboolaconvaduserdata = () => {
      settabLoader(true);
      CampaignApi.taboolaconvaduserdata({
        tb_ad_account_id : tbAdAccountId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          settabLoader(false);
          setTbAdAccountDetails(data.data.adaccount_details || []);
        }
      })
      .catch(error => {
        settabLoader(false);
        console.log(error);
      });
    };
    
    const gettaboolaconversionpagedetail = () => {
      CampaignApi.gettaboolaconversionpagedetail({
        campaign_id : campaign_id || campaignId,
        tb_conv_ad_account_dd : tbAdAccountId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setTbConversionAction(data.data.adaccount_details || []);
          setTbConvPageDetail(data.data.tb_conv_page_detail || []);
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

    const gettaboolaconversiondata = () => {
      CampaignApi.gettaboolaconversiondata({
        campaign_id : campaign_id || campaignId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setTbConvMessageRow(data.data.message_row || []);
          if(taboolaConversionPageview === true){
            setTaboolaConversionPageview(true);
          } else {
            setTaboolaConversionPageview(data.data.message_pageview_tb.taboola_conversion_pageview);
          }
          setTbConversionActionMP(data.data.message_pageview_tb.tb_conv_action_resource_name_pageview || []);
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

    useEffect(() => {
      if (activeTab === 'Taboola') {
        taboolaconvaduserdata();  
        gettaboolaconversionpagedetail();
        gettaboolaconversiondata();
      };
    }, [activeTab]);
  /*end taboola step 5*/

  /*start outbrain step 5*/
    const outbrainconvaduserdata = () => {
      settabLoader(true);
      CampaignApi.outbrainconvaduserdata({
        ob_ad_account_id : "obadaccountid"
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          settabLoader(false);
          setObAdAccountDetails(data.data.adaccount_details || []);
        }
      })
      .catch(error => {
        settabLoader(false);
        console.log(error);
      });
    };
    
    const getoutbrainconversionpagedetail = () => {
      CampaignApi.getoutbrainconversionpagedetail({
        campaign_id : campaign_id || campaignId,
        ob_conv_ad_account_dd : "obadaccountid"
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setObConversionAction(data.data.adaccount_details || []);
          setObConvPageDetail(data.data.ob_conv_page_detail || []);
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

    const getoutbrainconversiondata = () => {
      CampaignApi.getoutbrainconversiondata({
        campaign_id : campaign_id || campaignId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setObConvMessageRow(data.data.message_row || []);
          if(outbrainConversionPageview === true){
            setOutbrainConversionPageview(true);
          } else {
            setOutbrainConversionPageview(data.data.message_pageview_ob.outbrain_conversion_pageview);
          }
          setObConversionActionMP(data.data.message_pageview_ob.ob_conv_action_resource_name_pageview || []);
        }
      })
      .catch(error => {
        console.log(error);
      });
    };

    useEffect(() => {
      if (activeTab === 'Outbrain') {
        outbrainconvaduserdata();  
        getoutbrainconversionpagedetail();
        getoutbrainconversiondata();
      };
    }, [activeTab]);
  /*end outbrain step 5*/

  /*Facebook Step5*/
    const fbConversionGoalData = [
      ...(fbConversionGoal && typeof fbConversionGoal === 'object' ? 
        Object.keys(fbConversionGoal).map(key => ({
          value: key,
          label: fbConversionGoal[key]
        })) 
      : [])
    ];

    const fbConversionEventData = [
      { value: '', label: 'Select Event' },
      ...fbConversionEvent.map((event) => ({
        value: event.key,
        label: event.value,
      })),
    ];

    const fbConversionCurrencyData = [
      { value: '', label: 'Select Currency' },
      ...fbConversionCurrency.map((currency) => ({
        value: currency.key,
        label: currency.value,
      })),
    ];

    useEffect(() => {
      const fbgoalTotalRow = goalGroups.length;
      const fbmessagerowTotalRow = fbConvMessageRow.length;

      setFbTotalRowShow(fbgoalTotalRow + fbmessagerowTotalRow);
    }, [goalGroups,fbConvMessageRow]);

    const handleFbPixelIdChange = (index, value) => {
      setFbPixelIdValue((prev) => ({ ...prev, [index]: value }));
    };

    const handleFbAccessTokenChange = (index, value) => {
      setFbAccessTokenValue((prev) => ({ ...prev, [index]: value }));
    };

    const handlefbConversionGoalChange = (value: string[], id) => {
      /*const allSelectedGoalKeys = getAllSelectedGoalKeys();
      const alreadySelected = value.some(val => allSelectedGoalKeys.includes(val));
      if (alreadySelected) {
        alert("This goal is already selected in other goal Event.");
        return;
      }*/
      setGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, goal: value } : group
        )
      );
    };

    const handlefbConversionEventChange = (value,id) => {
      setGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, event: value } : group
        )
      );
    };

    const handlefbConversionCurrencyChange = (value,id) => {
      setGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, currency: value } : group
        )
      );
    };
    
    const handleFacebookConversionPageviewChange = (event) => {
      setFacebookConversionPageview(event.target.checked);
    };

    const fbConversionEventMPData = [
      { value: '', label: 'Select Event' },
      ...fbConversionEventMP.map((fbConversionEventMP) => ({
        value: fbConversionEventMP.key,
        label: fbConversionEventMP.value
      })),
    ];

    const fbConversionCurrencyMPData = [
      { value: '', label: 'Select Currency' },
      ...fbConversionCurrencyMP.map((fbConversionCurrencyMP) => ({
        value: fbConversionCurrencyMP.key,
        label: fbConversionCurrencyMP.value,
        selected: fbConversionCurrencyMP.selected,
      })),
    ];

    useEffect(() => {
      const selectedEventItem = fbConversionEventMP.find(item => item.selected === "SELECTED");
      if (selectedEventItem) {
        setFbConversionEventValueMP(selectedEventItem.key);
      }
      const selectedCurrencyItem = fbConversionCurrencyMP.find(item => item.selected === "SELECTED");
      if (selectedCurrencyItem) {
        setFbConversionCurrencyValueMP(selectedCurrencyItem.key);
      }
    }, [fbConversionEventMP,fbConversionCurrencyMP]);

    const getFbSelectedValue = (items, key, isMultiple = false) => {
      if (isMultiple) {
        return items.filter(item => item.selected === 'SELECTED').map(item => item[key]);
      }
      const selectedItem = items.find(item => item.selected === 'SELECTED');
      return selectedItem ? selectedItem[key] : '';
    };

    const handleFbSelectChange = (index, key, value, valueKey, isMultiple = false) => {
      /*const allSelectedGoalKeys = getAllSelectedGoalKeys();
      const alreadySelected = value.some(val => allSelectedGoalKeys.includes(val));
      if (alreadySelected) {
        alert("This goal is already selected in another goal event.");
        return;
      }*/

      const updatedRows = fbConvMessageRow.map((group, i) => {
        if (i === index) {
          return {
            ...group,
            [key]: group[key].map(item =>
              isMultiple
                ? value.includes(item[valueKey])
                  ? { ...item, selected: 'SELECTED' }
                  : { ...item, selected: '' }
                : item[valueKey] === value
                ? { ...item, selected: 'SELECTED' }
                : { ...item, selected: '' }
            )
          };
        }
        return group;
      });
      setFbConvMessageRow(updatedRows);
    };


    /*const getAllSelectedGoalKeys = () => {
      return fbConvMessageRow.flatMap((group) => 
        getFbSelectedValue(group.fb_conversion_goal, 'key', true) || []
      );
    };*/

    const addfbGoals = () => {
      setGoalGroups(groups => [
        ...groups,
        { id: groups.length + 1, goal: [], event: '', currency: '' }
      ]);
    };

    const removefbGoals = (id,name) => {
      if(name === 'getFbValue'){
        setFbConvMessageRow(prevRows => prevRows.filter((_, i) => i !== id));
      }else{
        setGoalGroups(groups => groups.filter(group => group.id !== id));
      }
    };
  /*End Facebook Step5*/

  /*Google Step5*/
    const glCustomerData = [
      { value: '', label: 'Select Customer ID' },
      ...glCustomer.map((glCustomer) => ({
        value: glCustomer.key,
        label: glCustomer.value,
        selected: glCustomer.selected,
      })),
    ];

    const glSubCustomerData = [
      { value: '', label: 'Select Sub Customer ID' },
      ...glSubCustomer.map((glSubCustomer) => ({
        value: glSubCustomer.key,
        label: glSubCustomer.value,
        selected: glSubCustomer.selected,
      })),
    ];

    const handleglCustomerChange = (value) => {
      setGlCustomerValue(value);
      setGlSubCustomerValue('');
      getgoogleconvadsubcustomer(value);
      setGlConvActionValue('');
      setGlConvMessageRow([]);
      setGlGoalGroups([]);
    };

    const handleglSubCustomerChange = (value) => {
      setGlSubCustomerValue(value);
      setGlConvActionValue('');
      setGlConvMessageRow([]);
      setGlGoalGroups([]);
    };

    const glConvPageDetailData = glConvPageDetail.map((glConvPageDetail) => ({
        value: glConvPageDetail.key,
        label: glConvPageDetail.value,
      }));

    const glConversionActionMPData = [
      { value: '', label: 'Select Conversion Action' },
      ...glConversionActionMP.map((glConversionActionMP) => ({
        value: glConversionActionMP.key_resource,
        label: glConversionActionMP.value_resource,
      })),
    ];

    useEffect(() => {
      const selectedItem = glConversionActionMP.find(item => item.selected === "selected");
      if (selectedItem) {
        setGlConvActionValue(selectedItem.key_resource);
        setUseglConversionActionMPData(true);
      } else {
        setUseglConversionActionMPData(false);
      }
    }, [glConversionActionMP]); 

    const glConvActionData = [
      { value: '', label: 'Select Conversion Action' },
      ...glConvAction.map((glConvAction) => ({
        value: glConvAction.key,
        label: glConvAction.value,
      })),
    ];

    const handleglConversionActionChange = (value,id) => {
      setGlGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, action: value } : group
        )
      );
    };

    const handleglConvPageGoalChange = (value, id) => {
      /*const allSelectedGoalGlKeys = getAllSelectedGlGoalKeys();
      const alreadySelected = value.some(val => allSelectedGoalGlKeys.includes(val));
      if (alreadySelected) {
        alert("This page is already selected in another goal or event.");
        return;
      }*/

      setGlGoalGroups(groups =>
        groups.map(group =>
          group.id === id ? { ...group, page: value } : group
        )
      );
    };

    const handleGoogleConversionPageviewChange = (event) => {
      setGoogleConversionPageview(event.target.checked);
    };

    const getGlSelectedValue = (items, key, isMultiple = false) => {
      if (isMultiple) {
        return items.filter(item => item.selected === 'selected').map(item => item[key]);
      }
      const selectedItem = items.find(item => item.selected === 'selected');
      return selectedItem ? selectedItem[key] : '';
    };

    const handleGlSelectChange = (index, key, value, valueKey, isMultiple = false) => {
      
      /*const allSelectedGoalGlKeys = getAllSelectedGlGoalKeys();
      const alreadySelected = value.some(val => allSelectedGoalGlKeys.includes(val));
      if (alreadySelected) {
        alert("This goal is already selected in another goal event.");
        return;
      }*/

      const updatedRows = glConvMessageRow.map((group, i) => {
        if (i === index) {
          return {
            ...group,
            [key]: group[key].map(item =>
              isMultiple
                ? value.includes(item[valueKey])
                  ? { ...item, selected: 'selected' }
                  : { ...item, selected: '' }
                : item[valueKey] === value
                ? { ...item, selected: 'selected' }
                : { ...item, selected: '' }
            )
          };
        }
        return group;
      });
      setGlConvMessageRow(updatedRows);
    };

    const getAllSelectedGlGoalKeys = () => {
       const selectedFromMessageRow = glConvMessageRow.flatMap(group => 
        getGlSelectedValue(group.gl_conv_page_id, 'key_goal_gl', true) || []
      );
      const selectedFromGoalGroups = glgoalGroups.flatMap(group => group.page || []);
      return [...selectedFromMessageRow, ...selectedFromGoalGroups];
    };

    const addglGoals = () => {
      setGlGoalGroups(groups => [
        ...groups,
        { id: groups.length + 1, action: '', page: '' }
      ]);
    };

    const removeglGoals = (id,name) => {
      if(name === 'getGlValue'){
        setGlConvMessageRow(prevRows => prevRows.filter((_, i) => i !== id));
      }else{
        setGlGoalGroups(groups => groups.filter(group => group.id !== id));
      }
    };

    useEffect(() => {
      const glgoalTotalRow = glgoalGroups.length;
      const glmessagerowTotalRow = glConvMessageRow.length;

      setGlTotalRowShow(glgoalTotalRow + glmessagerowTotalRow);
    }, [glgoalGroups,glConvMessageRow]);
  /*End Google Step5*/

  /*Youtube Step5*/
    const ytCustomerData = [
      { value: '', label: 'Select Customer ID' },
      ...ytCustomer.map((ytCustomer) => ({
        value: ytCustomer.key,
        label: ytCustomer.value,
        selected: ytCustomer.selected,
      })),
    ];

    const ytSubCustomerData = [
      { value: '', label: 'Select Sub Customer ID' },
      ...ytSubCustomer.map((ytSubCustomer) => ({
        value: ytSubCustomer.key,
        label: ytSubCustomer.value,
        selected: ytSubCustomer.selected,
      })),
    ];

    const handleytCustomerChange = (value) => {
      setYtCustomerValue(value);
      setYtSubCustomerValue('');
      getyoutubeconvadsubcustomer(value);
      setYtConvActionValue('');
      setYtConvMessageRow([]);
      setYtGoalGroups([]);
    };

    const handleytSubCustomerChange = (value) => {
      setYtSubCustomerValue(value);
      // setYtCustomerSet(true);
      setYtConvActionValue('');
      setYtConvMessageRow([]);
      setYtGoalGroups([]);
    };

    const ytConvPageDetailData = ytConvPageDetail.map((ytConvPageDetail) => ({
        value: ytConvPageDetail.key,
        label: ytConvPageDetail.value,
      }));

    const ytConvActionData = [
      { value: '', label: 'Select Conv Action' },
      ...ytConvAction.map((ytConvAction) => ({
        value: ytConvAction.key,
        label: ytConvAction.value,
      })),
    ];

    const ytConversionActionMPData = [
      { value: '', label: 'Select Conversion Action' },
      ...ytConversionActionMP.map((ytConversionActionMP) => ({
        value: ytConversionActionMP.key_resource,
        label: ytConversionActionMP.value_resource,
      })),
    ];

    useEffect(() => {
      const selectedItem = ytConversionActionMP.find(item => item.selected === "selected");
      if (selectedItem) {
        setYtConvActionValue(selectedItem.key_resource);
        setUseytConversionActionMPData(true);
      } else {
        setUseytConversionActionMPData(false);
      }
    }, [ytConversionActionMP]);

    const handleytConversionActionChange = (value,id) => {
      setYtGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, action: value } : group
        )
      );
    };

    const handleytConvPageGoalChange = (value,id) => {
      setYtGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, page: value } : group
        )
      );
    };

    const handleYoutubeConversionPageviewChange = (event) => {
      setYoutubeConversionPageview(event.target.checked);
    };
    
    const getYtSelectedValue = (items, key, isMultiple = false) => {
      if (isMultiple) {
        return items.filter(item => item.selected === 'selected').map(item => item[key]);
      }
      const selectedItem = items.find(item => item.selected === 'selected');
      return selectedItem ? selectedItem[key] : '';
    };

    const handleYtSelectChange = (index, key, value, valueKey, isMultiple = false) => {
      /*const allSelectedYtGoalKeys = getAllSelectedYtGoalKeys();
      const alreadySelected = value.some(val => allSelectedYtGoalKeys.includes(val));
      if (alreadySelected) {
        alert("This goal is already selected in another goal event.");
        return;
      }*/

      const updatedRows = ytConvMessageRow.map((group, i) => {
        if (i === index) {
          return {
            ...group,
            [key]: group[key].map(item =>
              isMultiple
                ? value.includes(item[valueKey])
                  ? { ...item, selected: 'selected' }
                  : { ...item, selected: '' }
                : item[valueKey] === value
                ? { ...item, selected: 'selected' }
                : { ...item, selected: '' }
            )
          };
        }
        return group;
      });
      setYtConvMessageRow(updatedRows);
    };

    /*const getAllSelectedYtGoalKeys = () => {
      return ytConvMessageRow.flatMap((group) => 
        getYtSelectedValue(group.yt_conv_page_id, 'key_goal_yt', true) || []
      );
    };*/

    const addytGoals = () => {
      setYtGoalGroups(groups => [
        ...groups,
        { id: groups.length + 1, action: '', page: '' }
      ]);
    };

    const removeytGoals = (id,name) => {
      if(name === 'getYtValue'){
        setYtConvMessageRow(prevRows => prevRows.filter((_, i) => i !== id));
      }else{
        setYtGoalGroups(groups => groups.filter(group => group.id !== id));
      }
    };

    useEffect(() => {
      const ytgoalTotalRow = ytgoalGroups.length;
      const ytmessagerowTotalRow = ytConvMessageRow.length;

      setYtTotalRowShow(ytgoalTotalRow + ytmessagerowTotalRow);
    }, [ytgoalGroups,ytConvMessageRow]);
  /*End Youtube Step5*/

  /*Microsoft Step5*/
    const msAdAccountDetailsData = [
      { value: '', label: 'Select Ads Account ID' },
      ...msAdAccountDetails.map((msAdAccountDetails) => ({
        value: msAdAccountDetails.key,
        label: msAdAccountDetails.value,
        selected: msAdAccountDetails.selected,
      })),
    ];

    useEffect(() => {
      const selectedItem = msAdAccountDetails.find(item => item.selected === "selected");
      if (selectedItem) {
        setMsAdAccountDetailsValue(selectedItem.key);
      } else {
        setMsAdAccountDetailsValue('');
      }
    }, [msAdAccountDetails]);

    const msConversionActionMPData = [
      { value: '', label: 'Select Conversion Action' },
      ...msConversionActionMP.map((msConversionActionMP) => ({
        value: msConversionActionMP.id,
        label: msConversionActionMP.name,
      })),
    ];

    const msConversionActionData = [
      { value: '', label: 'Select Conversion Action' },
      ...msConversionAction.map((msConversionAction) => ({
        value: msConversionAction.id,
        label: msConversionAction.name,
      })),
    ];

    useEffect(() => {
      const selectedItem = msConversionActionMP.find(item => item.selected === "selected");
      if (selectedItem) {
        setMsConvActionValue(selectedItem.id);
        setUseMsConversionActionMPData(true);
      } else {
        setMsConvActionValue('');
        setUseMsConversionActionMPData(false);
      }
    }, [msConversionActionMP]); 

    const msConvPageDetailData = [
      { value: '', label: 'Select Conversion Page' },
      ...msConvPageDetail.map((msConvPageDetail) => ({
        value: msConvPageDetail.key,
        label: msConvPageDetail.value,
      })),
    ];


    const handlemsAdAccountDetailsChange = (value) => {
      setMsAdAccountDetailsValue(value);
      setMsConvActionValue('');
    };

    const handleMicrosoftConversionPageviewChange = (event) => {
      setMicrosoftConversionPageview(event.target.checked);
    };

    const getMsSelectedValue = (items, key) => {
      const selectedItem = items.find(item => item.selected === 'selected');
      return selectedItem ? selectedItem[key] : '';
    };

    const handleMsSelectChange = (index, key, value, valueKey) => {
      const updatedRows = msConvMessageRow.map((group, i) => {
        if (i === index) {
          return {
            ...group,
            [key]: group[key].map(item =>
              item[valueKey] === value
                ? { ...item, selected: 'selected' }
                : { ...item, selected: '' }
            )
          };
        }
        return group;
      });
      setMsConvMessageRow(updatedRows);
    };

    const handlemsConvPageDetailChange = (value,id) => {
      setMsGoalGroups(groups =>
        groups.map(group => 
          group.id === id ? { ...group, page: value } : group
        )
      );
    };

    const handlemsConversionActionChange = (value,id) => {
      setMsGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, action: value } : group
        )
      );
    };

    const addmsGoals = () => {
      setMsGoalGroups(groups => [
        ...groups,
        { id: groups.length + 1, action: '', page: '' }
      ]);
    };

    const removemsGoals = (id,name) => {
      if(name === 'getMsValue'){
        setMsConvMessageRow(prevRows => prevRows.filter((_, i) => i !== id));
      }else{
        setMsGoalGroups(groups => groups.filter(group => group.id !== id));
      }
    };
  /*End Microsoft Step5*/

  /*Taboola Step5*/
    const tbAdAccountDetailsData = [
      { value: '', label: 'Select Ads Account ID' },
      ...tbAdAccountDetails.map((tbAdAccountDetails) => ({
        value: tbAdAccountDetails.key,
        label: tbAdAccountDetails.value,
        selected: tbAdAccountDetails.selected,
      })),
    ];

    useEffect(() => {
      const selectedItem = tbAdAccountDetails.find(item => item.selected === "selected");
      if (selectedItem) {
        setTbAdAccountDetailsValue(selectedItem.key);
      } else {
        setTbAdAccountDetailsValue('');
      }
    }, [tbAdAccountDetails]);

    const tbConversionActionMPData = [
      { value: '', label: 'Select Conversion Action' },
      ...tbConversionActionMP.map((tbConversionActionMP) => ({
        value: tbConversionActionMP.event_name,
        label: tbConversionActionMP.display_name,
      })),
    ];

    const tbConversionActionData = [
      { value: '', label: 'Select Conversion Action' },
      ...tbConversionAction.map((tbConversionAction) => ({
        value: tbConversionAction.event_name,
        label: tbConversionAction.display_name,
      })),
    ];

    useEffect(() => {
      const selectedItem = tbConversionActionMP.find(item => item.selected === "selected");
      if (selectedItem) {
        setTbConvActionValue(selectedItem.event_name);
        setUseTbConversionActionMPData(true);
      } else {
        setTbConvActionValue('');
        setUseTbConversionActionMPData(false);
      }
    }, [tbConversionActionMP]); 

    const tbConvPageDetailData = [
      { value: '', label: 'Select Conversion Page' },
      ...tbConvPageDetail.map((tbConvPageDetail) => ({
        value: tbConvPageDetail.key,
        label: tbConvPageDetail.value,
      })),
    ];


    const handletbAdAccountDetailsChange = (value) => {
      setTbAdAccountDetailsValue(value);
      setTbConvActionValue('');
    };

    const handleTaboolaConversionPageviewChange = (event) => {
      setTaboolaConversionPageview(event.target.checked);
    };

    const getTbSelectedValue = (items, key) => {
      const selectedItem = items.find(item => item.selected === 'selected');
      return selectedItem ? selectedItem[key] : '';
    };

    const handleTbSelectChange = (index, key, value, valueKey) => {
      const updatedRows = tbConvMessageRow.map((group, i) => {
        if (i === index) {
          return {
            ...group,
            [key]: group[key].map(item =>
              item[valueKey] === value
                ? { ...item, selected: 'selected' }
                : { ...item, selected: '' }
            )
          };
        }
        return group;
      });
      setTbConvMessageRow(updatedRows);
    };

    const handletbConvPageDetailChange = (value,id) => {
      setTbGoalGroups(groups =>
        groups.map(group => 
          group.id === id ? { ...group, page: value } : group
        )
      );
    };

    const handletbConversionActionChange = (value,id) => {
      setTbGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, action: value } : group
        )
      );
    };

    const addtbGoals = () => {
      setTbGoalGroups(groups => [
        ...groups,
        { id: groups.length + 1, action: '', page: '' }
      ]);
    };

    const removetbGoals = (id,name) => {
      if(name === 'getTbValue'){
        setTbConvMessageRow(prevRows => prevRows.filter((_, i) => i !== id));
      }else{
        setTbGoalGroups(groups => groups.filter(group => group.id !== id));
      }
    };
  /*End Taboola Step5*/

  /*Outbrain Step5*/
    const obAdAccountDetailsData = [
      { value: '', label: 'Select Ads Account ID' },
      ...obAdAccountDetails.map((obAdAccountDetails) => ({
        value: obAdAccountDetails.id,
        label: obAdAccountDetails.name,
        selected: obAdAccountDetails.selected,
      })),
    ];

    useEffect(() => {
      const selectedItem = obAdAccountDetails.find(item => item.selected === "selected");
      if (selectedItem) {
        setObAdAccountDetailsValue(selectedItem.id);
      } else {
        setObAdAccountDetailsValue('');
      }
    }, [obAdAccountDetails]);

    const obConversionActionMPData = [
      { value: '', label: 'Select Conversion Action' },
      ...obConversionActionMP.map((obConversionActionMP) => ({
        value: obConversionActionMP.key,
        label: obConversionActionMP.value,
      })),
    ];

    const obConversionActionData = [
      { value: '', label: 'Select Conversion Action' },
      ...obConversionAction.map((obConversionAction) => ({
        value: obConversionAction.key,
        label: obConversionAction.value,
      })),
    ];

    useEffect(() => {
      const selectedItem = obConversionActionMP.find(item => item.selected === "selected");
      if (selectedItem) {
        setObConvActionValue(selectedItem.key);
        setUseObConversionActionMPData(true);
      } else {
        setObConvActionValue('');
        setUseObConversionActionMPData(false);
      }
    }, [obConversionActionMP]); 

    const obConvPageDetailData = [
      { value: '', label: 'Select Conversion Page' },
      ...obConvPageDetail.map((obConvPageDetail) => ({
        value: obConvPageDetail.key,
        label: obConvPageDetail.value,
      })),
    ];


    const handleobAdAccountDetailsChange = (value) => {
      setObAdAccountDetailsValue(value);
      setObConvActionValue('');
    };

    const handleOutbrainConversionPageviewChange = (event) => {
      setOutbrainConversionPageview(event.target.checked);
    };

    const getObSelectedValue = (items, key) => {
      const selectedItem = items.find(item => item.selected === 'selected');
      return selectedItem ? selectedItem[key] : '';
    };

    const handleObSelectChange = (index, key, value, valueKey) => {
      const updatedRows = obConvMessageRow.map((group, i) => {
        if (i === index) {
          return {
            ...group,
            [key]: group[key].map(item =>
              item[valueKey] === value
                ? { ...item, selected: 'selected' }
                : { ...item, selected: '' }
            )
          };
        }
        return group;
      });
      setObConvMessageRow(updatedRows);
    };

    const handleobConvPageDetailChange = (value,id) => {
      setObGoalGroups(groups =>
        groups.map(group => 
          group.id === id ? { ...group, page: value } : group
        )
      );
    };

    const handleobConversionActionChange = (value,id) => {
      setObGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, action: value } : group
        )
      );
    };

    const addobGoals = () => {
      setObGoalGroups(groups => [
        ...groups,
        { id: groups.length + 1, action: '', page: '' }
      ]);
    };

    const removeobGoals = (id,name) => {
      if(name === 'getObValue'){
        setObConvMessageRow(prevRows => prevRows.filter((_, i) => i !== id));
      }else{
        setObGoalGroups(groups => groups.filter(group => group.id !== id));
      }
    };
  /*End Outbrain Step5*/

  /*Tiktok Step5*/
    const tkAdvertiserAccount = [
      { value: '7156155148614615041', label: 'Visiopt1019', selected: false },
      { value: '7202954174781308929', label: 'Visiopt dev advertiser', selected: false },
      { value: '7203468866968731649', label: 'Visiopt', selected: true}
    ];

    const tkAdvertiserAccountData = [
      { value: '', label: 'Select Advertiser Account', selected: false },
      ...tkAdvertiserAccount.map((account) => ({
        value: account.value,
        label: account.label,
        selected: account.selected,
      })),
    ];

    const handletkAdvertiserAccountChange = (value) => {
      setTkAdvertiserAccountValue(value);
    };

    const handleTiktokConversionPageviewChange = (event) => {
      setTiktokConversionPageview(event.target.checked);
    };

    const tkPixelMP = [
      { value: 'CFR09H3C77UBG01LTFCG', label: 'Visiopt Dev 1' },
      { value: 'CFR0AOJC77UBIS8PJJ8G', label: 'Visiopt Dev 2' },
      { value: 'CG4T223C77U573C8LFKG', label: 'Visiopt Dev 3' },
      { value: 'CLTD4K3C77U2ODAMQRUG', label: 'Visiopt test' },
      { value: 'CLTDFB3C77U95UC4L0F0', label: 'Visiopt Test 2' }
    ];

    const tkPixelMPData = [
      { value: '', label: 'Select Pixel' },
      ...tkPixelMP.map((tkPixelMP) => ({
        value: tkPixelMP.value,
        label: tkPixelMP.label,
      })),
    ];

    const handletkPixelMPChange = (value) => {
      setTkPixelMPValue(value);
    };

    const tkEventMP = [
      { value: 'AddPaymentInfo', label: 'Add Payment Info' },
      { value: 'AddToCart', label: 'Add to Cart' },
      { value: 'AddToWishlist', label: 'Add to Wishlist' },
      { value: 'ClickButton', label: 'Click Button' },
      { value: 'CompletePayment', label: 'Complete Payment' },
      { value: 'CompleteRegistration', label: 'Complete Registration' },
      { value: 'Contact', label: 'Contact' },
      { value: 'Download', label: 'Download' },
      { value: 'InitiateCheckout', label: 'Initiate Checkout' },
      { value: 'PlaceAnOrder', label: 'Place an Order' },
      { value: 'Search', label: 'Search' },
      { value: 'SubmitForm', label: 'Submit Form' },
      { value: 'Subscribe', label: 'Subscribe' },
      { value: 'ViewContent', label: 'View Content' }
    ];

    const tkEventMPData = [
      { value: '', label: 'Select Event Name' },
      ...tkEventMP.map((tkEventMP) => ({
        value: tkEventMP.value,
        label: tkEventMP.label,
      })),
    ];

    const handletkEventMPChange = (value) => {
      setTkEventMPValue(value);
    };
  /*End Tiktok Step5*/

/*Start Save Conversion Data*/
  const handleSaveConversionData = () => {
    const fbPixelArray = Array.isArray(fbAdAccountDetails)
    ? fbAdAccountDetails.map((element, index) => ({
        fb_ad_account_id: fbAccountIdValue[index] ?? element.fb_account_id,
        fb_pixel_id: fbPixelIdValue[index] ?? element.fb_pixel_id,
        fb_access_token: fbAccessTokenValue[index] ?? element.fb_access_token
      }))
    : [];

    const fbConversionGoal = {};
    const fbConversionEvent = {};
    const fbConversionCurrency = {};

    const getValidNumbers = (values) => {
      return values
        .map(value => parseInt(value, 10))
        .filter(value => !isNaN(value))
        .join(',');
    };

    fbConvMessageRow.forEach((group, index) => {   
      const fbGoalValues = getFbSelectedValue(group.fb_conversion_goal, 'key', true);           
      fbConversionGoal[`${index + 1}`] = getValidNumbers(fbGoalValues);
      fbConversionEvent[`${index + 1}`] = getFbSelectedValue(group.fb_conversion_event, 'key');
      fbConversionCurrency[`${index + 1}`] = getFbSelectedValue(group.fb_conversion_currency, 'key');
    });

    goalGroups.forEach((group, i) => {
      const adjustedIndex = fbConvMessageRow.length + i + 1;
      fbConversionGoal[`${adjustedIndex}`] = group.goal.map(value => parseInt(value, 10)).join(',');
      fbConversionEvent[`${adjustedIndex}`] = group.event;
      fbConversionCurrency[`${adjustedIndex}`] = group.currency;
    });

    const glConversionAction = {};
    const glConvPageDetail = {};

    glConvMessageRow.forEach((group, index) => {
      const glGoalValues = getGlSelectedValue(group.gl_conv_page_id, 'key_goal_gl', true);           
      glConvPageDetail[`${index + 1}`] = getValidNumbers(glGoalValues);
      glConversionAction[`${index + 1}`] = getGlSelectedValue(group.gl_conv_action_resource_name, 'key_resource');
    });

    glgoalGroups.forEach((group, i) => {
      const adjustedIndex = glConvMessageRow.length + i + 1;
      glConvPageDetail[`${adjustedIndex}`] = (Array.isArray(group.page) ? group.page : []).map(value => parseInt(value, 10)).join(',');
      glConversionAction[`${adjustedIndex}`] = group.action;
    });

    const ytConversionAction = {};
    const ytConvPageDetail = {};

    ytConvMessageRow.forEach((group, index) => {
      const ytGoalValues = getYtSelectedValue(group.yt_conv_page_id, 'key_goal_yt', true);           
      ytConvPageDetail[`${index + 1}`] = getValidNumbers(ytGoalValues);
      ytConversionAction[`${index + 1}`] = getYtSelectedValue(group.yt_conv_action_resource_name, 'key_resource');
    });

    ytgoalGroups.forEach((group, i) => {
      const adjustedIndex = ytConvMessageRow.length + i + 1;
      ytConvPageDetail[`${adjustedIndex}`] = (Array.isArray(group.page) ? group.page : []).map(value => parseInt(value, 10)).join(',');
      ytConversionAction[`${adjustedIndex}`] = group.action;
    });

    const tbConversionActionValue = tbgoalGroups.map(group => group.action).join(',');
    const tbConvPageDetailValue = tbgoalGroups.map(group => group.page).join(',');

    const tb_conv_action_resource_name = tbConvMessageRow.map(group => 
      getTbSelectedValue(group.tb_conv_action_resource_name, 'event_name')
    ).join(',');

    const tb_conv_page_id = tbConvMessageRow.map(group => 
      getTbSelectedValue(group.tb_conv_page_id, 'key_goal_tb')
    ).join(',');

    const tbConversionAction = `${tb_conv_action_resource_name},${tbConversionActionValue}`;
    const tbConvPageDetail = `${tb_conv_page_id},${tbConvPageDetailValue}`;

    const obConversionActionValue = obgoalGroups.map(group => group.action).join(',');
    const obConvPageDetailValue = obgoalGroups.map(group => group.page).join(',');

    const ob_conv_action_resource_name = obConvMessageRow.map(group => 
      getObSelectedValue(group.ob_conv_action_resource_name, 'key')
    ).join(',');

    const ob_conv_page_id = obConvMessageRow.map(group => 
      getObSelectedValue(group.ob_conv_page_id, 'key')
    ).join(',');

    const obConversionAction = `${ob_conv_action_resource_name},${obConversionActionValue}`;
    const obConvPageDetail = `${ob_conv_page_id},${obConvPageDetailValue}`;

    const msConversionActionValue = msgoalGroups.map(group => group.action).join(',');
    const msConvPageDetailValue = msgoalGroups.map(group => group.page).join(',');

    const ms_conv_action_resource_name = msConvMessageRow.map(group => 
      getMsSelectedValue(group.ms_conv_action_resource_name, 'key')
    ).join(',');

    const ms_conv_page_id = msConvMessageRow.map(group => 
      getMsSelectedValue(group.ms_conv_page_id, 'key')
    ).join(',');

    const msConversionAction = `${ms_conv_action_resource_name},${msConversionActionValue}`;
    const msConvPageDetail = `${ms_conv_page_id},${msConvPageDetailValue}`;
    
    const conversion_api_type = 
      activeTab === 'Facebook' ? 'facebook' :
      activeTab === 'Google' ? 'google ads' :
      activeTab === 'Microsoft' ? 'microsoft ads' :
      activeTab === 'Tiktok' ? 'tiktok ads' :
      activeTab === 'Youtube' ? 'youtube-ads' :
      activeTab === 'Taboola' ? 'taboola-ads' :
      activeTab === 'Outbrain' ? 'outbrain-ads' :
    'facebook';
  
    let hasEmptyGroup = false;
    if (activeTab === 'Facebook' && (!fbConvMessageRow || fbConvMessageRow.length === 0) && (!goalGroups || goalGroups.length === 0 || goalGroups.every(group => !group.event.trim() || (Array.isArray(group.goal) ? group.goal.length === 0 : !group.goal.trim())) )) {
      hasEmptyGroup = true;
    }
    if (activeTab === 'Google' && (!glConvMessageRow || glConvMessageRow.length === 0) && (!glgoalGroups || glgoalGroups.length === 0 || glgoalGroups.every(group => !group.action.trim() || (Array.isArray(group.page) ? group.page.length === 0 : !group.page.trim())) )) {
      hasEmptyGroup = true;
    }
    if (activeTab === 'Youtube' && (!ytConvMessageRow || ytConvMessageRow.length === 0) && (!ytgoalGroups || ytgoalGroups.length === 0 || ytgoalGroups.every(group => !group.action.trim() || (Array.isArray(group.page) ? group.page.length === 0 : !group.page.trim())) )) {
      hasEmptyGroup = true;
    }
    if (hasEmptyGroup) {
      modals.open({
        title: 'Please correct the following errors:',
        children: (
        <>
          <Text size="sm">
            Please add at least one goal to save the campaign
          </Text>
          <Group position="right" mt="sm">
            <Button onClick={() => modals.closeAll()}>OK</Button>
          </Group>
        </>
        ),
      });
      return;
    }

    let hasEmptyCustomer = false;
    let errorMessage = "";

    if (activeTab === 'Facebook' && (fbPixelArray.every(group => (Array.isArray(group.fb_access_token) ? group.fb_access_token.length === 0 : !group.fb_access_token.trim())) )) {
      hasEmptyCustomer = true;
      errorMessage += "Facebook Access Token is required.\n";
    }

    if (activeTab === 'Facebook' && (fbPixelArray.every(group => (Array.isArray(group.fb_pixel_id) ? group.fb_pixel_id.length === 0 : !group.fb_pixel_id.trim())) )) {
      hasEmptyCustomer = true;
      errorMessage += "Facebook Pixel ID is required.\n";
    }

    if (activeTab === 'Google' && (!glCustomerValue || glCustomerValue.length === 0)) {
      hasEmptyCustomer = true;
      errorMessage += "Please Select Customer ID\n";
    }

    if (activeTab === 'Google' && (!glSubCustomerValue || glSubCustomerValue.length === 0)) {
      hasEmptyCustomer = true;
      errorMessage += "Please Select Sub Customer ID\n";
    }

    if (activeTab === 'Youtube' && (!ytCustomerValue || ytCustomerValue.length === 0)) {
      hasEmptyCustomer = true;
      errorMessage += "Please Select Customer ID\n";
    }

    if (activeTab === 'Youtube' && (!ytSubCustomerValue || ytSubCustomerValue.length === 0)) {
      hasEmptyCustomer = true;
      errorMessage += "Please Select Sub Customer ID\n";
    }

    if (hasEmptyCustomer) {
      modals.open({
        title: 'Please correct the following errors:',
        children: (
        <>
          <Text size="sm" style={{ whiteSpace: "pre-line" }}>
            {errorMessage.trim()}
          </Text>
          <Group position="right" mt="sm">
            <Button onClick={() => modals.closeAll()}>OK</Button>
          </Group>
        </>
        ),
      });
      return;
    }
    campaignLoader(true);
    CampaignApi.saveconversiondata({
      system_website_id : selectedMemberWebsite,
      campaign_id : campaign_id || campaignId,
      conv_attribution_model : convAttributionModel,
      fb_pixel_arr: fbPixelArray,
      fb_total_row: fbTotalRowShow,
      fb_conversion_event_pageview: fbConversionEventValueMP,
      fb_conversion_currency_pageview: fbConversionCurrencyValueMP,
      facebook_conversion_pageview: facebookConversionPageview === true ? 1 : 0,
      fb_conversion_goal: fbConversionGoal,
      fb_conversion_event: fbConversionEvent,
      fb_conversion_currency: fbConversionCurrency,
      send_all_conversion_fb: isFbSendAllConversionChecked === true ? 1 : 0,

      gl_total_row: glTotalRowShow,
      gl_conv_customer_id: glCustomerValue,
      gl_conv_subcustomer_id: glSubCustomerValue,
      google_conversion_pageview: googleConversionPageview === true ? 1 : 0,
      gl_conv_action_resource_name_pageview: glConvActionValue,
      gl_conv_action_resource_name: glConversionAction,
      gl_conv_page_id: glConvPageDetail,

      yt_total_row: ytTotalRowShow,
      yt_conv_customer_id: ytCustomerValue,
      yt_conv_subcustomer_id: ytSubCustomerValue,
      youtube_conversion_pageview: youtubeConversionPageview === true ? 1 : 0,
      yt_conv_action_resource_name_pageview: ytConvActionValue,
      yt_conv_action_resource_name: ytConversionAction,
      yt_conv_page_id: ytConvPageDetail,
      
      tb_conv_ad_account_id: tbAdAccountDetailsValue,
      taboola_conversion_pageview: taboolaConversionPageview === true ? 1 : 0,
      tb_conv_action_resource_name_pageview: tbConvActionValue,
      tb_conv_action_resource_name: tbConversionAction,
      tb_conv_page_id: tbConvPageDetail,

      ob_conv_ad_account_id: obAdAccountDetailsValue,
      outbrain_conversion_pageview: outbrainConversionPageview === true ? 1 : 0,
      ob_conv_action_resource_name_pageview: obConvActionValue,
      ob_conv_action_resource_name: obConversionAction,
      ob_conv_page_id: obConvPageDetail,

      ms_conv_ad_account_id: msAdAccountDetailsValue,
      microsoft_conversion_pageview: microsoftConversionPageview === true ? 1 : 0,
      ms_conv_action_resource_name_pageview: msConvActionValue,
      ms_conv_action_resource_name: msConversionAction,
      ms_conv_page_id: msConvPageDetail,
      conversion_api_type: conversion_api_type
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        campaignLoader(false);
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        navigate("/manage-campaigns");
      }
    })
    .catch(error => {
      campaignLoader(false);
      console.log(error);
    });
  }
/*End Save Conversion Data*/
  const handleTabChange = (value) => {
    if (value === 'Facebook' && !isFacebookChecked) return;
    if (value === 'Google' && !isGoogleChecked) return;
    if (value === 'Microsoft' && !isMicrosoftChecked) return;
    if (value === 'Tiktok' && !isTiktokChecked) return;
    if (value === 'Youtube' && !isYoutubeChecked) return;
    if (value === 'Taboola' && !isTaboolaChecked) return;
    if (value === 'Outbrain' && !isOutbrainChecked) return;
    setActiveTab(value);
  };

  useEffect(() => {
    const tabsMap = {
      'facebook': { name: 'Facebook', isChecked: isFacebookChecked },
      'google ads': { name: 'Google', isChecked: isGoogleChecked },
      'microsoft ads': { name: 'Microsoft', isChecked: isMicrosoftChecked },
      'tiktok ads': { name: 'Tiktok', isChecked: isTiktokChecked },
      'youtube-ads': { name: 'Youtube', isChecked: isYoutubeChecked },
      'taboola-ads': { name: 'Taboola', isChecked: isTaboolaChecked },
      'outbrain-ads': { name: 'Outbrain', isChecked: isOutbrainChecked },
    };

    let activeTab = null;
    if (tabsMap[conversionApiType]?.isChecked) {
      activeTab = tabsMap[conversionApiType].name;
    } else {
      const lastCheckedTab = Object.values(tabsMap)
        .reverse()
        .find((tab) => tab.isChecked);
      if (lastCheckedTab) {
        activeTab = lastCheckedTab.name;
      }
    }
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, [ conversionApiType, isFacebookChecked, isGoogleChecked, isMicrosoftChecked, isTiktokChecked, isYoutubeChecked, isTaboolaChecked, isOutbrainChecked]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (step5IconRef.current) {
      const { top, left, width } = step5IconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal &&
        ReactDOM.createPortal(
          <div>
            <SelectTooltip
              isVisible={showHelpModal}
              setIsVisible={setShowHelpModal}
              id={helpTargetId}
              tooltipPosition={tooltipPosition}
            />
          </div>,
        document.body
      )}
      {step5Show === '1' && (
      <Accordion.Item className={classes.item} value="step-5">
        <Accordion.Control disabled={step5Disabled}><Text component="span" color="red" weight={500}>Step 5</Text><Text component="span" weight={500}> - API Set Up </Text></Accordion.Control>
        <Accordion.Panel>
          <Group spacing="xs" mb={5}>
            <Text weight={500}>Conversion Attribution:</Text>
            <Select
              data={[
                  { value: '1', label: 'First Click' },
                  { value: '2', label: 'Last Click' },
                  { value: '3', label: 'Full Impact' }
                ]}
              value={convAttributionModel}
              onChange={handleConvAttributionModelChange}
              name="conv_attribution_model"
              w={200}
            />
          </Group>
          <Tabs value={activeTab} onTabChange={handleTabChange} style={{ position: 'relative' }}>
          <LoadingOverlay zIndex={9999999999} visible={tabLoader} overlayBlur={2} loaderProps={{variant:"bars", color:"gray"}}/>
          <Tabs.List grow>
            <Tabs.Tab value="Facebook">Facebook</Tabs.Tab>
            <Tabs.Tab value="Google">Google</Tabs.Tab>
            <Tabs.Tab value="Microsoft">Microsoft</Tabs.Tab>
            <Tabs.Tab value="Tiktok">Tiktok</Tabs.Tab>
            <Tabs.Tab value="Youtube">Youtube</Tabs.Tab>
            <Tabs.Tab value="Taboola">Taboola</Tabs.Tab>
            <Tabs.Tab value="Outbrain">Outbrain</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="Facebook" pt="xs">
            <Table striped highlightOnHover withBorder mb={10}>
              <thead>
                <tr>
                  <th>Ad Account ID</th>
                  <th>Pixel ID</th>
                  <th>Access Token</th>
                </tr>
              </thead>
              <tbody>
                {
                  Array.isArray(fbAdAccountDetails) && fbAdAccountDetails.length > 0 ? (
                  <>
                    {fbAdAccountDetails.map((element,index) => (
                      <tr key={element.fb_account_name}>
                        <td style={{ display: 'none' }}>
                          <input type="hidden" value={fbAccountIdValue['index']} />
                        </td>
                        <td>{element.fb_account_name_id} </td>
                        <td>
                          <Input
                            value={fbPixelIdValue[index] ?? element.fb_pixel_id}
                            onChange={(e) => handleFbPixelIdChange(index, e.target.value)}
                          />
                        </td>
                        <td>
                          <Input
                            value={fbAccessTokenValue[index] ?? element.fb_access_token}
                            onChange={(e) => handleFbAccessTokenChange(index, e.target.value)}
                          />
                        </td>
                      </tr>
                    ))}
                  </>
                  ) : (
                    <tr>
                      <td> </td>
                      <td style={{ textAlign: 'center' }}>No Data Found</td>
                      <td> </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
            <Group spacing={5} align="center">
              <Checkbox
                name="send_all_conversion_fb"
                checked={isFbSendAllConversionChecked}
                onChange={(event) => handleFbSendAllConversionChange(event)}
                label={<strong>Send All conversion to Facebook</strong>}
              />
              <IconHelp ref={step5IconRef} size="1.1rem" color="red" style={{ cursor: 'pointer'}} onClick={() => handleHelpClick(330)}/>
            </Group>
            <Group mt={10}>
              <Checkbox
                name="facebook_conversion_pageview"
                label={<strong>LP visit push as pageview:</strong>}
                checked={facebookConversionPageview}
                onChange={(event) => handleFacebookConversionPageviewChange(event)}
              />
              <Select
                data={fbConversionEventMPData}
                value={fbConversionEventValueMP}
                onChange={(value) => setFbConversionEventValueMP(value)}
                name="fb_conversion_event_pageview"
                w={250}
              />
              {fbConversionEventValueMP === 'Purchase' && (
                <Select
                  data={fbConversionCurrencyMPData}
                  value={fbConversionCurrencyValueMP}
                  onChange={(value) => setFbConversionCurrencyValueMP(value)}
                  name="fb_conversion_currency_pageview[1][]"
                  w={250}
                />
              )}
            </Group>
            <Text mt={10}>Select the goals you want to pass to Facebook. Then select desired Event to pass for each of your campaign goals.</Text>

            {Array.isArray(fbConvMessageRow) && fbConvMessageRow.length > 0 && (
              <>
                {fbConvMessageRow.map((group, index) => (
                  <Group key={index} mt={10}>
                    <MultiSelect
                      data={group.fb_conversion_goal.map(action => ({
                        value: action.key,
                        label: action.value
                      }))}
                      value={getFbSelectedValue(group.fb_conversion_goal, 'key', true)}
                      onChange={(value) => handleFbSelectChange(index, 'fb_conversion_goal', value, 'key', true)}
                      name={`fb_conversion_goal[${index}][]`}
                      placeholder="Select Goal"
                      w={250}
                      searchable
                      multiple
                    />
                    <Select
                      data={group.fb_conversion_event.map(action => ({
                        value: action.key,
                        label: action.value
                      }))}
                      value={getFbSelectedValue(group.fb_conversion_event, 'key', false)}
                      onChange={(value) => handleFbSelectChange(index, 'fb_conversion_event', value, 'key', false)}
                      name={`fb_conversion_event[${index}][]`}
                      w={250}
                    />
                    {group.fb_conversion_event === 'Purchase' && (
                      <Select
                        data={group.fb_conversion_currency.map(action => ({
                          value: action.key,
                          label: action.value
                        }))}
                        value={getFbSelectedValue(group.fb_conversion_currency, 'key', false)}
                        onChange={(value) => handleFbSelectChange(index, 'fb_conversion_currency', value, 'key', false)}
                        name={`fb_conversion_currency[${index}][]`}
                        w={250}
                      />
                    )}
                    <ActionIcon title="Remove">
                      <IconCircleMinus size="1.1rem" color="red" onClick={() => removefbGoals(index,'getFbValue')}/>
                    </ActionIcon>
                  </Group>
                ))}
              </>
            )}

            {goalGroups.map(group => (
              <Group key={group.id} mt={10}>
                <MultiSelect
                    data={fbConversionGoalData}
                    value={group.goal}
                    onChange={(value) => handlefbConversionGoalChange(value, group.id)}
                    name={`fb_conversion_goal[${group.id}][]`}
                    placeholder="Select Goal"
                    w={250}
                    searchable
                    multiple
                  />
                  <Select
                    data={fbConversionEventData}
                    value={group.event}
                    onChange={(value) => handlefbConversionEventChange(value, group.id)}
                    name={`fb_conversion_event[${group.id}][]`}
                    w={250}
                  />
                  {group.event === 'Purchase' && (
                    <Select
                      data={fbConversionCurrencyData}
                      value={group.currency}
                      onChange={(value) => handlefbConversionCurrencyChange(value, group.id)}
                      name={`fb_conversion_currency[${group.id}][]`}
                      w={250}
                    />
                  )}
                  <ActionIcon title="Remove">
                    <IconCircleMinus size="1.1rem" color="red" onClick={() => removefbGoals(group.id,'')}/>
                  </ActionIcon> 
              </Group>
            ))}
            <Button color="teal" mt={10} onClick={addfbGoals}>
              <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
            </Button>
          </Tabs.Panel>

          <Tabs.Panel value="Google" pt="xs">
            <Group spacing="xs" mb={5}>
              <Text weight={500}>Customer ID:</Text>
              <Select
                data={glCustomerData}
                value={glCustomerValue}
                onChange={handleglCustomerChange}
                name="gl_conv_customer_id"
                w={200}
                ml={28}
              />
            </Group>
            <Group spacing="xs" mb={5}>
              <Text weight={500}>Sub Customer ID:</Text>
              <Select
                data={glSubCustomerData}
                value={glSubCustomerValue}
                onChange={handleglSubCustomerChange}
                name="gl_conv_subcustomer_id"
                w={200}
              />
            </Group>
            <Group mt={10}>
              <Checkbox
                name="google_conversion_pageview"
                label={<strong>LP visit push as pageview:</strong>}
                checked={googleConversionPageview}
                onChange={(event) => handleGoogleConversionPageviewChange(event)}
              />
              {useglConversionActionMPData ? (
                <Select
                  data={glConversionActionMPData}
                  value={glConvActionValue}
                  onChange={(value) => setGlConvActionValue(value)}
                  name="gl_conv_action_resource_name_pageview"
                  w={250}
                />
              ) : (
                <Select
                  data={glConvActionData}
                  value={glConvActionValue}
                  onChange={(value) => setGlConvActionValue(value)}
                  name="gl_conv_action_resource_name_pageview"
                  w={250}
                />
              )}
            </Group>

            {glConvMessageRow.map((group, index) => (
              <Group key={index} mt={10}>
                <Select
                  data={group.gl_conv_action_resource_name.map(action => ({
                    value: action.key_resource,
                    label: action.value_resource
                  }))}
                  name={`gl_conv_action_resource_name[]`}
                  value={getGlSelectedValue(group.gl_conv_action_resource_name, 'key_resource', false)}
                  onChange={(value) => handleGlSelectChange(index, 'gl_conv_action_resource_name', value, 'key_resource', false)}
                  w={250}
                />
                <MultiSelect
                  data={group.gl_conv_page_id.map(page => ({
                    value: page.key_goal_gl,
                    label: page.value_goal_gl
                  }))}
                  name={`gl_conv_page_id[]`}
                  value={getGlSelectedValue(group.gl_conv_page_id, 'key_goal_gl', true)}
                  onChange={(value) => handleGlSelectChange(index, 'gl_conv_page_id', value, 'key_goal_gl', true)}
                  placeholder="Select Conversion Page"
                  w={250}
                  multiple
                />
                <ActionIcon title="Remove">
                  <IconCircleMinus size="1.1rem" color="red" onClick={() => removeglGoals(index,'getGlValue')}/>
                </ActionIcon>
              </Group>
            ))}

            {glgoalGroups.map(group => (
              <Group key={group.id} mt={10}>
                <Select
                    data={glConvActionData}
                    value={group.action}
                    onChange={(value) => handleglConversionActionChange(value, group.id)}
                    name={`gl_conv_action_resource_name[]`}
                    w={250}
                  />
                  <MultiSelect
                    data={glConvPageDetailData}
                    value={group.page}
                    onChange={(value) => handleglConvPageGoalChange(value, group.id)}
                    name={`gl_conv_page_id[]`}
                    placeholder="Select Conversion Page"
                    w={250}
                    multiple
                  />
                  <ActionIcon title="Remove">
                    <IconCircleMinus size="1.1rem" color="red" onClick={() => removeglGoals(group.id,'')}/>
                  </ActionIcon>
              </Group>
            ))}
            <Button color="teal" mt={10} onClick={addglGoals}>
              <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
            </Button>
          </Tabs.Panel>

          <Tabs.Panel value="Microsoft" pt="xs">
            <Group spacing="xs" mb={5}>
              <Text weight={500}>Ads Account ID:</Text>
              <Select
                data={msAdAccountDetailsData}
                value={msAdAccountDetailsValue}
                onChange={handlemsAdAccountDetailsChange}
                name="ms_conv_ads_account_id"
                w={200}
                ml={28}
              />
            </Group>
            <Group mt={10}>
              <Checkbox
                name="microsoft_conversion_pageview"
                label={<strong>LP visit push as pageview:</strong>}
                checked={microsoftConversionPageview}
                onChange={(event) => handleMicrosoftConversionPageviewChange(event)}
              />
              {useMsConversionActionMPData ? (
                <Select
                  data={msConversionActionMPData}
                  value={msConvActionValue}
                  onChange={(value) => setMsConvActionValue(value)}
                  name="ms_conv_goal_name_pageview"
                  w={250}
                />
              ) : (
                <Select
                  data={msConversionActionData}
                  value={msConvActionValue}
                  onChange={(value) => setMsConvActionValue(value)}
                  name="ms_conv_goal_name_pageview"
                  w={250}
                />
              )}
            </Group>

            {msConvMessageRow.map((group, index) => (
              <Group key={index} mt={10}>
                <Select
                  data={group.ms_conv_goal_name.map(action => ({
                    value: action.id,
                    label: action.name
                  }))}
                  name={`ms_conv_goal_name[]`}
                  value={getMsSelectedValue(group.ms_conv_goal_name, 'id')}
                  onChange={(value) => handleMsSelectChange(index, 'ms_conv_goal_name', value, 'id')}
                  w={250}
                />
                <Select
                  data={group.ms_conv_page_id.map(page => ({
                    value: page.key_goal_ms,
                    label: page.value_goal_ms
                  }))}
                  name={`ms_conv_page_id[]`}
                  value={getMsSelectedValue(group.ms_conv_page_id, 'key_goal_ms')}
                  onChange={(value) => handleMsSelectChange(index, 'ms_conv_page_id', value, 'key_goal_ms')}
                  w={250}
                />
                <ActionIcon title="Remove">
                  <IconCircleMinus size="1.1rem" color="red" onClick={() => removemsGoals(index,'getMsValue')}/>
                </ActionIcon>
              </Group>
            ))}

            {msgoalGroups.map(group => (
              <Group key={group.id} mt={10}>
                <Select
                  data={msConversionActionData}
                  value={group.action}
                  onChange={(value) => handlemsConversionActionChange(value, group.id)}
                  name={`ms_conv_goal_name[]`}
                  w={250}
                />
                <Select
                  data={msConvPageDetailData}
                  value={group.page}
                  onChange={(value) => handlemsConvPageDetailChange(value, group.id)}
                  name={`ms_conv_page_id[]`}
                  w={250}
                />
                <ActionIcon title="Remove">
                  <IconCircleMinus size="1.1rem" color="red" onClick={() => removemsGoals(group.id,'')}/>
                </ActionIcon>
              </Group>
            ))}
            <Button color="teal" mt={10} onClick={addmsGoals}>
              <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
            </Button>
          </Tabs.Panel>

          <Tabs.Panel value="Tiktok" pt="xs">
            <Group spacing="xs" mb={5}>
              <Text weight={500}>Advertiser Account:</Text>
              <Select
                data={tkAdvertiserAccountData}
                value={tkAdvertiserAccountValue}
                onChange={handletkAdvertiserAccountChange}
                name="tk_conv_ads_account_id"
                w={200}
                ml={28}
              />
            </Group>
            <Group mt={10}>
              <Checkbox
                name="tiktok_conversion_pageview"
                label={<strong>LP visit push as pageview:</strong>}
                checked={tiktokConversionPageview}
                onChange={(event) => handleTiktokConversionPageviewChange(event)}
              />
              <Select
                data={tkPixelMPData}
                value={tkPixelMPValue}
                onChange={handletkPixelMPChange}
                name="tk_conv_pixel_id_pageview"
                w={250}
              />
              <Select
                data={tkEventMPData}
                value={tkEventMPValue}
                onChange={handletkEventMPChange}
                name="tk_conv_event_name_pageview"
                w={250}
              />
            </Group>
            <Group mt={10}>
              <Select
                data={[
                    { value: '', label: 'Select Pixel'},
                    { value: 'CFR09H3C77UBG01LTFCG', label: 'Visiopt Dev 1' },
                    { value: 'CFR0AOJC77UBIS8PJJ8G', label: 'Visiopt Dev 2' },
                    { value: 'CG4T223C77U573C8LFKG', label: 'Visiopt Dev 3' },
                    { value: 'CLTD4K3C77U2ODAMQRUG', label: 'Visiopt test' },
                    { value: 'CLTDFB3C77U95UC4L0F0', label: 'Visiopt Test 2' }
                  ]}
                value=""
                name="tk_conv_pixel_id[]"
                w={250}
              />
              <Select
                data={[
                    { value: '', label: 'Select Event Name'},
                    { value: 'AddPaymentInfo', label: 'Add Payment Info' },
                    { value: 'AddToCart', label: 'Add to Cart' },
                    { value: 'AddToWishlist', label: 'Add to Wishlist' },
                    { value: 'ClickButton', label: 'Click Button' },
                    { value: 'CompletePayment', label: 'Complete Payment' },
                    { value: 'CompleteRegistration', label: 'Complete Registration' },
                    { value: 'Contact', label: 'Contact' },
                    { value: 'Download', label: 'Download' },
                    { value: 'InitiateCheckout', label: 'Initiate Checkout' },
                    { value: 'PlaceAnOrder', label: 'Place an Order' },
                    { value: 'Search', label: 'Search' },
                    { value: 'SubmitForm', label: 'Submit Form' },
                    { value: 'Subscribe', label: 'Subscribe' },
                    { value: 'ViewContent', label: 'View Content' }
                  ]}
                value=""
                name="tk_conv_event_name[]"
                w={250}
              />
              <Select
                data={[
                    { value: '', label: 'Select Conversion Page'},
                    { value: '1', label: 'goal 1' },
                    { value: '3', label: 'goal 3' },
                    { value: '5', label: 'goal 5' }
                  ]}
                value=""
                name="tk_conv_page_id[]"
                w={250}
              />
              <ActionIcon title="Remove">
                <IconCircleMinus size="1.1rem" color="red"/>
              </ActionIcon>
            </Group>
            <Button color="teal" mt={10}>
              <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
            </Button>
          </Tabs.Panel>

          <Tabs.Panel value="Youtube" pt="xs">
            <Group spacing="xs" mb={5}>
              <Text weight={500}>Customer ID:</Text>
              <Select
                data={ytCustomerData}
                value={ytCustomerValue}
                onChange={handleytCustomerChange}
                name="yt_conv_customer_id"
                w={200}
                ml={28}
              />
            </Group>
            <Group spacing="xs" mb={5}>
              <Text weight={500}>Sub Customer ID:</Text>
              <Select
                data={ytSubCustomerData}
                value={ytSubCustomerValue}
                onChange={handleytSubCustomerChange}
                name="yt_conv_subcustomer_id"
                w={200}
              />
            </Group>
            <Group mt={10}>
              <Checkbox
                name="youtube_conversion_pageview"
                label={<strong>LP visit push as pageview:</strong>}
                checked={youtubeConversionPageview}
                onChange={(event) => handleYoutubeConversionPageviewChange(event)}
              />
              {useytConversionActionMPData ? (
                <Select
                  data={ytConversionActionMPData}
                  value={ytConvActionValue}
                  onChange={(value) => setYtConvActionValue(value)}
                  name="yt_conv_action_resource_name_pageview"
                  w={250}
                />
              ) : (
                <Select
                  data={ytConvActionData}
                  value={ytConvActionValue}
                  onChange={(value) => setYtConvActionValue(value)}
                  name="yt_conv_action_resource_name_pageview"
                  w={250}
                />
              )}
            </Group>

            {ytConvMessageRow.map((group, index) => (
              <Group key={index} mt={10}>
                <Select
                  data={group.yt_conv_action_resource_name.map(action => ({
                    value: action.key_resource,
                    label: action.value_resource
                  }))}
                  name={`yt_conv_action_resource_name[]`}
                  value={getYtSelectedValue(group.yt_conv_action_resource_name, 'key_resource', false)}
                  onChange={(value) => handleYtSelectChange(index, 'yt_conv_action_resource_name', value, 'key_resource', false)}
                  w={250}
                />
                <MultiSelect
                  data={group.yt_conv_page_id.map(page => ({
                    value: page.key_goal_yt,
                    label: page.value_goal_yt
                  }))}
                  name={`yt_conv_page_id[]`}
                  value={getYtSelectedValue(group.yt_conv_page_id, 'key_goal_yt', true)}
                  onChange={(value) => handleYtSelectChange(index, 'yt_conv_page_id', value, 'key_goal_yt', true)}
                  placeholder="Select Conversion Page"
                  w={250}
                  multiple
                />
                <ActionIcon title="Remove">
                  <IconCircleMinus size="1.1rem" color="red" onClick={() => removeytGoals(index,'getYtValue')}/>
                </ActionIcon>
              </Group>
            ))}

            {ytgoalGroups.map(group => (
              <Group key={group.id} mt={10}>
                <Select
                  data={ytConvActionData}
                  value={group.action}
                  onChange={(value) => handleytConversionActionChange(value, group.id)}
                  name="yt_conv_action_resource_name[]"
                  w={250}
                />
                <MultiSelect
                  data={ytConvPageDetailData}
                  value={group.page}
                  onChange={(value) => handleytConvPageGoalChange(value, group.id)}
                  name="yt_conv_page_id[]"
                  placeholder="Select Conversion Page"
                  w={250}
                  multiple
                />
                <ActionIcon title="Remove">
                  <IconCircleMinus size="1.1rem" color="red" onClick={() => removeytGoals(group.id,'')}/>
                </ActionIcon>
              </Group>
            ))}
            <Button color="teal" mt={10} onClick={addytGoals}>
              <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
            </Button>
          </Tabs.Panel>

          <Tabs.Panel value="Taboola" pt="xs">
            <Group spacing="xs" mb={5}>
              <Text weight={500}>Ad Account ID:</Text>
              <Select
                data={tbAdAccountDetailsData}
                value={tbAdAccountDetailsValue}
                onChange={handletbAdAccountDetailsChange}
                name="tb_conv_ad_account_dd"
                w={200}
                ml={28}
              />
            </Group>
            <Group mt={10}>
              <Checkbox
                name="taboola_conversion_pageview"
                label={<strong>LP visit push as pageview:</strong>}
                checked={taboolaConversionPageview}
                onChange={(event) => handleTaboolaConversionPageviewChange(event)}
              />
              {useTbConversionActionMPData ? (
                <Select
                  data={tbConversionActionMPData}
                  value={tbConvActionValue}
                  onChange={(value) => setTbConvActionValue(value)}
                  name="tb_conv_action_resource_name_pageview"
                  w={250}
                />
              ) : (
                <Select
                  data={tbConversionActionData}
                  value={tbConvActionValue}
                  onChange={(value) => setTbConvActionValue(value)}
                  name="tb_conv_action_resource_name_pageview"
                  w={250}
                />
              )}
            </Group>

            {tbConvMessageRow.map((group, index) => (
              <Group key={index} mt={10}>
                <Select
                  data={group.tb_conv_action_resource_name.map(action => ({
                    value: action.event_name,
                    label: action.display_name
                  }))}
                  name={`tb_conv_action_resource_name[]`}
                  value={getTbSelectedValue(group.tb_conv_action_resource_name, 'event_name')}
                  onChange={(value) => handleTbSelectChange(index, 'tb_conv_action_resource_name', value, 'event_name')}
                  w={250}
                />
                <Select
                  data={group.tb_conv_page_id.map(page => ({
                    value: page.key_goal_tb,
                    label: page.value_goal_tb
                  }))}
                  name={`tb_conv_page_id[]`}
                  value={getTbSelectedValue(group.tb_conv_page_id, 'key_goal_tb')}
                  onChange={(value) => handleTbSelectChange(index, 'tb_conv_page_id', value, 'key_goal_tb')}
                  w={250}
                />
                <ActionIcon title="Remove">
                  <IconCircleMinus size="1.1rem" color="red" onClick={() => removetbGoals(index,'getTbValue')}/>
                </ActionIcon>
              </Group>
            ))}

            {tbgoalGroups.map(group => (
              <Group key={group.id} mt={10}>
                <Select
                  data={tbConversionActionData}
                  value={group.action}
                  onChange={(value) => handletbConversionActionChange(value, group.id)}
                  name={`tb_conv_action_resource_name[]`}
                  w={250}
                />
                <Select
                  data={tbConvPageDetailData}
                  value={group.page}
                  onChange={(value) => handletbConvPageDetailChange(value, group.id)}
                  name={`tb_conv_page_id[]`}
                  w={250}
                />
                <ActionIcon title="Remove">
                  <IconCircleMinus size="1.1rem" color="red" onClick={() => removetbGoals(group.id,'')}/>
                </ActionIcon>
              </Group>
            ))}
            <Button color="teal" mt={10} onClick={addtbGoals}>
              <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
            </Button>
          </Tabs.Panel>

          <Tabs.Panel value="Outbrain" pt="xs">
            <Group spacing="xs" mb={5}>
              <Text weight={500}>Ad Account ID:</Text>
              <Select
                data={obAdAccountDetailsData}
                value={obAdAccountDetailsValue}
                onChange={handleobAdAccountDetailsChange}
                name="ob_conv_ad_account_dd"
                w={200}
                ml={28}
              />
            </Group>
            <Group mt={10}>
              <Checkbox
                name="outbrain_conversion_pageview"
                label={<strong>LP visit push as pageview:</strong>}
                checked={outbrainConversionPageview}
                onChange={(event) => handleOutbrainConversionPageviewChange(event)}
              />
              {useObConversionActionMPData ? (
                <Select
                  data={obConversionActionMPData}
                  value={obConvActionValue}
                  onChange={(value) => setObConvActionValue(value)}
                  name="ob_conv_action_resource_name_pageview"
                  w={250}
                />
              ) : (
                <Select
                  data={obConversionActionData}
                  value={obConvActionValue}
                  onChange={(value) => setObConvActionValue(value)}
                  name="ob_conv_action_resource_name_pageview"
                  w={250}
                />
              )}
            </Group>

            {obConvMessageRow.map((group, index) => (
              <Group key={index} mt={10}>
                <Select
                  data={group.ob_conv_action_resource_name.map(action => ({
                    value: action.key,
                    label: action.value
                  }))}
                  name={`ob_conv_action_resource_name[]`}
                  value={getObSelectedValue(group.ob_conv_action_resource_name, 'key')}
                  onChange={(value) => handleObSelectChange(index, 'ob_conv_action_resource_name', value, 'key')}
                  w={250}
                />
                <Select
                  data={group.ob_conv_page_id.map(page => ({
                    value: page.key,
                    label: page.value
                  }))}
                  name={`ob_conv_page_id[]`}
                  value={getObSelectedValue(group.ob_conv_page_id, 'key')}
                  onChange={(value) => handleObSelectChange(index, 'ob_conv_page_id', value, 'key')}
                  w={250}
                />
                <ActionIcon title="Remove">
                  <IconCircleMinus size="1.1rem" color="red" onClick={() => removeobGoals(index,'getObValue')}/>
                </ActionIcon>
              </Group>
            ))}

            {obgoalGroups.map(group => (
              <Group key={group.id} mt={10}>
                <Select
                  data={obConversionActionData}
                  value={group.action}
                  onChange={(value) => handleobConversionActionChange(value, group.id)}
                  name={`ob_conv_action_resource_name[]`}
                  w={250}
                />
                <Select
                  data={obConvPageDetailData}
                  value={group.page}
                  onChange={(value) => handleobConvPageDetailChange(value, group.id)}
                  name={`ob_conv_page_id[]`}
                  w={250}
                />
                <ActionIcon title="Remove">
                  <IconCircleMinus size="1.1rem" color="red" onClick={() => removeobGoals(group.id,'')}/>
                </ActionIcon>
              </Group>
            ))}
            <Button color="teal" mt={10} onClick={addobGoals}>
              <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
            </Button>
          </Tabs.Panel>
        </Tabs>
        <Button mt={20} onClick={handleSaveConversionData}>Save Campaign</Button>
        <Button color="red" mt={20} ml={10}>Cancel</Button>
        </Accordion.Panel>
      </Accordion.Item>
      )}
    </>
  );
}