import { Group, Text, Button, Box, Grid, ActionIcon, Flex, Modal, Container, Card, Skeleton } from '@mantine/core';
import { IconHelp, IconTrash, IconPencil, IconCheck } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import CampaignReport from "../../api/CampaignReport";
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function CampaignReports() {
  const navigate = useNavigate();
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [reportList, setReportList] = useState([]);
  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const resportIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  useDocumentTitle("Visiopt - Reports");

  const updateReport = (report_id:any) => {
    navigate("/create-report", { state: { report_id: report_id} });
  }

  const deleteReportConfirm = (deleteReportId:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to delete this Report?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {},
    onConfirm: () => {handleDeleteCampaignReport(deleteReportId)}
  });

  const handleDeleteCampaignReport = (reportId:any) => {
    setShowSkeleton(true);
    CampaignReport.deletereport({
      report_id: reportId
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
          fetchCampaignReportList();
          setShowSkeleton(false);
        }else{
          seterrorCloseModal(true);
          setShowErrorHeader(true); 
          setShowMessageHeader(false);
          setErrorMessage(data.message);
          setShowSkeleton(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setShowSkeleton(false);
      });
  };

  const fetchCampaignReportList = () => {
    setShowSkeleton(true);
    CampaignReport.getreportlist()
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setReportList(data.data.report_list);
          setShowSkeleton(false);
        }else{
          seterrorCloseModal(true);
          setShowErrorHeader(true); 
          setShowMessageHeader(false);
          setErrorMessage(data.message);
          setShowSkeleton(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setShowSkeleton(false);
      });
  };

  useEffect(() => {
    if(selectedMemberWebsite !== ''){
      fetchCampaignReportList();
    }
  }, [selectedMemberWebsite]);

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (resportIconRef.current) {
      const { top, left, width } = resportIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

	return (
		<>
    {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
    {errorModelOpen && (
      <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
        <Modal.Overlay />
        <Modal.Content>
          {showMessageHeader ? (
            <Modal.Header>
              <Modal.Title>Campaign Report</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ): showErrorHeader ? (
            <Modal.Header>
              <Modal.Title>ERROR</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ): (
            <Modal.Header>
            </Modal.Header>
          )}
          <Modal.Body>
            <form>
              <div style={{ display: 'flex'}}>
                  <Text weight={500}>{errorMessage}</Text>
              </div>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      )}

    <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
    <Container size="xl">
      <Card shadow="sm" padding="sm" radius="md" withBorder>
  			<Group position="apart" mb="xs">
          <Text weight={500}>REPORTS <IconHelp ref={resportIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(248)}/></Text>
        </Group>
        <Button type="button">
          <Link to="/create-report" style={{color:"white",textDecoration: "none"}}>
            Create a New Report
          </Link>
        </Button>
        {showSkeleton ? (
          <Flex direction="column" gap="sm" mt={10}>
            {Array.from({ length: 12 }).map((_, index) => (
              <Skeleton key={index} height={44} width={1262} />
            ))}
          </Flex>
        ) : (
          reportList.map((Report, ReportIndex) => (
            <Box
              key={ReportIndex}
              p={4}
              pl={20}
              mt={10}
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
                padding: "10px",
                borderRadius: theme.radius.md,
                border: "0.0625rem solid transparent",
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
              })}
            >
              <Grid gutter="xs">
                <Grid.Col span={6}>
                  <Text 
                    mt={3} 
                    onClick={() => updateReport(Report.report_id)}
                    style={{ cursor: "pointer" }}
                  >
                    {Report.report_name}
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    title="Edit"
                    onClick={() => updateReport(Report.report_id)}
                  >
                    <IconPencil size="1.1rem" />
                  </ActionIcon>
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    title="Delete"
                    onClick={() => deleteReportConfirm(Report.report_id)}
                  >
                    <IconTrash size="1.1rem" />
                  </ActionIcon>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Box>
          ))
        )}
      </Card>
    </Container>
		</>
	);
}