import { Group, Text, Flex, Container, Card, Radio, NumberInput, Checkbox, Input, Button } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import { DatePickerInput } from '@mantine/dates';
import { useState, useEffect, useRef } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { useNavigate, useLocation } from "react-router-dom";
import './RecordingSetting.css';
import InsightsApi from "../../api/InsightsApi";
import { VisiLoader, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function RecordingSetting() {
  useDocumentTitle("Visiopt - Insights");
  const navigate = useNavigate();
  const location = useLocation();
  const funnelId = location.state?.funnel_id;
  const currentDate = new Date();
  const [selectedFunnelId] = useState(funnelId);
  const [recordingSettingId, setRecordingSettingId] = useState('');
  const [isVisiLoaderVisible, setIsVisiLoaderVisible] = useState(false);
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [recordingSessionCount, setRecordingSessionCount] = useState('');
  const [recordingType, setRecordingType] = useState('');
  const [maxNumberInput, setMaxNumberInput] = useState<number | null>();
  const [sessionRecValue, setSessionRecValue] = useState<number | null>();
  const [uniformRecSession, setUniformRecSession] = useState(0);
  const [customFromDate, setCustomFromDate] = useState<Date | null>();
  const [customToDate, setCustomToDate] = useState<Date | null>();
  const [spendNumber, setSpendNumber] = useState<number | null>(60);
  const [visitNumber, setVisitNumber] = useState<number | null>(2);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [trackPageList, setTrackPageList] = useState([]);
  const recordingSettingIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const handleRecordingTypeChange = (value:any) => {
    setRecordingType(value);
  };

  const handleSessionRecValue = (value:any) => {
    setSessionRecValue(value);
  };

  const handleCustomFromDateChange = (date: Date | null) => {
    setCustomFromDate(date);
  };

  const handleCustomToDateChange = (date: Date | null) => {
    setCustomToDate(date);
  };

  const handleSpendNumberChange = (value:any) => {
    setSpendNumber(value);
  };

  const handleVisitNumberChange = (value:any) => {
    setVisitNumber(value);
  };

  const handlePrivacyChecked = () => {
    setPrivacyChecked(!privacyChecked);
  };

  const handleCancel = () => {
    navigate('/recordings');
  };

  const fetchRecordingSettingDetail = () => {
    setIsVisiLoaderVisible(true);
    InsightsApi.getrecordingsettingdetail({
      funnel_id: selectedFunnelId,
      system_website_id: selectedMemberWebsite
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1) {
          setRecordingSettingId(data.data.recording_setting_id);
          setTrackPageList(data.data.track_page_list);
          setCustomFromDate(new Date(data.data.start_date));
          setCustomToDate(new Date(data.data.end_date));
          setUniformRecSession(data.data.uniform_rec_session);
          setMaxNumberInput(data.data.original_session_value);
          setSessionRecValue(data.data.session_rec_value);
          setRecordingSessionCount(data.data.recording_session_count);
          if(data.data.sampling_rate_uniform === 'checked') {
            setRecordingType('1');
          }else if(data.data.sampling_rate_consecutive === 'checked') {
            setRecordingType('2');
          }else if(data.data.sampling_rate_custom === 'checked') {
            setRecordingType('3');
          }else if(data.data.sampling_rate_no_sampling === 'checked') {
            setRecordingType('4');
          }
          setSpendNumber(data.data.duration);
          setVisitNumber(data.data.no_of_view_page);
          const termChecked = data.data.terms_checked === 'checked' ? true : false;
          setPrivacyChecked(termChecked);          
        }
        setIsVisiLoaderVisible(false);
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  };

  const handleTrackPageChecked = (pageId:any) => {
    setTrackPageList((prev) => 
      prev.map((page) => 
        page.page_id === pageId 
          ? { ...page, page_checked: !page.page_checked }
          : page
      )
    );
  };

  const handleSaveSettingChanges = () => {
    const selectedTrackPages = trackPageList.filter((page) => page.page_checked === true).map((page) => page.page_id);
    let dataObject = {
      system_website_id: selectedMemberWebsite,
      funnel_id: selectedFunnelId,
      recording_setting_id: recordingSettingId,
      terms_for_the_user: privacyChecked === true ? 1 : 0,
      duration: spendNumber,
      no_of_view_page: visitNumber,
      track_page: selectedTrackPages,
    } as any;
    if(recordingType === '1') {
      dataObject.sampling_rate = 'uniform';
    }else if(recordingType === '2') {
      dataObject.sampling_rate = 'consecutive';
      dataObject.consecutive_recordings = sessionRecValue;
    }else if(recordingType === '3') {
      dataObject.sampling_rate = 'custom';
      dataObject.custom_recordings = sessionRecValue;
      dataObject.start_date = customFromDate.toISOString().split("T")[0];
      dataObject.end_date = customToDate.toISOString().split("T")[0];
    }else if(recordingType === '4') {
      dataObject.sampling_rate = 'no_sampling';
    }

    setIsVisiLoaderVisible(true);
    InsightsApi.saverecordingsettingchanges(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1) {
          navigate('/recordings');
        }
        setIsVisiLoaderVisible(false);
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  };

  useEffect(() => {
    if(selectedMemberWebsite !== '' && selectedFunnelId !== '') {
      fetchRecordingSettingDetail();
    }
  }, [selectedMemberWebsite, selectedFunnelId]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (recordingSettingIconRef.current) {
      const { top, left, width } = recordingSettingIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  const handleBackToFunnel = () => {
    navigate("/recordings");
  };

	return (
		<>
      <VisiLoader VisiLoaderVisible={isVisiLoaderVisible}/>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      <Container size="xl">
        <Card shadow="sm" padding="sm" radius="md" withBorder>
          <Group position="apart" mb="xs">
            <Text weight={500}>RECORDINGS <IconHelp ref={recordingSettingIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(257)}/></Text>
            <Button variant="default" onClick={handleBackToFunnel}>Back to Funnel Selection</Button>
          </Group>   
          <div style={{ marginBottom: '10px' }}>
            <Text weight={500} mt={12}>Sampling Rate</Text>
            <Text>Your Plan includes {recordingSessionCount} Recordings per month. Choose how you want Visiopt to record these sessions.</Text>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <Radio.Group
              defaultValue="1"
              value={recordingType}
              onChange={(value) => handleRecordingTypeChange(value)}
            >
              <Radio value="1" label="Uniform" />
              <Text ml={30}>{recordingSessionCount} sessions will be recorded evenly throughout the calender month.</Text>
              {recordingType === '1' &&
                <div className="recording-type">
                  <Text>This will come out to approximately {uniformRecSession} recording per day on your current plan.</Text>
                </div>
              }
              <Radio value="2" label="Consecutive" />
              <Text ml={30}>Every visitor session will be recorded until the specified number is reached. After this accelerated period ends, your sampling rate will revert to Uniform.</Text>
              {recordingType === '2' &&
                <div className="recording-type">
                  <Text style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>Record the next 
                    <NumberInput
                      styles={{ input: { height: '20px', width: '80px' } }}
                      max={maxNumberInput}
                      value={sessionRecValue}
                      onChange={handleSessionRecValue} 
                    /> visitor sessions.
                  </Text>
                  <Text>You're set! After this accelerated period ends, your sampling rate will revert to Uniform.</Text>
                </div>
              }
              <Radio value="3" label="Custom" />
              <Text ml={30}>Specify the number of sessions that you'd like recorded over a fixed timeframe. After this accelerated period ends, your sampling rate will revert to Uniform.</Text>
              {recordingType === '3' &&
                <div className="recording-type">
                  <Text style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>Record  
                    <NumberInput
                      styles={{ input: { height: '20px', width: '80px' } }}
                      min={1}
                      max={maxNumberInput}
                      value={sessionRecValue}
                      onChange={handleSessionRecValue} 
                    /> Sessions from 
                    <DatePickerInput
                      styles={{ input: { height: '20px', width: '100px' } }}
                      popoverProps={{ withinPortal: true }}
                      valueFormat="YYYY-MM-DD"
                      value={customFromDate}
                      onChange={handleCustomFromDateChange}
                      maxDate={currentDate}
                    /> to 
                    <DatePickerInput
                      styles={{ input: { height: '20px', width: '100px' } }}
                      popoverProps={{ withinPortal: true }}
                      valueFormat="YYYY-MM-DD"
                      value={customToDate}
                      onChange={handleCustomToDateChange}
                      maxDate={currentDate}
                    />.
                  </Text>
                  <Text>Please enter a number that ranges from 1-{maxNumberInput}. if you proceed without entering a number, your changes will be lost and we will revert to Uniform.</Text>
                </div>
              }
              <Radio value="4" label="No Sampling" />
              <Text ml={30}>All sessions will be recorded until your monthly recordings limit is reached or the month ends, whichever comes first.</Text>
            </Radio.Group>
          </div>
          <div style={{ marginTop: '30px' }}>
            <Text weight={500}>Track the following pages:</Text>
            <div style={{ marginTop: '20px' }}>
              {trackPageList.map((page) => (
                <div key={page.page_id} style={{ display: 'flex', marginTop: 10, gap: '8px' }}>
                  <Checkbox
                    size="md"
                    value={page.page_id}
                    label={page.custom_title}
                    style={{ fontWeight: 'bold' }}
                    checked={page.page_checked}
                    onChange={() => handleTrackPageChecked(page.page_id)}
                  />
                  <Text>{page.page_name}</Text>
                </div>
              ))}
            </div>
          </div>
          <div style={{ marginTop: '30px' }}>
            <Text weight={500}>Engaged visitors:</Text>
            <Text mt={15}>Engaged visitors are considered to be visitors who</Text>
            <Text mt={10} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>- Spent more than
              <Input
                styles={{ input: { height: '20px', width: '50px' } }}
                value={spendNumber}
                onChange={(e) => handleSpendNumberChange(e.currentTarget.value)} 
              />seconds on a page or
            </Text>
            <Text mt={10} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>- Visited at least
              <Input
                styles={{ input: { height: '20px', width: '50px' } }}
                value={visitNumber}
                onChange={(e) => handleVisitNumberChange(e.currentTarget.value)} 
              />pages
            </Text>          
          </div>
          <div style={{ marginTop: '30px' }}>
            <Checkbox
              checked={privacyChecked}
              label="Privacy Protection:"
              styles={{
                label: {
                  fontWeight: 'bold',fontSize: '16px',
                },
              }}
              onChange={handlePrivacyChecked}
            />
            <Text mt={10}>As default, Visiopt protects any information being entered into form fields. We recommend that you leave this box checked to conform with any privacy requirements.</Text>
            <Text>By removing this checkbox, you agree that you are solely responsible for any legal or other action that results.</Text>
          </div>
          <div>
          <Flex
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button size="sm" w={120} mt={10} onClick={handleSaveSettingChanges}>
               Save Changes
            </Button>
            <Button size="sm" w={80} color="red" mt={10} ml={5} onClick={handleCancel}>
               Cancel
            </Button>
          </Flex>
          </div>
        </Card>
      </Container>
		</>
	);
}