import React from 'react';
import { Box, Text, Space, Anchor } from '@mantine/core';

export function TestTotal({
    TestNameShow,
    TestPageLinkShow,
    TestPageTitleShow,
    TotalUniquesShow,
    TotalVisitsShow,
    TotalConversionsShow
  }:any) {

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
          padding: "10px",
          borderRadius: "4px",
          width:"auto"
        })}
      >
        <div style={{ display: 'flex'}}>
          <Text size="sm" fz={14} fw={500} mr={5}>Test Totals</Text>
          <div style={{ display: 'inline-block'}}>
            <Text size="sm" fz={14} mr={5}>({TestNameShow} - <Anchor href={TestPageLinkShow} target="_blank" fw={500} mr={5} mt={-2}>{TestPageTitleShow} </Anchor>)</Text>
          </div>
        </div>
        <Space h="xs" />
        <div style={{ display: 'flex'}}>
          <Text size="sm" fz={14} fw={500} mr={5}>Uniques:</Text>
          <Text size="sm" fz={14}>{TotalUniquesShow}</Text>
        </div>
        <div style={{ display: 'flex'}}>
          <Text size="sm" fz={14} fw={500} mr={5}>Visits:</Text>
          <Text size="sm" fz={14}>{TotalVisitsShow}</Text>
        </div>
        <div style={{ display: 'flex'}}>
          <Text size="sm" fz={14} fw={500} mr={5}>Conversions:</Text>
          <Text size="sm" fz={14}>{TotalConversionsShow}</Text>
        </div>
      </Box>
    </>
  );
}