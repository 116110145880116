import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Select, Button, Group, Text, Divider, ActionIcon } from '@mantine/core';
import { IconHelp, IconCheck, IconCircleMinus, IconPlus } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import CampaignReport from "../../api/CampaignReport";
import { notifications } from '@mantine/notifications';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function BehavioralDimensions({ selectedMemberWebsite, campaignId, isBehavioralFilterModalOpen, onBehavioralFilterClose, selectedVisioptCampaign, CampaignGoalDropDown, getbehavioraldimensionfilterdata }:any) {
  const navigate = useNavigate();
  const [behavioralFilterFirstDropdown, setBehavioralFilterFirstDropdown] = useState([]);
  const [behavioralFilterSecondDropdown, setBehavioralFilterSecondDropdown] = useState({});
  const [, setFirstDropdown] = useState('');
  const [, setSecondDropdown] = useState('');
  const [showFirstDropdown, setShowFirstDropdown] = useState(false);
  const [showSecondDropdown, setShowSecondDropdown] = useState({});
  const [dropdownSets, setDropdownSets] = useState([]);

  const behavioralIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isBehavioralFilterModalOpen) {
      CampaignReport.getcampaignbehavioraldimensions({
        campaign_id: campaignId,
        type: "custom_report"
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate('/logout');
          }
        }
      })
      .then((data) => {
        if (data.status === 1) {

          const dropdownValues1 = data.data.behavioral_filter.map((filter, index) => ({
            id: index,
            firstDropdown: filter.device_filter || '',
            secondDropdown: filter.traffic_source_key || '',
          }));
          setDropdownSets(dropdownValues1);

          if(dropdownValues1){
            TestsApi.behavioraldimensionsaddfilterfirstdropdown({
              type:"CampaignReport",
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if (data.status === 1) {
                setBehavioralFilterFirstDropdown(data.data.first_dropdown);
                setShowFirstDropdown(true);
              }
            })
            .catch(error => {
              console.error("API Error:", error);
            });
          }

          data.data.behavioral_filter.forEach((filter, index) => {
            if(filter.device_type === "Tracking tags" || filter.device_type === "Custom tags"){
              CampaignReport.campbehavioraldimensionsaddfilterseconddropdown({
                device_filter:filter.device_filter,
                campaign_id: campaignId
              })
              .then(response => {
                if (response.ok) {
                  return response.json();
                } else {
                  if (response.status === 401 || response.status === 400) {
                    navigate("/logout");
                  }
                }
              })
              .then(data => {
                if (data.status === 1) {
                  setBehavioralFilterSecondDropdown(prev => ({ ...prev, [index]: data.data.second_dropdown }));
                  setShowSecondDropdown(prev => ({ ...prev, [index]: true }));
                }
              })
              .catch(error => {
                console.error("API Error:", error);
              });
            } else {
              setShowSecondDropdown(prev => ({ ...prev, [index]: false }));
            }
          });

        }
      })
      .catch((error) => {
        console.error('API Error:', error);
      });
    }
  }, [isBehavioralFilterModalOpen, campaignId]);
  
  const handleAddFilter = () => {
    const newId = dropdownSets.length > 0 ? dropdownSets[dropdownSets.length - 1].id + 1 : 0;
    const newSet = { id: newId, firstDropdown: '', secondDropdown: '', thirdDropdown: '' };
    setDropdownSets([...dropdownSets, newSet]);

    setShowSecondDropdown(prev => ({ ...prev, [newId]: false }));

    TestsApi.behavioraldimensionsaddfilterfirstdropdown({
      type:"CampaignReport",
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        const firstDropdownData = data.data.first_dropdown;
        setBehavioralFilterFirstDropdown(firstDropdownData);
        setDropdownSets(prevSets => prevSets.map(set => {
          if (set.id === newId) {
            return { ...set, firstDropdown: firstDropdownData.length > 0 ? firstDropdownData[0].key : null };
          }
          return set;
        }));
        setShowFirstDropdown(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleFirstDropdown = (value:any,setId) => {
    setFirstDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, firstDropdown: value } : set
      )
    );

    if(value === "SjliZHA4M1lKLzVaZUNNbGlwcXd5Zz09" || value === "WGxCTW9LYlErd0RIWTdrc3hvL2d3Zz09"){
      CampaignReport.campbehavioraldimensionsaddfilterseconddropdown({
        device_filter:value,
        campaign_id: campaignId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          const secondDropdownData = data.data.second_dropdown;
          setBehavioralFilterSecondDropdown(prev => ({ ...prev, [setId]: secondDropdownData }));

          setDropdownSets(prevSets => prevSets.map(set => {
            if (set.id === setId) {
              return { ...set, secondDropdown: secondDropdownData.length > 0 ? secondDropdownData[0].key : null };
            }
            return set;
          }));
          setShowSecondDropdown(prev => ({ ...prev, [setId]: true }));
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    } else {
      setShowSecondDropdown(prev => ({ ...prev, [setId]: false }));
    }
  };

  const handleSecondDropdown = (value:any,setId) => {
    setSecondDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, secondDropdown: value } : set
      )
    );
  }

  const handleRemoveFilter = (setId) => {
    const updatedDropdownSets = dropdownSets.filter(set => set.id !== setId);
    setDropdownSets(updatedDropdownSets);
  };

  const handleSaveBehavioralFilter = (e) => {
    e.preventDefault();
    const deviceFilters = [];
    const trafficSourceKeys = [];

    dropdownSets.forEach((set) => {
      deviceFilters.push(set.firstDropdown);
      trafficSourceKeys.push(set.secondDropdown);
    });

    CampaignReport.savecampaignbehavioraldimensions({
      type:"custom_report",
      campaign_id: campaignId,
      device_filter: deviceFilters,
      traffic_source_key: trafficSourceKeys
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleBehavioralFilterCloseModal();
        if(selectedVisioptCampaign === campaignId){
          CampaignGoalDropDown(campaignId, 'first_campaign');
        }else{
          CampaignGoalDropDown(campaignId, 'comparison_campaign');
        }
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  };

  const handleResetBehavioralFilter = () => {
    CampaignReport.resetfiltercampbehavioraldimensions({
      type:"custom_report",
      campaign_id: campaignId
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleBehavioralFilterCloseModal();
        if(selectedVisioptCampaign === campaignId){
          CampaignGoalDropDown(campaignId, 'first_campaign');
        }else{
          CampaignGoalDropDown(campaignId, 'comparison_campaign');
        }
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  };

  const handleBehavioralFilterCloseModal = () => {
    onBehavioralFilterClose();
    setDropdownSets([]);
    setShowFirstDropdown(false);
    setShowSecondDropdown(false);
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (behavioralIconRef.current) {
      const { top, left, width } = behavioralIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      <Modal.Root opened={isBehavioralFilterModalOpen} onClose={onBehavioralFilterClose} size="60%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>BEHAVIORAL DIMENSIONS <IconHelp ref={behavioralIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(182)}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSaveBehavioralFilter}>
              <Group>
              <div>
                {dropdownSets.map((set) => (
                  <div key={set.id} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                    {showFirstDropdown && (
                    <Select
                      data={behavioralFilterFirstDropdown.map((item) => ({ value: item.key, label: item.value }))}
                      value={set.firstDropdown}
                      onChange={(value) => handleFirstDropdown(value, set.id)}
                      searchable
                      placeholder="Select value"
                      nothingFound="No options"
                      size="sm"
                      dropdownPosition="bottom"
                      mb={10}
                      style={{marginRight:15}}
                      withinPortal 
                    />
                    )}
                    {showSecondDropdown[set.id] && (
                    <Select
                      data={(behavioralFilterSecondDropdown[set.id] || []).map((item) => ({ value: item.key, label: item.value }))}
                      value={set.secondDropdown}
                      onChange={(value) => handleSecondDropdown(value, set.id)}
                      searchable
                      nothingFound="No options"
                      size="sm"
                      dropdownPosition="bottom"
                      mb={10}
                      style={{ marginRight: 15 }}
                      withinPortal 
                    />
                    )}
                    {showFirstDropdown && (
                      <ActionIcon title="Remove">
                        <IconCircleMinus size="1.1rem" color="red" style={{marginBottom:8, cursor: 'pointer'}} onClick={() => handleRemoveFilter(set.id)}/>
                      </ActionIcon>
                    )}
                  </div>
                ))}
              </div>
              </Group>
              <Button onClick={handleAddFilter}>
                <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Filter</Text>
              </Button>
              <Divider my="sm" />  
              <Group position="right">
                <Button type="button" size="sm" color="orange" onClick={handleResetBehavioralFilter}>
                  Reset Filter
                </Button>
                <Button type="submit" size="sm" w={80}>
                  Save
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}