import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Select, Button, Group, Text, Accordion, Checkbox, Input, ActionIcon, NumberInput  } from '@mantine/core';
import { IconCheck, IconPlus, IconWebhook, IconX, IconHelp } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import CampaignApi from "../../api/CampaignApi";
import { modals } from '@mantine/modals';
import classes from '../../pages/DashBoard/DashBoard.module.css';
import { TrackButtons } from "./TrackButtonEditor";

export function Step4({ selectedMemberWebsite, SelectedFunnelValue, campaignLPPageValue, selectedWebhookPageid, campaign_id, campaignId, campaignName, 
                        step4Disabled, campaignLoader, setStep5Disabled, stepValue, setStepValue, setStep5Show, isClassicChecked, isFacebookChecked,
                        isGoogleChecked, isMicrosoftChecked, isTiktokChecked, isYoutubeChecked, isTaboolaChecked, isOutbrainChecked,
                        gl_check_existing, ms_check_existing, tk_check_existing, yt_check_existing, tb_check_existing, ob_check_existing, fbuseraccesstoken,
                        fbuserid, fbadaccountid, fbadaccountsdetails, convAttributionModel, glUserRefreshtoken, glAccountsDetails, gladaccountid, ytadaccountid,
                        obadaccountid, obUsertoken, obAccountsDetails, tbUserRefreshtoken, tbAccountsDetails, tbadaccountid, msUserRefreshtoken, msAuthorizationData,
                        msadaccountid, tkUserRefreshtoken, tkAccountsDetails, tkadaccountid, conversionApiType 
                      }:any) {
  const navigate = useNavigate();
  const [isTrackingType, setIsTrackingType] = useState('1');
  const [isConversionApiChecked, setIsConversionApiChecked] = useState(false);

  const [trackButtonModelOpen, setTrackButtonCloseModal] = useState(false);
  const [pageIdShow, setPageId] = useState('');
  const [goalActionNumber, setGoalActionNumber] = useState(0);
  
  const [cpc, setCpc] = useState('');
  const [campaignGoalResult, setCampaignGoalResult] = useState([]);
  const [goalOptions, setGoalOptions] = useState([]);
  const [postbackOptions, setPostbackOptions] = useState([]);
  const [buttonOptions, setButtonOptions] = useState([]);
  const [goals, setGoals] = useState(Array.from({ length: 7 }, (_, i) => i + 1));
  const [optionsBtn, setOptionsBtn] = useState(Array(7).fill('1'));
  const [goalAction, setGoalAction] = useState(Array(7).fill(''));
  const [postbackAction, setPostbackAction] = useState(Array(7).fill(''));
  const [goalIdAction, setGoalIdAction] = useState(Array(7).fill(''));
  const [goalUrlAction, setGoalUrlAction] = useState(Array(7).fill(''));
  const [goalNames, setGoalNames] = useState(Array(7).fill(''));
  const [trackBName, setTrackBName] = useState(Array(7).fill(''));
  const [salesValues, setSalesValues] = useState(Array(7).fill(0));
  const [excludeTopline, setExcludeTopline] = useState(Array(7).fill(0));
  const [includeAov, setIncludeAov] = useState(Array(7).fill(0));
  const [excludeUndefinedConversions, setExcludeUndefinedConversions] = useState(Array(7).fill(0));
  const [goalWebhookPage, setGoalWebhookPage] = useState(Array(7).fill(0));
  const [checkedGoals, setCheckedGoals] = useState([]);
  const [checkedCustomerPath, setCheckedCustomerPath] = useState([]);
  const [buttonName, setButtonName] = useState('');

  const [webhookSet, setWebhookSet] = useState([]);

  const goalIconref = useRef(null);
  const normalTrackingIconRef = useRef(null);
  const customerPathIconRef = useRef(null);
  const toplineRevenue = useRef(null);
  const aovIncludeIconref = useRef(null);
  const undefinedConversions = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});

  const handleTrackingTypeChange = (value:any) => {
    setIsTrackingType(value);
  }

  const handleIsConversionApiChange = (event) => {
    setIsConversionApiChecked(event.target.checked);
  };

  const handleTrackButtonCloseModal = () => {
    setTrackButtonCloseModal(false);
  };

  const handleCampaignLPPageChange = (value:any,index:any) => {
    if(value !== "" && optionsBtn[index] === '1'){
      CampaignApi.checkexistingcampaignpage({
        campaign_page_id : value,
        c_id : campaignId || campaign_id
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setWebhookSet((prevWebhookSet) => {
            const updatedWebhookSet = [...prevWebhookSet];
            updatedWebhookSet[index] = data.data.webhook_set;
            return updatedWebhookSet;
          });
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  useEffect(() => {
    if(campaign_id !== "" && campaign_id !== undefined){
      handleCampaignGoalResult();
    }
  }, [campaign_id]);

  const handleCampaignGoalResult = () => {
    campaignLoader(true);
    CampaignApi.getcampaigngoalresult({
      c_id:campaign_id
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          campaignLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        if (data.data.campaign_detail.normal_tracking === "checked") {
          setIsTrackingType('1');
        }
        if (data.data.campaign_detail.customer_path === "checked") {
          setIsTrackingType('2');
        } 
        setCpc(data.data.campaign_detail.cpc);
        setIsConversionApiChecked(data.data.campaign_detail.is_conversion_api !== "0");
        setCampaignGoalResult(data.data.campaign_goal_result.get_goal);
      }
      campaignLoader(false);
    })
    .catch(error => {
      console.log(error);
      campaignLoader(false);
    });
  }

  useEffect(() => {
    if(campaignLPPageValue !== ''){
      CampaignApi.getgoalsdropdown({
        system_website_id : selectedMemberWebsite,
        funnel_id : SelectedFunnelValue,
        page_id : campaignLPPageValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setGoalOptions(data.data.goal_options);
          setPostbackOptions(data.data.postback_options);
          setButtonOptions(data.data.button_options);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [campaignLPPageValue]);

  const goalOptionsData = [
    { value: '', label: 'Select a goal' },
    ...goalOptions.map((goalOptions) => ({
      value: goalOptions.page_id,
      label: goalOptions.title,
    })),
  ];

  const postbackOptionsData = [
    { value: '', label: 'Select a postback' },
    ...postbackOptions.map((postbackOptions) => ({
      value: postbackOptions.postback_id,
      label: postbackOptions.postback_name,
    })),
  ];

  const buttonOptionsData = [
    { value: '', label: 'Select a goal' },
    ...buttonOptions.map((buttonOptions) => ({
      value: buttonOptions.page_id,
      label: buttonOptions.title,
    })),
  ];

  const optionsData = [
    { value: '1', label: 'Goal' },
    { value: '2', label: 'Postback' },
    { value: '3', label: 'Button' },
  ];

  useEffect(() => {
    if (campaignGoalResult) {
      const goalsData = campaignGoalResult;
      const updatedOptionsBtn = [...optionsBtn];
      const updatedGoalAction = [...goalAction];
      const updatedPostbackAction = [...postbackAction];
      const updatedGoalIdAction = [...goalIdAction];
      const updatedGoalUrlAction = [...goalUrlAction];
      const updatedGoalNames = [...goalNames];
      const updatedTrackBName = [...trackBName];
      const updatedSalesValues = [...salesValues];
      const updatedExcludeTopline = [...excludeTopline];
      const updatedIncludeAov = [...includeAov];
      const updatedExcludeUndefinedConversions = [...excludeUndefinedConversions];
      const updatedGoalWebhookPage = [...goalWebhookPage];
      const updatedCheckedGoals = [...checkedGoals];
      const updatedCheckedCustomerPath = [...checkedCustomerPath];
      let highestGoal = 7;

      goalsData.forEach((goal) => {
        const goalIndex = parseInt(goal.goal, 10) - 1;
        updatedCheckedGoals[goalIndex] = goal.goal;
        updatedOptionsBtn[goalIndex] = goal.type === "goal" ? "1" : goal.type === "postback" ? "2" : "3";
        updatedGoalAction[goalIndex] = goal.type === "goal" ? goal.goal_page_id : goal.type === "postback" ? goal.goal_postback_id : goal.goal_page_id;
        updatedPostbackAction[goalIndex] = goal.type === "goal" ? "" : goal.type === "postback" ? goal.goal_postback_id : "";
        updatedGoalIdAction[goalIndex] = goal.page_id;
        updatedGoalUrlAction[goalIndex] = goal.goal_page_url;
        updatedGoalNames[goalIndex] = goal.goal_name;
        updatedTrackBName[goalIndex] = goal.track_b_name;
        updatedSalesValues[goalIndex] = parseInt(goal.sales_value, 10);
        updatedExcludeTopline[goalIndex] = goal.exclude_topline_revenue_val === "1";
        updatedIncludeAov[goalIndex] = goal.include_aov_val === "1";
        updatedExcludeUndefinedConversions[goalIndex] = goal.exclude_undefined_conversions_val === "1";
        updatedGoalWebhookPage[goalIndex] = goal.goal_webhook_page === "1";
        updatedCheckedCustomerPath[goalIndex] = goal.customer_path_val === "1";
        if (goalIndex + 1 > highestGoal) {
          highestGoal = goalIndex + 1;
        }
      });
      if (goals.length < highestGoal) {
        const newGoals = Array.from({ length: highestGoal }, (_, i) => i + 1);
        setGoals(newGoals);
      }
      setCheckedGoals(updatedCheckedGoals);
      setCheckedCustomerPath(updatedCheckedCustomerPath);
      setOptionsBtn(updatedOptionsBtn);
      setGoalAction(updatedGoalAction);
      setPostbackAction(updatedPostbackAction);
      setGoalIdAction(updatedGoalIdAction);
      setGoalUrlAction(updatedGoalUrlAction);
      setGoalNames(updatedGoalNames);
      setTrackBName(updatedTrackBName);
      setSalesValues(updatedSalesValues);
      setExcludeTopline(updatedExcludeTopline);
      setIncludeAov(updatedIncludeAov);
      setExcludeUndefinedConversions(updatedExcludeUndefinedConversions);
      setGoalWebhookPage(updatedGoalWebhookPage);
    }
  }, [campaignGoalResult, goals.length]);

  const handleCheckboxChange = (index) => {
    const updatedCheckedGoals = [...checkedGoals];
    if (updatedCheckedGoals[index]) {
      updatedCheckedGoals[index] = undefined;
    } else {
      updatedCheckedGoals[index] = (index + 1).toString();
    }
    setCheckedGoals(updatedCheckedGoals);
  };

  const handleCustomerPathChange = (index) => {
    const updatedCheckedCustomerPath = [...checkedCustomerPath];
    if (updatedCheckedCustomerPath[index]) {
      updatedCheckedCustomerPath[index] = undefined;
    } else {
      updatedCheckedCustomerPath[index] = (index + 1).toString();
    }
    setCheckedCustomerPath(updatedCheckedCustomerPath);
  };

  const handleOptionsBtnChange = (value,index) => {
    const updatedOptionsBtns = [...optionsBtn];
    updatedOptionsBtns[index] = value;
    setOptionsBtn(updatedOptionsBtns);
  };

  const handleGoalOptionsChange = (value,index) => {
    const updatedGoalActions = [...goalAction];
    const updatedPostbackActions = [...postbackAction];
    updatedGoalActions[index] = value;
    updatedPostbackActions[index] = value;
    setGoalAction(updatedGoalActions);
    setPostbackAction(updatedPostbackActions);
    handleCampaignLPPageChange(value,index);
  };

  const handleGoalNameChange = (value, index) => {
    const updatedGoalNames = [...goalNames];
    updatedGoalNames[index] = value;
    setGoalNames(updatedGoalNames);
  };

  const handleSalesValueChange = (value, index) => {
    const updatedSalesValues = [...salesValues];
    updatedSalesValues[index] = value;
    setSalesValues(updatedSalesValues);
  }; 

  const handleExcludeToplineChange = (value, index) => {
    const updatedExcludeTopline = [...excludeTopline];
    updatedExcludeTopline[index] = value;
    setExcludeTopline(updatedExcludeTopline);
  };

  const handleIncludeAovChange = (value, index) => {
    const updatedIncludeAov = [...includeAov];
    updatedIncludeAov[index] = value;
    setIncludeAov(updatedIncludeAov);
  };

  const handleExcludeUndefinedConversionsChange = (value, index) => {
    const updatedExcludeUndefinedConversions = [...excludeUndefinedConversions];
    updatedExcludeUndefinedConversions[index] = value;
    setExcludeUndefinedConversions(updatedExcludeUndefinedConversions);
  };

  const MAX_GOALS = 14;
  const addGoal = () => {
    if (goals.length >= MAX_GOALS) {
      return;
    }
    const newTotalGoals = [...goals, goals.length + 1];
    setGoals(newTotalGoals);
    setOptionsBtn([...optionsBtn, '1']);
    setGoalAction([...goalAction, goalOptionsData[0].value]);
    setGoalNames([...goalNames, '']);
    setTrackBName([...trackBName, '']);
    setSalesValues([...salesValues, 0]);
    setExcludeTopline([...excludeTopline, 0]);
    setIncludeAov([...includeAov, 0]);
    setGoalWebhookPage([...goalWebhookPage, 0]);
    setCheckedGoals([...checkedGoals, undefined]);
    setCheckedCustomerPath([...checkedCustomerPath, undefined]);
  };

  const TrackButton = (page_id, goalNumber) => {
    setTrackButtonCloseModal(true);
    setPageId(page_id);
    setGoalActionNumber(goalNumber);
  };

  const handleSaveCampaign = () => {
    const noGoalsSelected = !checkedGoals.some(goal => goal);

    const goalsWithoutAction = checkedGoals
    .map((goal, index) => goal && !goalAction[index] ? index + 1 : null)
    .filter(goalNumber => goalNumber !== null);

    if(noGoalsSelected && goalsWithoutAction){
      modals.open({
        title: 'Message',
        children: (
        <>
          <Text size="sm">
            Please select Goal checkbox.<br/>
            Please select Goal value.
          </Text>
          <Group position="right" mt="sm">
            <Button onClick={() => modals.closeAll()}>OK</Button>
          </Group>
        </>
        ),
      });
      return;
    } 

    if (goalsWithoutAction.length > 0) {
      modals.open({
        title: 'Message',
        children: (
          <>
            <Text size="sm">
              {`Please select Goal ${goalsWithoutAction.join(', ')} value`}
            </Text>
            <Group position="right" mt="sm">
              <Button onClick={() => modals.closeAll()}>OK</Button>
            </Group>
          </>
        ),
      });
      return;
    }

    const selectedTypes = optionsBtn.map(btn =>
      btn === "1" ? "goal" : btn === "2" ? "postback" : btn === "3" ? "button" : "goal"
    );

    const selectedGoals = checkedGoals
      .map((goal, index) => goal !== undefined && goal ? goals[index] : null)
      .filter((goal) => goal !== null);
    
    const checkedCustomerPathRevenue = {};
    const excludeToplineRevenue = {};
    const includeAovRevenue = {};
    const excludeUndefinedConversionsRevenue = {};

    selectedGoals.forEach((goal, index) => {
      const goalIndex = goals.indexOf(goal);
      checkedCustomerPathRevenue[goal] = checkedCustomerPath[goalIndex] ? 1 : 0;
      excludeToplineRevenue[goal] = excludeTopline[goalIndex] ? 1 : 0;
      includeAovRevenue[goal] = includeAov[goalIndex] ? 1 : 0;
      excludeUndefinedConversionsRevenue[goal] = excludeUndefinedConversions[goalIndex] ? 1 : 0;
    });

    const campaigntype_classic = isClassicChecked === true ? 'Classic' : '';
    const campaigntype_facebook = isFacebookChecked === true ? 'Facebook' : '';
    const campaigntype_google = isGoogleChecked === true ? 'Google' : '';
    const campaigntype_microsoft = isMicrosoftChecked === true ? 'Microsoft' : '';
    const campaigntype_tiktok = isTiktokChecked === true ? 'Tiktok' : '';
    const campaigntype_youtube = isYoutubeChecked === true ? 'Youtube' : '';
    const campaigntype_taboola = isTaboolaChecked === true ? 'Taboola' : '';
    const campaigntype_outbrain = isOutbrainChecked === true ? 'Outbrain' : '';
    const fbcheck_existing = fbadaccountsdetails !== '' ? 'edit' : 'add';
    const fbuser_accesstoken = fbuseraccesstoken !== null ? 1 : 0;
    const fbuser_id = fbuserid ? fbuserid : '';
    const fbad_accounts_details = fbadaccountsdetails !== '' ? fbadaccountsdetails : '';
    const fbad_account_id = fbadaccountid;
    const glcheck_existing = glAccountsDetails !== '' ? 'edit' : 'add';
    const gluser_refreshtoken = glUserRefreshtoken !== null ? 1 : 0;
    const glad_accounts_details = glAccountsDetails !== '' ? glAccountsDetails : '';
    const glad_account_id = gladaccountid;
    const ytcheck_existing = glAccountsDetails !== '' ? 'edit' : 'add';
    const ytad_account_id = ytadaccountid;
    const obuser_token = obUsertoken !== null ? 1 : 0;
    const obcheck_existing = obAccountsDetails !== '' ? 'edit' : 'add';
    const obad_accounts_details = obAccountsDetails !== '' ? obAccountsDetails : '';
    const obad_account_id = obadaccountid;
    const tbuser_refreshtoken = tbUserRefreshtoken !== null ? 1 : 0;
    const tbcheck_existing = tbAccountsDetails !== '' ? 'edit' : 'add';
    const tbad_accounts_details = tbAccountsDetails !== '' ? tbAccountsDetails : '';
    const tbad_account_id = tbadaccountid;
    const msuser_refreshtoken = msUserRefreshtoken !== null ? 1 : 0;
    const mscheck_existing = msAuthorizationData !== '' ? 'edit' : 'add';
    const msauthorization_data = msAuthorizationData !== '' ? msAuthorizationData : '';
    const msad_account_id = msadaccountid;
    const tkuser_refreshtoken = tkUserRefreshtoken !== null ? 1 : 0;
    const tkcheck_existing = tkAccountsDetails !== '' ? 'edit' : 'add';
    const tkad_accounts_details = tkAccountsDetails !== '' ? tkAccountsDetails : '';
    const tkad_account_id = tkadaccountid;
    
    campaignLoader(true);
    CampaignApi.savecampaigndetail({
      system_website_id : selectedMemberWebsite,
      c_id : campaign_id || campaignId,
      campaign_name : campaignName,
      page_id : campaignLPPageValue,
      tracking_type : isTrackingType,
      campaign_type_classic : campaigntype_classic,
      campaign_type_google : campaigntype_google,
      campaign_type_microsoft : campaigntype_microsoft,
      campaign_type_tiktok : campaigntype_tiktok,
      campaign_type_youtube : campaigntype_youtube,
      campaign_type_taboola : campaigntype_taboola,
      campaign_type_outbrain : campaigntype_outbrain,
      campaign_type_facebook : campaigntype_facebook,
      fb_ad_account_id: fbad_account_id,
      fb_ad_accounts_details: fbad_accounts_details,
      fb_user_accesstoken: fbuser_accesstoken,
      fb_user_id: fbuser_id,
      fb_check_existing: fbcheck_existing,
      gl_user_refreshtoken: gluser_refreshtoken,
      gl_ad_accounts_details: glad_accounts_details,
      gl_ad_account_id: glad_account_id,
      gl_check_existing: glcheck_existing,  
      yt_check_existing: ytcheck_existing,
      yt_ad_account_id: ytad_account_id,
      ob_user_token: obuser_token,
      ob_check_existing: obcheck_existing,
      ob_ad_accounts_details: obad_accounts_details,
      ob_ad_account_id: obad_account_id,
      tb_user_refreshtoken: tbuser_refreshtoken,
      tb_check_existing: tbcheck_existing,
      tb_ad_accounts_details: tbad_accounts_details,
      tb_ad_account_id: tbad_account_id,
      ms_user_refreshtoken: msuser_refreshtoken,
      ms_check_existing: mscheck_existing,
      ms_authorization_data: msauthorization_data,
      ms_ad_account_id: msad_account_id,
      tk_user_refreshtoken: tkuser_refreshtoken,
      tk_check_existing: tkcheck_existing,
      tk_ad_accounts_details: tkad_accounts_details,
      tk_ad_account_id: tkad_account_id,
      conv_attribution_model: convAttributionModel,
      cpc : cpc,
      ADDED_TOTAL_GOAL : goals.length,
      is_conversion_api: isConversionApiChecked !== false ? '1' : '0',
      conversion_api_type: conversionApiType,
      goal : selectedGoals,
      customer_path_goal : checkedCustomerPathRevenue,
      type : selectedTypes,
      goal_action : goalAction,
      postback : postbackAction,
      goal_name : goalNames,
      goal_sales_val : salesValues,
      exclude_topline_revenue : excludeToplineRevenue,
      include_aov : includeAovRevenue,
      exclude_undefined_conversions : excludeUndefinedConversionsRevenue,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        if(isConversionApiChecked !== false){
          campaignLoader(false);
          setStep5Disabled(false);
          setStepValue('step-5');
          setStep5Show('1');
        } else{
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
          navigate("/manage-campaigns");
        }
      }
    })
    .catch(error => {
      campaignLoader(false);
      console.log(error);
    });
  }

  useEffect(() => {
    if(stepValue === 'step-4'){
      handleCampaignGoalResult();
      setStep5Show('0');
    }
  },[stepValue]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRefMapping = {
      323: goalIconref,
      308: normalTrackingIconRef,
      321: toplineRevenue,
      322: aovIncludeIconref,
      324: undefinedConversions
    };
    const iconRef = iconRefMapping[id];
    if (iconRef.current) {
      const { top, left, width } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: {top: top + scrollOffset, left: left + width + 10},
      });
    }
  };
  return (
    <>
      {showHelpModal &&
        ReactDOM.createPortal(
        <div>
          {Object.keys(tooltipPosition).map((key) => (
            <SelectTooltip
              isVisible={showHelpModal}
              setIsVisible={setShowHelpModal}
              id={helpTargetId}
              tooltipPosition={tooltipPosition[key]} 
            />
          ))}
        </div>,
        document.body
      )}
      <Accordion.Item className={classes.item} value="step-4">
        <Accordion.Control disabled={step4Disabled}><Text component="span" color="red" weight={500}>Step 4</Text><Text component="span" weight={500}> - Goals </Text><IconHelp ref={goalIconref} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(323)}/></Accordion.Control>
        <Accordion.Panel>
          <Group mb={5}>
            <Text weight={500}><IconHelp ref={normalTrackingIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(308)}/> Tracking Type:</Text>
            <Select
              data={[
                  { value: '1', label: 'Normal Tracking' },
                  { value: '2', label: 'Customer Path' }
                ]}
              value={isTrackingType}
              name="tracking_type"
              onChange={handleTrackingTypeChange}
              w={250}
            />
          </Group>
          <Group mb={5}>
            <div style={{ width: 78 }}></div>
            <div style={{ width: 22, display: isTrackingType === '1' ? "none" : "block"}}></div>
            <div style={{ width: 120 }}></div>
            <div style={{ width: 230 }}></div>
            <div style={{ width: 230 }}></div>
            <div style={{ width: 80 }}></div>
            <Text style={{ width: 105, textAlign: 'center', fontWeight: 500 }}><IconHelp ref={toplineRevenue} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(321)}/> Exclude from topline revenue</Text>
            <Text style={{ width: 80, textAlign: 'center', fontWeight: 500}}><IconHelp ref={aovIncludeIconref} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(322)}/> Include in AOV</Text>
            <Text style={{ width: 141, fontWeight: 500 }}><IconHelp ref={undefinedConversions} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(324)}/> Exclude Undefined Conversions </Text>
          </Group>

          {goals.map((goalNumber, index) => (
            <Group key={goalNumber} mb={3}>
              <Checkbox w={78}
                label={<strong>{`Goal ${goalNumber}:`}</strong>}
                checked={!!checkedGoals[index]}
                onChange={() => handleCheckboxChange(index)} 
              />
              <Checkbox w={22} 
                style={{ display: isTrackingType === '1' ? "none" : "block" }}
                checked={!!checkedCustomerPath[index]}
                onChange={() => handleCustomerPathChange(index)}
              />
              <Select
                data={optionsData}
                value={optionsBtn[index]}
                onChange={(value) => handleOptionsBtnChange(value, index)}
                name="tracking_type"
                w={120}
              />
              <Select
                value={goalAction[index]}
                data={optionsBtn[index] === '1' ? goalOptionsData : optionsBtn[index] === '2' ? postbackOptionsData : buttonOptionsData}
                onChange={(value) => handleGoalOptionsChange(value, index)}
                w={240}
              />
              {optionsBtn[index] === '3' ? (
                <div style={{ width: 240, display: "flex" }}>
                  <Button 
                    w={120}
                    onClick={() => TrackButton(goalAction[index], goalNumber)}
                  >
                    Track Button
                  </Button>
                  <Text w={110} ml={10} mt={8} lineClamp={1}>{trackBName[index] || buttonName}</Text>
                </div>
              ) : (
                <Input 
                  placeholder="Name your Goal" 
                  value={goalNames[index]}
                  onChange={(event) => handleGoalNameChange(event.target.value, index)} 
                  w={240}
                />
              )}

              {optionsBtn[index] === '2' ? ( 
                <div style={{ width:105}}>
                </div>
              ) : (
                <>
                  {webhookSet[index] === '1' && optionsBtn[index] === '1'? (
                    <div style={{ width: 105 }}>
                      <ActionIcon size="lg" variant="default" title="Webhook Connected">
                        <IconWebhook stroke={2} color="black" />
                      </ActionIcon>
                    </div>
                  ) : goalWebhookPage[index] === true && optionsBtn[index] === '1' ? (
                    <div style={{ width:105}}>
                      <ActionIcon size="lg" variant="default" title="Webhook Connected">
                        <IconWebhook stroke={2} color="black"/>
                      </ActionIcon>
                    </div>
                  ) : ( 
                    <NumberInput 
                      placeholder="Goal Sales" 
                      value={salesValues[index]}
                      onChange={(value) => handleSalesValueChange(value, index)}
                      w={105} 
                      min={0}
                    />
                  )}
                </>
              )}

              <Checkbox
                checked={excludeTopline[index]}
                onChange={(event) => handleExcludeToplineChange(event.target.checked, index)}
                w={50} ml={20}
              />
              <Checkbox
                checked={includeAov[index]}
                onChange={(event) => handleIncludeAovChange(event.target.checked, index)}
                w={50} ml={20}
              />
              <Checkbox
                checked={excludeUndefinedConversions[index]}
                onChange={(event) => handleExcludeUndefinedConversionsChange(event.target.checked, index)}
                w={50} ml={60}
              />
            </Group>
          ))}

          <Button color="teal" mt={10} onClick={addGoal}>
            <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
          </Button>
          <Group mb={5} mt={10}>
            <Text weight={500}>CPC :</Text>
            <Input value={cpc} onChange={(e) => setCpc(e.target.value)}/>
          </Group>
          <Checkbox
            name="is_conversion_api"
            checked={isConversionApiChecked}
            onChange={(event) => handleIsConversionApiChange(event)}
            label={<strong>Conversion API</strong>}/>
          <Button mt={10} onClick={handleSaveCampaign}>{isConversionApiChecked ? 'Save & Continue To API Set Up' : 'Save Campaign'}</Button>
          <Button color="red" mt={10} ml={10} onClick={() => navigate("/manage-campaigns")}>Cancel</Button>
        </Accordion.Panel>
      </Accordion.Item>
      {/*Track Button Model*/}
      {trackButtonModelOpen && (
        <TrackButtons
          selectedMemberWebsite={selectedMemberWebsite}
          TrackButtonModal={trackButtonModelOpen} 
          onClose={handleTrackButtonCloseModal} 
          PageId={pageIdShow}
          CampaignId={campaign_id || campaignId} 
          GoalActionNumber={goalActionNumber}
          setButtonName={setButtonName}
        />
      )}
    </>
  );
}