import { Group, Text, ActionIcon, Container, Card, Menu, TextInput, Button } from '@mantine/core';
import { IconHelp, IconClockHour4, IconSettings } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { SelectTimePicker, SelectTooltip, ErrorModal } from "../../components/GeneralComponent/GeneralComponent";
import { visiUserId, visiUserToken } from '../../GeneralFunction';
import { AssetsUrl } from './AssetsUrl';
import InsightsApi from "../../api/InsightsApi";
import './HeatmapView.css';

const loadResource = (tag, attributes) => {
  return new Promise((resolve, reject) => {
    const element = document.createElement(tag);
    Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
    element.onload = resolve;
    element.onerror = reject;
    document.head.appendChild(element);
  });
};

export function ScrollMapRecordingView() {
  useDocumentTitle("Visiopt - Insights");

  const navigate = useNavigate();
  const location = useLocation();
  const scrollmap_id = location.state?.scroll_map_id;
  const [selectedScrollmapId] = useState(scrollmap_id);
  const [selectedScrollmapName, setSelectedScrollmapName] = useState('');
  const [scrollmapPauseStatus, setScrollmapPauseStatus] = useState('');
  const [testAssetsUrl, setTestAssetsUrl] = useState('');
  const [deviceType, setDeviceType] = useState('0');
  const [scrollmapStartDate, setScrollmapStartDate] = useState('');
  const [scrollmapEndDate, setScrollmapEndDate] = useState('');
  const [scrollmapRequestUrl, setScrollmapRequestUrl] = useState('');
  const [scrollmapCreateDate, setScrollmapCreateDate] = useState('');
  const [scrollmapDetails, setScrollmapDetails] = useState(false);
  const [isAssetsUrlModalOpen, setIsAssetsUrlModalOpen] = useState(false);
  const [assetsUrlValue, setAssetsUrlValue] = useState('');
  const [iframeContent, setIframeContent] = useState('');
  const [loadResourceLoaded, setLoadResourceLoaded] = useState(0);
  const [pageId, setPageId] = useState('');
  const [pageUrl, setPageUrl] = useState('');
  const [frontEndFramework, setFrontEndFramework] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  let memberId = visiUserId() ? visiUserId() : '';
  const token = visiUserToken() ? visiUserToken() : '';
  const recordingIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [createdDate, setCreatedDate] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);

  useEffect(() => {
    const loadResources = async () => {
      try {
        const frontendPublicUrl = process.env.REACT_APP_FRONTEND_PUBLIC_URL;
        await loadResource('script', { src: frontendPublicUrl+'js/jquery.min.js' });
        setLoadResourceLoaded(1);
      } catch (error) {
        console.error('Error loading resource:', error);
      }
    };
    if(pageId !== ""){
      loadResources();
    }
    
  }, [pageId]);

  const handleButtonClickTest = async () => {
    handleShowHideLoader(true);
    let params = {
      page_url : scrollmapRequestUrl,
      page_id : pageId,
      front_end_framework : frontEndFramework,
      member_id: memberId,
      website_url : websiteUrl,
      test_assets_url: testAssetsUrl,
      pause_status: scrollmapPauseStatus,
      site_id: selectedMemberWebsite,
      scrollmap_id: selectedScrollmapId,
      device_type: deviceType,
      selectedTime: selectedTimePicker,
      start_date: scrollmapStartDate,
      end_date: scrollmapEndDate,
      visi_token: token,
    };
    let paramsJSON = JSON.stringify(params);
    let paramsJSONEncoded = btoa(paramsJSON);
  try {
    const response = await axios.post(
      process.env.REACT_APP_FRONTEND_BASE_URL+"/src/pages/Insights/scrollmap-data.php",
      { paramsJSONEncoded },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setIframeContent(response.data);
    handleShowHideLoader(false);
  } catch (error) {
    handleShowHideLoader(false);
    console.error('Error:', error);
  }
  };

  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [selectedTimePicker, setSelectedTimePicker] = useState(0);
  const [selectedTimePickerToDate, setSelectedTimePickerToDate] = useState('');
  const [selectedTimePickerFromDate, setSelectedTimePickerFromDate] = useState('');

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const handleselectedTimePickerComponentChange = (Value:any) => {
    setSelectedTimePicker(Value);
  };

  const handleselectedTimePickerToDateChange = (Value:any) => {
    setSelectedTimePickerToDate(Value);
  };

  const handleselectedTimePickerFromDateChange = (Value:any) => {
    setSelectedTimePickerFromDate(Value);
  };

  const handleMobileView = () => {
    setDeviceType('1');
    const testmobileFunction = (window as any).testmobile;
    if (typeof testmobileFunction === 'function') {
      testmobileFunction();
    };
  };

  const handlePCView = () => {
    setDeviceType('0');
    const testPcFunction = (window as any).testpc;
    if (typeof testPcFunction === 'function') {
      testPcFunction();
    };
  };

  const handleTabletView = () => {
    setDeviceType('2');
    const testTabletFunction = (window as any).testtablet;
    if (typeof testTabletFunction === 'function') {
      testTabletFunction();
    };
  };

  const fetchScrollmapDetails = () => {
    const dataObject = {
      scrollmap_id: selectedScrollmapId,
      system_website_id: selectedMemberWebsite,
      filter: 1,
      time: selectedTimePicker
    } as any;
    if(selectedTimePicker === -2){
      const [fromDay, fromMonth, fromYear] = selectedTimePickerFromDate.split("-").map(num => parseInt(num, 10));
      const [endDay, endMonth, endYear] = selectedTimePickerToDate.split("-").map(num => parseInt(num, 10));
      const fromDate = `${fromMonth}/${fromDay}/${fromYear}`;
      const toDate = `${endMonth}/${endDay}/${endYear}`;
      dataObject.filter = 2;
      dataObject.from = fromDate;
      dataObject.to = toDate;
    }
    handleShowHideLoader(true);
    InsightsApi.getscrollmapdetails(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            handleShowHideLoader(false);
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setSelectedScrollmapName(data.data.scrollmap_name);
          setPageUrl(data.data.page_url);
          setWebsiteUrl(data.data.website_url);
          setPageId(data.data.page_id);
          setFrontEndFramework(data.data.front_end_framework);
          setScrollmapPauseStatus(data.data.pause_status);
          setTestAssetsUrl(data.data.test_assets_url);
          setScrollmapStartDate(data.data.start_date);
          setScrollmapEndDate(data.data.end_date);
          setScrollmapRequestUrl(data.data.request_url);
          setScrollmapCreateDate(data.data.create_time);
          setCreatedDate(data.data.scrollmap_created_date);
          let decodedUrl = atob(data.data.test_assets_url);
          setAssetsUrlValue(decodedUrl);
          setScrollmapDetails(true);
          handleShowHideLoader(false);
        }else{
          handleShowHideLoader(false);
          setIsErrorModalOpen(true);
          setErrorMessage(data.message);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        handleShowHideLoader(false);
      });
  };

  const handleAssetsUrlModalOpen = () => {
    setIsAssetsUrlModalOpen(true);
  };

  const handleAssetsUrlModalClose = () => {
    setIsAssetsUrlModalOpen(false);
  };

  const handleResetStatClick = () => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to reset this report? You will lose any current report data.
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {console.log('Cancel')},
    onConfirm: () => {handleResetData()},
  });

  const handleResetData = () => {
    InsightsApi.resetscrollmapdata({
      scrollmap_id: selectedScrollmapId
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          fetchScrollmapDetails();
        }else{
          setIsErrorModalOpen(true);
          setErrorMessage(data.message);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  };

  const getStartDate = () => {
    const [year, month, day] = createdDate.split('-');
    return `${year}-${month}-${day}`;
  };

  const getSelectedFromDate = () => {
    if(selectedTimePickerFromDate){
      const [day, month, year] = selectedTimePickerFromDate.split('-');
      return `${year}-${month}-${day}`;
    }else{
      const [year, month, day] = createdDate.split('-');
      return `${year}-${month}-${day}`;
    }
  };

  const getSelectedToDate = () => {
    if(selectedTimePickerToDate){
      const [day, month, year] = selectedTimePickerToDate.split('-');
      return `${year}-${month}-${day}`;
    }else{
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  };

  const handleBackToInsightsClick = () => {
    navigate("/scroll-maps");
  };

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };

  useEffect(() => {
    if(selectedScrollmapId !== '' && selectedMemberWebsite !== '' && deviceType){
      fetchScrollmapDetails();
    }
  }, [selectedScrollmapId, selectedMemberWebsite, deviceType]);



  useEffect(() => {
    if(scrollmapDetails) {
      handleButtonClickTest();
      setScrollmapDetails(false);
    }
  }, [scrollmapDetails]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (recordingIconRef.current) {
      const { top, left, width } = recordingIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  const handleShowHideLoader = (value:any) => {
    var overlay = window.parent.parent.document.getElementById('overlay');
    if(value === true){
      overlay.innerHTML = '<div id="small_overlay" style="visibility: visible; display: block; background: none;"><h1>Page Loading...</h1><img src="img/loading.gif" height="22" width="126" alt="Loading..."></div>';
      overlay.style.display = 'block';
      overlay.style.visibility = 'visible';
    }else{
      overlay.style.display = 'none';
      overlay.style.visibility = 'hidden';
    }
  };

  return (
    <>
      {showHelpModal && (<SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />)}
      {/*START ASSETS URL*/}
      <AssetsUrl
          isAssetsUrlModalOpen={isAssetsUrlModalOpen}
          setIsAssetsUrlModalOpen={setIsAssetsUrlModalOpen}
          handleAssetsUrlModalClose={handleAssetsUrlModalClose}
          assetsUrlValue={assetsUrlValue}
          setAssetsUrlValue={setAssetsUrlValue}
          pageId={pageId}
          assetsredirecturl={fetchScrollmapDetails}
      />
      {/*END ASSETS URL*/}
      {/*START ERROR MODAL*/}
      {isErrorModalOpen &&
        <ErrorModal
          errorModelOpen={isErrorModalOpen}
          errorMessage={errorMessage}
          showErrorHeader={showErrorHeader}
          onClose={handleErrorModalClose}
        />
      }
      {/*END ERROR MODAL*/}
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>  
      <div style={{ height: '100vh' }}>
        <Container size="xxl">
          <Card shadow="sm" padding="sm" radius="md" withBorder style={{ height: '100%' }}>
            <div style={{ display: 'flex',marginBottom: '10px' }}>
              <input id="scrollmap_created_date" value={scrollmapCreateDate} style={{ display: 'none' }} readOnly />
              <input id="get_time_selected" value={selectedTimePicker} style={{ visibility: 'hidden', position: 'absolute', left: '-9999px' }} />
              <TextInput id="from" value={selectedTimePickerFromDate} style={{ display: 'none' }} readOnly />
              <TextInput id="to" value={selectedTimePickerToDate} style={{ display: 'none' }} readOnly />
              <Group position="apart" mb="xs">
                <Text weight={500}>{selectedScrollmapName} <IconHelp ref={recordingIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(255)}/></Text>
              </Group>
              <div style={{ display: 'flex',marginLeft:"auto"}}>
                <div rel="devicechange" className="test-pc-active" id="test-pc" onClick={handlePCView}></div>
                <div rel="devicechange" className="test-tablet" id="test-tablet" onClick={handleTabletView}></div>
                <div rel="devicechange" className="test-mobile" id="test-mobile" onClick={handleMobileView}></div>
                <ActionIcon
                    variant="white"
                    size="lg"
                    ml={10}
                >
                  <IconClockHour4 size="1.1rem" />
                </ActionIcon>
                <SelectTimePicker SelectTimePickerValue={selectedTimePicker} SelectTimePickerOnChange={handleselectedTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedTimePickerToDateChange} SelectTestStartDate={getSelectedFromDate()} SelectTestEndDate={getSelectedToDate()} SelectStartDate={getStartDate()}/>
                <Menu width={260} position="bottom-end" withinPortal>
                  <Menu.Target>
                    <ActionIcon
                      variant="default"
                      size="lg"
                      sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
                      })}
                      title="Settings"
                      ml={5}
                    >
                      <IconSettings size="1.1rem" />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item px={10} py={6} id="scrollmap_refresh_stats">Refresh Stats</Menu.Item>
                    <Menu.Item px={10} py={6} onClick={handleResetStatClick}>Reset Stats</Menu.Item>
                    <Menu.Item px={10} py={6} onClick={handleAssetsUrlModalOpen}>Image/Style Not Loaded</Menu.Item>
                  </Menu.Dropdown>
                </Menu> 
                <Button variant="default" style={{ marginLeft: '5px' }} onClick={handleBackToInsightsClick}>Back to Insights</Button>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <Text weight={500}>Page URL: </Text>
              <Link to={pageUrl} target="_blank" style={{ textDecoration: 'none', color: 'black' }}>{pageUrl}</Link>
            </div>
            <div className="test-wrapper flexed" style={{ width: '100%', height: '100%' }}>
              <Helmet>
                <link rel="stylesheet" type="text/css" href="/css/visiopt-visual.css"/>
                {loadResourceLoaded === 1 ?                  
                <script type="text/javascript">
                {`
                  var jQcustom = jQuery.noConflict();
                  function adjustIframeSize() {
                    var offset = jQcustom('.header-element').outerHeight() || 200;
                    var window_height = jQcustom(window).height() - offset;
                    var window_width = jQcustom(window).width();

                    jQcustom('#vis').css({
                      "height": window_height + "px",
                      "width": window_width + "px !important"
                    });
                  }

                  adjustIframeSize();

                  jQcustom(window).resize(function() {
                    adjustIframeSize();
                  });

                  function testmobile(){
                    jQcustom('#test-pc').removeClass('test-pc-active');
                    jQcustom('#test-tablet').removeClass('test-tablet-active');
                    jQcustom('#test-mobile').addClass('test-mobile-active');
                    jQcustom('#test-pc').addClass('test-pc');
                    jQcustom('#test-tablet').addClass('test-tablet');
                    jQcustom('#test-mobile').removeClass('test-mobile');
                    jQcustom('#vis').addClass('active_visi_mobile_insight');
                    jQcustom('#vis').removeClass('active_visi_tablet');
                  }
                  function testpc(){
                    jQcustom('#test-pc').removeClass('test-pc');
                    jQcustom('#test-mobile').addClass('test-mobile');
                    jQcustom('#test-tablet').addClass('test-tablet');
                    jQcustom('#test-pc').addClass('test-pc-active');
                    jQcustom('#test-mobile').removeClass('test-mobile-active');
                    jQcustom('#test-tablet').removeClass('test-tablet-active');
                    jQcustom('#vis').removeClass('active_visi_mobile_insight');
                    jQcustom('#vis').removeClass('active_visi_tablet');
                  }
                  function testtablet(){
                    jQcustom('#test-pc').addClass('test-pc');
                    jQcustom('#test-mobile').addClass('test-mobile');
                    jQcustom('#test-tablet').removeClass('test-tablet');
                    jQcustom('#test-pc').removeClass('test-pc-active');
                    jQcustom('#test-mobile').removeClass('test-mobile-active');
                    jQcustom('#test-tablet').addClass('test-tablet-active');
                    jQcustom('#vis').removeClass('active_visi_mobile_insight');
                    jQcustom('#vis').addClass('active_visi_tablet');
                  }
                `}
                </script>
                : ''}
              </Helmet>
              <link rel="stylesheet" id="test_added_font" type="text/css" />
              <style type="text/css" id="test_added_css"></style>
              <iframe
                style={{
                  border: 'none',
                  userSelect: 'none',
                  marginLeft: '-8px'
                }}
                id="vis"
                title="Scrollmap Iframe"
                srcDoc={iframeContent}
              />
            </div>
          </Card>
        </Container>
      </div>
      <div id="overlay" style={{ visibility: "hidden", display: "none", backgroundImage: "none", backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: '9999' }} />
    </>
  );
}