import React, { useState } from 'react';
import { IconSettings } from "@tabler/icons-react";
import { Menu, ActionIcon } from '@mantine/core';
import { ErrorModal } from "../../components/GeneralComponent/GeneralComponent";
import { Logs } from '../../components/CreateReport/Logs';
import { CampaignNotes } from '../../components/CreateReport/CampaignNotes';

export function CampaignSetting({
    selectedMemberWebsite,
    SelectedVisioptCampaign,
    ConversionGoalOptions,
    reportId,
    updateReportId
  }:any) {

  const [isCampaignNotesModalOpen, setIsCampaignNotesModalOpen] = useState(false);
  const [isConversionLogsModalOpen, setIsConversionLogsModalOpen] = useState(false);

  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);

/* Campaign NOTES */
  const handleCampaignNotesOpenModal = (value:any) => {
    setIsCampaignNotesModalOpen(true);
  };

  const handleCampaignNotesCloseModal = () => {
    setIsCampaignNotesModalOpen(false);
  };
/* END Campaign NOTES */

  const handleConversionLogsOpenModal = (value:any) => {
    if(SelectedVisioptCampaign){
      setIsConversionLogsModalOpen(true);
    }
  };

  const handleConversionLogsCloseModal = () => {
    setIsConversionLogsModalOpen(false);
  };

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

  return (
    <>
    {/*Campaign Notes Model*/}
    {((reportId && reportId !== '') || updateReportId !== '') && (
      <CampaignNotes
        campaignId={SelectedVisioptCampaign}
        isCampaignNotesModalOpen={isCampaignNotesModalOpen}
        onCampaignNotesClose={handleCampaignNotesCloseModal}
        errorModelOpen={seterrorCloseModal}
        errorMessage={setErrorMessage}
        showErrorHeader={setShowErrorHeader}
        showMessageHeader={setShowMessageHeader}
      />
    )}

    {/*Conversion Logs Model*/}
    {((reportId && reportId !== '') || updateReportId !== '') && (
      <Logs
        selectedMemberWebsite={selectedMemberWebsite}
        campaignId={SelectedVisioptCampaign}        
        testStartDate={"2024-10-14"}
        isConversionLogsModalOpen={isConversionLogsModalOpen}
        onConversionLogsClose={handleConversionLogsCloseModal}
        errorModelOpen={seterrorCloseModal}
        errorMessage={setErrorMessage}
        showErrorHeader={setShowErrorHeader}
        showMessageHeader={setShowMessageHeader}
      />
    )}
    {/*Error Model*/}
    <ErrorModal errorModelOpen={errorModelOpen} errorMessage={errorMessage} showErrorHeader={showErrorHeader} showMessageHeader={showMessageHeader} onClose={handlerrorCloseModal} />
      <Menu width={260} position="bottom-end" withinPortal>
        <Menu.Target>
          <ActionIcon
            variant="default"
            size="lg"
            sx={(theme) => ({
               backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
             })}
            title="Settings"
            ml={5}
          >
            <IconSettings size="1.1rem" />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item onClick={handleCampaignNotesOpenModal}>Campaign Notes</Menu.Item>
          <Menu.Item onClick={handleConversionLogsOpenModal}>Logs</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}