import React from 'react';
import { Text, Table, Anchor } from '@mantine/core';

export function RotationPageList({
  SelectedPageCreateTests,
  SelectedThumbnail,
  SelectedThumbnailChange,
  }:any) {

  const handleMultipleThumbnailClick = (pageId) => {
    SelectedThumbnailChange((prevSelectedThumbnails) => {
      if (prevSelectedThumbnails.includes(pageId)) {
        return prevSelectedThumbnails.filter(id => id !== pageId);
      } else {
        return [...prevSelectedThumbnails, pageId];
      }
    });
  };

  return (
    <>
      <Table striped highlightOnHover withBorder withColumnBorders mb={10}>
        <thead>
          <tr>
            <th>Preview</th>
            <th>URL</th>
            <th>Page Title</th>
            <th>Draft Test name</th>
            <th>Draft Test Type</th>
          </tr>
        </thead>
        <tbody>
          {SelectedPageCreateTests.map((item,index) => (
            <tr key={index} 
              style={{
              cursor: 'pointer',
              backgroundColor: (item.selected || SelectedThumbnail.includes(item.page_id)) ? '#00b975' : '',
              transition: 'background-color 0.1s'
              }}
              onClick={item.selected ? undefined : () => handleMultipleThumbnailClick(item.page_id)}
            >
              <td><a href={item.page_url} target="_blank" rel="noreferrer">Preview</a></td>
              <td><Text><Anchor href={item.page_url} target="_blank" color="inherit">{item.page_url}</Anchor></Text></td>
              <td><Text>{item.page_title}</Text></td>
              <td><Text>{item.draft_test_name}</Text></td>
              <td><Text>{item.draft_test_type_name}</Text></td>
            </tr>
        ))}
        </tbody>
      </Table>      
    </>
  );
}