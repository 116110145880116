import React from 'react';
import { Text, Table, Anchor } from '@mantine/core';

export function PageList({
  SelectedPageCreateTests,
  SelectedThumbnail,
  SelectedThumbnailChange,
  SelectedDraftThumbnail,
  SelectedDraftThumbnailChange,
  DraftThumbnailIsSelected,
  DraftThumbnailIsSelectedChange,
  ThumbnailIsSelected,
  ThumbnailIsSelectedChange,
  SelectedDraftPageIdChange,
  SelectedDraftTaguchiIdChange
  }:any) {

  const handleDraftThumbnailClick = (pageId,taguchiTestId,funnelId,testsId) => {
    if (DraftThumbnailIsSelected && SelectedDraftThumbnail === pageId) {
      SelectedDraftThumbnailChange(null);
      DraftThumbnailIsSelectedChange(false);
    } else {
      SelectedThumbnailChange(null);  
      SelectedDraftThumbnailChange(pageId);
      DraftThumbnailIsSelectedChange(true);
      SelectedDraftPageIdChange(pageId);
      SelectedDraftTaguchiIdChange(taguchiTestId);
    }
  };

  const handleThumbnailClick = (pageId,taguchiTestId,funnelId,testsId) => {
    if (ThumbnailIsSelected && SelectedThumbnail === pageId) {
      SelectedThumbnailChange(null);
      ThumbnailIsSelectedChange(false);
    } else {
      SelectedThumbnailChange(pageId);
      ThumbnailIsSelectedChange(true);
      SelectedDraftThumbnailChange(null);  
    }
  };

  return (
    <>
      <Table striped highlightOnHover withBorder withColumnBorders mb={10}>
        <thead>
          <tr>
            <th>URL</th>
            <th>Page Title</th>
            <th>Draft Test name</th>
            <th>Draft Test Type</th>
          </tr>
        </thead>
        <tbody>
          {SelectedPageCreateTests.map((item,index) => (
          <tr key={index} 
              style={{ backgroundColor: (item.selected || SelectedThumbnail === item.page_id || SelectedDraftThumbnail === item.page_id) ? '#00b975' : '',
                transition: 'background-color 0.1s',
                color: (item.selected || SelectedThumbnail === item.page_id) ? 'white' : 'inherit' 
              }} 
              onClick={item.selected ? undefined : () => {
                if (item.draft_test_type_name === "Single Variable Test") {
                    handleDraftThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id);
                } else if (item.draft_test_type_name === "Modern AB") {
                    handleDraftThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id);
                } else {
                    handleThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id);
                }
              }}
          >
            <td><a href={item.page_url} target="_blank" rel="noreferrer" style={{color: (item.selected || SelectedThumbnail === item.page_id) ? 'white' : ''}}>Preview</a></td>
            <td><Text><Anchor href={item.page_url} target="_blank" color="inherit">{item.page_url}</Anchor></Text></td>
            <td><Text>{item.draft_test_name}</Text></td>
            <td><Text>{item.draft_test_type_name}</Text></td>
          </tr>
        ))}
        </tbody>
      </Table>      
    </>
  );
}