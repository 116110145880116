import React from 'react';
import { Text, Table, Anchor } from '@mantine/core';

export function ReviewPageList({
    SelectedPageTests,
    SelectedThumbnail,
    SelectedThumbnailChange,
    IsThumbnailSelected,
    IsThumbnailSelectedChange,
    SelectedTaguchiIdChange,
    SelectedFunnelIdChange,
    SelectedTestIdChange
  }:any) {

  const handleThumbnailClick = (pageId,taguchiTestId,funnelId,testsId) => {
    if (IsThumbnailSelected && SelectedThumbnail === pageId) {
      SelectedThumbnailChange(null);
      SelectedTaguchiIdChange(null);
      SelectedFunnelIdChange(null);
      SelectedTestIdChange(null);
      IsThumbnailSelectedChange(false);
    } else {
      SelectedThumbnailChange(pageId);
      SelectedTaguchiIdChange(taguchiTestId);
      SelectedFunnelIdChange(funnelId);
      SelectedTestIdChange(testsId);
      IsThumbnailSelectedChange(true);
    }
  };

  return (
    <>
      <Table striped highlightOnHover withBorder withColumnBorders mb={10} style={{cursor: 'pointer'}}>
        <thead>
          <tr>
            <th>Preview</th>
            <th>URL</th>
            <th>Page Title</th>
            <th>Uniques</th>
            <th>Visits</th>
            <th>Active Test name</th>
            <th>Test Type</th>
          </tr>
        </thead>
        <tbody>
          {SelectedPageTests.map((item) => (
            <tr key={item.page_id || ''} 
                style={{ backgroundColor: (item.selected || SelectedThumbnail === item.page_id) ? '#00b975' : '',
                         transition: 'background-color 0.1s',
                         color: (item.selected || SelectedThumbnail === item.page_id) ? 'white' : 'inherit' 
                      }} 
                onClick={item.selected ? undefined : () => handleThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id)}
            >
              <td><a href={item.page_url} target="_blank" rel="noreferrer" style={{color: (item.selected || SelectedThumbnail === item.page_id) ? 'white' : ''}}>Preview</a></td>
              <td><Text w={350} style={{ overflow: 'hidden' }}><Anchor href={item.page_url} target="_blank" color="inherit">{item.page_url}</Anchor></Text></td>
              <td><Text w={300} style={{ overflow: 'hidden' }}>{item.page_title}</Text></td>
              <td><Text>{item.uniques}</Text></td>
              <td><Text>{item.visits}</Text></td>
              <td><Text>{item.test_name}</Text></td>
              <td><Text>{item.test_type_name}</Text></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}