import React from 'react';
import { useNavigate } from "react-router-dom";
import { Image, Grid } from '@mantine/core';
import TestsApi from "../../api/TestsApi";

export function ReviewPageGridList({
    SelectedPageTests,
    SelectedThumbnail,
    SelectedThumbnailChange,
    IsThumbnailSelected,
    IsThumbnailSelectedChange,
    SelectedTaguchiIdChange,
    SelectedFunnelIdChange,
    SelectedTestIdChange,
    SelectedMemberWebsite,
    ErrorMessageChange,
    ErrorCloseModal,
    ShowMessageHeader,
  }:any) {

  const navigate = useNavigate();

  const handleThumbnailDoubleClick = async (SelectedThumbnail,taguchi_test_id,funnel_id,tests_id) =>{ 
    SelectedTaguchiIdChange(taguchi_test_id);
    SelectedFunnelIdChange(funnel_id);
    try {
      const APIResponse = await TestsApi.checkpageinfunnelvalidation({
        page_id: SelectedThumbnail,
        taguchi_test_id: taguchi_test_id,
        system_website_id : SelectedMemberWebsite,
        funnel_id:funnel_id
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        navigate("/reviewing-test", { state: { pageId: SelectedThumbnail, taguchiTestId: taguchi_test_id, funnelId:funnel_id, testsId:tests_id} });
      }else{
        ErrorMessageChange(APIResultJSON.message);
        ErrorCloseModal(true);
        ShowMessageHeader(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleThumbnailClick = (pageId,taguchiTestId,funnelId,testsId) => {
    if (IsThumbnailSelected && SelectedThumbnail === pageId) {
      SelectedThumbnailChange(null);
      SelectedTaguchiIdChange(null);
      SelectedFunnelIdChange(null);
      SelectedTestIdChange(null);
      IsThumbnailSelectedChange(false);
    } else {
      SelectedThumbnailChange(pageId);
      SelectedTaguchiIdChange(taguchiTestId);
      SelectedFunnelIdChange(funnelId);
      SelectedTestIdChange(testsId);
      IsThumbnailSelectedChange(true);
    }
  };

  return (
    <>
      <Grid ml={0} mt={10} mb={10} >
        {SelectedPageTests.map((item, index) => (
          <div className="visi-page" key={index} style={{marginRight:10}} >
            <div className={item.pageClass} 
                  style={{ cursor: 'pointer', backgroundColor: (item.selected || SelectedThumbnail === item.page_id) ? '#00b975' : '' }} 
                  onClick={item.selected ? undefined : () => handleThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id)}
                >
              <span title={item.page_url} onDoubleClick={item.selected || item.pageClass === "page winner" ? undefined : () => handleThumbnailDoubleClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id)}>
                <span className="page-url">{item.page_title}</span>
              </span>
              <div className="thumbnail" style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.img_url})` }} title={item.page_url} onDoubleClick={item.selected || item.pageClass === "page winner" ? undefined : () => handleThumbnailDoubleClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id)}>
                {(item.marge_img !== '' && (
                    <Image
                    id="merge_img"
                    src={item.marge_img}
                    title="This Page is merged with other links"
                  />
                  ))
                }
                {(item.track_check !== '' && (
                  <Image
                    id="track_img"
                    src={item.track_check}
                    title={item.track_check_title}
                  />
                  ))
                }
              </div>
              <span className="page-title">{item.custom_title}</span>
            </div>
          </div>
        ))}
      </Grid>
    </>
  );
}