import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import { ActionIcon, Anchor, Text, Modal,  } from '@mantine/core';
import { IconHelp, IconInfoCircle } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function TestInfo({ pageId, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [testInfoModalOpen, setTestInfoModalOpen] = useState(false);
  const [testInfoPageLink, setTestInfoPageLink] = useState('');
  const [testInfoPageTitle, setTestInfoPageTitle] = useState('');
  const [testInfoTestTime, setTestInfoTestTime] = useState('');
  const [testInfoTestType, setTestInfoTestType] = useState('');
  const [testInfoVisioptTestId, setTestInfoVisioptTestId] = useState('');

  const testInfoRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleTestInfoOpenModal = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    }
    TestsApi.gettestinfo({
      page_id:pageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setTestInfoPageLink(data.data.page_link);
        setTestInfoPageTitle(data.data.page_title);
        setTestInfoTestTime(data.data.test_time);
        setTestInfoTestType(data.data.test_type);
        setTestInfoVisioptTestId(data.data.visiopt_test_id);
        setTestInfoModalOpen(true);
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleTestInfoCloseModal = () => {
    setTestInfoModalOpen(false);
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (testInfoRef.current) {
      const { top, left, width } = testInfoRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
    {showHelpModal &&
      ReactDOM.createPortal(
        <div style={{ zIndex: 9999 }}>
          <SelectTooltip
            isVisible={showHelpModal}
            setIsVisible={setShowHelpModal}
            id={helpTargetId}
            tooltipPosition={tooltipPosition}
          />
        </div>,
        document.body
      )}
      <ActionIcon
        variant="default"
        size="lg"
        sx={(theme) => ({
           backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
         })}
        title="Test Info"
        ml={5}
        onClick={() => handleTestInfoOpenModal(1)}
      >
        <IconInfoCircle size="1.1rem" />
      </ActionIcon>     
 
      <Modal.Root opened={testInfoModalOpen} onClose={handleTestInfoCloseModal}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title><Text weight={500}>TEST INFORMATION <IconHelp ref={testInfoRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(203)}/></Text></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form>
              <div style={{ display: 'flex'}}>
                <Text size="sm" fz={14} fw={500} mr={5}>Test ID:</Text>
                <Text size="sm" fz={14}>{testInfoVisioptTestId}</Text>
              </div>
              <div style={{ display: 'flex'}}>
                <Text size="sm" fz={14} fw={500} mr={5}>Tested Page:</Text>
                <Anchor href={testInfoPageLink} target="_blank" fw={500} mr={5} mt={-2}>{testInfoPageTitle} </Anchor>
              </div>
              <div style={{ display: 'flex'}}>
                <Text size="sm" fz={14} fw={500} mr={5}>Test Type:</Text>
                <Text size="sm" fz={14} mr={5}>{testInfoTestType}</Text>
              </div>
              <div style={{ display: 'flex'}}>
                <Text size="sm" fz={14} fw={500} mr={5}>Test Start on:</Text>
                <Text size="sm" fz={14}>{testInfoTestTime}</Text>
              </div>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>      
    </>
  );
}