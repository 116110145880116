import { Group, Text, Button, ActionIcon, Select, Input, Table } from '@mantine/core';
import { IconHelp, IconTrash, IconCheck, IconX } from '@tabler/icons-react';
import React, { useState, useEffect, useRef } from 'react';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import SettingsApi from "../../../api/SettingsApi";
import { SelectMemberWebsiteList, SelectTooltip, VisiLoader } from "../../GeneralComponent/GeneralComponent";

export function CustomTags({ SelectMemberWebsiteListValue }:any) {

	const navigate = useNavigate();
	const activeURLBuilder = 'Custom-tags';
	const [websitePageList, setWebsitePageList] = useState([]);
	const [customTagsData, setCustomTagsData] = useState([]);
	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
	const [selectedPageValue, setSelectedPageValue] = useState('');
	const [customTagName, setCustomTagName] = useState('');
	const customTagIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [visiLoaderVisible, setVisiLoaderVisible] = useState(false);

	useEffect(() => {
  	if(SelectMemberWebsiteListValue !== ""){
  		setSelectedMemberWebsite(SelectMemberWebsiteListValue);
  	}
  }, [SelectMemberWebsiteListValue]);

	const fetchCustomTagDetails = () => {
		setVisiLoaderVisible(true);
		SettingsApi.getcustomtagdetails()
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            setVisiLoaderVisible(false);
          }
        }
      })
      .then(data => {
        if(data.status === 1){       	
          setCustomTagsData(data.data);   
          setVisiLoaderVisible(false);
        }
      })
      .catch(error => {
        console.log(error);
        setVisiLoaderVisible(false);
      });
    };

	const deleteCustomTagConfirm = (deleteCustomTagId:any) => modals.openConfirmModal({
    title: 'Delete Custom Tag',
    children: (
      <Text size="sm">
        Are you sure you want to delete this Custom Tag?
      </Text>
    ),
    labels: { confirm: 'Delete Custom Tag', cancel: "No don't delete it" },
    confirmProps: { color: 'red' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => console.log('Confirmed',handleCustomTagDelete(deleteCustomTagId)),
  });


  const handleCustomTagDelete = (deleteCustomTagId:any) => {
  	setVisiLoaderVisible(true);
    SettingsApi.deletecustomtag({
      custom_tag_id: deleteCustomTagId,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
          setVisiLoaderVisible(false);
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        }); 
        fetchCustomTagDetails();
        setVisiLoaderVisible(false);   
      }else{
      	notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        });
        setVisiLoaderVisible(false);
      }
    })
    .catch(error => {
      console.log(error);
      setVisiLoaderVisible(false);
    });
  }

  const handleMemberWebsiteChange = (e:any) => {
  		setSelectedMemberWebsite(e);			
  };

  const fetchMemberPages = () => {
  	SettingsApi.getwebsitepages({
	      system_website_id: selectedMemberWebsite,
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	    	setWebsitePageList(data.data)
	    })
	    .catch(error => {
	      console.log(error);
	    });	
  };

  const handleAddCustomTag = (e:any) => {
  	e.preventDefault();  		
  	SettingsApi.addcustomtag({
      page_id:selectedPageValue,
  		custom_tag:customTagName,
  		system_website_id:selectedMemberWebsite
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });  
        setSelectedMemberWebsite('');
        setSelectedPageValue('');
        setCustomTagName('');
        fetchCustomTagDetails();   
      }else{
      	notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        });
      }
    })
    .catch(error => {
      console.log(error);
    });  	
  };

  useEffect(() => {
      fetchCustomTagDetails();
  },[]);

  useEffect(() => {
    if (selectedMemberWebsite) {
      fetchMemberPages(); 
    }
  }, [selectedMemberWebsite]);

   const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (customTagIconRef.current) {
      const { top, left, width } = customTagIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10,
      });
    }
  };

	return (
		<>  	
			<VisiLoader VisiLoaderVisible={visiLoaderVisible} />
			{showHelpModal &&
        ReactDOM.createPortal(
          <div>
            <SelectTooltip
              isVisible={showHelpModal}
              setIsVisible={setShowHelpModal}
              id={helpTargetId}
              tooltipPosition={tooltipPosition} 
            />
          </div>,
        document.body
      )}
			<div style={{ display: activeURLBuilder === "Custom-tags" ? "block" : "none"}}>
				<Group position="apart" mb="xs">
		      <Text weight={500}>CUSTOM TAGS <IconHelp ref={customTagIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(231)}/></Text>
		    </Group>
		    <form onSubmit={handleAddCustomTag}>
			    <Group mb={15}>		    		
		          <Text fw={500}>Select domain :</Text>
		          <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleMemberWebsiteChange} SelectMemberWebsiteListWidth={250} SelectMemberWebsiteListUsedIn="settings" SelectMemberWebsiteListMaxDropdownHeight={300} SelectMemberWebsiteListdropdownPosition="bottom" SelectMemberWebsiteListML={4}/>
		          <Text fw={500}>Page :</Text>
		          <Select
				        data={[
							    { value: '', label: 'Select Page' }, 
							    ...websitePageList.slice(1).map((page, index) => ({
							      value: page.page_id,
							      label: page.page_url
							    }))
							  ]}
				        value={selectedPageValue}
				        onChange={(value) => setSelectedPageValue(value)}	            
		          />
		      		<Input 
		      			w={200} 
		      			ml={0} 
		      			placeholder='Custom Tag' 
		      			value={customTagName} 
		      			onChange={(e) => setCustomTagName(e.target.value)} 
		      		/>	
		      		<Button type="submit" size="sm" w={80}>
	             Save
	          </Button>	      	         
		      </Group>
		    </form>
	      <Table>
	      	<thead>
	      		<tr>
	      			<th>ID</th>
	      			<th>Custom Tag</th>
	      			<th>Domain</th>
	      			<th>Page Name</th>
	      			<th></th>
	      		</tr>
	      	</thead>
	      	<tbody>
	      		{customTagsData.map((data,index) => (
	      			<tr key={index}>
		      			<td>{index+1}</td>
		      			<td>{data.custom_tag}</td>
		      			<td>{data.domain_name}</td>
		      			<td>{data.title}</td>
		      			<td>
		      				<ActionIcon
	                  variant="default"
	                  size="lg"
	                  mx={3} 
                    title="Delete"
	                  onClick={() => deleteCustomTagConfirm(data.custom_tag_id)}                 
	                	>
	                  <IconTrash size="1.1rem" />
	                </ActionIcon>
	              </td>
		      		</tr> 
	      		))}	      		     	
	      	</tbody>
	      </Table>
	      </div>
		</>
	);
}