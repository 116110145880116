import { Group, Text, Box, Grid, ActionIcon, Flex, Container, Card, Image, Select, Checkbox, Pagination, Button, Skeleton } from '@mantine/core';
import { IconClockHour4, IconHelp, IconTrash, IconPlayerPlay, IconDeviceDesktop, IconDeviceMobile, IconDeviceTablet, IconBrandChrome, IconBrandFirefox, IconBrandOpera, IconBrandSafari, IconBrandEdge, IconRefresh, IconWorld, IconMapPin } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { useNavigate, useLocation } from "react-router-dom";
import { modals } from '@mantine/modals';
import { SelectTimePicker, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import InsightsApi from "../../api/InsightsApi";

export function RecordingsList() {
  useDocumentTitle("Visiopt - Insights");
  const navigate = useNavigate();

  const location = useLocation();
  const funnelId = location.state?.funnel_id;
  const [selectedFunnelId] = useState(funnelId);
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [durationVisitValue, setDurationVisitValue] = useState('');
  const [pageVisitValue, setPageVisitValue] = useState('');
  const [filterDeviceValue, setFilterDeviceValue] = useState('-1');
  const [selectedTimePicker, setSelectedTimePicker] = useState('');
  const [selectedTimePickerToDate, setSelectedTimePickerToDate] = useState('');
  const [selectedTimePickerFromDate, setSelectedTimePickerFromDate] = useState('');
  const [engagedVisitorChecked, setEngagedVisitorChecked] = useState(false);
  const [durationValueChecked, setDurationValueChecked] = useState(true);
  const [pageValueChecked, setPageValueChecked] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalRecordingPages, setTotalRecordingPages] = useState(0);
  const [refreshList, setRefreshList] = useState(false);
  const recordingListIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [showSkeleton, setShowSkeleton] = useState(false);

  const [recordingList, setRecordingList] = useState([]);
  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const fetchRecordingsList = (dataObject:any) => {    
    setShowSkeleton(true);
    InsightsApi.getrecordingslist(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            setShowSkeleton(false);
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setDurationVisitValue(data.data.duration);
          setPageVisitValue(data.data.no_of_view_page);
          setRecordingList(data.data.recording_list);
          setTotalRecordingPages(data.data.total_pages);
          if(selectedTimePicker === '-2'){
            const [fromMonth, fromDate, fromYear] = data.data.from.split('/');
            setSelectedTimePickerFromDate(`${fromDate}-${fromMonth}-${fromYear}`);
            const [endMonth, endDate, endYear] = data.data.to.split('/');
            setSelectedTimePickerToDate(`${endDate}-${endMonth}-${endYear}`);
          }
          setShowSkeleton(false);
        }else{
          setShowSkeleton(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setShowSkeleton(false);
      });
  }

  const handleRecordToggle = (userSession:any) => {
    navigate('/recording-view', { state: { userSession: userSession, funnelId: selectedFunnelId } });
  };

  const handleDeleteRecording = (deleteRecordingId:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to delete this Recording?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {console.log('Cancel')},
    onConfirm: () => {handleRecordingDelete(deleteRecordingId)}
  });

  const handleRecordingDelete = (recordingId:any) => {    
    InsightsApi.deleterecording({
      recording_id: recordingId
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          const dataObject = {
            funnel_id: selectedFunnelId,
            system_website_id: selectedMemberWebsite,
          };
          fetchRecordingsList(dataObject);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  }

  const handleFilterDeviceChange = (value:any) => {
    setFilterDeviceValue(value);
  };

  const handleselectedTimePickerComponentChange = (Value:any) => {
    setSelectedTimePicker(Value);   
  };

  const handleselectedTimePickerToDateChange = (Value:any) => {
    setSelectedTimePickerToDate(Value);
  };

  const handleselectedTimePickerFromDateChange = (Value:any) => {
    setSelectedTimePickerFromDate(Value);
  };

  const handleEngagedVisitorChecked = () => {
    setEngagedVisitorChecked(!engagedVisitorChecked);
    setDurationValueChecked(true);
    setPageValueChecked(true);
  };

  const handleDurationValueChecked = () => {
    setDurationValueChecked(!durationValueChecked);
  };

  const handlePageValueChecked = () => {
    setPageValueChecked(!pageValueChecked);
  };

  const handleFiltersChange = (selectedFunnelId:any, selectedMemberWebsite:any, filterDeviceValue:any, timeValue:any, fromDate:any, toDate:any, engagedVisitorChecked:any, durationChecked:any, pageChecked:any, activePage:any) => {
    if(selectedFunnelId !== '' && selectedMemberWebsite !== ''){
      if(timeValue === -2 && fromDate === '' && toDate === ''){
        return;
      }
      const dataObject = {
        funnel_id: selectedFunnelId,
        system_website_id: selectedMemberWebsite,
        filter_by: filterDeviceValue,
        page: activePage
      } as any;
      if(timeValue !== -2){
        dataObject.filter = 1;
        dataObject.time = timeValue;
      }
      if(timeValue === -2 && fromDate !== '' && toDate !== ''){
        dataObject.filter = 2;
        dataObject.time = timeValue;
        const [fromdate, fromMonth, fromYear] = fromDate.split('-');
        const fromDateValue = `${parseInt(fromMonth)}/${parseInt(fromdate)}/${fromYear}`;
        const [endDate, endMonth, endYear] = toDate.split('-');
        const toDateValue = `${parseInt(endMonth)}/${parseInt(endDate)}/${endYear}`;
        dataObject.from = fromDateValue;
        dataObject.to = toDateValue;
      }
      if(engagedVisitorChecked) {
        dataObject.engaged_visitors = 'engaged_visitors';
        if(durationChecked) {
          dataObject.duration = durationVisitValue;
        }
        if(pageChecked) {
          dataObject.no_of_view_page = pageVisitValue;
        }
      }
      fetchRecordingsList(dataObject);
    }
  };

  const getSelectedFromDate = () => {    
    if(selectedTimePickerFromDate){
      const [day, month, year] = selectedTimePickerFromDate.split('-');      
      return `${year}-${month}-${day}`; 
    }else{
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }    
  };

  const getSelectedToDate = () => {
    if(selectedTimePickerToDate){
      const [day, month, year] = selectedTimePickerToDate.split('-');
      return `${year}-${month}-${day}`;
    }else{
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }    
  };

  const handleActivePageChange = (value:any) => {
    setActivePage(value);
  };

  const clearReportConfirm = () => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to remove all the data ?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {console.log('Cancel')},
    onConfirm: () => handleClearReport()
  });

  const handleClearReport = () => {
    const dataObject = {
      system_website_id: selectedMemberWebsite,
      funnel_id: selectedFunnelId
    };
    InsightsApi.clearrecordinglist(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        const dataObject = {
          funnel_id: selectedFunnelId,
          system_website_id: selectedMemberWebsite,
        };
        fetchRecordingsList(dataObject);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (recordingListIconRef.current) {
      const { top, left, width } = recordingListIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  useEffect(() => {
    handleFiltersChange(selectedFunnelId, selectedMemberWebsite, filterDeviceValue, selectedTimePicker, selectedTimePickerFromDate, selectedTimePickerToDate, engagedVisitorChecked, durationValueChecked, pageValueChecked, activePage);
  }, [selectedFunnelId, selectedMemberWebsite, filterDeviceValue, selectedTimePicker, selectedTimePickerFromDate, selectedTimePickerToDate, engagedVisitorChecked, durationValueChecked, pageValueChecked, activePage, refreshList]);

  return (
    <>      
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      <Container size="xl">
        <Card shadow="sm" padding="sm" radius="md" withBorder>
          <Group position="apart" mb="xs">
            <Text weight={500}>RECORDINGS <IconHelp ref={recordingListIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(305)}/></Text>
          </Group>
          <Box
            p={4}
            pl={20}
            mt={10}
            sx={(theme) => ({
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
              padding: "10px",
              borderRadius: theme.radius.md,
              fontWeight: "bold",
            })}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '30px', marginTop: '10px' }}>
                <ActionIcon
                  variant="default"
                  size="lg"
                  mr={10}
                  title="Refresh List"
                  onClick={() => setRefreshList(!refreshList)}
                >
                  <IconRefresh size="1.1rem" />
                </ActionIcon>
                <Text weight={400} mt={5}>Filter by device : </Text>
                <Select
                  data={[
                    { value: '-1', label: 'All' },
                    { value: '0', label: 'Desktop' },
                    { value: '1', label: 'Mobile' },
                    { value: '2', label: 'Tablet' },
                  ]}
                  ml={5}
                  dropdownPosition="bottom"
                  withinPortal 
                  value={filterDeviceValue}
                  onChange={handleFilterDeviceChange}
                />
                <ActionIcon
                  variant="white"
                  size="lg"
                  ml={10}
                  title="Time Filter"
                >
                  <IconClockHour4 size="1.1rem" />
                </ActionIcon>
                <SelectTimePicker SelectTimePickerValue={selectedTimePicker} SelectTimePickerOnChange={handleselectedTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedTimePickerToDateChange} SelectTestStartDate={getSelectedFromDate()} SelectTestEndDate={getSelectedToDate()}/>
                <Checkbox
                  mt={5}
                  ml={10}
                  label="Engaged visitors"
                  labelPosition="left"
                  styles={{
                    label: {
                      fontWeight: 'normal',
                      fontSize: '16px',
                    },
                  }}
                  checked={engagedVisitorChecked}
                  onChange={handleEngagedVisitorChecked}
                />
                {engagedVisitorChecked &&
                  <>
                    <Checkbox
                      mt={5}
                      ml={10}
                      label="Duration: "
                      labelPosition="left"
                      styles={{
                        label: {
                          fontWeight: 'normal',
                          fontSize: '16px',
                        },
                      }}
                      checked={durationValueChecked}
                      onChange={handleDurationValueChecked}
                    />
                    <Text weight={400} mt={4} ml={5}>{durationVisitValue}</Text>
                    <Checkbox
                      mt={5}
                      ml={10}
                      label="Pages: "
                      labelPosition="left"
                      styles={{
                        label: {
                          fontWeight: 'normal',
                          fontSize: '16px',
                        },
                      }}
                      checked={pageValueChecked}
                      onChange={handlePageValueChecked}
                    />
                    <Text weight={400} mt={4} ml={5}>{pageVisitValue}</Text>
                  </>
                }
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '30px', marginTop: '10px' }}>
                <Button variant="default" onClick={clearReportConfirm}>Clear Report</Button>
              </div>
            </div>
            <Grid gutter="xs">
              <Grid.Col span={2}>
                <Text mt={3} weight={500}>Play</Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text mt={3} weight={500}>Date</Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text mt={3} weight={500}>Duration</Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text mt={3} weight={500}>Pages</Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex
                  justify="flex-start"
                  align="center"
                  direction="row"
                  wrap="wrap"
                  gap="sm"
                  mt={3}
                >
                  <ActionIcon title="Device"><IconDeviceDesktop style={{ color: 'initial' }} /></ActionIcon>
                  <ActionIcon title="Browser"><IconWorld style={{ color: 'initial' }} /></ActionIcon>
                  <ActionIcon title="Location"><IconMapPin style={{ color: 'initial' }} /></ActionIcon>
                  <ActionIcon title="Delete"><IconTrash style={{ color: 'initial' }} /></ActionIcon>
                </Flex>
              </Grid.Col>
            </Grid>
          </Box>
          {showSkeleton ? (
            <Flex direction="column" gap={4}>  
              {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton key={index} height={44} width={1246} mt={10} />
              ))}
            </Flex>
          ) : (
            recordingList && recordingList.map((recording, recordingIndex) => (
              <Box
                key={recordingIndex}
                p={4}
                pl={20}
                mt={10}
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
                  padding: "10px",
                  borderRadius: theme.radius.md,
                  border: "0.0625rem solid transparent",
                  borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
                })}
              >
                <Grid gutter="xs">
                  <Grid.Col span={2}>
                    <ActionIcon
                      size="lg"
                      style={{ backgroundColor: "#1c7ed6" }}
                      title="Play"
                      onClick={() => handleRecordToggle(recording.userSession)}
                    >
                      <IconPlayerPlay color="white" />
                    </ActionIcon>
                  </Grid.Col>  
                  <Grid.Col span={3}>
                    <Text mt={3}>
                      {recording.date}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text mt={3}>
                      {recording.duration}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Text mt={3}>
                      {recording.pages}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Flex
                      justify="flex-start"
                      align="center"
                      direction="row"
                      wrap="wrap"
                      gap="sm"
                    >
                      <ActionIcon title="Device">
                        {recording.device_type === "0" && <IconDeviceDesktop />}
                        {recording.device_type === "1" && <IconDeviceMobile />}
                        {recording.device_type === "2" && <IconDeviceTablet style={{ transform: 'rotate(90deg)', width: '30px', height: '30px' }} />}
                      </ActionIcon>
                      <ActionIcon title="Browser">
                        {recording.browser === 'chrome' && <IconBrandChrome />}
                        {recording.browser === 'edge' && <IconBrandEdge />}
                        {recording.browser === 'firefox' && <IconBrandFirefox />}
                        {recording.browser === 'safari' && <IconBrandSafari />}
                        {recording.browser === 'ie' && <IconBrandEdge />}
                        {recording.browser === 'opera' && <IconBrandOpera />}
                      </ActionIcon>
                      {recording.country_code !== '' ? (
                        <Image
                          src={`./img/flags/${recording.country_code}.svg`} alt={`${recording.country_code} flag`}
                          style={{ height: '22px', width: '22px', marginTop: '3px' }}
                          title="Location"
                        />
                      ) : (
                        <Image style={{ height: '22px', width: '22px', marginTop: '3px' }} />
                      )}                      
                      <ActionIcon
                        variant="default"
                        size="lg"
                        mx={3}
                        title="Delete"
                        onClick={() => handleDeleteRecording(recording.recording_id)}
                      >
                        <IconTrash size="1.1rem" />
                      </ActionIcon>
                    </Flex>                  
                  </Grid.Col>
                </Grid>              
              </Box>
            ))
          )}
          {totalRecordingPages > 1 &&
            <Pagination.Root
              value={activePage}
              onChange={handleActivePageChange}
              total={totalRecordingPages}              
            >
              <Group spacing={5} position="center" mt={10}>
                <Pagination.First />
                <Pagination.Previous />
                <Pagination.Items />
                <Pagination.Next />
                <Pagination.Last />
              </Group>
            </Pagination.Root>
          }
        </Card>
      </Container>
    </>
  );
}