import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button, Input, Group, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import CampaignReport from "../../api/CampaignReport";
import { notifications } from '@mantine/notifications';
import { VisiLoader } from "../../components/GeneralComponent/GeneralComponent";

export function ResetStats({ siteId, campaignId, isResetStatsModalOpen, onResetStatsClose, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader, selectedVisioptCampaign, CampaignGoalDropDown, reportId, fetchTrafficTableData }:any) {
  const navigate = useNavigate();
  const [resetStatsValue, setResetStatsValue] = useState<string>('');
  const [error, setError] = useState('');
  const [isVisiLoaderVisible, setIsVisiLoaderVisible] = useState(false);

  const handleResetStatsTest = () => {
    if (resetStatsValue.trim() === 'RESET') {
      setIsVisiLoaderVisible(true);
      CampaignReport.campaignresetstats({
        system_website_id: siteId,
        campaign_id: campaignId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setIsVisiLoaderVisible(false);
          onResetStatsClose();
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
          setResetStatsValue('');
          setError('');
          if(selectedVisioptCampaign === campaignId){
            CampaignGoalDropDown(campaignId, 'first_campaign');
          }else{
            CampaignGoalDropDown(campaignId, 'comparison_campaign');
          }
          fetchTrafficTableData({ report_id: reportId });
        } else {
          setIsVisiLoaderVisible(false);
          errorMessage(data.message);
          errorModelOpen(true);
          showErrorHeader(true);
          onResetStatsClose();
        }
      })
      .catch(error => {
        setIsVisiLoaderVisible(false);
        console.error("API Error:", error);
      });
    } else {
      setError('Word does not match.');
    }
  };

  return (
    <>
      <Modal
        opened={isResetStatsModalOpen}
        onClose={() => {
          onResetStatsClose();
          setResetStatsValue('');
          setError('');
        }}
        title="Reset Stats"
      >
        <VisiLoader VisiLoaderVisible={isVisiLoaderVisible}/>
        <Text size="sm" style={{ userSelect: 'none' }}>
          Are you sure you want to reset the stats of your test to zero? This will also reset your campaign data so you will lose all data. If you want to proceed, please enter the word <b>RESET</b> below:
        </Text>
        <Input
          mt={10}
          mb={10}
          value={resetStatsValue}
          onChange={(e) => {
            const value = e.currentTarget.value;
            setResetStatsValue(value);
            if (value.trim() === 'RESET') {
              setError('');
            }
          }}
        />
        {error && (
          <Text color="red" size="sm" mt={10}>
            {error}
          </Text>
        )}
        <Group position="right">
          <Button onClick={() => handleResetStatsTest()}>OK</Button>
        </Group>
      </Modal>
    </>
  );
}