import { ServerAPI } from '../GeneralFunction';

const FunnelAPI = {
	addfunnel: (data) => {
		let url = "add_funnel";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	deletefunnel: (data) => {
		let url = "delete_funnel";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getpageinaddbuttonsection: (data) => {
		let url = "get_page_in_add_button_section";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getbuttoninaddbuttonsection: (data) => {
		let url = "get_button_in_add_button_section";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	addbuttontofunnel: (data) => {
		let url = "add_button_to_funnel";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getblockipaddress: (data) => {
		let url = "get_block_ip_address";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	addblockipaddress: (data) => {
		let url = "add_block_ip_address";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	deleteblockipaddress: (data) => {
		let url = "delete_block_ip_address";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	selectfunnel: (data) => {
		let url = "selectfunnel";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getpagetimeout: (data) => {
		let url = "get_page_timeout";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savepagetimeout: (data) => {
		let url = "save_page_timeout";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getpagedetail: (data) => {
		let url = "get_page_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	editpage: (data) => {
		let url = "edit_page";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	marksalesconversion: (data) => {
		let url = "mark_sales_conversion";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gettrackingcode: (data) => {
		let url = "get_tracking_code";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	unsetwinner: (data) => {
		let url = "unset_winner";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	removefromfunnel: (data) => {
		let url = "remove_from_funnel";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	removebuttonfromfunnel: (data) => {
		let url = "remove_button_from_funnel";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	checktracking: (data) => {
		let url = "check_tracking";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	discarddraft: (data) => {
		let url = "discard_draft";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	deletepage: (data) => {
		let url = "delete_page";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	refreshscreenshot: (data) => {
		let url = "refresh_screenshot";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	addpage: (data) => {
		let url = "add_page";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savecustomtitle: (data) => {
		let url = "save_custom_title";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	selectpagesfromfunnel: (data) => {
		let url = "select_pages_from_funnel";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	addtofunnel: (data) => {
		let url = "add_to_funnel";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getfunnelpagelist: (data) => {
		let url = "get_funnel_page_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	updateorder: (data) => {
		let url = "update_order";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getignorepageslist: (data) => {
		let url = "get_ignorepages_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	saveignorepages: (data) => {
		let url = "save_ignorepages";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getmergepageslist: (data) => {
		let url = "get_mergepages_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savemergepages: (data) => {
		let url = "save_mergepages";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	checkmergepagesvalidation: (data) => {
		let url = "check_mergepages_validation";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
};
export default FunnelAPI;