import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Modal, Container, Card, Group, Text, Button, Accordion, Table, Image, Radio, Checkbox, LoadingOverlay } from '@mantine/core';
import { IconBrandFacebook, IconBrandGoogle, IconBrandWindows, IconBrandTiktok, IconHelp } from '@tabler/icons-react';
import classes from '../DashBoard/DashBoard.module.css';

import { modals } from '@mantine/modals';
import { useEffect } from 'react';
import { SelectTooltip, VisiLoader, ErrorModal } from "../../components/GeneralComponent/GeneralComponent";
import { visiUserId } from '../../GeneralFunction';
import CampaignApi from "../../api/CampaignApi";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';
import { LoginSocialMicrosoft, LoginSocialTiktok } from 'reactjs-social-login';
import { Step1 } from '../../components/CreateCampaign/Step1';
import { Step2 } from '../../components/CreateCampaign/Step2';
import { Step4 } from '../../components/CreateCampaign/Step4';
import { Step5 } from '../../components/CreateCampaign/Step5';

export function CreateCampaign() {

  let memberId = visiUserId() ? visiUserId() : '';

	const navigate = useNavigate();
	useDocumentTitle("Visiopt - Create Campaign");
	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

  const location = useLocation();
  const { campaign_id } = location.state || {};

  const campaignIconRef = useRef(null);
  const selectCampaignTypeRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};
	const [campaignLPPageValue, setCampaignLPPage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

	const [isMessageModelOpen, setIsMessageModelOpen] = useState(false);
	const [isModelMessage, setIsModelMessage] = useState('');
  const [conversionApiType, setConversionApiType] = useState('');

	// State to manage the checkbox
	const [isClassicChecked, setIsClassicChecked] = useState(true);
  const [isFacebookChecked, setIsFacebookChecked] = useState(false);
  const [isGoogleChecked, setIsGoogleChecked] = useState(false);
  const [isMicrosoftChecked, setIsMicrosoftChecked] = useState(false);
  const [isTiktokChecked, setIsTiktokChecked] = useState(false);
  const [isYoutubeChecked, setIsYoutubeChecked] = useState(false);
  const [isTaboolaChecked, setIsTaboolaChecked] = useState(false);
  const [isOutbrainChecked, setIsOutbrainChecked] = useState(false);

  const [facebookLoginButton, setFacebookLoginButton] = useState(false);
  const [facebookLogoutButton, setFacebookLogoutButton] = useState(false);
  const [facebookAppId, setFacebookAppId] = useState('');
  const [facebookElements, setFacebookElements] = useState([]);
  const [taboolaClientId, setTaboolaClientId] = useState('');
  const [googleLoginButton, setGoogleLoginButton] = useState(false);
  const [googleLogoutButton, setGoogleLogoutButton] = useState(false);
  const [googleElements, setGoogleElements] = useState([]);
  const [youtubeElements, setYoutubeElements] = useState([]);
  const [tiktokLoginButton, setTiktokLoginButton] = useState(false);
  const [tiktokLogoutButton, setTiktokLogoutButton] = useState(false);
  const [outbrainLoginButton, setOutbrainLoginButton] = useState(false);
  const [outbrainLogoutButton, setOutbrainLogoutButton] = useState(false);
  const [outbrainElements, setOutbrainElements] = useState([]);
  const [taboolaLoginButton, setTaboolaLoginButton] = useState(false);
  const [taboolaLogoutButton, setTaboolaLogoutButton] = useState(false);
  const [taboolaElements, setTaboolaElements] = useState([]);
  const [microsoftLoginButton, setMicrosoftLoginButton] = useState(false);
  const [microsoftLogoutButton, setMicrosoftLogoutButton] = useState(false);
  const [microsoftElements, setMicrosoftElements] = useState([]);

  const [fbloginResponse, setfbloginResponse] = useState('');
  const [fbResponse, setfbResponse] = useState('');
  const [fbUserId, setfbUserId] = useState('');
  const [fbUserAccesstoken, setfbUserAccesstoken] = useState(0);
  const [fbAccountsDetails, setfbAccountsDetails] = useState([]);
  const [selectedFbAccounts, setSelectedFbAccounts] = useState([]);

  const [glUserRefreshtoken, setglUserRefreshtoken] = useState(0);
  const [glAccountsDetails, setglAccountsDetails] = useState([]);
  const [selectedGlAccounts, setSelectedGlAccounts] = useState('');
  const [selectedYtAccounts, setSelectedYtAccounts] = useState('');

  const [obUsertoken, setobUsertoken] = useState(0);
  const [obAccountsDetails, setobAccountsDetails] = useState([]);
  const [selectedObAccounts, setSelectedObAccounts] = useState('');

  const [googleLoader, setGoogleLoader] = useState(false);
  const [fbAllConversionSend, setfbAllConversionSend] = useState(0);

  const [tbUserRefreshtoken, settbUserRefreshtoken] = useState(0);
  const [tbAccountsDetails, settbAccountsDetails] = useState([]);
  const [selectedTbAccounts, setSelectedTbAccounts] = useState('');

  const [msUserRefreshtoken, setmsUserRefreshtoken] = useState(0);
  const [msAuthorizationData, setmsAuthorizationData] = useState([]);
  const [selectedMsAccounts, setSelectedMsAccounts] = useState('');

  const [tkUserRefreshtoken, settkUserRefreshtoken] = useState(0);
  const [tkAccountsDetails, settkAccountsDetails] = useState([]);
  const [selectedTkAccounts, setSelectedTkAccounts] = useState('');
  const [tiktokElements, setTiktokElements] = useState([]);
  const handleFacebookChecked = () => {
    setCampaignLoader(true);
    CampaignApi.getcampaigntypeaduserdata({
      campaign_type: 'facebook',
      campaign_id: campaignId || campaign_id
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data){
        if(data.data){
          if(data.data.show_fb_ad_account_login_logout_button){
            if(data.data.show_fb_ad_account_login_logout_button === 'login'){
              setFacebookLoginButton(true);
              setFacebookLogoutButton(false);
            }else if(data.data.show_fb_ad_account_login_logout_button === 'logout'){
              setFacebookLogoutButton(true);
              setFacebookLoginButton(false);
            }
          }
          if(data.data.facebook_app_id){
            setFacebookAppId(data.data.facebook_app_id);
          }
          if(data.data.fb_ad_accounts){
            const fbad_accounts = Array.isArray(data.data.fb_ad_accounts)? data.data.fb_ad_accounts: data.data.fb_ad_accounts === "No Data Found."
              ? [] : [data.data.fb_ad_accounts];
            setFacebookElements(fbad_accounts);
          }
          setCampaignLoader(false);
        }
      }
    })
    .catch(error => {
      setCampaignLoader(false);
      console.error("API Error:", error);
    });
  };

  const handleGoogleChecked = () => {
    setGoogleLoader(true);
    CampaignApi.getcampaigntypeaduserdata({
      campaign_type: 'google',
      campaign_id: campaignId || campaign_id
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data){
        if(data.data){
          if(data.data.show_gl_ad_account_login_logout_button){
            if(data.data.show_gl_ad_account_login_logout_button === 'login'){
              setGoogleLoginButton(true);
              setGoogleLogoutButton(false);
            }else if(data.data.show_gl_ad_account_login_logout_button === 'logout'){
              setGoogleLogoutButton(true);
              setGoogleLoginButton(false);
            }
          }
          if(data.data.gl_adaccount_details){
            const glad_accounts = Array.isArray(data.data.gl_adaccount_details)? data.data.gl_adaccount_details: data.data.gl_adaccount_details === "No Data Found."
              ? [] : [data.data.gl_adaccount_details];
            setGoogleElements(glad_accounts);
          }
          if(data.data.yt_adaccount_details){
            const ytad_accounts = Array.isArray(data.data.yt_adaccount_details)? data.data.yt_adaccount_details: data.data.yt_adaccount_details === "No Data Found."
              ? [] : [data.data.yt_adaccount_details];
            setYoutubeElements(ytad_accounts);
          }
          setGoogleLoader(false);
        }
      }
    })
    .catch(error => {
      setGoogleLoader(false);
      console.error("API Error:", error);
    });  
  };

  const handleMicrosoftChecked = () => {
    setCampaignLoader(true);
    CampaignApi.getcampaigntypeaduserdata({
      campaign_type: 'microsoft',
      campaign_id: campaignId || campaign_id
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data){
        if(data.data){
          if(data.data.show_ms_ad_account_login_logout_button){
            if(data.data.show_ms_ad_account_login_logout_button === 'login'){
              setMicrosoftLoginButton(true);
              setMicrosoftLogoutButton(false);
            }else if(data.data.show_ms_ad_account_login_logout_button === 'logout'){
              setMicrosoftLogoutButton(true);
              setMicrosoftLoginButton(false);
            }
          }
          if(data.data.ms_ad_accounts){
            const msad_accounts = Array.isArray(data.data.ms_ad_accounts)? data.data.ms_ad_accounts: data.data.ms_ad_accounts === "No Data Found."
              ? [] : [data.data.ms_ad_accounts];
            setMicrosoftElements(msad_accounts);
          }
          setCampaignLoader(false);
        }
      }
    })
    .catch(error => {
      setCampaignLoader(false);
      console.error("API Error:", error);
    });
  };

  const handleTiktokChecked = () => {
    setCampaignLoader(true);
    CampaignApi.getcampaigntypeaduserdata({
      campaign_type: 'tiktok',
      campaign_id: campaignId || campaign_id
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data){
        if(data.data){
          if(data.data.show_tk_ad_account_login_logout_button){
            if(data.data.show_tk_ad_account_login_logout_button === 'login'){
              setTiktokLoginButton(true);
              setTiktokLogoutButton(false);
            }else if(data.data.show_tk_ad_account_login_logout_button === 'logout'){
              setTiktokLogoutButton(true);
              setTiktokLoginButton(false);
            }
          }
          if(data.data.tk_ad_accounts){
            const tkad_accounts = Array.isArray(data.data.tk_ad_accounts)? data.data.tk_ad_accounts: data.data.tk_ad_accounts === "No Data Found."
              ? [] : [data.data.tk_ad_accounts];
            setTiktokElements(tkad_accounts);
          }
          setCampaignLoader(false);
        }
      }
    })
    .catch(error => {
      setCampaignLoader(false);
      console.error("API Error:", error);
    });
  };

  // Handler for checkbox change
  const handleSocialMediaCheckboxChange = (event,value) => {
  	if(value === 'Classic'){
  		setIsClassicChecked(event.target.checked);
  	} else if(value === 'Facebook'){
  		setIsFacebookChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Facebook Data be sure to add the following parameter either direct to your url or in Facebook's URL Parameters.\nvisi_source=facebook&ad_id={{ad.id}}");
      	setIsMessageModelOpen(true);
        handleFacebookChecked();
  		}
  	} else if(value === 'Google'){
  		setIsGoogleChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Google Data be sure to add the following parameter either direct to your url or in Google's URL Tracking Template.\n{lpurl}?visi_source=google&creative={creative}\n\nIf you also want to track keywords than use the follow Tracking Template:\n{lpurl}?visi_source=google&creative={creative}&keyword={keyword}\n\nThe above will automatically pass campaign, ad group, ads and cost information. To add additional parameters' such as keyword, etc. Visit the URL builder in Settings page.");
      	setIsMessageModelOpen(true);
        handleGoogleChecked();
  		}
  	} else if(value === 'Microsoft'){
  		setIsMicrosoftChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Microsoft Data be sure to add the following parameter either direct to your url or in Microsoft's URL Tracking Template.\n{lpurl}?visi_source=microsoft&AdGroupID={AdGroupID}");
      	setIsMessageModelOpen(true);
        handleMicrosoftChecked();
  		}
  	} else if(value === 'Tiktok'){
  		setIsTiktokChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Tiktok Data be sure to add the following parameter either direct to your url or in Tiktok's URL Tracking Template.\n{lpurl}?visi_source=tiktok&adid=__ADID__");
      	setIsMessageModelOpen(true);
        handleTiktokChecked();
  		}
  	} else if(value === 'Youtube'){
  		setIsYoutubeChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Youtube Data be sure to add the following parameter either direct to your url or in Youtube's URL Tracking Template.\n{lpurl}?visi_source=youtube&creative={creative}\n\nIf you also want to track keywords than use the follow Tracking Template:\n{lpurl}?visi_source=youtube&creative={creative}&keyword={keyword}\n\nThe above will automatically pass campaign, ad group, ads and cost information. To add additional parameters' such as keyword, etc. Visit the URL builder in Settings page.");
      	setIsMessageModelOpen(true);
        handleGoogleChecked();
  		}
  	} else if(value === 'Taboola'){
  		setIsTaboolaChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Taboola Data be sure to add the following parameter either direct to your url or in Taboola's URL Tracking Template.\n{lpurl}?visi_source=taboola&campaign_id={campaign_id}\n\nIf you also want to track country and platform and site use the follow Tracking Template:\n{lpurl}?visi_source=taboola&campaign_id={campaign_id}&country={country}&platform={platform}&site={site}");
      	setIsMessageModelOpen(true);
        getTaboolaAdUserData();
  		}
  	} else if(value === 'Outbrain'){
  		setIsOutbrainChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Outbrain Data be sure to add the following parameter either direct to your url or in Outbrain's URL Tracking Template.\n{lpurl}?visi_source=outbrain&campaign_id={campaign_id}");
      	setIsMessageModelOpen(true);
        getOutbrainAdUserData();
    	}
  	}
  };
  const handleCloseModal = () => {
    setIsMessageModelOpen(false);
  };

  useEffect(() => {
    if(isFacebookChecked){
      handleFacebookChecked();
    }
  },[isFacebookChecked]);

  useEffect(() => {
    if(isGoogleChecked){
      handleGoogleChecked();
    }
  },[isGoogleChecked]);

  useEffect(() => {
    if(isYoutubeChecked){
      handleGoogleChecked();
    }
  },[isYoutubeChecked]);

  useEffect(() => {
    if(isMicrosoftChecked){
      handleMicrosoftChecked();
    }
  },[isMicrosoftChecked]);

  useEffect(() => {
    if(isTiktokChecked){
      handleTiktokChecked();
    }
  },[isTiktokChecked]);

  useEffect(() => {
    if(isTaboolaChecked){
      getTaboolaAdUserData();
    }
  },[isTaboolaChecked]);

  useEffect(() => {
    if(isOutbrainChecked){
      getOutbrainAdUserData();
    }
  },[isOutbrainChecked]);

  const handleCheckboxChange = (adAccountId) => {
    setSelectedFbAccounts((prevSelected) => {
      const selectedArray = Array.isArray(prevSelected) ? prevSelected : [];
      if (selectedArray.includes(adAccountId)) {
        return selectedArray.filter((id) => id !== adAccountId);
      } else {
        return [...selectedArray, adAccountId];
      }
    });
  };

  const formattedSelectedFbAccounts = Array.isArray(selectedFbAccounts)
  ? selectedFbAccounts
  : [selectedFbAccounts];

	const facebookRows = facebookElements.length > 0 ? (
  facebookElements.map((element) => (
    <tr key={element.account_name}>
      <td>{element.ad_account_id}</td>
      <td>{element.account_name}</td>
      <td>{element.currency}</td>
      <td>
        <Checkbox
          checked={selectedFbAccounts.includes(element.ad_account_id)}
          onChange={() => handleCheckboxChange(element.ad_account_id)}
        />
      </td>
    </tr>
  ))
  ) : (
    <tr>
      <td colSpan={4} style={{ textAlign: "center" }}><Text>No data found</Text></td>
    </tr>
  );

  const handleGlRadioChange = (value) => {
    setSelectedGlAccounts(value);
  };

	const googleRows = googleElements.length > 0 ? (
  googleElements.map((element) => (
    <tr key={element.subcustomer_id}>
      <td>{element.customer_id}</td>
      <td>{element.subcustomer_id}</td>
      <td>{element.subcustomer_name}</td>
      <td>{element.currency}</td>
      <td>
        <Radio
          name="googleIntegrationAccount"
          value={`${element.subcustomer_id}_${element.customer_id}`}
          checked={selectedGlAccounts === `${element.subcustomer_id}_${element.customer_id}`}
          onChange={(e) => handleGlRadioChange(`${element.subcustomer_id}_${element.customer_id}`)}
        />
      </td>
    </tr>
  ))
  ) : (
    <tr>
      <td colSpan={5} style={{ textAlign: "center" }}><Text>No data found</Text></td>
    </tr>
  );

  const handleMsRadioChange = (value) => {
    setSelectedMsAccounts(value);
  };

	const microsoftRows = microsoftElements.length > 0 ? (
  microsoftElements.map((element) => (
    <tr key={element.account_name}>
      <td>{element.ads_account_id}</td>
      <td>{element.account_name}</td>
      <td>{element.currency}</td>
      <td>
        <Radio
          name="microsoftIntegrationAccount"
          value={`${element.ads_account_id}`}
          checked={selectedMsAccounts === `${element.ads_account_id}`}
          onChange={(e) => handleMsRadioChange(`${element.ads_account_id}`)}
        />
      </td>
    </tr>
  ))
  ) : (
    <tr>
      <td colSpan={5} style={{ textAlign: "center" }}><Text>No data found</Text></td>
    </tr>
  );

  const handleTkRadioChange = (value) => {
    setSelectedTkAccounts(value);
  };

	const tiktokRows = tiktokElements.length > 0 ? (
  tiktokElements.map((element) => (
    <tr key={element.account_name}>
      <td>{element.ads_account_id}</td>
      <td>{element.account_name}</td>
      <td>{element.currency}</td>
      <td>
        <Radio
          name="tiktokIntegrationAccount"
          value={`${element.ads_account_id}`}
          checked={selectedTkAccounts === `${element.ads_account_id}`}
          onChange={(e) => setSelectedTkAccounts(`${element.ads_account_id}`)}
        />
      </td>
    </tr>
  ))
  ) : (
    <tr>
      <td colSpan={5} style={{ textAlign: "center" }}><Text>No data found</Text></td>
    </tr>
  );
  
  const handleYtRadioChange = (value) => {
    setSelectedYtAccounts(value);
  };

	const youtubeRows = youtubeElements.length > 0 ? (
  youtubeElements.map((element) => (
    <tr key={element.subcustomer_id}>
      <td>{element.customer_id}</td>
      <td>{element.subcustomer_id}</td>
      <td>{element.subcustomer_name}</td>
      <td>{element.currency}</td>
      <td>
        <Radio
          name="youtubeIntegrationAccount"
          value={`${element.subcustomer_id}_${element.customer_id}`}
          checked={selectedYtAccounts === `${element.subcustomer_id}_${element.customer_id}`}
          onChange={(e) => handleYtRadioChange(`${element.subcustomer_id}_${element.customer_id}`)}
        />
      </td>
    </tr>
  ))
  ) : (
    <tr>
      <td colSpan={5} style={{ textAlign: "center" }}><Text>No data found</Text></td>
    </tr>
  );

  const handleTbRadioChange = (value) => {
    setSelectedTbAccounts(value);
  };

	const taboolaRows = taboolaElements.length > 0 ? (
    taboolaElements.map((element) => (
    <tr key={element.account_name}>
      <td>{element.ad_account_id}</td>
      <td>{element.account_name}</td>
      <td>{element.currency}</td>
      <td>
        <Radio
          name="taboolaIntegrationAccount"
          value={`${element.ads_account_id}_${element.account_id}`}
          checked={selectedTbAccounts === `${element.ads_account_id}_${element.account_id}`}
          onChange={(e) => handleTbRadioChange(`${element.ads_account_id}_${element.account_id}`)}
        />
      </td>
    </tr>
    ))
  ) : (
    <tr>
      <td colSpan={4} style={{ textAlign: "center" }}><Text>No data found</Text></td>
    </tr>
  );

  const handleObRadioChange = (value) => {
    setSelectedObAccounts(value);
  };

	const outbrainRows = outbrainElements.length > 0 ? (
    outbrainElements.map((element) => (
    <tr key={element.account_name}>
      <td>{element.ads_account_id}</td>
      <td>{element.account_name}</td>
      <td>{element.currency}</td>
      <td>
        <Radio
          name="outbrainIntegrationAccount"
          value={`${element.ads_account_id}`}
          checked={selectedObAccounts === `${element.ads_account_id}`}
          onChange={(e) => handleObRadioChange(`${element.ads_account_id}`)}
        />
      </td>
    </tr>
    ))
  ) : (
    <tr>
      <td colSpan={4} style={{ textAlign: "center" }}><Text>No data found</Text></td>
    </tr>
  );

  const [convAttributionModel, setConvAttributionModel] = useState('1');
  const handleConvAttributionModelChange = (value:any) => {
    setConvAttributionModel(value);
  }

	const [SelectedFunnelValue, setSelectedFunnelValue] = useState(null);
	const [campaignLoader, setCampaignLoader] = useState(false);
	const [step1Disabled, setStep1Disabled] = useState(false);
  const [step1FieldDisabled, setStep1FieldDisabled] = useState(false);
	const [step2Disabled, setStep2Disabled] = useState(true);
	const [step3Disabled, setStep3Disabled] = useState(true);
	const [step4Disabled, setStep4Disabled] = useState(true);
	const [step5Disabled, setStep5Disabled] = useState(true);
  const [step5Show, setStep5Show] = useState('0');
	const [campaignName, setCampaignName] = useState('');
	const [stepValue, setStepValue] = useState('step-1');
	const [campaignId, setCampaignId] = useState('');

  const [glConvCustomerId, setGlConvCustomerId] = useState('');
  const [glConvSubCustomerId, setGlConvSubCustomerId] = useState('');
  const [ytAdAccountId, setYtAdAccountId] = useState('');
  const [ytConvCustomerId, setYtConvCustomerId] = useState('');
  const [ytConvSubCustomerId, setYtConvSubCustomerId] = useState('');
  const [tbAdAccountId, setTbAdAccountId] = useState('');
  const [tkAdAccountId, setTkAdAccountId] = useState('');

  useEffect(() => {
    if(campaign_id !== "" && campaign_id !== undefined){
      handleCampaignDetail();
    }
  }, [campaign_id]);

  const handleCampaignDetail = () => {
    setCampaignLoader(true);
    CampaignApi.getcampaigndetail({
      c_id:campaign_id
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setCampaignLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setStep1FieldDisabled(true);
        setStep2Disabled(false);
        setStep3Disabled(false);
        setStep4Disabled(false);
        setStep5Disabled(false);
        setSelectedFunnelValue(data.data.campaign_detail.funnel_id);
        setCampaignName(data.data.campaign_detail.campaign_name);
        setCampaignLPPage(data.data.campaign_detail.campaign_page);
        setIsClassicChecked(data.data.campaign_detail.campaign_type_classic);
        setIsFacebookChecked(data.data.campaign_detail.campaign_type_facebook);
        setIsGoogleChecked(data.data.campaign_detail.campaign_type_google);
        setIsMicrosoftChecked(data.data.campaign_detail.campaign_type_microsoft);
        setIsOutbrainChecked(data.data.campaign_detail.campaign_type_outbrain);
        setIsTaboolaChecked(data.data.campaign_detail.campaign_type_taboola);
        setIsTiktokChecked(data.data.campaign_detail.campaign_type_tiktok);
        setIsYoutubeChecked(data.data.campaign_detail.campaign_type_youtube);
        setGlConvCustomerId(data.data.campaign_detail.gl_conv_customer_id);
        setGlConvSubCustomerId(data.data.campaign_detail.gl_conv_subcustomer_id);
        setYtAdAccountId(data.data.campaign_detail.yt_ad_account_id);
        setYtConvCustomerId(data.data.campaign_detail.yt_conv_customer_id);
        setYtConvSubCustomerId(data.data.campaign_detail.yt_conv_subcustomer_id);
        setTbAdAccountId(data.data.campaign_detail.tb_ad_account_id);
        setConvAttributionModel(data.data.campaign_detail.conv_attribution_model);
        setfbUserId(data.data.facebook_data.fb_user_id);
        setfbUserAccesstoken(data.data.facebook_data.fb_user_accesstoken);
        setfbAccountsDetails(data.data.facebook_data.fb_ad_accounts_details);
        setSelectedFbAccounts(data.data.campaign_detail.fb_ad_account_id);
        setglUserRefreshtoken(data.data.google_data.gl_user_refreshtoken);
        setglAccountsDetails(data.data.google_data.gl_ad_accounts_details);
        setSelectedGlAccounts(data.data.campaign_detail.gl_ad_account_id);
        setSelectedYtAccounts(data.data.campaign_detail.yt_ad_account_id);
        setSelectedObAccounts(data.data.campaign_detail.ob_ad_account_id);
        setobUsertoken(data.data.outbrain_data.ob_user_token);
        setobAccountsDetails(data.data.outbrain_data.ob_ad_accounts_details);
        setfbAllConversionSend(data.data.campaign_detail.fb_all_conversion_send);
        settbUserRefreshtoken(data.data.taboola_data.tb_user_refreshtoken);
        settbAccountsDetails(data.data.taboola_data.tb_ad_accounts_details);
        setmsUserRefreshtoken(data.data.microsoft_data.ms_user_refreshtoken);
        setmsAuthorizationData(data.data.microsoft_data.ms_authorization_data);
        settbUserRefreshtoken(data.data.tiktok_data.tk_user_accesstoken);
        settbAccountsDetails(data.data.tiktok_data.tk_ad_accounts_details);
        setTkAdAccountId(data.data.campaign_detail.tk_ad_account_id);
        setConversionApiType(data.data.campaign_detail.conversion_api_type);
      }
      setCampaignLoader(false);
    })
    .catch(error => {
      console.log(error);
      setCampaignLoader(false);
    });
  };

  // console.log('facebook_api_path:', `${process.env.REACT_APP_FACEBOOK_API_PATH}`);
  const handleFacebookCallback = (response) => {
    const accessToken = response.accessToken;
    const userId = response.userID;
    fetchAdAccounts(accessToken,userId);
  };

  const fetchAdAccounts = async (accessToken,userId) => {
    try {
      const response = await fetch(
        `https://graph.facebook.com/v20.0/me/adaccounts?fields=id,account_id,name,currency,amount_spent&access_token=${accessToken}`
      );
      const data = await response.json();

      const formattedAdAccounts = {
        data: data.data.map((item) => ({ ...item })),
        paging: {
          cursors: { ...data.paging.cursors }
        }
      };

      CampaignApi.fbadsintegration({
        campaign_id:campaign_id || campaignId,
        fb_user_id:userId,
        access_token:accessToken,
        fb_ad_accounts_details:formattedAdAccounts
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setfbloginResponse(data.data.fb_login_response);
          setfbResponse("fbsuccessfully");
        }else{
          console.log(data);
        }
      })
      .catch(error => {
        console.log(error);
      });
    } catch (error) {
      console.error('Error fetching ad accounts:', error);
    }
  };

  useEffect(() => {
    if(fbloginResponse === 'connected'){
      CampaignApi.getcampaigntypeaduserdata({
        campaign_type: 'facebook',
        campaign_id: campaignId || campaign_id
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data){
          if(data.data){
            if(data.data.show_fb_ad_account_login_logout_button){
              if(data.data.show_fb_ad_account_login_logout_button === 'login'){
                setFacebookLoginButton(true);
                setFacebookLogoutButton(false);
              }else if(data.data.show_fb_ad_account_login_logout_button === 'logout'){
                setFacebookLogoutButton(true);
                setFacebookLoginButton(false);
              }
            }
            if(data.data.facebook_app_id){
              setFacebookAppId(data.data.facebook_app_id);
            }
            if(data.data.fb_ad_accounts){
              const fbad_accounts = Array.isArray(data.data.fb_ad_accounts)? data.data.fb_ad_accounts: data.data.fb_ad_accounts === "No Data Found."
                ? [] : [data.data.fb_ad_accounts];
              setFacebookElements(fbad_accounts);
            }
          }
        }
      })
      .catch(error => {
        setCampaignLoader(false);
        console.error("API Error:", error);
      });
    }
  },[fbloginResponse,fbResponse]);

  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      setGoogleLoader(true);
      CampaignApi.savegoogleadaccountcredentials({
        code : codeResponse.code
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setGoogleLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setGoogleLoginButton(false);
          setGoogleLogoutButton(true);
          handleGoogleChecked();
        }else{
          console.log(data);
        }
      })
      .catch(error => {
        console.log(error);
        setGoogleLoader(false);
      });
    },
    onError: codeError => console.log(codeError),
    flow: 'auth-code',
  });

  const removeSocialMediaIntegration = (integrationType) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <>
      <Text size="sm">
        Are you sure you want to remove this integration? Any feature relying on it will stop working immediately.
      </Text>
      <br/>
      <Text size="sm">
        If you don�t want to remove the integration, you can just close this message. Otherwise click below to remove the integration now.
      </Text>
      </>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {},
    onConfirm: () => {
      setCampaignLoader(true);
      CampaignApi.deleteadsintegrations({
        c_id : campaignId || campaign_id,
        ads_type : integrationType
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setCampaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          if(integrationType === 'google'){
            setGoogleLoginButton(true);
            setGoogleLogoutButton(false);
          }else if(integrationType === 'outbrain'){
            setOutbrainLoginButton(true);
            setOutbrainLogoutButton(false);
          }else if(integrationType === 'taboola'){
            setTaboolaLoginButton(true);
            setTaboolaLogoutButton(false);
          }else if(integrationType === 'facebook'){
            setFacebookLoginButton(true);
            setFacebookLogoutButton(false);
          }else if(integrationType === 'microsoft'){
            setMicrosoftLoginButton(true);
            setMicrosoftLogoutButton(false);
          }
        }
        setCampaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        setCampaignLoader(false);
      });
    } 
  });

  const loginTiktok = () => {
    alert("Work In Progress");
  };

  const authHandlerTiktok = (provider:any, data:any) => {
    console.log(provider, data);
  };
  
  const loginOutBrain = () => {
    let outbrainWin = window.open(process.env.REACT_APP_BACKEND_API_PATH+'outbrainloginpopup.php?member_id='+memberId, 'formpopup', 'width=450,height=600,resizeable,scrollbars');
    let popupTick = setInterval(function () {
      if (outbrainWin.closed) {
        clearInterval(popupTick);
        getOutbrainAdUserData();
      }
    }, 2);
  };

  const loginTaboola = () => {
    let outbrainWin = window.open(process.env.REACT_APP_BACKEND_API_PATH+'taboolaloginpopup.php?taboola_client_id='+taboolaClientId+'&taboola_redirect_uri='+process.env.REACT_APP_BACKEND_API_PATH+'taboolaloginpopup.php', 'formpopup', 'width=450,height=600,resizeable,scrollbars');
    let popupTick = setInterval(function () {
      if (outbrainWin.closed) {
        clearInterval(popupTick);
        // getOutbrainAdUserData();
      }
    }, 2);
  };

  const getOutbrainAdUserData = () => {
    setCampaignLoader(true);
    CampaignApi.getcampaigntypeaduserdata({
      campaign_type: 'outbrain',
      campaign_id: campaignId || campaign_id
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data){
        if(data.data){
          if(data.data.show_ob_ad_account_login_logout_button){
            if(data.data.show_ob_ad_account_login_logout_button === 'login'){
              setOutbrainLoginButton(true);
              setOutbrainLogoutButton(false);
            }else if(data.data.show_ob_ad_account_login_logout_button === 'logout'){
              setOutbrainLogoutButton(true);
              setOutbrainLoginButton(false);
            }
          }
          if(data.data.ob_ad_accounts){
            const obad_accounts = Array.isArray(data.data.ob_ad_accounts)? data.data.ob_ad_accounts: data.data.ob_ad_accounts === "No Data Found."
                ? [] : [data.data.ob_ad_accounts];
            setOutbrainElements(obad_accounts);
          }
          setCampaignLoader(false);
        }
      }
    })
    .catch(error => {
      setCampaignLoader(false);
      console.error("API Error:", error);
    });
  }

  const getTaboolaAdUserData = () => {
    setCampaignLoader(true);
    CampaignApi.getcampaigntypeaduserdata({
      campaign_type: 'taboola',
      campaign_id: campaignId || campaign_id
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data){
        if(data.data){
          if(data.data.show_tb_ad_account_login_logout_button){
            if(data.data.show_tb_ad_account_login_logout_button === 'login'){
              setTaboolaLoginButton(true);
              setTaboolaLogoutButton(false);
            }else if(data.data.show_tb_ad_account_login_logout_button === 'logout'){
              setTaboolaLogoutButton(true);
              setTaboolaLoginButton(false);
            }
          }
          if(data.data.taboola_client_id){
            setTaboolaClientId(data.data.taboola_client_id);
          }
          if(data.data.tb_ad_accounts){
            const tbad_accounts = Array.isArray(data.data.tb_ad_accounts)? data.data.tb_ad_accounts: data.data.tb_ad_accounts === "No Data Found."
                ? [] : [data.data.tb_ad_accounts];
            setTaboolaElements(tbad_accounts);
          }
          setCampaignLoader(false);
        }
      }
    })
    .catch(error => {
      setCampaignLoader(false);
      console.error("API Error:", error);
    });
  }

  const authHandler = (provider:any, data:any) => {
    console.log(provider, data);
  };

  const submitStep3 = () => {
    setStepValue('step-4');
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRef = id === 159 ? campaignIconRef : selectCampaignTypeRef;
    if (iconRef.current) {
      const { top, left, width } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: {top: top + scrollOffset, left: left + width + 10},
      });
    }
  };

	return (
	  <>
	  <VisiLoader VisiLoaderVisible={campaignLoader} />
    <LoadingOverlay zIndex={9999999999} visible={googleLoader} overlayBlur={2} loaderProps={{variant:"bars", color:"gray"}} style={{width:'100%', height:'100%', display:'flex', position:'fixed'}}/>
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
    {showHelpModal && Object.keys(tooltipPosition).map((key) => (
      <SelectTooltip
        isVisible={showHelpModal}
        setIsVisible={setShowHelpModal}
        id={helpTargetId}
        tooltipPosition={tooltipPosition[key]} 
      />
    ))}
	  <Modal opened={isMessageModelOpen} onClose={handleCloseModal} withCloseButton={false} size="60%" closeOnEscape={false} closeOnClickOutside={false}>
      {isModelMessage.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
      <div style={{float: 'right', margin: '10px'}}>
        <Button onClick={() => setIsMessageModelOpen(false)}>OK</Button>
      </div>
    </Modal>

  	<Container size="xl">
  		<Card shadow="sm" padding="sm" radius="md" withBorder>
	  	  <Group position="apart" mb="xs">
	  	    <Text weight={500}>Campaign <IconHelp ref={campaignIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(159)}/></Text>
	  	  </Group>
	  	  <Container size="xl" px={0}>
	  	    <Accordion chevronPosition="right" defaultValue="step-1" variant="separated" value={stepValue} onChange={setStepValue}>
	  	      <Step1
              selectedMemberWebsite={selectedMemberWebsite}
              campaign_id={campaign_id}
              campaignId={campaignId}
              setCampaignId={setCampaignId}
              SelectedFunnelValue={SelectedFunnelValue}
              setSelectedFunnelValue={setSelectedFunnelValue}
              campaignLPPageValue={campaignLPPageValue}
              setCampaignLPPage={setCampaignLPPage}
              campaignName={campaignName}
              setCampaignName={setCampaignName}
              step1Disabled={step1Disabled}
              step1FieldDisabled={step1FieldDisabled}
              setStep2Disabled={setStep2Disabled}
              setStep3Disabled={setStep3Disabled}
              setStep4Disabled={setStep4Disabled}
              setStepValue={setStepValue}
              campaignLoader={setCampaignLoader}
            />
            <Step2
              selectedMemberWebsite={selectedMemberWebsite}
              campaign_id={campaign_id}
              campaignId={campaignId}
              setCampaignId={setCampaignId}
              SelectedFunnelValue={SelectedFunnelValue}
              setSelectedFunnelValue={setSelectedFunnelValue}
              campaignLPPageValue={campaignLPPageValue}
              setCampaignLPPage={setCampaignLPPage}
              step2Disabled={step2Disabled}
              setStep2Disabled={setStep2Disabled}
              setStep3Disabled={setStep3Disabled}
              setStep4Disabled={setStep4Disabled}
              setStepValue={setStepValue}
              campaignLoader={setCampaignLoader}
              errorModelOpen={seterrorCloseModal}
              errorMessage={setErrorMessage}
              showErrorHeader={setShowErrorHeader}
              showMessageHeader={setShowMessageHeader}
              // setSelectedWebhookPageid={setSelectedWebhookPageid}
            />
	  	      <Accordion.Item className={classes.item} value="step-3">
	  	        <Accordion.Control disabled={step3Disabled}><Text component="span" color="red" weight={500}>Step 3</Text><Text component="span" weight={500}> - Campaign Type </Text><IconHelp ref={selectCampaignTypeRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(232)}/></Accordion.Control>
 	  	        <Accordion.Panel>
 	  	        	<div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3A - </strong><strong>Select Campaign type :</strong></span></div>
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_classic"
 	  	          	checked={isClassicChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Classic')}
 	  	            label={<span><strong>Classic - </strong> You choose goals. (pages, postback or button type) </span>}
 	  	          />
 	  	          {/*Facebook*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_facebook"
 	  	          	checked={isFacebookChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Facebook')}
 	  	            label={<span><strong>Facebook - </strong> Connect and pull data from your Facebook campaign.</span>}
 	  	          />
 	  	          {isFacebookChecked ? (
 	  	          <>
                {facebookLoginButton &&
   	  	          <Group mb={3} ml={35}>
                  <FacebookLogin
                    appId={facebookAppId}
                    autoLoad={false}
                    fields="name,email,picture"
                    onSuccess={handleFacebookCallback}
                    scope="business_management,ads_read"
                    render={renderProps => (
                      <Button onClick={renderProps.onClick} leftIcon={<IconBrandFacebook stroke={2} />} style={{backgroundColor:"#0c6ec2"}}>
                    Login with Facebook
                    </Button>
                    )}
                  />
  	 	  	        </Group>
                }
                {facebookLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('facebook')}>
  	                Remove Facebook Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Ad Account ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{facebookRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Google*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_google"
 	  	          	checked={isGoogleChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Google')}
 	  	            label={<span><strong>Google - </strong> Connect and pull data from your Google campaign.</span>}
 	  	          />
 	  	          {isGoogleChecked ? (
 	  	          <>
                {googleLoginButton &&
   	  	          <Group mb={5} ml={35}>
                   <Button onClick={() => googleLogin()} leftIcon={<IconBrandGoogle stroke={2} />}>
                    Sign in with Google
                   </Button>
  	 	  	        </Group>
                }
                {googleLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('google')}>
  	                Remove Google Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  		                		<th>Customer ID</th>
  	              	      <th>Subcustomer ID</th>
  	              	      <th>Subcustomer Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Subcustomer Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{googleRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Microsoft*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_microsoft"
 	  	          	checked={isMicrosoftChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Microsoft')}
 	  	            label={<span><strong>Microsoft - </strong> Connect and pull data from your Microsoft campaign.</span>}
 	  	          />
 	  	          {isMicrosoftChecked ? (
 	  	          <>
                {microsoftLoginButton &&
   	  	          <Group mb={5} ml={35}>
  	 	  	          {/*<Button variant="outline" color="dark" leftIcon={<IconBrandWindows stroke={2} />}>
  	 	  	          Sign in with Microsoft
  	 	  	          </Button>*/}
                    <LoginSocialMicrosoft
                      isOnlyGetToken
                      client_id="df964e45-d6e9-4385-8a2a-ff1982fd9d4a"
                      redirect_uri={process.env.REACT_APP_BACKEND_API_BASE_URL}
                      onReject={(err) => {
                        console.log(err)
                      }}
                      onResolve={({ provider, data }) => {
                        authHandler(provider, data);
                      }}
                    >
                      <Button leftIcon={<IconBrandWindows stroke={2} />}>
                        Sign in with Microsoft
                       </Button>
                    </LoginSocialMicrosoft>
  	 	  	        </Group>
                }
                {microsoftLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('microsoft')}>
  	                Remove Microsoft Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Ad Account ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{microsoftRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Tiktok*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_tiktok"
 	  	          	checked={isTiktokChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Tiktok')}
 	  	            label={<span><strong>Tiktok - </strong> Connect and pull data from your Tiktok campaign.</span>}
 	  	          />
 	  	          {isTiktokChecked ? (
 	  	          <>
                {tiktokLoginButton &&
   	  	          <Group mb={5} ml={35}>
  	 	  	          <LoginSocialTiktok
                      client_key="7203314941367943169"
                      redirect_uri={process.env.REACT_APP_BACKEND_API_BASE_URL}
                      onResolve={({ provider, data }) => {
                        authHandlerTiktok(provider, data);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <Button leftIcon={<IconBrandTiktok stroke={2} />} style={{backgroundColor:"black"}}>
                      Login with Tiktok
                      </Button>
                    </LoginSocialTiktok>
  	 	  	        </Group>
                }
                {tiktokLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35}>
  	                Remove Tiktok Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Ad Account ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{tiktokRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Youtube*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_youtube"
 	  	          	checked={isYoutubeChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Youtube')}
 	  	            label={<span><strong>Youtube - </strong> Connect and pull data from your Youtube campaign.</span>}
 	  	          />
 	  	          {isYoutubeChecked ? (
 	  	          <>
                {googleLoginButton &&
   	  	          <Group mb={5} ml={35}>
  	 	  	          <Button onClick={() => googleLogin()} leftIcon={<IconBrandGoogle stroke={2} />}>
                      Sign in with Google
                    </Button>
  	 	  	        </Group>
                }
                {googleLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('google')}>
                    Remove Google Integration
                  </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Customer ID</th>
  	              	      <th>Subcustomer ID</th>
  	              	      <th>Subcustomer Name</th>
                          <th>Currency</th>
                          <th>Select Subcustomer Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{youtubeRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Taboola*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_taboola"
 	  	          	checked={isTaboolaChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Taboola')}
 	  	            label={<span><strong>Taboola - </strong> Connect and pull data from your Taboola campaign.</span>}
 	  	          />
 	  	          {isTaboolaChecked ? (
 	  	          <>
                {taboolaLoginButton &&
   	  	          <Group mb={5} ml={35}>
  	 	  	          <Button onClick={() => loginTaboola()} variant="outline" color="blue.9">
  	 	  	            <Image src="img/social-media/taboola-icon.png" mr={5}/>Login with Taboola
  	 	  	          </Button>
  	 	  	        </Group>
                }
                {taboolaLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('taboola')}>
  	                Remove Taboola Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Ad Account ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{taboolaRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Outbrain*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_outbrain"
 	  	          	checked={isOutbrainChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Outbrain')}
 	  	            label={<span><strong>Outbrain - </strong> Connect and pull data from your Outbrain campaign.</span>}
 	  	          />
 	  	          {isOutbrainChecked ? (
 	  	          <>
                {outbrainLoginButton &&
   	  	          <Group mb={5} ml={35}>
  	 	  	          <Button onClick={() => loginOutBrain()} variant="outline" color="orange">
  	 	  	            <Image src="img/social-media/outbrain-icon.png" mr={5}/>Connect Outbrain
  	 	  	          </Button>
  	 	  	        </Group>
                }
                {outbrainLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('outbrain')}>
  	                Remove Outbrain Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Ad Account ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{outbrainRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

		            <Button type="button" mt={5} onClick={() => submitStep3()}>Continue</Button>
 	  	        </Accordion.Panel>
	  	      </Accordion.Item>
            <Step4 
              selectedMemberWebsite={selectedMemberWebsite}
              SelectedFunnelValue={SelectedFunnelValue}
              // handleCampaignLPPageChange={handleCampaignLPPageChange}
              campaignLPPageValue={campaignLPPageValue}
              // selectedWebhookPageid={selectedWebhookPage}
              campaign_id={campaign_id}
              campaignId={campaignId}
              campaignName={campaignName}
              step4Disabled={step4Disabled}
              campaignLoader={setCampaignLoader}
              setStep5Disabled={setStep5Disabled}
              stepValue={stepValue}
              setStepValue={setStepValue}
              setStep5Show={setStep5Show}

              isClassicChecked={isClassicChecked}
              isFacebookChecked={isFacebookChecked}
              isGoogleChecked={isGoogleChecked}
              isMicrosoftChecked={isMicrosoftChecked}
              isTiktokChecked={isTiktokChecked}
              isYoutubeChecked={isYoutubeChecked}
              isTaboolaChecked={isTaboolaChecked}
              isOutbrainChecked={isOutbrainChecked}

              gl_check_existing={"gl_check_existing"}
              ms_check_existing={"ms_check_existing"}
              tk_check_existing={"tk_check_existing"}
              yt_check_existing={"yt_check_existing"}
              tb_check_existing={"tb_check_existing"}
              ob_check_existing={"ob_check_existing"}

              fbuseraccesstoken={fbUserAccesstoken}
              fbuserid={fbUserId}
              fbadaccountid={formattedSelectedFbAccounts}
              fbadaccountsdetails={fbAccountsDetails}
              glUserRefreshtoken={glUserRefreshtoken}
              glAccountsDetails={glAccountsDetails}
              gladaccountid={selectedGlAccounts}
              ytadaccountid={selectedYtAccounts}
              obUsertoken={obUsertoken}
              obAccountsDetails={obAccountsDetails}
              obadaccountid={selectedObAccounts}
              tbUserRefreshtoken={tbUserRefreshtoken}
              tbAccountsDetails={tbAccountsDetails}
              tbadaccountid={selectedTbAccounts}
              msUserRefreshtoken={msUserRefreshtoken}
              msAuthorizationData={msAuthorizationData}
              msadaccountid={selectedMsAccounts}
              tkUserRefreshtoken={tkUserRefreshtoken}
              tkAccountsDetails={tkAccountsDetails}
              tkadaccountid={selectedTkAccounts}
              convAttributionModel={convAttributionModel}
              conversionApiType={conversionApiType !== '' ? conversionApiType : 'facebook'}
            />
            <Step5
              selectedMemberWebsite={selectedMemberWebsite}
              campaign_id={campaign_id}
              campaignId={campaignId}
              glConvCustomerId={glConvCustomerId}
              glConvSubCustomerId={glConvSubCustomerId}
              ytAdAccountId={ytAdAccountId}
              ytConvCustomerId={ytConvCustomerId}
              ytConvSubCustomerId={ytConvSubCustomerId}
              tbAdAccountId={tbAdAccountId}
              step5Disabled={step5Disabled}
              campaignLoader={setCampaignLoader}
              setStep5Disabled={setStep5Disabled}
              setStepValue={setStepValue}
              setStep5Show={step5Show}
              step5Show={step5Show}
              convAttributionModel={convAttributionModel}
              handleConvAttributionModelChange={handleConvAttributionModelChange}
              sendAllConversionFb={fbAllConversionSend}
              isFacebookChecked={isFacebookChecked === true ? true : false}
              isGoogleChecked={isGoogleChecked === true ? true : false}
              isMicrosoftChecked={isMicrosoftChecked === true ? true : false}
              isTiktokChecked={isTiktokChecked === true ? true : false}
              isYoutubeChecked={isYoutubeChecked === true ? true : false}
              isTaboolaChecked={isTaboolaChecked === true ? true : false}
              isOutbrainChecked={isOutbrainChecked === true ? true : false}
              obadaccountid={selectedObAccounts}
              msadaccountid={selectedMsAccounts}
              conversionApiType={conversionApiType !== '' ? conversionApiType : 'facebook'}
            />
	  	    </Accordion>
	  	  </Container>
	  	</Card>
	  	{/*Error Model*/}
      <ErrorModal errorModelOpen={errorModelOpen} errorMessage={errorMessage} showErrorHeader={showErrorHeader} showMessageHeader={showMessageHeader} onClose={handlerrorCloseModal} />
	  </Container>
	  </>
	);
}