import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button, Input, Group, Text, Divider, Grid, Checkbox } from '@mantine/core';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function GoogleAnalytics({ selectedMemberWebsite, pageId, isGoogleAnalyticsModalOpen, onGoogleAnalyticsClose, setMarksalestime, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [gaAccountPrefix, setGaAccountPrefix] = useState('');
  const [gaDimension, setGaDimension] = useState('');
  const [taguchiTypeId, setTaguchiTypeId] = useState('');
  const [testDimIndex, setTestDimIndex] = useState('');
  const [testDimName, setTestDimName] = useState('');
  const [, setTestName] = useState('');
  const [varDimIndex, setVarDimIndex] = useState('');
  const [varDimName, setVarDimName] = useState('');
  const [, setVariationNumber] = useState('');
  const [visiTestName, setVisiTestName] = useState('');
  const [isCheckboxTestNameChecked, setIsCheckboxTestNameChecked] = useState(false);
  const [isCheckboxVariationNumberChecked, setIsCheckboxVariationNumberChecked] = useState(false);

  const googleRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isGoogleAnalyticsModalOpen) {
      if (!pageId) {
        navigate('/review-tests');
        return;
      }
      TestsApi.getgoogleanalytics({
        system_website_id: selectedMemberWebsite,
        page_id:pageId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setGaAccountPrefix(data.data.ga_account_prefix);
          setGaDimension(data.data.ga_dimension);
          setTaguchiTypeId(data.data.taguchi_type_id);
          setTestDimIndex(data.data.test_dim_index);
          setTestDimName(data.data.test_dim_name);
          setVarDimIndex(data.data.var_dim_index);
          setVarDimName(data.data.var_dim_name);
          setVisiTestName(data.data.visi_test_name);
          setTestName(data.data.test_name === "checked" ? "checked" : "");
          setIsCheckboxTestNameChecked(data.data.test_name === "checked");
          setVariationNumber(data.data.variation_number === "checked" ? "checked" : "");
          setIsCheckboxVariationNumberChecked(data.data.variation_number === "checked");
        } else {
          errorMessage(data.message);
          showErrorHeader(true);
          errorModelOpen(true);
          onGoogleAnalyticsClose();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  }, [isGoogleAnalyticsModalOpen, pageId]);

  const handleCheckboxChange = (event, checkboxType) => {
    const isChecked = event.target.checked;

    if (checkboxType === 'testName') {
      setIsCheckboxTestNameChecked(isChecked);
      setTestName(isChecked ? "checked" : "");
    } else if (checkboxType === 'variationNumber') {
      setIsCheckboxVariationNumberChecked(isChecked);
      setVariationNumber(isChecked ? "checked" : "");
    }
  };

  const handleSaveGoogleAnalytics = (e) => {
    e.preventDefault();
    TestsApi.savegoogleanalytics({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      taguchi_type_id:taguchiTypeId,
      visi_test_name:visiTestName,
      test_name:isCheckboxTestNameChecked ? 1 : 0,
      test_dim_name:testDimName,
      test_dim_index:testDimIndex,
      variation_number:isCheckboxVariationNumberChecked ? 1 : 0,
      var_dim_name:varDimName,
      var_dim_index:varDimIndex
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        onGoogleAnalyticsClose();
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onGoogleAnalyticsClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (googleRef.current) {
      const { top, left, width } = googleRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      <Modal.Root opened={isGoogleAnalyticsModalOpen} onClose={onGoogleAnalyticsClose} size="45%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>GOOGLE ANALYTICS <IconHelp ref={googleRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(199)}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSaveGoogleAnalytics}>
              <Text weight={500}>Traking ID: {gaAccountPrefix} {gaDimension}</Text>
              <Text weight={500} mt={4}>Note: Make sure you have added Google Tracking JS to your page.</Text>
              <Grid mt={15} justify="center">
                <Grid.Col span={5}><Text mt={5}>Visiopt Test Name:</Text></Grid.Col>
                <Grid.Col span={7}><Input value={visiTestName} onChange={(e) => setVisiTestName(e.target.value)}/></Grid.Col>
              </Grid>
              <Divider my="sm" />
              <Grid justify="center">
                <Grid.Col span={5}><Text>Send Test Name to GA?</Text></Grid.Col>
                <Grid.Col span={7}><Checkbox checked={isCheckboxTestNameChecked} onChange={(event) => handleCheckboxChange(event, 'testName')} /></Grid.Col>
              </Grid>
              <Grid justify="center">
                <Grid.Col span={5}><Text mt={5}>Custom Dimension Name:</Text></Grid.Col>
                <Grid.Col span={7}><Input value={testDimName} onChange={(e) => setTestDimName(e.target.value)}/></Grid.Col>
              </Grid>
              <Grid justify="center">
                <Grid.Col span={5}><Text mt={5}>Custom Dimension Index:</Text></Grid.Col>
                <Grid.Col span={7}><Input value={testDimIndex} onChange={(e) => setTestDimIndex(e.target.value)}/></Grid.Col>
              </Grid>
              <Divider my="sm" />
              <Grid justify="center">
                <Grid.Col span={5}><Text>Send Variation Number to GA?</Text></Grid.Col>
                <Grid.Col span={7}><Checkbox checked={isCheckboxVariationNumberChecked}  onChange={(event) => handleCheckboxChange(event, 'variationNumber')} /></Grid.Col>
              </Grid>
              <Grid justify="center">
                <Grid.Col span={5}><Text mt={5}>Custom Dimension Name:</Text></Grid.Col>
                <Grid.Col span={7}><Input value={varDimName} onChange={(e) => setVarDimName(e.target.value)}/></Grid.Col>
              </Grid>
              <Grid justify="center">
                <Grid.Col span={5}><Text mt={5}>Custom Dimension Index:</Text></Grid.Col>
                <Grid.Col span={7}><Input value={varDimIndex} onChange={(e) => setVarDimIndex(e.target.value)}/></Grid.Col>
              </Grid>
              <Group position="right">
                <Button type="submit" size="sm" w={80} mt={25}>
                  Save
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}