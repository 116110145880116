import { Group, Text, ActionIcon, Container, Card, Menu, TextInput, Button } from '@mantine/core';
import { IconHelp, IconClockHour4, IconSettings } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useDocumentTitle } from '@mantine/hooks';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { SelectTimePicker, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import { visiUserId, visiUserToken } from '../../GeneralFunction';
import InsightsApi from "../../api/InsightsApi";
import { modals } from '@mantine/modals';
import { AssetsUrl } from "./AssetsUrl";
import './HeatmapView.css';

const loadResource = (tag, attributes) => {
  return new Promise((resolve, reject) => {
    const element = document.createElement(tag);
    Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
    element.onload = resolve;
    element.onerror = reject;
    document.head.appendChild(element);
  });
};

export function HeatMapRecordingView() {
  useDocumentTitle("Visiopt - Insights");

  const navigate = useNavigate();
  const location = useLocation();
  const HeatmapId = location.state?.heatmap_id;
  const HeatmapName = location.state?.heatmapname;
  const pageUrl = location.state?.page_url;
  const system_website_id = location.state?.system_website_id;
  const token = visiUserToken() ? visiUserToken() : '';

  const [iframeContent, setIframeContent] = useState('');
  const [loadResourceLoaded, setLoadResourceLoaded] = useState(0);
  const [deviceType, setDeviceType] = useState('0');
  const [heatmapDetails, setHeatmapDetails] = useState(false);
  const [createTime, setCreateTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [frontEndFramework, setFrontEndFramework] = useState('');
  const [heatmapHighAccuracy, setHeatmapHighAccuracy] = useState('');
  const [pageId, setPageId] = useState('');
  const [requestUrl, setRequestUrl] = useState('');
  const [testAssetsUrl, setTestAssetsUrl] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [isAssetsUrlModalOpen, setIsAssetsUrlModalOpen] = useState(false);

  let frontendBaseUrl = process.env.REACT_APP_FRONTEND_BASE_URL;
  const [heatmapCreateDate, setHeatmapCreateDate] = useState('');
  const heatmapIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  let memberId = visiUserId() ? visiUserId() : '';

  useEffect(() => {
    const loadResources = async () => {
      try {
        const frontendPublicUrl = process.env.REACT_APP_FRONTEND_PUBLIC_URL;
        await loadResource('script', { src: frontendPublicUrl+'js/yui-all.js' });       
        await loadResource('script', { src: frontendPublicUrl+'js/flobn-all.js' });
        await loadResource('script', { src: frontendPublicUrl+'js/jquery.min.js' });
        setLoadResourceLoaded(1);
      } catch (error) {
        console.error('Error loading resource:', error);
      }
    };
    if(HeatmapId !== ""){
      loadResources();
    }
    
  }, [HeatmapId]);

  const handleGetheatmapdetails = () => {
    const dataObject = {
      system_website_id: system_website_id,
      heatmap_id: HeatmapId,
      time: selectedTimePicker,
      filter: '1',
      device_type: '0',
    } as any;
    if(selectedTimePicker === -2){
      const [fromDay, fromMonth, fromYear] = selectedTimePickerFromDate.split("-").map(num => parseInt(num, 10));
      const [endDay, endMonth, endYear] = selectedTimePickerToDate.split("-").map(num => parseInt(num, 10));
      const fromDate = `${fromMonth}/${fromDay}/${fromYear}`;
      const toDate = `${endMonth}/${endDay}/${endYear}`;
      dataObject.filter = '2';
      dataObject.from = fromDate;
      dataObject.to = toDate;
    }
    InsightsApi.getheatmapdetails(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setCreateTime(data.data.create_time);
          setStartDate(data.data.start_date);
          setEndDate(data.data.end_date);
          setFrontEndFramework(data.data.front_end_framework);
          setHeatmapHighAccuracy(data.data.heatmap_high_accuracy);
          setPageId(data.data.page_id);
          setRequestUrl(data.data.request_url);
          setTestAssetsUrl(data.data.test_assets_url);
          setWebsiteUrl(data.data.website_url);
          setHeatmapCreateDate(data.data.heatmap_created_date);
          setHeatmapDetails(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  };

  const handleClickHeatMap = async () => {
    let params = {
      page_url : pageUrl,
      heatmap_id : HeatmapId,
      token : token,
      system_website_id: system_website_id,
      createTime: createTime,
      startDate: startDate,
      endDate: endDate,
      frontEndFramework: frontEndFramework,
      heatmapHighAccuracy: heatmapHighAccuracy,
      pageId: pageId,
      requestUrl: requestUrl,
      testAssetsUrl: testAssetsUrl,
      websiteUrl: websiteUrl,
      deviceType: deviceType,
      selectedTime: selectedTimePicker,
      member_id: memberId,
    };
    let paramsJSON = JSON.stringify(params);
    let paramsJSONEncoded = btoa(paramsJSON);

  try {
    const response = await axios.get(
      frontendBaseUrl+`src/pages/Insights/heatmapsetup-data.php`,
      {
        params: {
          heatmapParams: paramsJSONEncoded,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setIframeContent(response.data);
  } catch (error) {
    console.error('Error:', error);
  }
  };

  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [selectedTimePicker, setSelectedTimePicker] = useState(0);
  const [selectedTimePickerToDate, setSelectedTimePickerToDate] = useState('');
  const [selectedTimePickerFromDate, setSelectedTimePickerFromDate] = useState('');

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const getStartDate = () => {
    const [year, month, day] = heatmapCreateDate.split('-');
    return `${year}-${month}-${day}`;
  };

  const getSelectedFromDate = () => {
    if(selectedTimePickerFromDate){
      const [day, month, year] = selectedTimePickerFromDate.split('-');
      return `${year}-${month}-${day}`;
    }else{
      const [year, month, day] = heatmapCreateDate.split('-');
      return `${year}-${month}-${day}`;
    }
  };

  const getSelectedToDate = () => {
    if(selectedTimePickerToDate){
      const [day, month, year] = selectedTimePickerToDate.split('-');
      return `${year}-${month}-${day}`;
    }else{
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  };

  const handleselectedTimePickerComponentChange = (Value:any) => {
    setSelectedTimePicker(Value);
  };

  const handleselectedTimePickerToDateChange = (Value:any) => {
    setSelectedTimePickerToDate(Value);
  };

  const handleselectedTimePickerFromDateChange = (Value:any) => {
    setSelectedTimePickerFromDate(Value);
  };

  const handleMobileView = () => {
    setDeviceType('1');
    const testmobileFunction = (window as any).testmobile;
    if (typeof testmobileFunction === 'function') {
      testmobileFunction();
    };
  };

  const handlePCView = () => {
    setDeviceType('0');
    const testPcFunction = (window as any).testpc;
    if (typeof testPcFunction === 'function') {
      testPcFunction();
    };
  };

  const handleTabletView = () => {
    setDeviceType('2');
    const testTabletFunction = (window as any).testtablet;
    if (typeof testTabletFunction === 'function') {
      testTabletFunction();
    };
  };

  const handleAssetsUrlModalOpen = () => {
    setIsAssetsUrlModalOpen(true);
  };

  const handleAssetsUrlModalClose = () => {
    setIsAssetsUrlModalOpen(false);
  };

  const handleBackToInsightsClick = () => {
    navigate("/heat-maps");
  };

  useEffect(() => {
    if(HeatmapId !== '' && deviceType){
      handleGetheatmapdetails();
    }
  }, [HeatmapId, deviceType]);


  useEffect(() => {
    if(heatmapDetails) {
      handleClickHeatMap();
      setHeatmapDetails(false);
    }
  }, [heatmapDetails]);

  const handleResetStatClick = () => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to reset this report? You will lose any current report data.
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {console.log('Cancel')},
    onConfirm: () => {handleResetData()},
  });

  const handleResetData = () => {
    InsightsApi.resetheatmapdata({
      heatmap_id: HeatmapId
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          handleGetheatmapdetails();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (heatmapIconRef.current) {
      const { top, left, width } = heatmapIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      {/*START ASSETS URL*/}
      <AssetsUrl
        isAssetsUrlModalOpen={isAssetsUrlModalOpen}
        setIsAssetsUrlModalOpen={setIsAssetsUrlModalOpen}
        handleAssetsUrlModalClose={handleAssetsUrlModalClose}
        assetsUrlValue={testAssetsUrl}
        setAssetsUrlValue={setTestAssetsUrl}
        pageId={pageId}
        assetsredirecturl={handleGetheatmapdetails}
      />
      {/*END ASSETS URL*/}  
      <div style={{ height: '100vh' }}>
        <Container size="xxl">
          <Card shadow="sm" padding="sm" radius="md" withBorder style={{ height: '100%' }}>
            <div style={{ display: 'flex',marginBottom: '10px' }}>
              <TextInput id="heatmap_created_date" value={createTime} style={{ display: 'none' }} readOnly />
              <TextInput id="get_time_selected" value={selectedTimePicker} style={{ display: 'none' }} readOnly />
              <TextInput id="from" value={selectedTimePickerFromDate} style={{ display: 'none' }} readOnly />
              <TextInput id="to" value={selectedTimePickerToDate} style={{ display: 'none' }} readOnly />
              <Group position="apart" mb="xs">
                <Text weight={500}>{HeatmapName} <IconHelp ref={heatmapIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(252)}/></Text>
              </Group>
              <div style={{ display: 'flex',marginLeft:"auto"}}>
                <div rel="devicechange" className="test-pc-active" id="test-pc" onClick={handlePCView} title="Desktop"></div>
                <div rel="devicechange" className="test-tablet" id="test-tablet" onClick={handleTabletView} title="Tablet"></div>
                <div rel="devicechange" className="test-mobile" id="test-mobile" onClick={handleMobileView} title="Mobile"></div>
                <ActionIcon
                    variant="white"
                    size="lg"
                    ml={10}
                    title="Time Filter"
                >
                  <IconClockHour4 size="1.1rem" />
                </ActionIcon>
                <SelectTimePicker  SelectTimePickerValue={selectedTimePicker} SelectTimePickerOnChange={handleselectedTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedTimePickerToDateChange} SelectTestStartDate={getSelectedFromDate()} SelectTestEndDate={getSelectedToDate()} SelectStartDate={getStartDate()}/>
                <Menu width={260} position="bottom-end" withinPortal>
                  <Menu.Target>
                    <ActionIcon
                      variant="default"
                      size="lg"
                      sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
                      })}
                      title="Settings"
                      ml={5}
                    >
                      <IconSettings size="1.1rem" />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item px={10} py={6} id="heatmap_refresh_stats" >Refresh Stats</Menu.Item>
                    <Menu.Item px={10} py={6} onClick={handleResetStatClick}>Reset Stats</Menu.Item>
                    <Menu.Item px={10} py={6} onClick={handleAssetsUrlModalOpen}>Image/Style Not Loaded</Menu.Item>
                  </Menu.Dropdown>
                </Menu> 
                <Button variant="default" style={{ marginLeft: '5px' }} onClick={handleBackToInsightsClick}>Back to Insights</Button>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <Text weight={500}>Page URL:</Text>
              <Link to={pageUrl} target="_blank" style={{ textDecoration: 'none', color: 'black' }}>{pageUrl}</Link>
            </div>
            <div style={{ display: 'flex', gap: '4px' }}>
              <Text weight={500}>Total Clicks:</Text>
              <Text id={'heatmap_total_click'}></Text>
            </div>
             <div style={{ display: 'flex', gap: '4px' }}>
              <Text weight={500}>Total Visits:</Text>
              <Text id={'heatmap_total_visits'}></Text>
            </div>
            <div className="test-wrapper flexed" style={{ width: '100%', height: '100%' }}>
              <Helmet>
                <link rel="stylesheet" type="text/css" href="/css/visiopt-visual.css"/>
                {loadResourceLoaded === 1 ?
                <script type="text/javascript">
                {`
                  var jQcustom = jQuery.noConflict();
                  function adjustIframeSize() {
                    var offset = jQcustom('.header-element').outerHeight() || 230;
                    var window_height = jQcustom(window).height() - offset;
                    var window_width = jQcustom(window).width();

                    jQcustom('#vis').css({
                      "height": window_height + "px",
                      "width": window_width + "px !important"
                    });
                  }

                  adjustIframeSize();

                  jQcustom(window).resize(function() {
                    adjustIframeSize();
                  });

                  function testmobile(){
                    jQcustom('#test-pc').removeClass('test-pc-active');
                    jQcustom('#test-tablet').removeClass('test-tablet-active');
                    jQcustom('#test-mobile').addClass('test-mobile-active');
                    jQcustom('#test-pc').addClass('test-pc');
                    jQcustom('#test-tablet').addClass('test-tablet');
                    jQcustom('#test-mobile').removeClass('test-mobile');
                    jQcustom('#vis').addClass('active_visi_mobile_insight');
                    jQcustom('#vis').removeClass('active_visi_tablet');
                  }
                  function testpc(){
                    jQcustom('#test-pc').removeClass('test-pc');
                    jQcustom('#test-mobile').addClass('test-mobile');
                    jQcustom('#test-tablet').addClass('test-tablet');
                    jQcustom('#test-pc').addClass('test-pc-active');
                    jQcustom('#test-mobile').removeClass('test-mobile-active');
                    jQcustom('#test-tablet').removeClass('test-tablet-active');
                    jQcustom('#vis').removeClass('active_visi_mobile_insight');
                    jQcustom('#vis').removeClass('active_visi_tablet');
                  }
                  function testtablet(){
                    jQcustom('#test-pc').addClass('test-pc');
                    jQcustom('#test-mobile').addClass('test-mobile');
                    jQcustom('#test-tablet').removeClass('test-tablet');
                    jQcustom('#test-pc').removeClass('test-pc-active');
                    jQcustom('#test-mobile').removeClass('test-mobile-active');
                    jQcustom('#test-tablet').addClass('test-tablet-active');
                    jQcustom('#vis').removeClass('active_visi_mobile_insight');
                    jQcustom('#vis').addClass('active_visi_tablet');
                  }
                `}
                </script>
                : ''}
              </Helmet>
              <link rel="stylesheet" id="test_added_font" type="text/css" />
              <style type="text/css" id="test_added_css"></style>
              <iframe srcDoc={iframeContent} style={{ height: "100vh", border: 'none', userSelect: 'none', marginLeft: '-15px', marginTop: '-5px' }} id="vis">
              </iframe>
            </div>
          </Card>
        </Container>
      </div>
      <div id="overlay" style={{ visibility: "hidden", display: "none", backgroundImage: "none", backgroundColor: 'rgba(255, 255, 255, 0.85555)' }} />
    </>
  );
}