import React, { useState,useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Iframe from 'react-iframe';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Modal, Select, Button } from '@mantine/core';
import { VisiLoader, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import { visiUserSession, visiUserId } from '../../GeneralFunction';
import { IconX, IconCheck, IconHelp } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import CampaignApi from "../../api/CampaignApi";

const loadResource = (tag, attributes) => {
  return new Promise((resolve, reject) => {
    const element = document.createElement(tag);
    Object.keys(attributes).forEach(key => element.setAttribute(key, attributes[key]));
    element.onload = resolve;
    element.onerror = reject;
    document.head.appendChild(element);
  });
};

export function TrackButtons({ selectedMemberWebsite, TrackButtonModal, onClose, PageId, CampaignId, GoalActionNumber, setButtonName }:any) {

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.63.1/codemirror.min.js";
    script1.onload = () => {
      const script2 = document.createElement("script");
      script2.src = "https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.63.1/mode/javascript/javascript.min.js";
      document.body.appendChild(script2);
    };
    document.body.appendChild(script1);
  }, []);

  const navigate = useNavigate();

  const devicePreviewIframeSrc = `${process.env.REACT_APP_FRONTEND_BASE_URL}src/pages/Tests/setDevicePreviewLocalStorage.php`;

  const [iframeContent, setIframeContent] = useState('');
  const [loadResourceLoaded, setLoadResourceLoaded] = useState(0);

  const [testButtonsData, setTestButtonsData] = useState([]);
  const [pageUrl, setPageUrl] = useState('');
  const [frontEndFramework, setFrontEndFramework] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [pageIdDecode, setPageIdDecode] = useState('');

  let websiteId = visiUserSession().website ? visiUserSession().website : '';
  let memberId = visiUserId() ? visiUserId() : '';
  const [testEditorLoader, setTestEditorLoader] = useState(false);

  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const buttonIconref = useRef(null);
  const definebuttonIconref = useRef(null);

  useEffect(() => {
    const loadResources = async () => {
      try {
        setTestEditorLoader(true);
        const frontendPublicUrl = process.env.REACT_APP_FRONTEND_PUBLIC_URL;
        await loadResource('script', { src: frontendPublicUrl+'js/yui-all.js' });       
        await loadResource('script', { src: frontendPublicUrl+'js/flobn-all.js' });
        await loadResource('script', { src: frontendPublicUrl+'js/jquery.min.js' });
        setLoadResourceLoaded(1);
        handleButtonClickTest();
      } catch (error) {
        console.error('Error loading resource:', error);
      }
    };
    if(pageUrl !== ""){
      loadResources();
    }
    
  }, [pageUrl]);

  const handleButtonClickTest = async () => {

    let params = {
      page_url : pageUrl,
      page_id : pageIdDecode,
      front_end_framework : frontEndFramework,
      website_url : websiteUrl,
      page : pageIdDecode,
    };
    let paramsJSON = JSON.stringify(params);
    let paramsJSONEncoded = btoa(paramsJSON);

  try {
    const response = await axios.get(process.env.REACT_APP_FRONTEND_BASE_URL+'src/pages/Tests/testsetup-data.php?testParams='+paramsJSONEncoded);
    setIframeContent(response.data);
    const script = document.createElement('script');
    script.id = 'yui__dyn_0';
    script.type = 'text/javascript';
    script.src = process.env.REACT_APP_FRONTEND_BASE_URL+'public/js/campaign_visual-ui.js';
    script.async = true;
    document.head.appendChild(script);
  } catch (error) {
    setTestEditorLoader(false);
    console.error('Error:', error);
  }
  };

  const handleIframeLoad = () => {
    setTestEditorLoader(false);
  };

  const gettrackbutton = async () => {
    CampaignApi.gettrackbutton({
      system_website_id: selectedMemberWebsite,
      c_id: CampaignId,
      page_id: PageId,
      goal_action: GoalActionNumber
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setTestButtonsData(data.data.track_button || null);
        setPageUrl(data.data.page_url);
        setFrontEndFramework(data.data.front_end_framework);
        setWebsiteUrl(data.data.website_url);
        setPageIdDecode(data.data.page_id_decode);
      } else {
        console.error("Error:", "Error");
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  useEffect(() => {
    if(TrackButtonModal === true){
      gettrackbutton();
    }
  }, [TrackButtonModal]);

  const handleSaveTrackButton = (formValues) => {

    const transformedBValues = (formValues) => {
      const result = {};

      Object.keys(formValues).forEach((key) => {
        const match = key.match(/^b\[(\d+)\]\[(\w+)\]$/);
        
        if (match) {
          const [, index, property] = match;
          
          if (!result[index]) {
            result[index] = {};
          }
          
          result[index][property] = formValues[key];
        }
      });

      return result;
    };
    const transformedB = transformedBValues(formValues);

    const transformBToArrayFormat = (transformedB) => {
      const b = [];
      const parent_id = [];
      const button_id = [];
      const selector = [];
      const name = [];

      Object.keys(transformedB).forEach((key) => {
        const item = transformedB[key];
        b.push(Number(key));
        parent_id.push(item.parent_id || 0);
        button_id.push(item.button_id || 0);
        selector.push(item.selector || '');
        name.push(item.name || '');
      });

      return { b, parent_id, button_id, selector, name };
    };

    const { b, parent_id, button_id, selector, name } = transformBToArrayFormat(transformedB);
    setTestEditorLoader(true);
    CampaignApi.savetrackbutton({
      c_id: formValues.campaign_id,
      page_id:PageId,
      b,
      button_id,
      parent_id,
      selector,
      name,
      goal: formValues.goal,
      Random_button_name: "",
      deleted_buttons:formValues.deleted_buttons,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setTestEditorLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setButtonName(data.data.button_name);
        onClose();
      } else {
        setTestEditorLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        });
      }
    })
    .catch(error => {
      setTestEditorLoader(false);
      console.error("API Error:", error);
    });
  }

  const handleHelpClick = (id:any,val:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRefMapping = {
      button: buttonIconref,
      define_button: definebuttonIconref,

    };
    const iconRef = iconRefMapping[val];
    if (iconRef.current) {
      const { top, left, width, height } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      let calculatedTop, calculatedLeft;
      if (val !== 'button') {
        calculatedTop = top + scrollOffset;
        calculatedLeft = left + width + 10;
      } else {
        calculatedTop = top + height + scrollOffset;
        calculatedLeft = left - 100;
      }
      setTooltipPosition({
        [id]: { top: calculatedTop, left: calculatedLeft },
      });
    }
  };

  return (
    <>
      {showHelpModal &&
        ReactDOM.createPortal(
        <div>
          {Object.keys(tooltipPosition).map((key) => (
            <SelectTooltip
              isVisible={showHelpModal}
              setIsVisible={setShowHelpModal}
              id={helpTargetId}
              tooltipPosition={tooltipPosition[key]} 
            />
          ))}
        </div>,
        document.body
      )}
      <VisiLoader VisiLoaderVisible={testEditorLoader} />
      {TrackButtonModal && (
      <Modal.Root 
        size="100%"
        opened={true}
        onClose={onClose}>
        <Modal.Overlay/>
        <Modal.Content >
          <Modal.Body>
            <div className="test-wrapper flexed">
              <Helmet>
              <link rel="stylesheet" type="text/css" href="/css/campaign-trackbutton.css"/>
              {loadResourceLoaded === 1 ?
              <script type="text/javascript">
              {`
                var jQcustom = jQuery.noConflict();

                var window_height = jQcustom(window).height() - 230;
                jQcustom('.variable-holder .variable-tree').css("height", window_height+"px");
                jQcustom('.visi_button-holder .visi_button-tree').css("height", window_height+"px");

                jQcustom( "iframe" ).on('load',function() {
                  if(document.getElementById("vis").contentDocument.title == '403 Forbidden'){
                    document.title = 'Visiopt - Tests';
                  } else {
                    document.title = document.getElementById("vis").contentDocument.title;
                  }  
                });

                jQcustom('.visi_btn_type').change(function() {
                  var visi_btn_type = jQcustom(this).attr('id');
                  if(visi_btn_type == 'new'){
                    jQcustom('#btn_label').show();
                    jQcustom('#btn_id').hide();
                  } else{
                    jQcustom('#btn_label').hide();
                    jQcustom('#btn_id').show();
                    jQcustom('#visi_defineBtn #btn_id').html('<option value="">Select Existing Button</option>');
                    var btn_arr=[];
                    jQcustom('.visi_button-holder .visi_button-tree li').each(function() {
                      var value = jQcustom( this ).attr( "id" );
                      var name = jQcustom( this ).find( "#name_"+value ).val();
                      var parent_id = jQcustom( this ).find( "#button_"+value ).val();
                      if(parent_id == null || parent_id == '0'){
                        parent_id = 1;
                      }
                      btn_arr[value] = name;
                      jQcustom('#visi_defineBtn #btn_id').append('<option value="'+parent_id+'">'+name+'</option>');
                    });
                    var btn_options = {};
                    jQcustom("#visi_defineBtn #btn_id > option").each(function () {
                      if(btn_options[this.text]) {
                        jQcustom(this).remove();
                      } else {
                        btn_options[this.text] = this.value;
                      }
                    });
                  }
                });            
              `}
              </script>
              : ''}
              </Helmet>
              <link rel="stylesheet" id="test_added_font" type="text/css" />
              <style type="text/css" id="test_added_css"></style>
              <input type="hidden" name ="new_site_ssl" id="new_site_ssl" defaultValue="https://visioptdev.com/client/" />
              <form action="index.php" method="post" id="test_editor_form" className="flexed">
              <input type="hidden" name="page_id" defaultValue={pageIdDecode} id="page_id"/>
              <input type="hidden" name="campaign_id" defaultValue={CampaignId} id="campaign_id"/>
              <input type="hidden" name="goal" defaultValue={GoalActionNumber} id="goal"/>
              <input type="hidden" defaultValue={pageUrl} id="page_url"/>

              <input type="hidden" defaultValue={pageIdDecode} id="page_id_dp"/>
              <input type="hidden" defaultValue={frontEndFramework} id="front_end_framework_dp"/>
              <input type="hidden" defaultValue={process.env.REACT_APP_BACKEND_API_PATH} id="react_app_backend_api_path"/>
              <input type="hidden" defaultValue={websiteId} id="website_id"/>
              <input type="hidden" defaultValue={memberId} id="member_id"/>

              <iframe src={devicePreviewIframeSrc} id="devicePreviewIframe" style={{display: "none" }}></iframe>
              <div className="test-top">
                <div className="selection-block" id="live-selection" style={{height: "80vh",borderRadius: "10px",border: "1px solid #e7e7e7"}}>
                  <div className="selection-site" style={{borderRadius: "10px" }}>
                    <iframe height="100%" width="100%" title="My Iframe" id="vis" onLoad={handleIframeLoad} srcDoc={iframeContent}></iframe>
                  </div>
                </div>
                <div id="visi-variables-sidebar" className="visi-variables-sidebar visi-right-click-pop">
                  <div className="variable-block" id="variables">
                    <div className="visi_section-title">
                      <h2  id="button_section" className="visi-tab_section tab_section active_tab">
                      Buttons
                      <IconHelp ref={buttonIconref} size="1.1rem" color="red" style={{marginLeft:3, marginBottom:-5, cursor: 'pointer'}} onClick={() => handleHelpClick(336,'button')}/>
                      </h2>
                    </div>
                    <div className="visi_button-holder">
                      <input type="hidden" name="deleted_buttons" id="deleted_buttons"/>
                      <ul className="visi_tree visi_button-tree">
                        {testButtonsData.map((item, index) => (
                          <li key={index} className="visi_selected" id={item.BUTTON_COUNT}>
                            <span className="li_span_btn">{item.SAME_AS}{item.BUTTON_NAME}</span>
                            <span className="visi_actions" style={{float: "right"}}>
                              <a href="#" rel="removebutton" className="visi_remove visi_icon-remove"> </a>
                            </span>
                            <input name={`b[${item.BUTTON_COUNT}][parent_id]`} id={`parent_${item.BUTTON_COUNT}`} type="hidden" value={item.BUTTON_PARENT_ID}/>
                            <input name={`b[${item.BUTTON_COUNT}][button_id]`} id={`button_${item.BUTTON_COUNT}`} type="hidden" value={item.BUTTON_ID}/>
                            <textarea name={`b[${item.BUTTON_COUNT}][selector]`} id={`selector_${item.BUTTON_COUNT}`} style={{display: "none"}} defaultValue={item.BUTTON_SELECTOR}/>
                            <textarea name={`b[${item.BUTTON_COUNT}][name]`} id={`name_${item.BUTTON_COUNT}`} style={{display: "none" }} defaultValue={item.BUTTON_NAME}/>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="visi_float-right vert-bottom" style={{marginLeft: "10px" }}>
                      <a className="visi_small-btn visi_red-btn test_cancle" 
                      onClick={onClose}
                      >Cancel</a>
                      <Button type="button" 
                        className="visi_small-btn" 
                        id="visi_continue" 
                        onClick={() => {
                            const formElement = document.getElementById('test_editor_form') as HTMLFormElement;
                            const formData = new FormData(formElement);
                            const formValues = Object.fromEntries(formData.entries());
                            handleSaveTrackButton(formValues);
                        }}
                      >
                        Save Test
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              </form>
              <div id="visi-right-click" className="visi-right-click visi-right-click-pop">
                <a href="#" className="visi-r-close visi-right-click-pop" rel="close"></a>
                <ul className="visi-right-click-pop">
                  <li className="visi-right-click-pop">
                  <a href="#" className="visi-r-add visi-right-click-pop" rel="defineButton">Track Button</a>
                  </li>
                </ul>
              </div>
              <div id="overlay" style={{ visibility: "hidden", display: "none" }} />
              <div id="visi_overlay" style={{backgroundImage:  "url(/img/test-editor/overlay.png)",position: "fixed",height: "100%",width: "100%",left: "0px",top: "0px",zIndex: "9999999",backgroundColor: "transparent",outline: "0",visibility: "visible",display: "none"}}>
                <div className="visi_container campaign_add_track_button_modal" id="visi_defineBtn" style={{display:"none"}}>
                  <div className="visi_section-title">
                    <h3>DEFINE BUTTON</h3>
                    <IconHelp ref={definebuttonIconref} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(336,'define_button')}/>
                    <button type="button" className="close icon-close" rel="closeWindow"></button>
                  </div>
                  <div className="visi_content-holder visi-right-click-pop" style={{marginTop:"14px"}}>
                    <fieldset>
                      <div className="visi_line">
                        <input type="radio" name="visi_btn_type" id="new" defaultValue="new" className="visi_btn_type" checked/>
                        <label htmlFor="new" className="req new" style={{fontSize:"14px"}}>New Button</label>
                        <input type="radio" name="visi_btn_type" id="existing" defaultValue="existing" className="visi_btn_type" checked/>
                        <label htmlFor="existing" className="req existing" style={{fontSize:"14px"}}>Existing Button</label>
                        <br/>
                        <br/>
                        <input type="text" name="btn_label" id="btn_label" className="visi-select"/>
                        <select name="btn_id" id="btn_id" className="visi-select" style={{ display: "none" }}>
                        <option defaultValue="">Select Existing Button</option>
                        </select>
                      </div>
                      <div className="buttons">
                        <button className="visi_small-btn visi_blue-btn visi_float-right" rel="save" type="button" id="btn_save">Save</button>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      )}
    </>
  );
}