import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Card, Text, Group, Grid, ActionIcon, Box, LoadingOverlay } from '@mantine/core';
import DashBoardApi from "../../api/DashBoardApi";
import { IconHelp, IconClockHour4} from "@tabler/icons-react";
import { Chart } from "react-google-charts";
import { SelectWebsitePages, SelectTimePicker, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function Sources({ selectedPieChartMemberWebsite }:any) {
  const navigate = useNavigate();
  const sourceIconRef = useRef(null);
  const [SourcesLoader, setSourcesLoader] = useState(false);
  const [selectedWebsitePage, setSelectedWebsitePage] = useState('');

  const [selectedPieChartTimePicker, setSelectedPieChartTimePicker] = useState(0);
  const [selectedPieChartTimePickerFromDate, setSelectedPieChartTimePickerFromDate] = useState('');
  const [selectedPieChartTimePickerToDate, setSelectedPieChartTimePickerToDate] = useState('');

  const [PieChartData, setPieChartData] = useState([]);
  const [PieChartColors, setPieChartColors] = useState([]);

  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleselectedPieChartTimePickerComponentChange = (Value:any) => {
    setSelectedPieChartTimePicker(Value);
  }

  const handleselectedPieChartTimePickerFromDateChange = (Value:any) => {
    setSelectedPieChartTimePickerFromDate(Value);
  }

  const handleselectedPieChartTimePickerToDateChange = (Value:any) => {
    setSelectedPieChartTimePickerToDate(Value);
  }

  const handleselectedWebsitePageComponentChange = (Value:any) => {
    setSelectedWebsitePage(Value);
  }

  const getTodayDate = () => {
    if(selectedPieChartTimePickerFromDate){
      const [day, month, year] = selectedPieChartTimePickerFromDate.split('-');
      return `${year}-${month}-${day}`; 
    }else{
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }    
  };

  const getEndDate = () => {
    if(selectedPieChartTimePickerToDate){
      const [day, month, year] = selectedPieChartTimePickerToDate.split('-');
      return `${year}-${month}-${day}`;
    }else{
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }    
  };

  const fetchSourcesData = () => {
    setSourcesLoader(true);
    DashBoardApi.getsourcepia({
      system_website_id : selectedPieChartMemberWebsite,
      filter_page : selectedWebsitePage,
      time : selectedPieChartTimePicker,
      from : selectedPieChartTimePickerFromDate,
      to : selectedPieChartTimePickerToDate
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setSourcesLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      let PieData = [
        ["Website", "Traffic"]
      ];
      let PieColor = [];
      if(data.status === 1){
        if(data.data && data.data.length > 0){
          data.data.map(Pie => (
            PieData.push([Pie.labels, Number(Pie.percentage)]),
            PieColor.push(Pie.backgroundColor)
          ))
          setPieChartData(PieData);
          setPieChartColors(PieColor);
          setSourcesLoader(false);
        }
        setSourcesLoader(false);
      }
      setSourcesLoader(false);
    })
    .catch(error => {
      console.log(error);
      setSourcesLoader(false);
    });
  };

  useEffect(() => {
    if(selectedPieChartMemberWebsite !== '' && selectedWebsitePage !== '' && selectedPieChartTimePicker && selectedPieChartTimePicker !== -2){
      fetchSourcesData();
    }
  }, [selectedPieChartMemberWebsite, selectedWebsitePage, selectedPieChartTimePicker,navigate]);

  useEffect(() => {
    if(selectedPieChartTimePicker === -2 && selectedPieChartTimePickerFromDate !== '' && selectedPieChartTimePickerToDate !== ''){
      fetchSourcesData();
    }
  }, [selectedPieChartTimePickerFromDate, selectedPieChartTimePickerToDate]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (sourceIconRef.current) {
      const { top, left, width } = sourceIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      <Grid.Col span={6}>
        <Box pos="relative">
          <LoadingOverlay zIndex={9999999999} visible={SourcesLoader} overlayBlur={2} loaderProps={{variant:"bars", color:"gray"}} />
          <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
            <Card.Section>
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>SOURCES <IconHelp ref={sourceIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(2)}/></Text>
            </Group>
            {PieChartData.length > 1 && (
            <Chart
              chartType="PieChart"
              data={PieChartData}
              options={
                {
                  colors: PieChartColors
                }
              }
            />
            )}
            <Grid justify="space-between">
              <Grid.Col span={6} style={{ textAlign: 'left' }}>
                <SelectWebsitePages SelectWebsitePagesValue={selectedWebsitePage} SelectWebsitePagesOnChange={handleselectedWebsitePageComponentChange} SelectWebsitePagesSelectedMemberWebsite={selectedPieChartMemberWebsite}/>
              </Grid.Col>
              <Grid.Col span={6} style={{ textAlign: 'right' }}>
                <Group>
                  <Grid justify="space-between">
                    <Grid.Col span={2} style={{ textAlign: 'left' }}>
                      <ActionIcon
                        variant="white"
                        size="lg"
                        title="Time Filter"
                      >
                        <IconClockHour4 size="1.1rem" />
                      </ActionIcon>
                    </Grid.Col>
                    <Grid.Col span={10} style={{ textAlign: 'right' }}>
                      <SelectTimePicker SelectTimePickerValue={selectedPieChartTimePicker} SelectTimePickerOnChange={handleselectedPieChartTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedPieChartTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedPieChartTimePickerToDateChange} SelectTestStartDate={getTodayDate()} SelectTestEndDate={getEndDate()}/>
                    </Grid.Col>
                  </Grid>
                </Group>
              </Grid.Col>
            </Grid>
          </Card>
        </Box>
      </Grid.Col>
    </>
  );
}