import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Select, Button, Input, Group } from '@mantine/core';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function AudienceSize({ selectedMemberWebsite, pageId, isAudienceSizeModalOpen, onAudienceSizeClose, setMarksalestime, TestOptimizeReport, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [audienceSizeOptions, setAudienceSizeOptions] = useState([]);
  const [selectedAudienceSize, setSelectedAudienceSize] = useState(0);
  const [audienceSizeInput, setAudienceSizeInput] = useState('');

  const audienceRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isAudienceSizeModalOpen) {
      TestsApi.getaudiancesize({
        page_id: pageId,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then((data) => {
          if (data.status === 1) {
            setAudienceSizeOptions(data.data);
            const defaultSelectedOption = data.data.find(
              (option) => option.selected === "true"
            );
            if (defaultSelectedOption) {
              setSelectedAudienceSize(defaultSelectedOption.option_value);
              setAudienceSizeInput(defaultSelectedOption.option_value);
            } else {
              setSelectedAudienceSize(0);
              setAudienceSizeInput("");
            }
          } else {
            errorMessage(data.message);
            errorModelOpen(true);
            showErrorHeader(true);
            onAudienceSizeClose();
          }
        })
        .catch((error) => {
          console.error('API Error:', error);
        });
    }
  }, [isAudienceSizeModalOpen, pageId]);

  const handleAudienceSizeChange = (value) => {
    setSelectedAudienceSize(value);
    if (value === "0") {
      setAudienceSizeInput("");
    } else {
      const selectedOption = audienceSizeOptions.find(
        (option) => option.option_value.toString() === value.toString()
      );
      if (selectedOption) {
        setAudienceSizeInput(selectedOption.option_value);
      }
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setAudienceSizeInput(inputValue);
      const matchingOption = audienceSizeOptions.find(
        (option) => option.option_value.toString() === inputValue
      );

      if (matchingOption) {
        setSelectedAudienceSize(matchingOption.option_value);
      } else {
        const customOption = {
          option_name: "Custom",
          option_value: inputValue,
        };
        const updatedOptions = audienceSizeOptions.filter(
          (option) => option.option_name !== "Custom"
        );
        setAudienceSizeOptions([...updatedOptions, customOption]);
        setSelectedAudienceSize(inputValue);
      }
    }
  };

  const handleSaveAudienceSize = (e) => {
    e.preventDefault();
    TestsApi.saveaudiancesizenum({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      winner_num_un:audienceSizeInput
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setMarksalestime(data.data.updated_time);
        onAudienceSizeClose();
        TestOptimizeReport();
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const dropdownOptions = audienceSizeOptions.map((option) => ({
    value: option.option_value.toString(),
    label: option.option_name,
  }));

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (audienceRef.current) {
      const { top, left, width } = audienceRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
       <Modal.Root opened={isAudienceSizeModalOpen} onClose={onAudienceSizeClose}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>AUDIENCE SIZE <IconHelp ref={audienceRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(185)}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSaveAudienceSize}>
              <Select
                label="Audience Size"
                value={selectedAudienceSize.toString()}
                onChange={handleAudienceSizeChange}
                data={dropdownOptions}
                searchable
                nothingFound="No options"
                maxDropdownHeight={180}
                size="sm"
                dropdownPosition="bottom"
                mb={10}
                withinPortal
              />
              <Input.Wrapper label="Audience Size Value:">
                <Input
                  id="audience_value"
                  w="auto"
                  ml={0}
                  value={audienceSizeInput}
                  onChange={handleInputChange}
                  placeholder="Enter a number"
                  type="number"
                  min="0"
                />
              </Input.Wrapper>
              <Group position="right">
                <Button type="submit" size="sm" w={80} mt={10}>
                  Save
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}