import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Card, Text, Group, Grid, Divider, ActionIcon, Box, LoadingOverlay } from '@mantine/core';
import DashBoardApi from "../../api/DashBoardApi";
import { IconHelp, IconClockHour4} from "@tabler/icons-react";
import { SelectMemberWebsiteList, SelectFilterType, SelectTimePicker, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function SystemUsage({ selectedMemberWebsite }:any) {
  const navigate = useNavigate();

  const systemUsageIconref = useRef(null);
  const [DashboardSystemUsageTotalVisitCount, setDashboardSystemUsageTotalVisitCount] = useState('0');
  const [DashboardSystemUsageTotalHeatmapCount, setDashboardSystemUsageTotalHeatmapCount] = useState('0');
  const [DashboardSystemUsageTotalScrollmapCount, setDashboardSystemUsageTotalScrollmapCount] = useState('0');
  const [DashboardSystemUsageTotalRecordingCount, setDashboardSystemUsageTotalRecordingCount] = useState('0');
  const [DashboardSystemUsageVisitDomainCount, setDashboardSystemUsageVisitDomainCount] = useState('0');
  const [DashboardSystemUsageHeatmapsDomainCount, setDashboardSystemUsageHeatmapsDomainCount] = useState('0');
  const [DashboardSystemUsageScrollmapDomainCount, setDashboardSystemUsageScrollmapDomainCount] = useState('0');
  const [DashboardSystemUsageRecordingsDomainCount, setDashboardSystemUsageRecordingsDomainCount] = useState('0');
  const [SystemUsageLoader, setSystemUsageLoader] = useState(false);

  const [selectedFilterType, setSelectedFilterType] = useState('');
  const [selectedTimePicker, setSelectedTimePicker] = useState(0);
  const [selectedTimePickerFromDate, setSelectedTimePickerFromDate] = useState('');
  const [selectedTimePickerToDate, setSelectedTimePickerToDate] = useState('');
  const [selectedDomainValue, setSelectedDomainValue] = useState('');
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleselectedTimePickerFromDateChange = (Value:any) => {
    setSelectedTimePickerFromDate(Value);
  }

  const handleselectedTimePickerToDateChange = (Value:any) => {
    setSelectedTimePickerToDate(Value);
  }

  const handleselectedFilterTypeComponentChange = (Value:any) => {
    setSelectedFilterType(Value);
  }

  const handleselectedTimePickerComponentChange = (Value:any) => {
    setSelectedTimePicker(Value);
  }

  const getTodayDate = () => {
    if(selectedTimePickerFromDate){
      const [day, month, year] = selectedTimePickerFromDate.split('-');
      return `${year}-${month}-${day}`; 
    }else{
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }    
  };

  const getEndDate = () => {
    if(selectedTimePickerToDate){
      const [day, month, year] = selectedTimePickerToDate.split('-');
      return `${year}-${month}-${day}`;
    }else{
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }    
  };

  const handleDomainChange = (value:any) => {
    setSelectedDomainValue(value);
  };

  const fetchSystemUsageData = () => {
    setSystemUsageLoader(true);
    DashBoardApi.getdashboardsystemusage({
      system_website_id : selectedDomainValue,
      filter_type : selectedFilterType,
      time : selectedTimePicker,
      from : selectedTimePickerFromDate,
      to : selectedTimePickerToDate
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setSystemUsageLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        if(data && data.data && data.data.total_visit_count){
          setDashboardSystemUsageTotalVisitCount(data.data.total_visit_count);
        }else{
          setDashboardSystemUsageTotalVisitCount('0');
        }
        if(data && data.data && data.data.visit_domain_count){
          setDashboardSystemUsageVisitDomainCount(data.data.visit_domain_count);
        }else{
          setDashboardSystemUsageVisitDomainCount('0');
        }
        if(data && data.data && data.data.total_heatmap_count){
          setDashboardSystemUsageTotalHeatmapCount(data.data.total_heatmap_count);
        }else{
          setDashboardSystemUsageTotalHeatmapCount('0');
        }
        if(data && data.data && data.data.total_scrollmap_count){
          setDashboardSystemUsageTotalScrollmapCount(data.data.total_scrollmap_count);
        }else{
          setDashboardSystemUsageTotalScrollmapCount('0');
        }
        if(data && data.data && data.data.total_recording_count){
          setDashboardSystemUsageTotalRecordingCount(data.data.total_recording_count);
        }else{
          setDashboardSystemUsageTotalRecordingCount('0');
        }
        if(data && data.data && data.data.heatmaps_domain_count){
          setDashboardSystemUsageHeatmapsDomainCount(data.data.heatmaps_domain_count);
        }else{
          setDashboardSystemUsageHeatmapsDomainCount('0');
        }
        if(data && data.data && data.data.scrollmap_domain_count){
          setDashboardSystemUsageScrollmapDomainCount(data.data.scrollmap_domain_count);
        }else{
          setDashboardSystemUsageScrollmapDomainCount('0');
        }
        if(data && data.data && data.data.recordings_domain_count){
          setDashboardSystemUsageRecordingsDomainCount(data.data.recordings_domain_count);
        }else{
          setDashboardSystemUsageRecordingsDomainCount('0');
        }
        setSystemUsageLoader(false);
      }
      setSystemUsageLoader(false);
    })
    .catch(error => {
      console.log(error);
      setSystemUsageLoader(false);
    });
  };

  useEffect(() => {
    if(selectedDomainValue !== '' && selectedFilterType && selectedTimePicker && selectedTimePicker !== -2){
      fetchSystemUsageData();
    }
  }, [selectedDomainValue, selectedFilterType, selectedTimePicker,navigate]);

  useEffect(() => {
    if(selectedTimePicker === -2 && selectedTimePickerFromDate !== '' && selectedTimePickerToDate !== ''){
      fetchSystemUsageData();
    }
  }, [selectedTimePickerFromDate, selectedTimePickerToDate]);

  useEffect(() => {
    if(selectedMemberWebsite) {
      setSelectedDomainValue(selectedMemberWebsite);
    }
  }, [selectedMemberWebsite]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
  };

  useEffect(() => {
    if (systemUsageIconref.current) {
      const { top, left, width } = systemUsageIconref.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  }, [showHelpModal]);

  return (
    <>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      <Grid.Col span={6}>
        <Box pos="relative">
          <LoadingOverlay zIndex={9999999999} visible={SystemUsageLoader} overlayBlur={2} loaderProps={{variant:"bars", color:"gray"}} />
          <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
            <Card.Section>
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>SYSTEM USAGE <IconHelp ref={systemUsageIconref} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(1)}/></Text>
            </Group>
            {(selectedFilterType === '1' || selectedFilterType === '2' || selectedFilterType === '3') && (
              <>
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Total Account Visits : </Text></Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalVisitCount}</Text></Grid.Col>
                </Grid>
                <Divider my={10} />
              </>
            )}
            {selectedFilterType === '4' && (
              <>
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Clicks : </Text></Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalHeatmapCount}</Text></Grid.Col>
                </Grid>
                <Divider my={10} />
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Scrollmap : </Text></Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalScrollmapCount}</Text></Grid.Col>
                </Grid>
                <Divider my={10} />
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Recording : </Text></Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalRecordingCount}</Text></Grid.Col>
                </Grid>
                <Divider my={10} />
              </>
            )}
            {selectedFilterType === '5' && (
            <>
              <Grid justify="space-between">
                <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Clicks : </Text></Grid.Col>
                <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalHeatmapCount}</Text></Grid.Col>
              </Grid>
              <Divider my={10} />
              </>
            )}
            {selectedFilterType === '6' && (
              <>
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Scrollmap : </Text></Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalScrollmapCount}</Text></Grid.Col>
                </Grid>
                <Divider my={10} />
              </>
            )}  
            {selectedFilterType === '7' && (
              <>
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Recording : </Text></Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalRecordingCount}</Text></Grid.Col>
                </Grid>
                <Divider my={10} />
              </>
            )}
            <Grid justify="space-between">
              <Grid.Col span={8} style={{ textAlign: 'left' }}>
                <Group>
                  <Text weight={500}>Domain Visits : </Text>
                  <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedDomainValue} SelectMemberWebsiteListOnChange={handleDomainChange} SelectMemberWebsiteListWidth={140} />
                </Group>
              </Grid.Col>
              {(selectedFilterType === '1' || selectedFilterType === '2' || selectedFilterType === '3' || selectedFilterType === '4') && (
                <>
                  <Grid.Col span={2} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageVisitDomainCount}</Text></Grid.Col>
                </>
              )}
              {selectedFilterType === '5' && ( 
                <>
                  <Grid.Col span={2} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageHeatmapsDomainCount}</Text></Grid.Col>
                </>
              )}
              {selectedFilterType === '6' && ( 
                <>
                  <Grid.Col span={2} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageScrollmapDomainCount}</Text></Grid.Col>
                </>
              )}
              {selectedFilterType === '7' && ( 
                <>
                  <Grid.Col span={2} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageRecordingsDomainCount}</Text></Grid.Col>
                </>
              )}
            </Grid>
            {selectedFilterType === '4' && (
              <>
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Clicks : </Text></Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageHeatmapsDomainCount}</Text></Grid.Col>
                </Grid>
                <Divider my={10} />
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Scrollmap : </Text></Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageScrollmapDomainCount}</Text></Grid.Col>
                </Grid>
                <Divider my={10} />
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Recording : </Text></Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageRecordingsDomainCount}</Text></Grid.Col>
                </Grid>
              </>
            )}
            {selectedFilterType !== '4' && (
              <Grid justify="space-between" h={118}>
              </Grid>
            )}
            <Grid justify="space-between">
              <Grid.Col span={6} style={{ textAlign: 'left' }}>
                <SelectFilterType SelectFilterTypeValue={selectedFilterType} SelectFilterTypeOnChange={handleselectedFilterTypeComponentChange}/>
              </Grid.Col>
              <Grid.Col span={6} style={{ textAlign: 'right' }}>
                <Group>
                  <Grid justify="space-between">
                    <Grid.Col span={2} style={{ textAlign: 'left' }}>
                      <ActionIcon
                        variant="white"
                        size="lg"
                        title="Time Filter"
                      >
                        <IconClockHour4 size="1.1rem" />
                      </ActionIcon>
                    </Grid.Col>
                    <Grid.Col span={10} style={{ textAlign: 'right' }}>
                      <SelectTimePicker SelectTimePickerValue={selectedTimePicker} SelectTimePickerOnChange={handleselectedTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedTimePickerToDateChange} SelectTestStartDate={getTodayDate()} SelectTestEndDate={getEndDate()}/>
                    </Grid.Col>
                  </Grid>
                </Group>
              </Grid.Col>
            </Grid>
          </Card>
        </Box>
      </Grid.Col>
    </>
  );
}