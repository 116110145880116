import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Group, Text, Tooltip, Card, Table, Space } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import ReactDOM from 'react-dom';
import TestsApi from "../../api/TestsApi";
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function Variables({ pageId, showVariablesTab, selectedTimePicker, selectedTimePickerFromDate, selectedTimePickerToDate, systemUsageLoader }:any) {
  const navigate = useNavigate();

  const [variablesMessageShow, setVariablesMessageShow] = useState('');
  const [variablesOptimumResponse, setVariablesOptimumResponse] = useState('');
  const [variablesVariablesTab, setVariablesVariablesTab] = useState([]);

  const variablesRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if(showVariablesTab){
      systemUsageLoader(true);
      TestsApi.getvariablestab({
        page_id: pageId,
        time: selectedTimePicker,
        from: selectedTimePickerFromDate,
        to: selectedTimePickerToDate
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate('/logout');
          }
        }
      })
      .then((data) => {
        if (data.status === 1) {
          setVariablesMessageShow(data.data.message_show);
          setVariablesOptimumResponse(data.data.optimum_response);
          setVariablesVariablesTab(data.data.variables_tab || []);
          systemUsageLoader(false);
        }
      })
      .catch((error) => {
        console.error('API Error:', error);
        systemUsageLoader(false);
      });
    }
  },[showVariablesTab]);

  const testVariablesRows = variablesVariablesTab.map((element, index) => (
    <tr key={index}>
      <td>
        {element.variables_name !== '' && (
          <Tooltip label={element.variables_name_complete}>
            <Text size="sm">{element.variables_name}</Text>
          </Tooltip>
        )} 
        {element.versions_name !== '' && (
          <Text size="sm" ml={20}>{element.versions_name}</Text>
        )}
      </td>
      <td>
        {element.variables_name !== '' && (
          <div>
            <Text fw={500} ta="center" ml={20}>{element.statistical_significance}</Text>
            {element.statistical_significance !== '-' && element.statistical_significance !== undefined ? (
              <progress
                value={element.statistical_significance.replace('%', '')}
                className={element.ss_class}
                max="100"
                style={{ width: "100%" }}
              ></progress>
            ) : (
              ''
            )}
          </div>
        )} {element.variables_name !== '' && (
          <Text size="sm" ml={20} ta="center">{element.best_option}</Text>
        )}
      </td>
    </tr>
  ));

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (variablesRef.current) {
      const { top, left, width } = variablesRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal &&
        ReactDOM.createPortal(
        <div>
          <SelectTooltip
            isVisible={showHelpModal}
            setIsVisible={setShowHelpModal}
            id={helpTargetId}
            tooltipPosition={tooltipPosition}
          />
        </div>,
        document.body
      )}
      <Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
        <Text weight={500} mt={10} c="red">{variablesMessageShow}</Text>
        <Text weight={500}>NOTE: if running repeat experiments this data will continue to update accordingly</Text>
          <Table highlightOnHover withColumnBorders withBorder my={15} fontSize="xs" maw={500}>
           <thead>
             <tr>
               <th>Variable / Version</th>
               <th>Variable influence</th>
             </tr>
           </thead>
           <tbody>{testVariablesRows}</tbody>
          </Table>
      </Card>
      <Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
        <Group position="apart" mb="xs">
          <Text weight={500}>OPTIMUM EXPECTED CONVERSION RATE <IconHelp ref={variablesRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(288)}/></Text>
        </Group>
        <Text weight={500} mt={20}>Optimum Expected Conversion Rate: {variablesOptimumResponse}%</Text>
        <Space h="xs" />
        <Text weight={500}>How To Use This Data:</Text>
        <Text>The above chart details which variables had the most influence on making a conversion. Above you will find the best variable options to use.Create a new page using the above influential variables.</Text>
        <Space h="xs" />
        <Text>Important Note:</Text>
        <Space h="xs" />
        <Text weight={500}>What about the Non-Influential Variables?</Text>
        <Text>You can not ignore them.</Text>
        <Text>Choose the option that is least expensive or most convenient. In most cases we recommend choosing the control version of the variable for non-influential variables.</Text>
        <Space h="xs" />
        <Text weight={500}>Optimum Expected Conversion Rate</Text>
        <Text>This is the estimated best expected conversion rate if you create a new page version using the data as described.</Text>
        <Text>Please note this is not a guarantee of a specific conversion rate as there are too many other factors in the marketplace that affect conversion.</Text>
        <Text>We highly recommend you test this new page against the best page from your last test.</Text>
        <Space h="xs" />
        <Text>Please also note that if you use the repeat experiment option variable influence data becomes more accurate.</Text>
      </Card>
    </>
  );
}