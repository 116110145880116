import { Group, Text, Box, Button, Grid, Flex, ActionIcon, Select, Input, Divider, Space, Checkbox, Textarea, CopyButton, TextInput, Skeleton } from '@mantine/core';
import { IconHelp, IconPencil, IconTrash, IconCheck, IconX } from '@tabler/icons-react';
import React, { useState, useEffect, useRef } from 'react';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import $ from 'jquery';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import SettingsApi from "../../../api/SettingsApi";
import { SelectMemberWebsiteList, SelectTooltip } from "../../GeneralComponent/GeneralComponent";

export function URLBuilderAndCustomTags({ SelectMemberWebsiteListValue }:any) {

  const navigate = useNavigate();
  const [activeURLBuilder,setActiveURLBuilder] = useState('URL-builder-and-custom-tags');
  const [activeAddURLBuilder, setActiveAddURLBuilder] = useState('');
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [selectedPageValue, setSelectedPageValue] = useState('');
  const [websitePageList, setWebsitePageList] = useState([]);
  const [customTagDropDown, setCustomTagDropDown] = useState([]);
  const [selectedPageUrl, setSelectedPageUrl] = useState('');
  const [urlBuilderName, setUrlBuilderName] = useState('');
  const [networkTokenValue, setNetworkTokenValue] = useState('facebook');
  const [selectedCustomTag, setSelectedCustomtag] = useState('');
  const [editURLBuilderMode, setEditURLBuilderMode] = useState(false);
  const [customTagURLId, setCustomTagURLId] = useState('');

  const [utmIdValue, setUtmIdValue] = useState('');
  const [utmSourceValue, setUtmSourceValue] = useState('');
  const [utmMediumValue, setUtmMediumValue] = useState('');
  const [utmCampValue, setUtmCampValue] = useState('');
  const [utmTermValue, setUtmTermValue] = useState('');
  const [utmContentValue, setUtmContentValue] = useState('');

  const [customTagKey1, setCustomTagKey1] = useState('');
  const [customTagValue1, setCustomTagValue1] = useState('');
  const [customTagKey2, setCustomTagKey2] = useState('');
  const [customTagValue2, setCustomTagValue2] = useState('');
  const [customTagKey3, setCustomTagKey3] = useState('');
  const [customTagValue3, setCustomTagValue3] = useState('');
  const [customTagKey4, setCustomTagKey4] = useState('');
  const [customTagValue4, setCustomTagValue4] = useState('');
  const [customTagKey5, setCustomTagKey5] = useState('');
  const [customTagValue5, setCustomTagValue5] = useState('');

  const facebookArray = ["ad_id", "adset_id", "campaign_id", "campaign_name", "ad_name", "adset_name", "placement", "site_source_name", "fbclid"];
  const googleArray = ["campaignid", "creative", "placement", "target", "keyword", "devicemodel", "matchtype", "network", "device", "adposition", "gclid", "gbraid", "wbraid", "adgroupid"];
  const microsoftArray = ["keyword", "QueryString", "AdGroupID", "AdGroup", "AdID", "CampaignID", "Campaign", "Device", "MatchType", "Network","msclkid"];
  const snapchatArray = ["ad_id", "adSet_id", "campaign_id", "creative_name", "adSet_name", "campaign_name", "creative_headline"];
  const revcontentArray = ["adv_targets", "boost_id", "content_id", "city", "state", "country", "widget_id"];
  const taboolaArray = ["tblci", "campaign_id", "campaign_name", "country", "platform", "site"];
  const mgidArray = ["teaser_id", "widget_id", "campaign_id", "category_id", "geo_region", "click_id", "click_price"];
  const outbrainArray = ["ad_title", "section_name", "publisher_name", "campaign_id", "section_id", "publisher_id", "ob_click_id"];
  const tiktokArray = ["adgroupid", "ttclid", "campaignid", "adid"];
  const youtubeArray = ["campaignid", "creative", "placement", "target", "keyword", "devicemodel", "matchtype", "network", "device", "adposition", "gclid", "gbraid", "wbraid", "adgroupid"];

  const [fbAdsNetParam, setfbAdsNetParam] = useState({
    campaign_name: false,
    adset_name: false,
    ad_name: false,
    placement: false,
    site_source_name: false,
    fbclid: false,
    campaign_id: false,
    adset_id: false,
    ad_id: false});

  const [gglAdsNetParam, setgglAdsNetParam] = useState({
    campaignid: false,
    wbraid: false,
    creative: false,
    keyword: false,
    placement: false,
    target: false,
    devicemodel: false,
    matchtype: false,
    network: false,
    g_device: false,
    adposition: false,
    gclid: false,
    gbraid: false,
    wbraid: false});

  const [micAdsNetParam, setmicAdsNetParam] = useState({
    Campaign: false,
    AdGroup: false,
    keyword: false,
    CampaignID: false,
    AdGroupID: false,
    AdID: false,
    QueryString: false,
    m_Device: false,
    MatchType: false,
    Network: false,
    msclkid: false});

  const [snaAdsNetParam, setsnaAdsNetParam] = useState({
    ad_id: false,
    adSet_id: false,
    campaign_id: false,
    creative_name: false,
    adSet_name: false,
    campaign_name: false,
    creative_headline: false});

  const [revAdsNetParam, setrevAdsNetParam] = useState({
    adv_targets: false,
    boost_id: false,
    content_id: false,
    city: false,
    state: false,
    country: false,
    widget_id: false});

  const [tubAdsNetParam, settubAdsNetParam] = useState({
    tblci: false,
    campaign_id: false,
    campaign_name: false,
    country: false,
    platform: false,
    site: false});

  const [mgAdsNetParam, setmgAdsNetParam] = useState({
    teaser_id: false,
    widget_id: false,
    campaign_id: false,
    category_id: false,
    geo_region: false,
    click_id: false,
    click_price: false});

  const [outAdsNetParam, setoutAdsNetParam] = useState({
    ad_title: false,
    section_name: false,
    publisher_name: false,
    campaign_id: false,
    section_id: false,
    publisher_id: false,
    ob_click_id: false});

  const [tiktokAdsNetParam, settiktokAdsNetParam] = useState({
    campaignid: false,
    adgroupid: false,
    adid: false,
    ttclid: false});

  const [ytAdsNetParam, setytAdsNetParam] = useState({
    campaignid: false,
    wbraid: false,
    creative: false,
    keyword: false,
    placement: false,
    target: false,
    devicemodel: false,
    matchtype: false,
    network: false,
    yt_device: false,
    adposition: false,
    gclid: false,
    yt_gbraid: false,
    wbraid: false});
  
  const [urlBuilderAndCustomData, setUrlBuilderAndCustomData] = useState([]);

  const urlBuilderIconRef = useRef(null);
  const addnewUrlIconRef = useRef(null);
  const standardTagIconRef = useRef(null);
  const customTagIconRef = useRef(null);
  const networkTokensIconRef = useRef(null);
  const facebookTagIconRef = useRef(null);
  const googleTagIconRef = useRef(null);
  const microsoftTagIconRef = useRef(null);
  const snapchatTagIconRef = useRef(null);
  const revContentIconRef = useRef(null);
  const taboolaTagIconRef = useRef(null);
  const mgidIconRef = useRef(null);
  const outbrainIconRef = useRef(null);
  const tiktokTagIconRef = useRef(null);
  const youtubeTagIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(false);
  
  useEffect(() => {
    if(SelectMemberWebsiteListValue !== ""){
      setSelectedMemberWebsite(SelectMemberWebsiteListValue);
    }
  }, [SelectMemberWebsiteListValue]);

  const handleMemberWebsiteChange = (e:any) => {
      setSelectedMemberWebsite(e);      
  };

  const handleFetchWebsitePages = () => {
    SettingsApi.getwebsitepages({
        system_website_id: selectedMemberWebsite,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        setWebsitePageList(data.data)
      })
      .catch(error => {
        console.log(error);
      }); 
  };

  useEffect(() => {
    if (selectedMemberWebsite) {
      handleFetchWebsitePages(); 
    }
  }, [selectedMemberWebsite]);

  useEffect(() => {
    if (selectedPageValue) {
      SettingsApi.getcustomtagsbypage({
        page_id: selectedPageValue,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(!editURLBuilderMode){
            setSelectedPageUrl(data.page_url);
        }        
        setCustomTagDropDown(data.data);
      })
      .catch(error => {
        console.log(error);
      }); 
    }
  }, [selectedPageValue]);

  const fetchURLBuilders = () => {
    setShowSkeleton(true);
    SettingsApi.geturlbuilders()
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            setShowSkeleton(false);
          }
        }
      })
      .then(data => {
        if(data.status === 1){        
          setUrlBuilderAndCustomData(data.data);
          setShowSkeleton(false);      
        }
      })
      .catch(error => {
        console.log(error);
        setShowSkeleton(false);
      });
  };

  useEffect(() => {
      fetchURLBuilders();
  },[]);

  const handleAddURLBuilder = () => {
    setActiveURLBuilder('');
    setActiveAddURLBuilder('add-URLbuilder');
  };

  const handleCloseBtn = () => {
    setActiveURLBuilder('URL-builder-and-custom-tags');
    setActiveAddURLBuilder('');
    setEditURLBuilderMode('');
    setSelectedPageValue('');
    setUtmIdValue('');
    setUtmSourceValue('');
    setUtmMediumValue('');
    setUtmCampValue('');
    setUtmTermValue('');
    setUtmContentValue('');
    handleNetworkTokenAction('facebook');
    setSelectedPageUrl('');
    setUrlBuilderName('');
    setCustomTagKey1('');setCustomTagValue1('');
    setCustomTagKey2('');setCustomTagValue2('');
    setCustomTagKey3('');setCustomTagValue3('');
    setCustomTagKey4('');setCustomTagValue4('');
    setCustomTagKey5('');setCustomTagValue5('');
  };

  const handleSaveUrlBuilder = () => {
    SettingsApi.saveupdateurlbuilder({
        url_name: urlBuilderName,
        page_id: selectedPageValue,
        your_url: selectedPageUrl,
        site_id: selectedMemberWebsite,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });          
          fetchURLBuilders();
          handleCloseBtn();
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const updateURLBuilder = (id:any) => {
    setCustomTagURLId(id);
    SettingsApi.getsingleurl({
      custom_tag_url_id: id,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      handleEditURLBuilderData(data.data);   
    })
    .catch(error => {
      console.log(error);
    });
  };

  const handleEditURLBuilderData = (data) => {
      setEditURLBuilderMode(true);
      handleAddURLBuilder();
      setSelectedPageValue(data.page_id);
      setSelectedPageUrl(data.your_url);
      setUrlBuilderName(data.url_name);
      const editURL = data.your_url;
      let sliced = editURL.split('?', 2);
      sliced = sliced[1];
      sliced = sliced.split('&');
      let visi_sourcevalue = '';
      if (sliced.includes("visi_source=facebook")) {
          visi_sourcevalue = 'facebook';
      } else if (sliced.includes("visi_source=google")) {
          visi_sourcevalue = 'google';
      } else if (sliced.includes("visi_source=microsoft")) {
          visi_sourcevalue = 'microsoft';
      } else if (sliced.includes("visi_source=snapchat")) {
          visi_sourcevalue = 'snapchat';
      } else if (sliced.includes("visi_source=revcontent")) {
          visi_sourcevalue = 'revcontent';
      } else if (sliced.includes("visi_source=taboola")) {
          visi_sourcevalue = 'taboola';
      } else if (sliced.includes("visi_source=mgid")) {
          visi_sourcevalue = 'mgid';
      } else if (sliced.includes("visi_source=outbrain")) {
          visi_sourcevalue = 'outbrain';
      } else if (sliced.includes("visi_source=tiktok")) {
          visi_sourcevalue = 'tiktok';
      } else if (sliced.includes("visi_source=youtube")) {
          visi_sourcevalue = 'youtube';
      }
      setNetworkTokenValue(visi_sourcevalue);
      let tag = 0;
      sliced.forEach(param => {
        const [paramKey, paramValue] = param.split('=');
        if(visi_sourcevalue === 'facebook'){
          if(paramKey === 'campaign_name'){
            setfbAdsNetParam((prev) => ({...prev, campaign_name: true }));
          }
          if(paramKey === 'adset_name'){
            setfbAdsNetParam((prev) => ({...prev, adset_name: true }));
          }
          if(paramKey === 'ad_name'){
            setfbAdsNetParam((prev) => ({...prev, ad_name: true }));
          }
          if(paramKey === 'placement'){
            setfbAdsNetParam((prev) => ({...prev, placement: true }));
          }
          if(paramKey === 'site_source_name'){
            setfbAdsNetParam((prev) => ({...prev, site_source_name: true }));
          }
          if(paramKey === 'fbclid'){
            setfbAdsNetParam((prev) => ({...prev, fbclid: true }));
          }
          if(paramKey === 'campaign_id'){
            setfbAdsNetParam((prev) => ({...prev, campaign_id: true }));
          }
          if(paramKey === 'adset_id'){
            setfbAdsNetParam((prev) => ({...prev, adset_id: true }));
          }
          if(paramKey === 'ad_id'){
            setfbAdsNetParam((prev) => ({...prev, ad_id: true }));
          }
        }else if(visi_sourcevalue === 'google'){
          if(paramKey === 'campaignid'){
            setgglAdsNetParam((prev) => ({...prev, campaignid: true }));
          }
          if(paramKey === 'adgroupid'){
            setgglAdsNetParam((prev) => ({...prev, adgroupid: true }));
          }
          if(paramKey === 'creative'){
            setgglAdsNetParam((prev) => ({...prev, creative: true }));
          }
          if(paramKey === 'keyword'){
            setgglAdsNetParam((prev) => ({...prev, keyword: true }));
          }
          if(paramKey === 'placement'){
            setgglAdsNetParam((prev) => ({...prev, placement: true }));
          }
          if(paramKey === 'target'){
            setgglAdsNetParam((prev) => ({...prev, target: true }));
          }
          if(paramKey === 'devicemodel'){
            setgglAdsNetParam((prev) => ({...prev, devicemodel: true }));
          }
          if(paramKey === 'matchtype'){
            setgglAdsNetParam((prev) => ({...prev, matchtype: true }));
          }
          if(paramKey === 'network'){
            setgglAdsNetParam((prev) => ({...prev, network: true }));
          }
          if(paramKey === 'device'){
            setgglAdsNetParam((prev) => ({...prev, g_device: true }));
          }
          if(paramKey === 'adposition'){
            setgglAdsNetParam((prev) => ({...prev, adposition: true }));
          }
          if(paramKey === 'gclid'){
            setgglAdsNetParam((prev) => ({...prev, gclid: true }));
          }
          if(paramKey === 'gbraid'){
            setgglAdsNetParam((prev) => ({...prev, gbraid: true }));
          }
          if(paramKey === 'wbraid'){
            setgglAdsNetParam((prev) => ({...prev, wbraid: true }));
          }
        }else if(visi_sourcevalue === 'microsoft'){
          if(paramKey === 'Campaign'){
            setmicAdsNetParam((prev) => ({...prev, Campaign: true }));
          }
          if(paramKey === 'AdGroup'){
            setmicAdsNetParam((prev) => ({...prev, AdGroup: true }));
          }
          if(paramKey === 'keyword'){
            setmicAdsNetParam((prev) => ({...prev, keyword: true }));
          }
          if(paramKey === 'CampaignID'){
            setmicAdsNetParam((prev) => ({...prev, CampaignID: true }));
          }
          if(paramKey === 'AdGroupID'){
            setmicAdsNetParam((prev) => ({...prev, AdGroupID: true }));
          }
          if(paramKey === 'AdID'){
            setmicAdsNetParam((prev) => ({...prev, AdID: true }));
          }
          if(paramKey === 'QueryString'){
            setmicAdsNetParam((prev) => ({...prev, QueryString: true }));
          }
          if(paramKey === 'Device'){
            setmicAdsNetParam((prev) => ({...prev, Device: true }));
          }
          if(paramKey === 'MatchType'){
            setmicAdsNetParam((prev) => ({...prev, MatchType: true }));
          }
          if(paramKey === 'Network'){
            setmicAdsNetParam((prev) => ({...prev, Network: true }));
          }
          if(paramKey === 'msclkid'){
            setmicAdsNetParam((prev) => ({...prev, msclkid: true }));
          }
        }else if(visi_sourcevalue === 'snapchat'){
          if(paramKey === 'ad_id'){
            setsnaAdsNetParam((prev) => ({...prev, ad_id: true }));
          }
          if(paramKey === 'adSet_id'){
            setsnaAdsNetParam((prev) => ({...prev, adSet_id: true }));
          }
          if(paramKey === 'campaign_id'){
            setsnaAdsNetParam((prev) => ({...prev, campaign_id: true }));
          }
          if(paramKey === 'creative_name'){
            setsnaAdsNetParam((prev) => ({...prev, creative_name: true }));
          }
          if(paramKey === 'adSet_name'){
            setsnaAdsNetParam((prev) => ({...prev, adSet_name: true }));
          }
          if(paramKey === 'campaign_name'){
            setsnaAdsNetParam((prev) => ({...prev, campaign_name: true }));
          }
          if(paramKey === 'creative_headline'){
            setsnaAdsNetParam((prev) => ({...prev, creative_headline: true }));
          }
        }else if(visi_sourcevalue === 'revcontent'){
          if(paramKey === 'adv_targets'){
            setrevAdsNetParam((prev) => ({...prev, adv_targets: true }));
          }
          if(paramKey === 'boost_id'){
            setrevAdsNetParam((prev) => ({...prev, boost_id: true }));
          }
          if(paramKey === 'content_id'){
            setrevAdsNetParam((prev) => ({...prev, content_id: true }));
          }
          if(paramKey === 'city'){
            setrevAdsNetParam((prev) => ({...prev, city: true }));
          }
          if(paramKey === 'state'){
            setrevAdsNetParam((prev) => ({...prev, state: true }));
          }
          if(paramKey === 'country'){
            setrevAdsNetParam((prev) => ({...prev, country: true }));
          }
          if(paramKey === 'widget_id'){
            setrevAdsNetParam((prev) => ({...prev, widget_id: true }));
          }
        }else if(visi_sourcevalue === 'taboola'){
          if(paramKey === 'tblci'){
            settubAdsNetParam((prev) => ({...prev, tblci: true }));
          }
          if(paramKey === 'campaign_id'){
            settubAdsNetParam((prev) => ({...prev, campaign_id: true }));
          }
          if(paramKey === 'campaign_name'){
            settubAdsNetParam((prev) => ({...prev, campaign_name: true }));
          }
          if(paramKey === 'country'){
            settubAdsNetParam((prev) => ({...prev, country: true }));
          }
          if(paramKey === 'platform'){
            settubAdsNetParam((prev) => ({...prev, platform: true }));
          }
          if(paramKey === 'site'){
            settubAdsNetParam((prev) => ({...prev, site: true }));
          }
        }else if(visi_sourcevalue === 'mgid'){
          if(paramKey === 'teaser_id'){
            setmgAdsNetParam((prev) => ({...prev, teaser_id: true }));
          }
          if(paramKey === 'widget_id'){
            setmgAdsNetParam((prev) => ({...prev, widget_id: true }));
          }
          if(paramKey === 'campaign_id'){
            setmgAdsNetParam((prev) => ({...prev, campaign_id: true }));
          }
          if(paramKey === 'category_id'){
            setmgAdsNetParam((prev) => ({...prev, category_id: true }));
          }
          if(paramKey === 'geo_region'){
            setmgAdsNetParam((prev) => ({...prev, geo_region: true }));
          }
          if(paramKey === 'click_id'){
            setmgAdsNetParam((prev) => ({...prev, click_id: true }));
          }
          if(paramKey === 'click_price'){
            setmgAdsNetParam((prev) => ({...prev, click_price: true }));
          }
        }else if(visi_sourcevalue === 'outbrain'){
          if(paramKey === 'ad_title'){
            setoutAdsNetParam((prev) => ({...prev, ad_title: true }));
          }
          if(paramKey === 'section_name'){
            setoutAdsNetParam((prev) => ({...prev, section_name: true }));
          }
          if(paramKey === 'publisher_name'){
            setoutAdsNetParam((prev) => ({...prev, publisher_name: true }));
          }
          if(paramKey === 'campaign_id'){
            setoutAdsNetParam((prev) => ({...prev, campaign_id: true }));
          }
          if(paramKey === 'section_id'){
            setoutAdsNetParam((prev) => ({...prev, section_id: true }));
          }
          if(paramKey === 'publisher_id'){
            setoutAdsNetParam((prev) => ({...prev, publisher_id: true }));
          }
          if(paramKey === 'ob_click_id'){
            setoutAdsNetParam((prev) => ({...prev, ob_click_id: true }));
          }
        }else if(visi_sourcevalue === 'tiktok'){
          if(paramKey === 'campaignid'){
            settiktokAdsNetParam((prev) => ({...prev, campaignid: true }));
          }
          if(paramKey === 'adgroupid'){
            settiktokAdsNetParam((prev) => ({...prev, adgroupid: true }));
          }
          if(paramKey === 'adid'){
            settiktokAdsNetParam((prev) => ({...prev, adid: true }));
          }
          if(paramKey === 'ttclid'){
            settiktokAdsNetParam((prev) => ({...prev, ttclid: true }));
          }
        }else if(visi_sourcevalue === 'youtube'){
          if(paramKey === 'campaignid'){
            setytAdsNetParam((prev) => ({...prev, campaignid: true }));
          }
          if(paramKey === 'adgroupid'){
            setytAdsNetParam((prev) => ({...prev, adgroupid: true }));
          }
          if(paramKey === 'creative'){
            setytAdsNetParam((prev) => ({...prev, creative: true }));
          }
          if(paramKey === 'keyword'){
            setytAdsNetParam((prev) => ({...prev, keyword: true }));
          }
          if(paramKey === 'placement'){
            setytAdsNetParam((prev) => ({...prev, placement: true }));
          }
          if(paramKey === 'target'){
            setytAdsNetParam((prev) => ({...prev, target: true }));
          }
          if(paramKey === 'devicemodel'){
            setytAdsNetParam((prev) => ({...prev, devicemodel: true }));
          }
          if(paramKey === 'matchtype'){
            setytAdsNetParam((prev) => ({...prev, matchtype: true }));
          }
          if(paramKey === 'network'){
            setytAdsNetParam((prev) => ({...prev, network: true }));
          }
          if(paramKey === 'device'){
            setytAdsNetParam((prev) => ({...prev, yt_device: true }));
          }
          if(paramKey === 'adposition'){
            setytAdsNetParam((prev) => ({...prev, adposition: true }));
          }
          if(paramKey === 'gclid'){
            setytAdsNetParam((prev) => ({...prev, gclid: true }));
          }
          if(paramKey === 'gbraid'){
            setytAdsNetParam((prev) => ({...prev, yt_gbraid: true }));
          }
          if(paramKey === 'wbraid'){
            setytAdsNetParam((prev) => ({...prev, wbraid: true }));
          }
        }
        if(paramKey === 'utm_id'){
          setUtmIdValue(paramValue);
        }
        if(paramKey === 'utm_source'){
          setUtmSourceValue(paramValue);
        }
        if(paramKey === 'utm_campaign'){
          setUtmCampValue(paramValue);
        }
        if(paramKey === 'utm_term'){
          setUtmTermValue(paramValue);
        }
        if(paramKey === 'utm_medium'){
          setUtmMediumValue(paramValue);
        }
        if(paramKey === 'utm_content'){
          setUtmContentValue(paramValue);
        }
        const url_params = ["visi_source","ad_id", "adset_id", "campaign_id", "ad_name", "adset_name", "campaign_name", "placement", "site_source_name", "fbclid", "campaignid", "creative", "target", "keyword", "devicemodel", "matchtype", "network", "device", "adposition", "gclid", "gbraid", "wbraid", "adgroupid", "QueryString", "AdGroupID", "AdGroup", "AdID", "CampaignID", "Campaign", "Device", "MatchType", "Network",  "adSet_id",  "creative_name", "adSet_name", "creative_headline", "adv_targets", "boost_id", "content_id", "city", "state", "country", "widget_id", "thumbnail", "title", "platform",  "campaign_item_id", "cpc", "teaser_id",  "category_id", "geo_region", "click_id", "click_price", "ad_title", "section_name", "publisher_name",  "section_id", "publisher_id", "ob_click_id","utm_medium","utm_id","utm_source","utm_campaign","utm_term","utm_content","msclkid","ttclid","adid","tblci","site"];

        const customTagKeySetters = [setCustomTagKey1, setCustomTagKey2, setCustomTagKey3, setCustomTagKey4, setCustomTagKey5];
        const customTagValueSetters = [setCustomTagValue1, setCustomTagValue2, setCustomTagValue3, setCustomTagValue4, setCustomTagValue5];
        if(!url_params.includes(paramKey)){
          customTagKeySetters[tag](paramKey);
          customTagValueSetters[tag](paramValue);
          tag++;
        }
      });      
  };

  const handleUpdateUrlBuilder = () => {
    SettingsApi.saveupdateurlbuilder({
        site_id: selectedMemberWebsite,
        page_id: selectedPageValue,
        url_name: urlBuilderName,
        your_url: selectedPageUrl,
        custom_tag_url_id: customTagURLId,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });  
          fetchURLBuilders();
          handleCloseBtn();
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
  
  const deleteURLBuilderConfirm = (deleteId:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to delete this URL Builder And Custom Tags?
      </Text>
    ),
    labels: { confirm: 'Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => console.log('Confirmed',handleURLBuilderDelete(deleteId)),
  });

  const handleURLBuilderDelete = (deleteId:any) => {
    SettingsApi.deleteurlbuilder({
      custom_tag_url_id: deleteId,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        }); 
        fetchURLBuilders();       
      }else{
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  const handleChangeEvent = (e: any, urlitem: string = '') => {
    generatePageUrl(e, urlitem);        
  };

  const clearValues = () => {
      const googleObjectArray = {"campaignid" : "{campaignid}",
        "creative" : "{creative}",
        "placement" : "{placement}",
        "target" : "{target}",
        "keyword" : "{keyword}",
        "devicemodel" : "{devicemodel}",
        "matchtype" : "{matchtype}",
        "network" : "{network}",
        "device" : "{device}",
        "adposition" : "{adposition}",
        "gclid" : "{gclid}",
        "gbraid" : "{gbraid}",
        "wbraid" : "{wbraid}",
        "adgroupid" : "{adgroupid}"};
      for (const [key, value] of Object.entries(googleObjectArray)) {
          setSelectedPageUrl(prevUrl => prevUrl.replace(`${key}=${value}&`, ''))            
      }

      const facebookObjectArray = {"ad_id" : "{{ad.id}}",
        "adset_id" : "{{adset.id}}",
        "campaign_id" : "{{campaign.id}}",
        "ad_name" : "{{ad.name}}",
        "adset_name" : "{{adset.name}}",
        "campaign_name" : "{{campaign.name}}",
        "placement" : "{{placement}}",
        "site_source_name" : "{{site_source_name}}",
        "fbclid" : "{{fbclid}}"};
      for (const [key, value] of Object.entries(facebookObjectArray)) {
          setSelectedPageUrl(prevUrl => prevUrl.replace(`${key}=${value}&`, ''))            
      }

      const microsoftObjectArray = {"keyword" : "{keyword}",
      "QueryString" : "{QueryString}",
      "AdGroupID" : "{AdGroupID}",
      "AdGroup" : "{AdGroup}",
      "AdID" : "{AdID}",
      "CampaignID" : "{CampaignID}",
      "Campaign" : "{Campaign}",
      "Device" : "{Device}",
      "MatchType" : "{MatchType}",
      "Network" : "{Network}",
      "msclkid" : "{msclkid}"};
      for (const [key, value] of Object.entries(microsoftObjectArray)) {
          setSelectedPageUrl(prevUrl => prevUrl.replace(`${key}=${value}&`, ''))            
      }

      const snapchatObjectArray = {"ad_id" : "{{ad.id}}",
        "adSet_id" : "{{adSet.id}}",
        "campaign_id" : "{{campaign.id}}",
        "creative_name" : "{{creative.name}}",
        "adSet_name" : "{{adSet.name}}",
        "campaign_name" : "{{campaign.name}}",
        "creative_headline" : "{{creative.headline}}"};
      for (const [key, value] of Object.entries(snapchatObjectArray)) {
          setSelectedPageUrl(prevUrl => prevUrl.replace(`${key}=${value}&`, ''))            
      }

      const revcontentObjectArray = {"adv_targets" : "{adv_targets}",
        "boost_id" : "{boost_id}",
        "content_id" : "{content_id}",
        "city" : "{city}",
        "state" : "{state}",
        "country" : "{country}",
        "widget_id" : "{widget_id}"};
      for (const [key, value] of Object.entries(revcontentObjectArray)) {
          setSelectedPageUrl(prevUrl => prevUrl.replace(`${key}=${value}&`, ''))            
      }

      const taboolaObjectArray = {"tblci" : "{tblci}",
        "campaign_id" : "{campaign_id}",
        "campaign_name" : "{campaign_name}",
        "country" : "{country}",
        "platform" : "{platform}",
        "site" : "{site}"};
      for (const [key, value] of Object.entries(taboolaObjectArray)) {
          setSelectedPageUrl(prevUrl => prevUrl.replace(`${key}=${value}&`, ''))            
      }

      const mgidObjectArray = {"teaser_id" : "{teaser_id}",
        "widget_id" : "{widget_id}",
        "campaign_id" : "{campaign_id}",
        "category_id" : "{category_id}",
        "geo_region" : "{geo_region}",
        "click_id" : "{click_id}",
        "click_price" : "{click_price}"};
      for (const [key, value] of Object.entries(mgidObjectArray)) {
          setSelectedPageUrl(prevUrl => prevUrl.replace(`${key}=${value}&`, ''))            
      }

      const outbrainObjectArray = {"ad_title" : "{ad_title}",
        "section_name" : "{section_name}",
        "publisher_name" : "{publisher_name}",
        "campaign_id" : "{campaign_id}",
        "section_id" : "{section_id}",
        "publisher_id" : "{publisher_id}",
        "ob_click_id" : "{ob_click_id}"};
      for (const [key, value] of Object.entries(outbrainObjectArray)) {
          setSelectedPageUrl(prevUrl => prevUrl.replace(`${key}=${value}&`, ''))            
      }

      const tiktokObjectArray = {"campaignid" : "__CAMPAIGNID__",
        "adgroupid" : "__ADGROUPID__",
        "adid" : "__ADID__",
        "ttclid" : "_CLICKID_"};
      for (const [key, value] of Object.entries(tiktokObjectArray)) {
          setSelectedPageUrl(prevUrl => prevUrl.replace(`${key}=${value}&`, ''))            
      }

      const youtubeObjectArray = {"campaignid" : "{campaignid}",
        "creative" : "{creative}",
        "placement" : "{placement}",
        "target" : "{target}",
        "keyword" : "{keyword}",
        "devicemodel" : "{devicemodel}",
        "matchtype" : "{matchtype}",
        "network" : "{network}",
        "device" : "{device}",
        "adposition" : "{adposition}",
        "gclid" : "{gclid}",
        "gbraid" : "{gbraid}",
        "wbraid" : "{wbraid}",
        "adgroupid" : "{adgroupid}"};
      for (const [key, value] of Object.entries(youtubeObjectArray)) {
          setSelectedPageUrl(prevUrl => prevUrl.replace(`${key}=${value}&`, ''))            
      }
  };
  
  const handleNetworkTokenAction = (value) => {
    setNetworkTokenValue(value);    
    setfbAdsNetParam({campaign_name: false,adset_name: false,ad_name: false,placement: false,site_source_name: false,fbclid: false,campaign_id: false,adset_id: false,ad_id: false});
    setgglAdsNetParam({campaignid: false,wbraid: false,creative: false,keyword: false,placement: false,target: false,devicemodel: false,matchtype: false,network: false,g_device: false,adposition: false,gclid: false,gbraid: false,wbraid: false});
    setmicAdsNetParam({Campaign: false,AdGroup: false,keyword: false,CampaignID: false,AdGroupID: false,AdID: false,QueryString: false,m_Device: false,MatchType: false,Network: false,msclkid: false});
    setsnaAdsNetParam({ad_id: false,adSet_id: false,campaign_id: false,creative_name: false,adSet_name: false,campaign_name: false,creative_headline: false});
    setrevAdsNetParam({adv_targets: false,boost_id: false,content_id: false,city: false,state: false,country: false,widget_id: false});
    settubAdsNetParam({tblci: false,campaign_id: false,campaign_name: false,country: false,platform: false,site: false});
    setmgAdsNetParam({teaser_id: false,widget_id: false,campaign_id: false,category_id: false,geo_region: false,click_id: false,click_price: false});
    setoutAdsNetParam({ad_title: false,section_name: false,publisher_name: false,campaign_id: false,section_id: false,publisher_id: false,ob_click_id: false});
    settiktokAdsNetParam({campaignid: false,adgroupid: false,adid: false,ttclid: false});
    setytAdsNetParam({campaignid: false,wbraid: false,creative: false,keyword: false,placement: false,target: false,devicemodel: false,matchtype: false,network: false,yt_device: false,adposition: false,gclid: false,yt_gbraid: false,wbraid: false});

    setSelectedPageUrl(prevUrl => prevUrl.replace('visi_source=facebook&',''));
    setSelectedPageUrl(prevUrl => prevUrl.replace('visi_source=google&',''));
    setSelectedPageUrl(prevUrl => prevUrl.replace('visi_source=microsoft&',''));
    setSelectedPageUrl(prevUrl => prevUrl.replace('visi_source=snapchat&',''));
    setSelectedPageUrl(prevUrl => prevUrl.replace('visi_source=revcontent&',''));
    setSelectedPageUrl(prevUrl => prevUrl.replace('visi_source=taboola&',''));
    setSelectedPageUrl(prevUrl => prevUrl.replace('visi_source=mgid&',''));
    setSelectedPageUrl(prevUrl => prevUrl.replace('visi_source=outbrain&',''));
    setSelectedPageUrl(prevUrl => prevUrl.replace('visi_source=tiktok&',''));
    setSelectedPageUrl(prevUrl => prevUrl.replace('visi_source=youtube&',''));
    clearValues();
  }; 
  
  const generatePageUrl = (e, urlitem) => {
    let utm_name = '';
    let utm_val = '';
    let utm_type = '';
    let generate_page_url = selectedPageUrl;

    if(selectedPageValue === '' || selectedPageUrl === ''){
      alert('Please select a page');
      return false;
    }
    if (urlitem === 'custom_tag_key') { 
      setSelectedCustomtag(e);
      return; 
    }else if(urlitem === 'custom_tag_value'){
      utm_name = selectedCustomTag;
      utm_val = e.target.value;
      utm_type = e.target.type;
    }else{
      const { name, checked } = e.target;
      setfbAdsNetParam((prev) => ({...prev, [name]: checked }));
      setgglAdsNetParam((prev) => ({...prev, [name]: checked }));
      setmicAdsNetParam((prev) => ({...prev, [name]: checked }));
      setsnaAdsNetParam((prev) => ({...prev, [name]: checked }));
      setrevAdsNetParam((prev) => ({...prev, [name]: checked }));
      settubAdsNetParam((prev) => ({...prev, [name]: checked }));
      setmgAdsNetParam((prev) => ({...prev, [name]: checked }));
      setoutAdsNetParam((prev) => ({...prev, [name]: checked }));
      settiktokAdsNetParam((prev) => ({...prev, [name]: checked }));
      setytAdsNetParam((prev) => ({...prev, [name]: checked }));
      utm_name = e.target.id;
      utm_val = e.target.value;
      utm_type = e.target.type;
    }
    
    if(generate_page_url.indexOf("?") === -1){
      generate_page_url += '?' + utm_name + '=' + utm_val + '&';
    }else {        
        if(generate_page_url.indexOf(utm_name) !== -1)
        {
          let old_utmval = generate_page_url.split(utm_name)[1];          
          old_utmval = old_utmval.split('&')[0];          
          if(utm_type !== 'checkbox'){
              generate_page_url = generate_page_url.replace(utm_name + old_utmval, utm_name + "=" + utm_val);
            }
        }
        else{
          const lastChar = generate_page_url.substr(generate_page_url.length - 1);
          if(lastChar === "&"){   
            generate_page_url += utm_name + '=' + utm_val + '&';        
          }
          else {
            const lastChar_a = (lastChar === "?")?'':'&';
            generate_page_url += lastChar_a + utm_name + '=' + utm_val + '&';
          }
        }
    }  
    if(utm_type === 'checkbox'){  
      const checkBox = document.querySelector(`#${utm_name}`);    
      if(checkBox.checked === false){
        if(utm_name === 'g_placement'){
            generate_page_url = generate_page_url.replace("placement="+utm_val+"&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name+"="+utm_val+"&", "");
          }
          if (utm_name === 's_campaign_id' || utm_name === 'tub_campaign_id' || utm_name === 'mg_campaign_id' || utm_name === 'out_campaign_id') {
            generate_page_url = generate_page_url.replace("campaign_id=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'm_keyword') {
            generate_page_url = generate_page_url.replace("keyword=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'mg_widget_id') {
            generate_page_url = generate_page_url.replace("widget_id=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 's_ad_id') {
            generate_page_url = generate_page_url.replace("ad_id=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'tub_campaign_name') {
            generate_page_url = generate_page_url.replace("campaign_name=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'tub_country') {
            generate_page_url = generate_page_url.replace("country=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 's_adSet_id') {
            generate_page_url = generate_page_url.replace("adSet_id=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'm_AdGroup') {
            generate_page_url = generate_page_url.replace("AdGroup=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 's_campaign_name') {
            generate_page_url = generate_page_url.replace("campaign_name=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'm_Campaign') {
            generate_page_url = generate_page_url.replace("Campaign=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'm_Device') {
            generate_page_url = generate_page_url.replace("Device=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'g_device') {
            generate_page_url = generate_page_url.replace("device=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 't_adgroupid') {
            generate_page_url = generate_page_url.replace("adgroupid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 't_campaignid') {
            generate_page_url = generate_page_url.replace("campaignid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 't_adid') {
            generate_page_url = generate_page_url.replace("adid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_campaignid') {
            generate_page_url = generate_page_url.replace("campaignid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_adgroupid') {
            generate_page_url = generate_page_url.replace("adgroupid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_creative') {
            generate_page_url = generate_page_url.replace("creative=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_keyword') {
            generate_page_url = generate_page_url.replace("keyword=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_placement') {
            generate_page_url = generate_page_url.replace("placement=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_target') {
            generate_page_url = generate_page_url.replace("target=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_devicemodel') {
            generate_page_url = generate_page_url.replace("devicemodel=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_matchtype') {
            generate_page_url = generate_page_url.replace("matchtype=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_network') {
            generate_page_url = generate_page_url.replace("netwo=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_device') {
            generate_page_url = generate_page_url.replace("device=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_adposition') {
            generate_page_url = generate_page_url.replace("adposition=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_gclid') {
            generate_page_url = generate_page_url.replace("gclid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_gbraid') {
            generate_page_url = generate_page_url.replace("gbraid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_wbraid') {
            generate_page_url = generate_page_url.replace("wbraid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
      }
    } 
    let ads_params = false;
    if (networkTokenValue === 'facebook') {
      const containsFacebookEle = facebookArray.some(item => generate_page_url.indexOf(item) !== -1);
      if(containsFacebookEle){
        ads_params = true;        
      }
      if (!generate_page_url.includes('visi_source') && ads_params) {
        generate_page_url = generate_page_url + 'visi_source=' + networkTokenValue + '&';
      } else if (generate_page_url.includes('visi_source') && !ads_params) {
        generate_page_url = generate_page_url.replace('visi_source=facebook&', '');
      }
    }else if (networkTokenValue === 'google') {
      const containsGoogleEle = googleArray.some(item => generate_page_url.indexOf(item) !== -1);
      if(containsGoogleEle){
        ads_params = true;
      }
      if (!generate_page_url.includes('visi_source') && ads_params) {
        generate_page_url = generate_page_url + 'visi_source=' + networkTokenValue + '&';
      } else if (generate_page_url.includes('visi_source') && !ads_params) {
        generate_page_url = generate_page_url.replace('visi_source=google&', '');
      }
    }else if (networkTokenValue === 'microsoft') {
      const containsMicrosoftEle = microsoftArray.some(item => generate_page_url.indexOf(item) !== -1);
      if(containsMicrosoftEle){
        ads_params = true;
      }
      if (!generate_page_url.includes('visi_source') && ads_params) {
        generate_page_url = generate_page_url + 'visi_source=' + networkTokenValue + '&';
      } else if (generate_page_url.includes('visi_source') && !ads_params) {
        generate_page_url = generate_page_url.replace('visi_source=microsoft&', '');
      }
    }else if (networkTokenValue === 'revcontent') {
      const containsRevcontentEle = revcontentArray.some(item => generate_page_url.indexOf(item) !== -1);
      if(containsRevcontentEle){
        ads_params = true;
      }
      if (!generate_page_url.includes('visi_source') && ads_params) {
        generate_page_url = generate_page_url + 'visi_source=' + networkTokenValue + '&';
      } else if (generate_page_url.includes('visi_source') && !ads_params) {
        generate_page_url = generate_page_url.replace('visi_source=revcontent&', '');
      }
    }else if (networkTokenValue === 'taboola') {
      const containsTaboolaEle = taboolaArray.some(item => generate_page_url.indexOf(item) !== -1);
      if(containsTaboolaEle){
        ads_params = true;
      }
      if (!generate_page_url.includes('visi_source') && ads_params) {
        generate_page_url = generate_page_url + 'visi_source=' + networkTokenValue + '&';
      } else if (generate_page_url.includes('visi_source') && !ads_params) {
        generate_page_url = generate_page_url.replace('visi_source=taboola&', '');
      }
    }else if (networkTokenValue === 'snapchat') {
      const containsSnapchatEle = snapchatArray.some(item => generate_page_url.indexOf(item) !== -1);
      if(containsSnapchatEle){
        ads_params = true;
      }
      if (!generate_page_url.includes('visi_source') && ads_params) {
        generate_page_url = generate_page_url + 'visi_source=' + networkTokenValue + '&';
      } else if (generate_page_url.includes('visi_source') && !ads_params) {
        generate_page_url = generate_page_url.replace('visi_source=snapchat&', '');
      }
    }else if (networkTokenValue === 'mgid') {
      const containsMgidEle = mgidArray.some(item => generate_page_url.indexOf(item) !== -1);
      if(containsMgidEle){
        ads_params = true;
      }
      if (!generate_page_url.includes('visi_source') && ads_params) {
        generate_page_url = generate_page_url + 'visi_source=' + networkTokenValue + '&';
      } else if (generate_page_url.includes('visi_source') && !ads_params) {
        generate_page_url = generate_page_url.replace('visi_source=mgid&', '');
      }
    }else if (networkTokenValue === 'outbrain') {
      const containsOutbrainEle = outbrainArray.some(item => generate_page_url.indexOf(item) !== -1);
      if(containsOutbrainEle){
        ads_params = true;
      }
      if (!generate_page_url.includes('visi_source') && ads_params) {
        generate_page_url = generate_page_url + 'visi_source=' + networkTokenValue + '&';
      } else if (generate_page_url.includes('visi_source') && !ads_params) {
        generate_page_url = generate_page_url.replace('visi_source=outbrain&', '');
      }
    }else if (networkTokenValue === 'tiktok') {
      const containsTiktokEle = tiktokArray.some(item => generate_page_url.indexOf(item) !== -1);
      if(containsTiktokEle){
        ads_params = true;
      }
      if (!generate_page_url.includes('visi_source') && ads_params) {
        generate_page_url = generate_page_url + 'visi_source=' + networkTokenValue + '&';
      } else if (generate_page_url.includes('visi_source') && !ads_params) {
        generate_page_url = generate_page_url.replace('visi_source=tiktok&', '');
      }
    }else if (networkTokenValue === 'youtube') {
      const containsYoutubeEle = youtubeArray.some(item => generate_page_url.indexOf(item) !== -1);
      if(containsYoutubeEle){
        ads_params = true;
      }
      if (!generate_page_url.includes('visi_source') && ads_params) {
        generate_page_url = generate_page_url + 'visi_source=' + networkTokenValue + '&';
      } else if (generate_page_url.includes('visi_source') && !ads_params) {
        generate_page_url = generate_page_url.replace('visi_source=youtube&', '');
      }
    }

    if (networkTokenValue === 'google') {
      generate_page_url = generate_page_url.replace('g_placement', 'placement');
      generate_page_url = generate_page_url.replace('g_device', 'device');
    }
    if (networkTokenValue === 'microsoft') {
      generate_page_url = generate_page_url.replace('m_keyword', 'keyword');
      generate_page_url = generate_page_url.replace('m_Device', 'Device');
      generate_page_url = generate_page_url.replace('m_Campaign', 'Campaign');
      generate_page_url = generate_page_url.replace('m_AdGroup', 'AdGroup');
    }
    if (networkTokenValue === 'taboola') {
      generate_page_url = generate_page_url.replace('tub_campaign_id', 'campaign_id');
      generate_page_url = generate_page_url.replace('tub_campaign_name', 'campaign_name');
      generate_page_url = generate_page_url.replace('tub_country', 'country');
    }
    if (networkTokenValue === 'snapchat') {
      generate_page_url = generate_page_url.replace('s_campaign_id', 'campaign_id');
      generate_page_url = generate_page_url.replace('s_campaign_name', 'campaign_name');
      generate_page_url = generate_page_url.replace('s_ad_id', 'ad_id');
      generate_page_url = generate_page_url.replace('s_adSet_id', 'adSet_id');
    }
    if (networkTokenValue === 'mgid') {
      generate_page_url = generate_page_url.replace('mg_campaign_id', 'campaign_id');
      generate_page_url = generate_page_url.replace('mg_widget_id', 'widget_id');
    }
    if (networkTokenValue === 'outbrain') {
      generate_page_url = generate_page_url.replace('out_campaign_id', 'campaign_id');
    }
    if (networkTokenValue === 'tiktok') {
      generate_page_url = generate_page_url.replace('t_adgroupid', 'adgroupid');
      generate_page_url = generate_page_url.replace('t_campaignid', 'campaignid');
      generate_page_url = generate_page_url.replace('t_adid', 'adid');
    }
    if (networkTokenValue === 'youtube') {
      generate_page_url = generate_page_url.replace('yt_campaignid', 'campaignid');
      generate_page_url = generate_page_url.replace('yt_adgroupid', 'adgroupid');
      generate_page_url = generate_page_url.replace('yt_creative', 'creative');
      generate_page_url = generate_page_url.replace('yt_keyword', 'keyword');
      generate_page_url = generate_page_url.replace('yt_placement', 'placement');
      generate_page_url = generate_page_url.replace('yt_target', 'target');
      generate_page_url = generate_page_url.replace('yt_devicemodel', 'devicemodel');
      generate_page_url = generate_page_url.replace('yt_matchtype', 'matchtype');
      generate_page_url = generate_page_url.replace('yt_network', 'network');
      generate_page_url = generate_page_url.replace('yt_device', 'device');
      generate_page_url = generate_page_url.replace('yt_adposition', 'adposition');
      generate_page_url = generate_page_url.replace('yt_gclid', 'gclid');
      generate_page_url = generate_page_url.replace('yt_gbraid', 'gbraid');
      generate_page_url = generate_page_url.replace('yt_wbraid', 'wbraid');
    }
    setSelectedPageUrl(generate_page_url)
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRefMapping = {
      242: urlBuilderIconRef,
      230: standardTagIconRef,
      231: customTagIconRef,
      302: networkTokensIconRef,
      293: facebookTagIconRef,
      295: googleTagIconRef,
      296: microsoftTagIconRef,
      297: snapchatTagIconRef,
      298: revContentIconRef,
      299: taboolaTagIconRef,
      300: mgidIconRef,
      301: outbrainIconRef,
      314: tiktokTagIconRef,
      318: youtubeTagIconRef
    };
    const iconRef = iconRefMapping[id];
    if (iconRef.current) {
      const { top, left, width } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: {top: top + scrollOffset, left: left + width + 10},
      });
    }
  };


  return (
    <>
      {showHelpModal &&
        ReactDOM.createPortal(
          <div>
            {Object.keys(tooltipPosition).map((key) => (
              <SelectTooltip
                isVisible={showHelpModal}
                setIsVisible={setShowHelpModal}
                id={helpTargetId}
                tooltipPosition={tooltipPosition[key]} 
              />
            ))}
          </div>,
        document.body
      )}
      <div style={{ display: activeURLBuilder === "URL-builder-and-custom-tags" ? "block" : "none"}}>
        <Group position="apart" mb="xs">
          <Text weight={500}>URL BUILDER AND CUSTOME TAGS <IconHelp ref={urlBuilderIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(242)}/></Text>
        </Group>
        <Button onClick={() => handleAddURLBuilder()}>Add a New URL</Button>
        {showSkeleton ? (
          <Flex direction="column" gap="sm" mt={10}>
            {Array.from({ length: 12 }).map((_, index) => (
              <Skeleton key={index} height={44} width={1262} />
            ))}
          </Flex>
        ) : (
          urlBuilderAndCustomData.map((item,index) => (
            <Box key={index} p={4} pl={20} mt={10}
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
                padding: "10px",
                borderRadius: theme.radius.md,
                border: "0.0625rem solid transparent",
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] :  '#dee2e6'
              })}
            >
              <Grid gutter="xs">
                <Grid.Col span={3}>
                  <Text mt={3}>
                    {item.url_name}
                  </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text mt={3}>
                    {item.domain_name}
                  </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Text mt={3}>
                    {item.title}
                  </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    <ActionIcon
                      variant="default"
                      size="lg"
                      mx={3}
                      title="Edit"
                      onClick={() => updateURLBuilder(item.custom_tag_url_id)}
                    >
                      <IconPencil size="1.1rem" />
                    </ActionIcon>
                    <ActionIcon
                      variant="default"
                      size="lg"
                      mx={3}
                      title="Delete"
                      onClick={() => deleteURLBuilderConfirm(item.custom_tag_url_id)}
                    >
                      <IconTrash size="1.1rem" />
                    </ActionIcon>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Box>
          ))
        )}
      </div>
      <div style={{ display: activeAddURLBuilder === "add-URLbuilder" ? "block" : "none",marginLeft:10}}>
        <Group position="apart" mb="xs">
          <Text weight={500}>URL BUILDER AND CUSTOME TAGS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} /></Text>
        </Group>
        <div>
          <Group spacing="xs" mb={20} mt={20}>
            <Text size="sm" fz={14} mt={-10}>Select domain:</Text>
              <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleMemberWebsiteChange} SelectMemberWebsiteListWidth={250} SelectMemberWebsiteListUsedIn="settings" SelectMemberWebsiteListMaxDropdownHeight={300} SelectMemberWebsiteListdropdownPosition="bottom" SelectMemberWebsiteListdropdownPositionMB={10} SelectMemberWebsiteListML={4}/>
          </Group>
          <Group spacing="xs" mb={20} mt={20}>
              <Text size="sm" fz={14} mt={-10}>Select URL Page:</Text>
              <Select
                data={[
                    { value: '', label: 'Select Page', disabled: true }, 
                    ...websitePageList.slice(1).map((page, index) => ({
                      value: page.page_id,
                      label: page.page_url
                    }))
                  ]}
                id="page_dd"
                value={selectedPageValue}                          
                onChange={(value) => setSelectedPageValue(value)}                      
              />               
          </Group>
          <Text size="sm" fw={500} mb={10}>Standard Tag <IconHelp ref={standardTagIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(230)}/></Text>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_id :</Text>
            <Input name="utm_id" id="utm_id" data-urlitem="generate_url" w={205} ml={55} 
            value={utmIdValue}
            onChange={(e) => {
                setUtmIdValue(e.target.value);
                handleChangeEvent(e); }}
            />
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_source :</Text>
            <Input name="utm_source" id="utm_source" data-urlitem="generate_url" w={205} ml={25}
            value={utmSourceValue}
            onChange={(e) => { 
              setUtmSourceValue(e.target.value);
              handleChangeEvent(e); }}
            />
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_medium :</Text>
            <Input name="utm_medium" id="utm_medium" data-urlitem="generate_url" w={205} ml={15}
            value={utmMediumValue}
            onChange={(e) => { 
              setUtmMediumValue(e.target.value);
              handleChangeEvent(e); }}
            />
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_campaign :</Text>
            <Input name="utm_campaign" id="utm_campaign" data-urlitem="generate_url" w={205} ml={5}
            value={utmCampValue}
            onChange={(e) => { 
              setUtmCampValue(e.target.value);
              handleChangeEvent(e); }}
            />
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_term :</Text>
            <Input name="utm_term" id="utm_term" data-urlitem="generate_url" w={205} ml={36}
            value={utmTermValue}
            onChange={(e) => { 
              setUtmTermValue(e.target.value);
              handleChangeEvent(e); }}
            />
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_content :</Text>
            <Input name="utm_content" id="utm_content" data-urlitem="generate_url" w={205} ml={18}
            value={utmContentValue}
            onChange={(e) => { 
              setUtmContentValue(e.target.value);
              handleChangeEvent(e); }}
            />
          </Group>
        </div>
        <Divider my="md" />
        <div>
          <Text size="sm" fw={500} mb={10}>Custom Tag <IconHelp ref={customTagIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(231)}/></Text>         
          <Group spacing="xs" mt={20} mb={10}>
            <Text size="sm" fz={14} w={100}>custom_tag-1:</Text>
            <Select
              name="custom_tag_key1"
              id="custom_tag_key1"
              data-urlitem="custom_tag_key"
              w={180}
              placeholder="Select Custom Tag"
              data={[
                  { value: '', label: 'Select Custom tag', disabled:true }, 
                  ...customTagDropDown.map((custom_tag, index) => ({
                    value: custom_tag.source_key,
                    label: custom_tag.source_key
                  }))
                ]}
              value={customTagKey1}
              onChange={(e) => {
                setCustomTagKey1(e);
                handleChangeEvent(e, 'custom_tag_key');
              }} 
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom" 
            />
            <Input id="custom_tag_value1" data-urlitem="custom_tag" name="custom_tag_1" w={200}
            value={customTagValue1}
            onChange={(e) =>{
              setCustomTagValue1(e.target.value);
              handleChangeEvent(e, 'custom_tag_value');
            }}
            />
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} w={100}>custom_tag-2:</Text>
            <Select
              name="custom_tag_key2"
              id="custom_tag_key2"
              data-urlitem="custom_tag_key"
              w={180}
              placeholder="Select Custom Tag"
              data={[
                  { value: '', label: 'Select Custom tag' }, 
                  ...customTagDropDown.map((custom_tag, index) => ({
                    value: custom_tag.source_key,
                    label: custom_tag.source_key
                  }))
                ]}
              value={customTagKey2}
              onChange={(e) => {
                setCustomTagKey2(e);
                handleChangeEvent(e, 'custom_tag_key');
              }}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
            />
            <Input id="custom_tag_value2" data-urlitem="custom_tag" name="custom_tag_2" w={200}
            value={customTagValue2}
            onChange={(e) =>{
              setCustomTagValue2(e.target.value);
              handleChangeEvent(e, 'custom_tag_value');
            }}
            />
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} w={100}>custom_tag-3:</Text>
            <Select
              name="custom_tag_key3"
              id="custom_tag_key3"
              data-urlitem="custom_tag_key"
              w={180}
              placeholder="Select Custom Tag"
              data={[
                  { value: '', label: 'Select Custom tag' }, 
                  ...customTagDropDown.map((custom_tag, index) => ({
                    value: custom_tag.source_key,
                    label: custom_tag.source_key
                  }))
                ]}
              value={customTagKey3}
              onChange={(e) => {
                setCustomTagKey3(e);
                handleChangeEvent(e, 'custom_tag_key');
              }}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
            />
            <Input id="custom_tag_value3" data-urlitem="custom_tag" name="custom_tag_3" w={200}
            value={customTagValue3}
            onChange={(e) =>{
              setCustomTagValue3(e.target.value);
              handleChangeEvent(e, 'custom_tag_value');
            }}
            />
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} w={100}>custom_tag-4:</Text>
            <Select
              name="custom_tag_key4"
              id="custom_tag_key4"
              data-urlitem="custom_tag_key"
              w={180}
              placeholder="Select Custom Tag"
              data={[
                  { value: '', label: 'Select Custom tag' }, 
                  ...customTagDropDown.map((custom_tag, index) => ({
                    value: custom_tag.source_key,
                    label: custom_tag.source_key
                  }))
                ]}
              value={customTagKey4}
              onChange={(e) => {
                setCustomTagKey4(e);
                handleChangeEvent(e, 'custom_tag_key');
              }}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
            />
            <Input id="custom_tag_value4" data-urlitem="custom_tag" name="custom_tag_4" w={200}
            value={customTagValue4}
            onChange={(e) =>{
              setCustomTagValue4(e.target.value);
              handleChangeEvent(e, 'custom_tag_value');
            }}
            />
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} w={100}>custom_tag-5:</Text>
            <Select
              name="custom_tag_key5"
              id="custom_tag_key5"
              data-urlitem="custom_tag_key"
              w={180}
              placeholder="Select Custom Tag"
              data={[
                  { value: '', label: 'Select Custom tag' }, 
                  ...customTagDropDown.map((custom_tag, index) => ({
                    value: custom_tag.source_key,
                    label: custom_tag.source_key
                  }))
                ]}
              value={customTagKey5}
              onChange={(e) => {
                setCustomTagKey5(e);
                handleChangeEvent(e, 'custom_tag_key');
              }}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
            />
            <Input id="custom_tag_value5" data-urlitem="custom_tag" name="custom_tag_5" w={200}
            value={customTagValue5}
            onChange={(e) =>{
              setCustomTagValue5(e.target.value);
              handleChangeEvent(e, 'custom_tag_value');
            }}
            />
          </Group>
        </div>
        <Divider my="md" />
        <div>
          <Text size="sm" fw={500} mb={10}>Network Token <IconHelp ref={networkTokensIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(302)}/></Text>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={5}>Select Network Token:</Text>
            <Select
              name="ads_network_tags"
              id="ads_network_tags"
              w={180}
              placeholder="Select Network Token"
              data={[
                { value: 'facebook', label: 'Facebook Tag' },
                { value: 'google', label: 'Google Tag' },
                { value: 'microsoft', label: 'Microsoft Tag' },
                { value: 'snapchat', label: 'Snapchat Tag' },
                { value: 'revcontent', label: 'RevContent Tag' },
                { value: 'taboola', label: 'Taboola Tag' },
                { value: 'mgid', label: 'MGID Tag' },
                { value: 'outbrain', label: 'Outbrain Tag' },
                { value: 'tiktok', label: 'Tiktok Tag' },
                { value: 'youtube', label: 'Youtube Tag' }
              ]}
              value={networkTokenValue}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
              onChange={handleNetworkTokenAction}
            />
          </Group>
          <div style={{marginBottom:20,display: networkTokenValue === "facebook" ? "block" : "none"}}>
            <Group mb={10}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                  padding: "10px",
                  borderRadius: "4px"
                })}
              >
                <div style={{ display: "Flex" }}>
                  <Text size="sm" fz={14} fw={500} mr={5} w={80}>Please note:</Text>
                  <Text size="sm" fz={14} w={530}>Visiopt has the ability to automatically track and pass Facebook Data directly through</Text>
                </div>
                <Text size="sm" fz={14} w={610}>Facebook's API. When you do this, you do not have to manually pass data from UTM parameters.</Text>
              </Box>
            </Group>
            <Text size="sm" fw={500} mb={20}>Facebook Tag <IconHelp ref={facebookTagIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(293)}/></Text>
            <Checkbox
              name="campaign_name" id="campaign_name" value="{{campaign.name}}" data-urlitem="fb_ads_net_param"
              mb={10}
              checked={fbAdsNetParam.campaign_name}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_name</Text>
                  <Text c="red">the name of the campaign that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="adset_name" id="adset_name" value="{{adset.name}}" data-urlitem="fb_ads_net_param"
              mb={10}
              checked={fbAdsNetParam.adset_name}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adset_name</Text>
                  <Text c="red">the name of the ad set that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="ad_name" id="ad_name" value="{{ad.name}}" data-urlitem="fb_ads_net_param"
              mb={10}
              checked={fbAdsNetParam.ad_name}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>ad_name</Text>
                  <Text c="red">the name of the ad that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="placement" id="placement" value="{{placement}}" data-urlitem="fb_ads_net_param"
              mb={10}
              checked={fbAdsNetParam.placement}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={140}>placement</Text>
                  <Text c="red">shows where the ad appeared. Includes: the desktop feed, mobile feed, right column of Facebook Messenger inbox,Messenger inbox, within the Instagram Feed or Instagram Stories</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="site_source_name" id="site_source_name" value="{{site_source_name}}" data-urlitem="fb_ads_net_param"
              mb={10}
              checked={fbAdsNetParam.site_source_name}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>site_source_name</Text>
                  <Text c="red">returns “fb” for Facebook, “ig” for Instagram, “msg” for Messenger, or “an” for the Audience Network</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="fbclid" id="fbclid" value="{{fbclid}}" data-urlitem="fb_ads_net_param"
              mb={10}
              checked={fbAdsNetParam.fbclid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>fbclid</Text>
                  <Text c="red"></Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="campaign_id" id="campaign_id" value="{{campaign.id}}" data-urlitem="fb_ads_net_param"
              mb={10}
              checked={fbAdsNetParam.campaign_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_id</Text>
                  <Text c="red">the ID of the campaign that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="adset_id" id="adset_id" value="{{adset.id}}" data-urlitem="fb_ads_net_param"
              mb={10}
              checked={fbAdsNetParam.adset_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adset_id</Text>
                  <Text c="red">the ID of the ad set that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="ad_id" id="ad_id" value="{{ad.id}}" data-urlitem="fb_ads_net_param"
              mb={10}
              checked={fbAdsNetParam.ad_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>ad_id</Text>
                  <Text c="red">the ID of the ad that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "google" ? "block" : "none"}}>
            <Group mb={10}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                  padding: "10px",
                  borderRadius: "4px"
                })}
              >
                <div style={{ display: "Flex" }}>
                  <Text size="sm" fz={14} fw={500} mr={5} w={80}>Please note:</Text>
                  <Text size="sm" fz={14} w={530}>Visiopt has the ability to automatically track and pass Google Data directly through </Text>
                </div>
                <Text size="sm" fz={14} w={610}>Google's API. When you do this, you do not have to manually pass data from UTM parameters.</Text>
              </Box>
            </Group>
            <Text size="sm" fw={500} mb={20}>Google Tag <IconHelp ref={googleTagIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(295)}/></Text>
            <Checkbox
              name="campaignid" id="campaignid" value="{campaignid}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.campaignid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaignid</Text>
                  <Text c="red">the ID of the campaign that served the ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="adgroupid" id="adgroupid" value="{adgroupid}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.adgroupid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adgroupid</Text>
                  <Text c="red">The ad group ID</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="creative" id="creative" value="{creative}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.creative}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>creative</Text>
                  <Text c="red">the ID of your ad creative</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Space h="md" />
            <Checkbox
              name="keyword" id="keyword" value="{keyword}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.keyword}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>keyword</Text>
                  <Text c="red">the keyword phrase that triggered the ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="placement" id="g_placement" value="{placement}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.placement}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>placement</Text>
                  <Text c="red">the site your ad was clicked on</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="target" id="target" value="{target}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.target}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>target</Text>
                  <Text c="red">the category of the placement</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="devicemodel" id="devicemodel" value="{devicemodel}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.devicemodel}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>devicemodel</Text>
                  <Text c="red">What model of phone or tablet the click came from (for example, "Apple+iPhone")</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="matchtype" id="matchtype" value="{matchtype}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.matchtype}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>matchtype</Text>
                  <Text c="red">will return “e” for exact, “p” for phrase, or “b” for broad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="network" id="network" value="{network}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.network}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>network</Text>
                  <Text c="red">returns “g” for Google search, “s” for search partner or “d” for display</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="g_device" id="g_device" value="{device}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.g_device}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>device</Text>
                  <Text c="red">returns “m” for mobile, “t” for tablet or “c” for computer</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="adposition" id="adposition" value="{adposition}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.adposition}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adposition</Text>
                  <Text c="red">The position on the page that your ad appeared in, with a value like "1t2"(this means page 1, top, position 2)</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="gclid" id="gclid" value="{gclid}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.gclid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>gclid</Text>
                  <Text c="red">The Google click identifier of a click that comes from your ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="gbraid" id="gbraid" value="{gbraid}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.gbraid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>gbraid</Text>
                  <Text c="red">App measurement (deep linked campaigns)</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="wbraid" id="wbraid" value="{wbraid}" data-urlitem="ggl_ads_net_param"
              mb={10}
              checked={gglAdsNetParam.wbraid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>wbraid</Text>
                  <Text c="red">Web measurement</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "microsoft" ? "block" : "none"}}>
            <Group mb={10}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                  padding: "10px",
                  borderRadius: "4px"
                })}
              >
                <div style={{ display: "Flex" }}>
                  <Text size="sm" fz={14} fw={500} mr={5} w={80}>Please note:</Text>
                  <Text size="sm" fz={14} w={530}>Visiopt has the ability to automatically track and pass Microsoft Data directly through </Text>
                </div>
                <Text size="sm" fz={14} w={610}>Microsoft's API. When you do this, you do not have to manually pass data from UTM parameters.</Text>
              </Box>
            </Group>
            <Text size="sm" fw={500} mb={20}>Microsoft Tag <IconHelp ref={microsoftTagIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(296)}/></Text>
            <Checkbox
              name="Campaign" id="m_Campaign" value="{Campaign}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.Campaign}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>Campaign</Text>
                  <Text c="red">the name of the campaign that triggered the ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="AdGroup" id="m_AdGroup" value="{AdGroup}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.AdGroup}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>AdGroup</Text>
                  <Text c="red">the name of the ad group that triggered the ad –</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="keyword" id="m_keyword" value="{keyword}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.keyword}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>keyword</Text>
                  <Text c="red">the keyword phrase that triggered the ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Space h="md" />
            <Checkbox
              name="CampaignID" id="CampaignID" value="{CampaignID}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.CampaignID}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>CampaignID</Text>
                  <Text c="red">the ID of the campaign that triggered the ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="AdGroupID" id="AdGroupID" value="{AdGroupID}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.AdGroupID}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>AdGroupID</Text>
                  <Text c="red">the ID of the ad group that triggered the ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="AdID" id="AdID" value="{AdID}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.AdID}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>AdID</Text>
                  <Text c="red">the numeric ID of your ad creative</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Space h="md" />
            <Checkbox
              name="QueryString" id="QueryString" value="{QueryString}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.QueryString}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>QueryString</Text>
                  <Text c="red">the exact search phrase entered by the user</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="m_Device" id="m_Device" value="{Device}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.m_Device}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>Device</Text>
                  <Text c="red">returns “m” for mobile, “t” for tablet or “c” for computer</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="MatchType" id="MatchType" value="{MatchType}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.MatchType}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>MatchType</Text>
                  <Text c="red">will return “e” for exact, “p” for phrase, or “b” for broad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="Network" id="Network" value="{Network}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.Network}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>Network</Text>
                  <Text c="red">returns “o” for Bing and AOL or “s” for syndicated ads</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="msclkid" id="msclkid" value="{msclkid}" data-urlitem="mic_ads_net_param"
              mb={10}
              checked={micAdsNetParam.msclkid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>msclkid</Text>
                  <Text c="red">The Microsoft click identifier of a click that comes from your ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "snapchat" ? "block" : "none"}}>
            <Text size="sm" fw={500} mb={20}>Snapchat Tag <IconHelp ref={snapchatTagIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(297)}/></Text>
            <Checkbox
              name="ad_id" id="s_ad_id" value="{{ad.id}}" data-urlitem="sna_ads_net_param"
              mb={10}
              checked={snaAdsNetParam.ad_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>ad_id</Text>
                  <Text c="red">the ID of the ad that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="adSet_id" id="s_adSet_id" value="{{adSet.id}}" data-urlitem="sna_ads_net_param"
              mb={10}
              checked={snaAdsNetParam.adSet_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adSet_id</Text>
                  <Text c="red">the ID of the ad set that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="campaign_id" id="s_campaign_id" value="{{campaign.id}}" data-urlitem="sna_ads_net_param"
              mb={10}
              checked={snaAdsNetParam.campaign_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_id</Text>
                  <Text c="red">the ID of the campaign that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="creative_name" id="creative_name" value="{{creative.name}}" data-urlitem="sna_ads_net_param"
              mb={10}
              checked={snaAdsNetParam.creative_name}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>creative_name</Text>
                  <Text c="red">the name of the ad that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="adSet_name" id="adSet_name" value="{{adSet.name}}" data-urlitem="sna_ads_net_param"
              mb={10}
              checked={snaAdsNetParam.adSet_name}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adSet_name</Text>
                  <Text c="red">name of the ad set that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="campaign_name" id="s_campaign_name" value="{{campaign.name}}" data-urlitem="sna_ads_net_param"
              mb={10}
              checked={snaAdsNetParam.campaign_name}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_name</Text>
                  <Text c="red">the name of the campaign that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="creative_headline" id="creative_headline" value="{{creative.headline}}" data-urlitem="sna_ads_net_param"
              mb={10}
              checked={snaAdsNetParam.creative_headline}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>creative_headline</Text>
                  <Text c="red">the headline of the ad that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "revcontent" ? "block" : "none"}}>
            <Text size="sm" fw={500} mb={20}>RevContent Tag <IconHelp ref={revContentIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(298)}/></Text>
            <Checkbox
              name="adv_targets" id="adv_targets" value="{adv_targets}" data-urlitem="rev_ads_net_param"
              mb={10}
              checked={revAdsNetParam.adv_targets}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adv_targets</Text>
                  <Text c="red">traffic type the creative was serving based on</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="boost_id" id="boost_id" value="{boost_id}" data-urlitem="rev_ads_net_param"
              mb={10}
              checked={revAdsNetParam.boost_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>boost_id</Text>
                  <Text c="red">numerical identifier of the campaign clicked on</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="content_id" id="content_id" value="{content_id}" data-urlitem="rev_ads_net_param"
              mb={10}
              checked={revAdsNetParam.content_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>content_id</Text>
                  <Text c="red">numerical identifier of the content clicked on</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="city" id="city" value="{city}" data-urlitem="rev_ads_net_param"
              mb={10}
              checked={revAdsNetParam.city}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>city</Text>
                  <Text c="red">city of the user clicking on the content</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="state" id="state" value="{state}" data-urlitem="rev_ads_net_param"
              mb={10}
              checked={revAdsNetParam.state}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>state</Text>
                  <Text c="red">state of the user clicking on the content</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="country" id="country" value="{country}" data-urlitem="rev_ads_net_param"
              mb={10}
              checked={revAdsNetParam.country}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>country</Text>
                  <Text c="red">country of the user clicking on the content</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="widget_id" id="widget_id" value="{widget_id}" data-urlitem="rev_ads_net_param"
              mb={10}
              checked={revAdsNetParam.widget_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>widget_id</Text>
                  <Text c="red">numerical identifier of the widget the click occurred on</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "taboola" ? "block" : "none"}}>
            <Text size="sm" fw={500} mb={20}>Taboola Tag <IconHelp ref={taboolaTagIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(299)}/></Text>
            <Checkbox
              name="tblci" id="tblci" value="{tblci}" data-urlitem="tub_ads_net_param"
              mb={10}
              checked={tubAdsNetParam.tblci}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>tblci</Text>
                  <Text c="red">The Taboola click identifier of a click that comes from your ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="campaign_id" id="tub_campaign_id" value="{campaign_id}" data-urlitem="tub_ads_net_param"
              mb={10}
              checked={tubAdsNetParam.campaign_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_id</Text>
                  <Text c="red">A unique Taboola campaign ID. This campaign ID can be found on the "Campaign Management" page in Backstage</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="campaign_name" id="tub_campaign_name" value="{campaign_name}" data-urlitem="tub_ads_net_param"
              mb={10}
              checked={tubAdsNetParam.campaign_name}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_name</Text>
                  <Text c="red">The campaign name, as defined in the "Campaign Management" page in Backstage</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="country" id="tub_country" value="{country}" data-urlitem="tub_ads_net_param"
              mb={10}
              checked={tubAdsNetParam.country}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>country</Text>
                  <Text c="red">country of the user clicking on the content</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="platform" id="platform" value="{platform}" data-urlitem="tub_ads_net_param"
              mb={10}
              checked={tubAdsNetParam.platform}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>platform</Text>
                  <Text c="red">The user platform on which your item was presented.This returns "Desktop," "Mobile," or "Tablet"</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="site" id="site" value="{site}" data-urlitem="tub_ads_net_param"
              mb={10}
              checked={tubAdsNetParam.site}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>site</Text>
                  <Text c="red">The user site on which your item was presented.</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "mgid" ? "block" : "none"}}>
            <Text size="sm" fw={500} mb={20}>MGID Tag <IconHelp ref={mgidIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(300)}/></Text>
            <Checkbox
              name="teaser_id" id="teaser_id" value="{teaser_id}" data-urlitem="mg_ads_net_param"
              mb={10}
              checked={mgAdsNetParam.teaser_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>teaser_id</Text>
                  <Text c="red">Returns the Teaser ID</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="widget_id" id="mg_widget_id" value="{widget_id}" data-urlitem="mg_ads_net_param"
              mb={10}
              checked={mgAdsNetParam.widget_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>widget_id</Text>
                  <Text c="red">Returns the site from which the user came</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="campaign_id" id="mg_campaign_id" value="{campaign_id}" data-urlitem="mg_ads_net_param"
              mb={10}
              checked={mgAdsNetParam.campaign_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_id</Text>
                  <Text c="red">Returns the Campaign ID in the MGID system</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="category_id" id="category_id" value="{category_id}" data-urlitem="mg_ads_net_param"
              mb={10}
              checked={mgAdsNetParam.category_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>category_id</Text>
                  <Text c="red">Returns the ID of the teaser category in the MGID system</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="geo_region" id="geo_region" value="{geo_region}" data-urlitem="mg_ads_net_param"
              mb={10}
              checked={mgAdsNetParam.geo_region}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>geo_region</Text>
                  <Text c="red">Returns the city or region of impressions selected in the campaign settings</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="click_id" id="click_id" value="{click_id}" data-urlitem="mg_ads_net_param"
              mb={10}
              checked={mgAdsNetParam.click_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>click_id</Text>
                  <Text c="red">Returns the unique ID of the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="click_price" id="click_price" value="{click_price}" data-urlitem="mg_ads_net_param"
              mb={10}
              checked={mgAdsNetParam.click_price}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>click_price</Text>
                  <Text c="red">Returns the price per click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "outbrain" ? "block" : "none"}}>
            <Text size="sm" fw={500} mb={20}>Outbrain Tag <IconHelp ref={outbrainIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(301)}/></Text>
            <Checkbox
              name="ad_title" id="ad_title" value="{ad_title}" data-urlitem="out_ads_net_param"
              mb={10}
              checked={outAdsNetParam.ad_title}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>ad_title</Text>
                  <Text c="red">Returns the headline of your ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="section_name" id="section_name" value="{section_name}" data-urlitem="out_ads_net_param"
              mb={10}
              checked={outAdsNetParam.section_name}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>section_name</Text>
                  <Text c="red">Returns the referrer's section name</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="publisher_name" id="publisher_name" value="{publisher_name}" data-urlitem="out_ads_net_param"
              mb={10}
              checked={outAdsNetParam.publisher_name}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>publisher_name</Text>
                  <Text c="red">Returns the referrer's publisher name</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="campaign_id" id="out_campaign_id" value="{campaign_id}" data-urlitem="out_ads_net_param"
              mb={10}
              checked={outAdsNetParam.campaign_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_id</Text>
                  <Text c="red">Returns the ID of the campaign</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="section_id" id="section_id" value="{section_id}" data-urlitem="out_ads_net_param"
              mb={10}
              checked={outAdsNetParam.section_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>section_id</Text>
                  <Text c="red">Returns the section ID of the referrer</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="publisher_id" id="publisher_id" value="{publisher_id}" data-urlitem="out_ads_net_param"s
              mb={10}
              checked={outAdsNetParam.publisher_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>publisher_id</Text>
                  <Text c="red">Returns the referrer's publisher ID</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="ob_click_id" id="ob_click_id" value="{ob_click_id}" data-urlitem="out_ads_net_param"
              mb={10}
              checked={outAdsNetParam.ob_click_id}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>ob_click_id</Text>
                  <Text c="red">Returns the unique click ID</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "tiktok" ? "block" : "none"}}>
            <Group mb={10}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                  padding: "10px",
                  borderRadius: "4px"
                })}
              >
                <div style={{ display: "Flex" }}>
                  <Text size="sm" fz={14} fw={500} mr={5} w={80}>Please note:</Text>
                  <Text size="sm" fz={14} w={530}>Visiopt has the ability to automatically track and pass Tiktok Data directly through</Text>
                </div>
                <Text size="sm" fz={14} w={610}>Tiktok's API. When you do this, you do not have to manually pass data from UTM parameters.</Text>
              </Box>
            </Group>
            <Text size="sm" fw={500} mb={20}>Tiktok Tag <IconHelp ref={tiktokTagIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(314)}/></Text>
            <Checkbox
              name="campaignid" id="t_campaignid" value="__CAMPAIGNID__" data-urlitem="tiktok_ads_net_param"
              mb={10}
              checked={tiktokAdsNetParam.campaignid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaignid</Text>
                  <Text c="red">the ID of the campaign that served the ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="adgroupid" id="t_adgroupid" value="__ADGROUPID__" data-urlitem="tiktok_ads_net_param"
              mb={10}
              checked={tiktokAdsNetParam.adgroupid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adgroupid</Text>
                  <Text c="red">The ad group ID</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="adid" id="t_adid" value="__ADID__" data-urlitem="tiktok_ads_net_param"
              mb={10}
              checked={tiktokAdsNetParam.adid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adid</Text>
                  <Text c="red">the ID of the ad that generated the click</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="ttclid" id="ttclid" value="_CLICKID_" data-urlitem="tiktok_ads_net_param"
              mb={10}
              checked={tiktokAdsNetParam.ttclid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={140}>ttclid</Text>
                  <Text c="red">The Tiktok click identifier of a click that comes from your ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "youtube" ? "block" : "none"}}>
            <Group mb={10}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                  padding: "10px",
                  borderRadius: "4px"
                })}
              >
                <div style={{ display: "Flex" }}>
                  <Text size="sm" fz={14} fw={500} mr={5} w={80}>Please note:</Text>
                  <Text size="sm" fz={14} w={530}>Visiopt has the ability to automatically track and pass Google Data directly through </Text>
                </div>
                <Text size="sm" fz={14} w={610}>Google's API. When you do this, you do not have to manually pass data from UTM parameters.</Text>
              </Box>
            </Group>
            <Text size="sm" fw={500} mb={20}>Youtube Tag <IconHelp ref={youtubeTagIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(318)}/></Text>
            <Checkbox
              name="campaignid" id="yt_campaignid" value="{campaignid}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.campaignid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaignid</Text>
                  <Text c="red">the ID of the campaign that served the ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="adgroupid" id="yt_adgroupid" value="{adgroupid}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.yt_adgroupid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adgroupid</Text>
                  <Text c="red">The ad group ID</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="creative" id="yt_creative" value="{creative}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.creative}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>creative</Text>
                  <Text c="red">the ID of your ad creative</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Space h="md" />
            <Checkbox
              name="keyword" id="yt_keyword" value="{keyword}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.keyword}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>keyword</Text>
                  <Text c="red">the keyword phrase that triggered the ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="placement" id="yt_placement" value="{placement}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.placement}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>placement</Text>
                  <Text c="red">the site your ad was clicked on</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="target" id="yt_target" value="{target}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.target}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>target</Text>
                  <Text c="red">the category of the placement</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="devicemodel" id="yt_devicemodel" value="{devicemodel}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.devicemodel}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>devicemodel</Text>
                  <Text c="red">What model of phone or tablet the click came from (for example, "Apple+iPhone")</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="matchtype" id="yt_matchtype" value="{matchtype}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.matchtype}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>matchtype</Text>
                  <Text c="red">will return “e” for exact, “p” for phrase, or “b” for broad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="network" id="yt_network" value="{network}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.network}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>network</Text>
                  <Text c="red">returns “g” for Google search, “s” for search partner or “d” for display</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="yt_device" id="yt_device" value="{device}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.yt_device}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>device</Text>
                  <Text c="red">returns “m” for mobile, “t” for tablet or “c” for computer</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="adposition" id="yt_adposition" value="{adposition}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.adposition}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adposition</Text>
                  <Text c="red">The position on the page that your ad appeared in, with a value like "1t2"(this means page 1, top, position 2)</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="gclid" id="yt_gclid" value="{gclid}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.gclid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>gclid</Text>
                  <Text c="red">The Google click identifier of a click that comes from your ad</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="yt_gbraid" id="yt_gbraid" value="{gbraid}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.yt_gbraid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>gbraid</Text>
                  <Text c="red">App measurement (deep linked campaigns)</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
            <Checkbox
              name="wbraid" id="yt_wbraid" value="{wbraid}" data-urlitem="yt_ads_net_param"
              mb={10}
              checked={ytAdsNetParam.wbraid}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>wbraid</Text>
                  <Text c="red">Web measurement</Text>
                </div>
              }
              onChange={(e) => handleChangeEvent(e)}
            />
          </div>
        </div>
        <Divider my="md" />
        <div>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} w={60} mt={-75}>Your URL:</Text>
            <Textarea
              name="your_url" id="your_url"
              //size="xl"
              autosize
              minRows={3}
              maxRows={20}
              w={700}    
              value={selectedPageUrl}   
              onChange={(e) => setSelectedPageUrl(e.target.value)}                 
            />
          </Group>
          <CopyButton timeout={5000} value={selectedPageUrl}>
            {({ copied, copy }) => (
              <Button color={copied ? 'teal' : 'blue'} onClick={copy} size="sm" w={120} ml={70}>
                {copied ? 'Copied URL' : ' Copy URL'}
              </Button>
            )}
          </CopyButton>
          <Text fz={14} mt={40}>To save the URL for future use, enter a name below:</Text>
          <Group spacing="xs" mb={20}>
            <TextInput name="urlname" id="urlname" w={170}
            value={urlBuilderName}
            onChange={(e) => setUrlBuilderName(e.target.value)}
            />            
            {!editURLBuilderMode && <Button size="sm" w={100} onClick={handleSaveUrlBuilder}>Save URL</Button>}
            {editURLBuilderMode && <Button size="sm" w={130} onClick={handleUpdateUrlBuilder}>Update URL</Button>}
            <Button size="sm" w={100} color='red' onClick={handleCloseBtn}>Close</Button>
          </Group>
        </div>
      </div>
    </>
  );
}