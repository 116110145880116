import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Container, Card, Group, Text, Button, Input, Grid, Pagination, Flex } from '@mantine/core';
import { IconHelp, IconSearch } from '@tabler/icons-react';
import { VisiLoader, ErrorModal, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import { TestHistoryList } from "../../components/TestHistory/TestHistoryList";
import TestsApi from "../../api/TestsApi";

export function TestHistory() {
	const navigate = useNavigate();
	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};

	useDocumentTitle("Visiopt - Test History");

	const [SystemUsageLoader, setSystemUsageLoader] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errorModelOpen, seterrorCloseModal] = useState(false);
	const [showErrorHeader, setShowErrorHeader] = useState(false);
	const [showMessageHeader, setShowMessageHeader] = useState(false);
	const [activePageNo, setActivePageNo] = useState(1);
	const [keyword, setKeyword] = useState('');
	const [testHistoryList, setTestHistoryList] = useState([]);
	const [totalPages, setTotalPages] = useState(10);
	const [totalPaginationNo, setTotalPaginationNo] = useState(1);
	const [selectedThumbnail, setSelectedThumbnail] = useState(null);
	const [selectedPageId, setSelectedPageId] = useState(null);

	const testHistoryRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
		if(selectedMemberWebsite !== ''){
	    setSystemUsageLoader(true);
	    seterrorCloseModal(false);
	    TestsApi.gettesthistorylist({
	      system_website_id: selectedMemberWebsite,
	      number:25,
	      page: activePageNo,
	      keyword: keyword
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          setSystemUsageLoader(false);
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	        if (data.data.test_history_list) {
	          setTestHistoryList(data.data.test_history_list);
	          if (data.data.active_page_no !== activePageNo) {
	            setActivePageNo(data.data.active_page_no);
	            setActivePageNo(1);
	          }
	          if (data.data.total_pages !== totalPages) {
	            setTotalPages(data.data.total_pages);
	          }
	          if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
	            setTotalPaginationNo(data.data.total_pagination_no);
	          }
	        } else{
	          console.log("No data received");
	          setTestHistoryList([]);
	        }
	      }else{
	        setErrorMessage(data.message);
	        seterrorCloseModal(true);
	      }
	      setSystemUsageLoader(false);
	    })
	    .catch(error => {
	      console.error("API Error:", error);
	      setSystemUsageLoader(false);
	    });
	  }
  }, [activePageNo, selectedMemberWebsite, keyword]);


  const handlePageChange = (newPage) => {
    setActivePageNo(newPage);
  };

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
    setActivePageNo(1);
  };

  const handleAddtoFunnel = async () => {
  	try {
      const APIResponse = await TestsApi.checktesthistorydata({
        tests_history_id : selectedThumbnail
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
      	navigate('/test-history-report', { state: { historyId: selectedThumbnail, pageId:selectedPageId} });
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
        setShowMessageHeader(false);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  }


	const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (testHistoryRef.current) {
      const { top, left, width } = testHistoryRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

	return (
	  <>
	  <VisiLoader VisiLoaderVisible={SystemUsageLoader}/>
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
	  {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
	  <Container size="xl" mb={10}>
	  	<Card shadow="sm" padding="sm" radius="md" withBorder>
	  		<Group position="apart" mb="xs">
	        <Text weight={500}>TEST HISTORY <IconHelp ref={testHistoryRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(120)}/></Text>
	      </Group>
			  <Grid>
	        <Grid.Col span={6}>
	        	  <Text fz={14} weight={500}>The following are tests that you have run in the past.</Text>
	        	  <Text fz={14} weight={500}>These tests have ended. This section allows you to go back and review past test results.</Text>
	        </Grid.Col>
	        <Grid.Col span={3} offset={3}>
          	<div style={{ display: 'flex'}}>
          		<Flex
			     	  	style={{marginLeft:"auto"}}
			     	    justify="flex-end"
			     	    align="center"
			     	    direction="row"
			     	    wrap="wrap"
			     	  >
				     	  <Input icon={<IconSearch size={16} />} placeholder="Search"
						     	maxLength={50}
						     	autoFocus
						     	onChange={handleKeywordChange}
				     	   />
			     	  </Flex>
          	</div>
	        </Grid.Col>
	      </Grid>
  	    <div style={{flex: "1 1 auto",overflowX: "hidden",marginTop:20}}>
		  	    {testHistoryList.some(item => Array.isArray(item) ? item.length > 0 : true) && (
		  	    <>
		  	    <TestHistoryList
		  	    	TestHistoryList={testHistoryList}
							SelectedThumbnail={selectedThumbnail}
							SelectedThumbnailChange={setSelectedThumbnail}
							SelectedPageIdChange={setSelectedPageId}
		  	    />
		  	    <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
		  	      <Group spacing={5} position="center" mb={20}>
		  	        <Pagination.First />
		  	        <Pagination.Previous />
		  	        <Pagination.Items />
		  	        <Pagination.Next />
		  	        <Pagination.Last />
		  	      </Group>
		  	    </Pagination.Root>
		  	    </>
		  	    )}
			  </div>
			  <div className="legend-holder">
				  <Grid>
		        <Grid.Col span={6} mt={15}>
		        	<Group spacing="xs" m={0}>
		        	  <span className="legend selected" />
		        	  <Text size="sm" ml={0} fz={12} className="legend-title">Selected</Text>
		        	</Group>
		        </Grid.Col>
		        <Grid.Col span={3} offset={3}>
	          	<div style={{ display: 'flex'}}>
	          		<Button type="button" onClick={() => handleAddtoFunnel()} style={{marginLeft:"auto"}}>Select</Button>
	          	</div>
		        </Grid.Col>
		      </Grid>
			  </div>
				{/*Error Model*/}
	      <ErrorModal errorModelOpen={errorModelOpen} errorMessage={errorMessage} showErrorHeader={showErrorHeader} showMessageHeader={showMessageHeader} onClose={handlerrorCloseModal} />
	    </Card>
	  </Container>
	  </>
	);
}