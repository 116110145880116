import { Group, Text, Box, Grid, ActionIcon, Flex, Container, Card, Modal, Checkbox, Button, Skeleton } from '@mantine/core';
import { IconHelp, IconSettings, IconPlayerPlay, IconPlayerPause, IconCaretUpFilled, IconCaretDownFilled } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { useNavigate } from "react-router-dom";
import InsightsApi from "../../api/InsightsApi";
import { SelectTooltip, ErrorModal } from "../../components/GeneralComponent/GeneralComponent";

export function Recordings() {
  useDocumentTitle("Visiopt - Insights");
  const navigate = useNavigate();

  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [recordingFunnelList, setRecordingFunnelList] = useState([]);
  const [isTrackPageModalOpen, setIsTrackPageModalOpen] = useState(false);
  const [trackPageList, setTrackPageList] = useState([]);
  const [selectedTrackFunnel, setSelectedTrackFunnel] = useState('');
  const recordingIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [sortKeyName, setSortKeyName] = useState('');
  const [sortingDirection, setSortingDirection] = useState('');
  const [sortedFunnelList, setSortedFunnelList] = useState([]);
  const [errorModelOpen, setErrorModelOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSkeleton, setShowSkeleton] = useState(false);

  const handleErrorModalClose = () => {
    setErrorModelOpen(false);
  };

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const handleTrackPageModalClose = () => {
    setIsTrackPageModalOpen(false);
  };

  const fetchRecordingFunnelList = () => {
    setShowSkeleton(true);
    InsightsApi.getrecordingfunnelslist({
      system_website_id: selectedMemberWebsite
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            setShowSkeleton(false);
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setRecordingFunnelList(data.data);
          setSortedFunnelList(data.data);
          setShowSkeleton(false);
        }else{
          setShowSkeleton(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setShowSkeleton(false);
      });
  };

  const handleRecordToggle = (pauseStatus:any, funnelId:any) => {
    if(pauseStatus === '0') {
      modals.openConfirmModal({
        title: 'Please confirm your action',
        children: (
          <Text size="sm">
            Are you sure you want to pause this Recording?
          </Text>
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onCancel: () => {console.log('Cancel')},
        onConfirm: () => handleUpdateRecordingStatus(pauseStatus, funnelId)
      });
    }else{
      handleUpdateRecordingStatus(pauseStatus, funnelId);
    }
  };

  const handleUpdateRecordingStatus = (pauseStatus:any, funnelId:any) => {
    const dataObject = {
      system_website_id: selectedMemberWebsite,
      funnel_id: funnelId,
      page_id: '0',
      pause_status: pauseStatus,
    }
    console.log(dataObject);
    InsightsApi.resumerecordings(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          if(pauseStatus === '1'){
            setSelectedTrackFunnel(funnelId);
            setTrackPageList(data.data);
            setIsTrackPageModalOpen(true);
          }
          fetchRecordingFunnelList();
        }else{
          setErrorModelOpen(true);
          setErrorMessage(data.message);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  };

  const handleRecordingSetting = (funnelId:any) => {
    navigate('/recording-setting', { state: { funnel_id: funnelId} });
  };

  const handleFunnelClick = (funnelId:any) => {
    navigate('/recordings-list', { state: { funnel_id: funnelId} });
  };

  const handleTrackPageChecked = (pageId:any) => {
    setTrackPageList((prev) => 
      prev.map((page) => 
        page.page_id === pageId 
          ? { ...page, page_checked: !page.page_checked }
          : page
      )
    );
  };

  const handleSaveTrackPage = () => {
    const selectedTrackPages = trackPageList.filter((page) => page.page_checked === true).map((page) => page.page_id);
    const dataObject = {
      system_website_id: selectedMemberWebsite,
      funnel_id: selectedTrackFunnel,
      track_pages: selectedTrackPages
    };
    InsightsApi.savetrackpage(dataObject)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setIsTrackPageModalOpen(false);
        }else{
          setErrorModelOpen(true);
          setErrorMessage(data.message);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  };

  useEffect(() => {
    if(selectedMemberWebsite !== '') {
      fetchRecordingFunnelList();
    }
  }, [selectedMemberWebsite]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (recordingIconRef.current) {
      const { top, left, width } = recordingIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  const handleSorting = (keyName: string) => {
    let newDirection = sortingDirection === 'desc' ? 'asc' : 'desc';
    setSortingDirection(newDirection);
    setSortKeyName(keyName);
    
    const sortedRecordingFunnelList = [...recordingFunnelList].sort((a, b) => {
      if (!keyName) return 0;

      const valueA = a[keyName]?.toString().toLowerCase() || '';
      const valueB = b[keyName]?.toString().toLowerCase() || '';

      if (valueA < valueB) return newDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return newDirection === 'asc' ? 1 : -1;
      return 0;
    });
    setSortedFunnelList(sortedRecordingFunnelList);
  };

  return (
    <>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      {/*START ERROR MODAL*/}
      {errorModelOpen && (
        <ErrorModal
          errorModelOpen={errorModelOpen}
          errorMessage={errorMessage}
          onClose={handleErrorModalClose}
        />
      )}
      {/*END ERROR MODAL*/}
      {/*START TRACK PAGE MODAL*/}
      <Modal.Root opened={isTrackPageModalOpen} onClose={handleTrackPageModalClose} size="25%">
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>TRACK THE FOLLOWING PAGES</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Text weight={500}>Check the pages you want to track</Text>
            <div style={{ marginTop: '40px' }}>
              {trackPageList && trackPageList.map((page) => (
                <div key={page.page_id} style={{ display: 'flex', marginTop: 10, gap: '8px' }}>
                  <Checkbox
                    size="md"
                    value={page.page_id}
                    label={page.custom_title}
                    style={{ fontWeight: 'bold' }}
                    checked={page.page_checked}
                    onChange={() => handleTrackPageChecked(page.page_id)}
                  />
                  <Text>{page.page_name}</Text>
                </div>
              ))}
            </div>
            <div style={{ marginTop: '20px', marginLeft: '80%' }}>
              <Button style={{ marginLeft: '5px' }} onClick={handleSaveTrackPage}>Save</Button>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      {/*END TRACK PAGE MODAL*/}
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      <Container size="xl">
        <Card shadow="sm" padding="sm" radius="md" withBorder>
          <Group position="apart" mb="xs">
            <Text weight={500}>RECORDINGS <IconHelp ref={recordingIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(256)}/></Text>
          </Group>
          <Box
            p={4}
            pl={20}
            mt={10}
            sx={(theme) => ({
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
              padding: "10px",
              borderRadius: theme.radius.md,
              fontWeight: "bold",
            })}
          >
            <Grid gutter="xs">
              <Grid.Col span={10}>
                <Text mt={3} weight={500} onClick={() => handleSorting('funnel_name')}>
                  <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {'Funnel Name'}
                    {sortKeyName === 'funnel_name' && (
                      sortingDirection === 'asc' ? <IconCaretUpFilled /> : <IconCaretDownFilled />
                    )}
                  </span>
                </Text>
              </Grid.Col>
            </Grid>
          </Box>
          {showSkeleton ? (   
            <Flex direction="column" gap={4}>  
              {Array.from({ length: 15 }).map((_, index) => (
                <Skeleton key={index} height={44} width={1246} mt={10} />
              ))}
            </Flex>
          ) : (
            sortedFunnelList.map((recording, recordingIndex) => (
              <Box
                key={recordingIndex}
                p={4}
                pl={20}
                mt={10}
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
                  padding: "10px",
                  borderRadius: theme.radius.md,
                  border: "0.0625rem solid transparent",
                  borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
                })}
              >
                <Grid gutter="xs">
                  <Grid.Col span={10}>
                    <Text mt={3} style={{ cursor: 'pointer' }} onClick={() => handleFunnelClick(recording.funnel_id)}>
                      {recording.funnel_name}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Flex
                      justify="flex-end"
                      align="center"
                      direction="row"
                      wrap="wrap"
                    >
                    <ActionIcon
                      variant="default"
                      size="lg"
                      mx={3}
                      title="Pause/Resume"
                      onClick={() => handleRecordToggle(recording.pause_status, recording.funnel_id)}
                    >
                      {recording.pause_status === '1' ? (
                        <IconPlayerPlay size="1.1rem" />
                      ) : (
                        <IconPlayerPause size="1.1rem" />
                      )}
                    </ActionIcon>
                    <ActionIcon
                      variant="default"
                      size="lg"
                      mx={3}
                      title="Settings"
                      onClick={() => handleRecordingSetting(recording.funnel_id)}
                    >
                      <IconSettings size="1.1rem" />
                    </ActionIcon>
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            ))
          )}
        </Card>
      </Container>
    </>
  );
}