import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Select, Button, Group } from '@mantine/core';
import { IconHelp, IconCheck } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { notifications } from '@mantine/notifications';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function Attribution({ selectedMemberWebsite, pageId, isAttributionModalOpen, onAttributionClose, setMarksalestime, TestOptimizeReport, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [attributionModalOptions, setAttributionModalOptions] = useState([]);
  const [selectedAttributionModal, setSelectedAttributionModal] = useState('');

  const attributionRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isAttributionModalOpen) {
      setSelectedAttributionModal('');
      if (!pageId) {
        navigate('/review-tests');
        return;
      }
      TestsApi.getattributionmodel({
        page_id:pageId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          const options = data.data.map(item => ({
            value: item.attribution_name,
            label: item.attribution_name
          }));
          setAttributionModalOptions(options);
          const selectedOptions = data.data.find(item => item.selected === "true");
          if (selectedOptions) {
            setSelectedAttributionModal(selectedOptions.attribution_name);
          }
        } else {
          errorMessage(data.message);
          showErrorHeader(true);
          errorModelOpen(true);
          onAttributionClose();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  }, [isAttributionModalOpen, pageId]);

  const handleSaveAttributionModel = (e) => {
  e.preventDefault();
  TestsApi.saveattributionmodel({
      page_id:pageId,
      attribution_name:selectedAttributionModal
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setMarksalestime(data.data.updated_time);
        TestOptimizeReport();
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        onAttributionClose();
      } else {
        errorMessage(data.message);
        errorModelOpen(true);
        showErrorHeader(true);
        onAttributionClose();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (attributionRef.current) {
      const { top, left, width } = attributionRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      <Modal.Root opened={isAttributionModalOpen} onClose={onAttributionClose}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>ATTRIBUTION MODEL <IconHelp ref={attributionRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(316)}/></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSaveAttributionModel}>
              <Select
                label="Attribution Model"
                value={selectedAttributionModal}
                onChange={(value) => setSelectedAttributionModal(value)}
                data={attributionModalOptions}
                searchable
                nothingFound="No options"
                maxDropdownHeight={200}
                size="sm"
                dropdownPosition="bottom"
                mb={10}
                withinPortal
              />
              <Group position="right">
                <Button type="submit" size="sm" w={80}>
                  Save
                </Button>
              </Group>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}