import { ServerAPI } from '../GeneralFunction';

export default {
	getmemberwebsitelist: () => {
		let url = "get_member_website_list";
		const requestOptions = ServerAPI(url, 'GET');
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getfiltertypelist: () => {
		let url = "get_filter_type_list";
		const requestOptions = ServerAPI(url, 'GET');
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gettimepicker: (data) => {
		let url = "get_time_picker";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getwebsitepages: (data) => {
		let url = "get_website_pages";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getdashboardactivetestdropdown: (data) => {
		let url = "get_dashboard_active_test_dropdown";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoal: (data) => {
		let url = "get_goal";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getpagethumb: (data) => {
		let url = "get_page_thumb";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getfunnellist: (data) => {
		let url = "get_funnel_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	// HeaderMenu
	getmembernotificationlist: (data) => {
		let url = "get_member_notification_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gethelpdetails: (data) => {
		let url = "get_help_details";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
};