import { ServerAPI } from '../GeneralFunction';

const CampaignReportAPI = {
	getreportlist: () => {
		let url = "get_report_list";
		const requestOptions = ServerAPI(url, 'GET');
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	deletereport: (data) => {
		let url = "delete_report";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaignlistbydomain: (data) => {
		let url = "get_campaign_list_by_domain";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaigngoaldropdownbycampaignid: (data) => {
		let url = "get_campaign_goal_dropdown_by_campaign_id";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaignreportdata: (data) => {
		let url = "get_campaign_report_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savetestvariationreport: (data) => {
		let url = "save_test_variation_report";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	campbehavioraldimensionsaddfilterseconddropdown: (data) => {
		let url = "camp_behavioral_dimensions_add_filter_second_dropdown";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaignbehavioraldimensions: (data) => {
		let url = "get_campaign_behavioral_dimensions";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savecampaignbehavioraldimensions: (data) => {
		let url = "save_campaign_behavioral_dimensions";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	resetfiltercampbehavioraldimensions: (data) => {
		let url = "reset_filter_camp_behavioral_dimensions";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaignconversionlogdetails: (data) => {
		let url = "get_campaign_conversion_log_details";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaigngoaldd: (data) => {
		let url = "get_campaign_goal_dd";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	campaignresetstats: (data) => {
		let url = "campaign_reset_stats";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaignnotedetails: (data) => {
		let url = "get_campaign_note_details";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savecampaignnote: (data) => {
		let url = "save_campaign_note";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcustomerjourneylist: (data) => {
		let url = "get_customer_journey_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaigngoaljourney: (data) => {
		let url = "get_campaign_goal_journey";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getadsetadsdata: (data) => {
		let url = "get_adset_ads_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcustomerjourneyviewdetail: (data) => {
		let url = "get_customer_journey_view_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savereportdata: (data) => {
		let url = "save_report_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	customnetworkheraricaldd: (data) => {
		let url = "custom_network_herarical_dd";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	sourcenetworkdd: (data) => {
		let url = "source_network_dd";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	trackingnetworkheraricaldd: (data) => {
		let url = "tracking_network_herarical_dd";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savetimereport: (data) => {
		let url = "save_time_report";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
    },
    getreportchartdata: (data) => {
        let url = "get_report_chart_data";
        const requestOptions = ServerAPI(url, 'POST', data);
        return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
    },
    getcampaigndropdown: (data) => {
        let url = "get_campaign_dropdown";
        const requestOptions = ServerAPI(url, 'POST', data);
        return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
    }
};

export default CampaignReportAPI;