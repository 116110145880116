import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Button, Group, Grid, Textarea, CopyButton, Divider, Card, Text, Anchor} from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import TestsApi from "../../api/TestsApi";
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function VisiSmartCode({ selectedMemberWebsite, pageId, isVisiSmartCodeModalOpen, onVisiSmartCodeClose, errorModelOpen, errorMessage, showErrorHeader, showMessageHeader }:any) {
  const navigate = useNavigate();
  const [visiSmartAdvancedOptions, setVisiSmartAdvancedOptions] = useState('Show');
  const [hideAdvancedOptions, setHideAdvancedOptions] = useState('');
  const [downloadCookiesUrl, setDownloadCookiesUrl] = useState('');
  const [testingCode, setTestingCode] = useState([]);

  const visismartRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (isVisiSmartCodeModalOpen) {
      if (!pageId) {
        navigate('/review-tests');
        return;
      }
      TestsApi.getvisismarttestingcode({
        system_website_id: selectedMemberWebsite,
        page_id:pageId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setTestingCode(data.data.testing_code);
          setDownloadCookiesUrl(data.data.download_cookies_url);
          setHideAdvancedOptions(data.data.hide_advanced_options);
          isVisiSmartCodeModalOpen();
        } else {
          errorMessage(data.message);
          errorModelOpen(true);
          showErrorHeader(true);
          onVisiSmartCodeClose();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  }, [isVisiSmartCodeModalOpen, pageId]);
  
  const handleAdvancedOptionsVisiSmart = (value:any) => {
    if(visiSmartAdvancedOptions === 'Show'){
      setVisiSmartAdvancedOptions('Hide');
    } else {
      setVisiSmartAdvancedOptions('Show');
    }
  };

  const hideAdvancedOptionsVisiSmartCode = [
    { id: 1, title:'Javascript',smartCode: "<script type='text/javascript'>var vtss = setInterval(function(){if (typeof _kx_tid != 'undefined') {console.log('_kx_tid: '+_kx_tid);/*Here will get the variation no in JS variable _kx_tid */clearInterval(vtss);}}, 50);</script>"},
    { id: 2, title:'Javascript',smartCode: "<script type='text/javascript'>function getVisiCookie(name) {var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');return v ? v[2] : null;}var v_kx_tid = getVisiCookie('_kx_tid');/*Here will get the variation no in JS variable v_kx_tid */console.log(v_kx_tid);</script>"},
    { id: 3, title:'PHP',smartCode: "<?php if (isset($_COOKIE) && isset($_COOKIE['_kx_tid'])) {$v_kx_tid = $_COOKIE['_kx_tid'];/*Here will get the variation no in PHP variable $v_kx_tid */}?>"},
    { id: 4, title:'Javascript',smartCode: "<script type='text/javascript'>function getVisiCookie2(name) {var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');return v ? v[2] : null;}var visiopt_test_name = getVisiCookie2('visiopt_test_name');/*Here will get the variation no in JS variable visiopt_test_name */console.log(visiopt_test_name);</script>"},
    { id: 5, title:'PHP',smartCode: "<?php if (isset($_COOKIE) && isset($_COOKIE['visiopt_test_name'])) {$visiopt_test_name = $_COOKIE['visiopt_test_name'];/*Here will get the Test name in PHP variable $visiopt_test_name */}?>"},
    { id: 6, title:'Javascript',smartCode: "<script type='text/javascript'>function getVisiCookie2(name) {var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');return v ? v[2] : null;}var v_kx_var_name = getVisiCookie2('_kx_var_name');/*Here will get the Variation name in JS variable v_kx_var_name */console.log(v_kx_var_name);</script>"},
    { id: 7, title:'PHP',smartCode: "<?php if (isset($_COOKIE) && isset($_COOKIE['_kx_var_name'])) {$v_kx_var_name = $_COOKIE['_kx_var_name'];/*Here will get the Variation name in PHP variable $v_kx_var_name */}?>"},
  ];

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = downloadCookiesUrl; 
    link.download = 'reset_cookies.php'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (visismartRef.current) {
      const { top, left, width } = visismartRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
    {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
    <Modal.Root opened={isVisiSmartCodeModalOpen} onClose={onVisiSmartCodeClose} size="80%">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>VISISMART CODE <IconHelp ref={visismartRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(188)}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          {testingCode.map((element,index) => (
            <Grid key={index} justify="space-around" align="flex-end">
              <Grid.Col span={11}>
                <Textarea
                  label={`Please add the following code into the page `+element.funnel_page_url+`: ( VisiSmart Code )`}
                  value={element.funnel_test_code_variant}
                  minRows={2}
                  maxRows={3}
                  maw={1000}
                  variant="filled"
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <CopyButton  timeout={2000} value={element.funnel_test_code_variant}>
                  {({ copied, copy }) => (
                    <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                      {copied ? 'Copied' : 'Copy'}
                    </Button>
                  )}
                </CopyButton>
              </Grid.Col>
            </Grid>
          ))}
          <Divider my="sm" />
          {hideAdvancedOptions === '' && (
            <Button type="submit" size="sm" color="orange" onClick={handleAdvancedOptionsVisiSmart}>
              {visiSmartAdvancedOptions} Advanced Options
            </Button>
          )}
          <Card style={{ display : visiSmartAdvancedOptions === 'Hide' ? "block": "none"}}>
            <Text>Code snippet for get variation #no on LP page:</Text>
            <Group>
            {hideAdvancedOptionsVisiSmartCode.map((advancedOption,index) => (
              <Grid key={index} justify="space-around" align="flex-end" w={340}>
                <Grid.Col span={9}>
                  <Textarea
                    label={advancedOption.title}
                    value={advancedOption.smartCode}
                    mt={10}
                    minRows={3}
                    maxRows={4}
                    maw={320}
                    variant="filled"
                    />
                </Grid.Col>
                <Grid.Col span={3}> 
                  <CopyButton  timeout={2000} value={advancedOption.smartCode}>
                    {({ copied, copy }) => (
                      <Button color={copied ? 'teal' : 'blue'} onClick={copy} mt={5}>
                        {copied ? 'Copied' : 'Copy'}
                      </Button>
                    )}
                  </CopyButton>
                </Grid.Col>
              </Grid>
            ))}
            </Group>
            <Divider my="sm" />
            <Text>Javascript</Text>
            <Text fw={500} size={12}>Note : <Anchor target="_blank" onClick={handleDownload}>Download reset_cookies.php </Anchor> File and upload in your server. Add below JavaScript before VisiSmart Code and replace CHANGE YOUR URL where you uploaded reset_cookies.php</Text>
            <Grid justify="space-around" align="flex-end" gutter="xs">
              <Grid.Col span={6}>
                <Textarea
                  value="<script>var visi_cookie_track_url = 'CHANGE YOUR URL/reset_cookies.php';</script>"
                  mt={10}
                  minRows={1}
                  variant="filled"
                  />
              </Grid.Col>
              <Grid.Col span={1}>
                <CopyButton  timeout={2000} value="<script>var visi_cookie_track_url = 'CHANGE YOUR URL/reset_cookies.php';</script>">
                  {({ copied, copy }) => (
                    <Button color={copied ? 'teal' : 'blue'} onClick={copy} mt={5} w={82}>
                      {copied ? 'Copied' : 'Copy'}
                    </Button>
                  )}
                </CopyButton>
              </Grid.Col>
              <Grid.Col span={4}>
                <Button component="a" onClick={handleDownload} target="_blank" >
                  Download PHP File
                </Button>
              </Grid.Col>
            </Grid>
          </Card>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    </>
  );
}