import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from '@mantine/core';
import TestsApi from "../../api/TestsApi";

export function AddToFunnel({
    SelectedThumbnail,
    SelectedTaguchiId,
    SelectedMemberWebsite,
    SelectedFunnelId,
    SelectedTestId,
    ErrorMessage,
    ErrorCloseModal,
    ShowMessageHeader
  }:any) {

  const navigate = useNavigate();

  const handleAddtoFunnel = async () => {
    try {
      const APIResponse = await TestsApi.checkpageinfunnelvalidation({
        page_id: SelectedThumbnail,
        taguchi_test_id: SelectedTaguchiId,
        system_website_id : SelectedMemberWebsite
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        navigate("/reviewing-test", { state: { pageId: SelectedThumbnail, taguchiTestId: SelectedTaguchiId, funnelId:SelectedFunnelId, testsId:SelectedTestId} });
      }else{
        ErrorMessage(APIResultJSON.message);
        ErrorCloseModal(true);
        ShowMessageHeader(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  return (
    <>
      <Button type="button" style={{ marginLeft: "auto" }} onClick={() => handleAddtoFunnel()}>
        Select
      </Button>
    </>
  );
}