import { Group, Text, Button, Box, Grid, ActionIcon, Flex, Container, Card, Skeleton } from '@mantine/core';
import { IconHelp, IconTrash, IconPencil, IconPlayerPlay, IconPlayerPause, IconCaretUpFilled, IconCaretDownFilled } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { useNavigate } from "react-router-dom";
import InsightsApi from "../../api/InsightsApi";
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function ManageScrollmaps() {
  useDocumentTitle("Visiopt - Insights");
  const navigate = useNavigate();

  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [scrollMapList, setScrollMapList] = useState([]);
  const manageScrollIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [sortedScrollmapList, setSortedScrollmapList] = useState([]);
  const [sortKeyName, setSortKeyName] = useState('');
  const [sortingDirection, setSortingDirection] = useState('');
  const [showSkeleton, setShowSkeleton] = useState(false);

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const fetchScrollmapList = () => {
    setShowSkeleton(true);
    InsightsApi.getscrollmaplist({
      system_website_id: selectedMemberWebsite
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            setShowSkeleton(false);
          }
        }
      })
      .then(data => {
        if(data.status === 1) {
          setScrollMapList(data.data);
          setSortedScrollmapList(data.data);
          setShowSkeleton(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setShowSkeleton(false);
      });
  }

  const handleRecordToggle = (pauseStatus:any, scrollmapId:any) => {
    if(pauseStatus === '0') {
      modals.openConfirmModal({
        title: 'Please confirm your action',
        children: (
          <Text size="sm">
            Are you sure you want to pause this Scroll Map?
          </Text>
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onCancel: () => {console.log('Cancel')},
        onConfirm: () => handleUpdateScrollmapRecord(scrollmapId)
      });
    }else{
      handleUpdateScrollmapRecord(scrollmapId);
    }
  };

  const handleUpdateScrollmapRecord = (scrollmapId:any) => {
    InsightsApi.resumescrollmap({
      scrollmap_id: scrollmapId
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          fetchScrollmapList();
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
  };

  const updateReport = (scroll_map_id:any) => {
    navigate("/create-scrollmap", { state: { scroll_map_id: scroll_map_id} });
  };

  const deleteScrollmapConfirm = (deleteScrollmapId:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to delete this Scroll Map?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {console.log('Cancel')},
    onConfirm: () => handleDeleteScrollMap(deleteScrollmapId)
  });

  const handleDeleteScrollMap = (deleteScrollmapId:any) => {
    if(deleteScrollmapId !== '') {
      InsightsApi.deletescrollmap({
        scrollmap_id: deleteScrollmapId
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          if(data.status === 1){
            fetchScrollmapList();
          }
        })
        .catch(error => {
          console.error("API Error:", error);
        });
    }
  };

  const handleScrollMapClick = (scrollmapId:any) => {
    navigate('/scrollmap-view', { state: { scroll_map_id: scrollmapId} });
  };

  useEffect(() => {
    if(selectedMemberWebsite !== '') {
      fetchScrollmapList();
    }
  }, [selectedMemberWebsite]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (manageScrollIconRef.current) {
      const { top, left, width } = manageScrollIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  const handleSorting = (keyName: string) => {
    let newDirection = sortingDirection === 'desc' ? 'asc' : 'desc';
    setSortingDirection(newDirection);
    setSortKeyName(keyName);
    
    const sortedScrollmapsList = [...scrollMapList].sort((a, b) => {
      if (!keyName) return 0;

      let valueA = a[keyName];
      let valueB = b[keyName];
      if (keyName === 'create_at') {
        valueA = new Date(a[keyName]);
        valueB = new Date(b[keyName]);
      } else {
        valueA = a[keyName]?.toString().toLowerCase() || '';
        valueB = b[keyName]?.toString().toLowerCase() || '';
      }

      if (valueA < valueB) return newDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return newDirection === 'asc' ? 1 : -1;
      return 0;
    });
    setSortedScrollmapList(sortedScrollmapsList);
  };

	return (
		<>
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      <Container size="xl">
        <Card shadow="sm" padding="sm" radius="md" withBorder>
          <Group position="apart" mb="xs">
            <Text weight={500}>SCROLL MAPS <IconHelp ref={manageScrollIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(253)}/></Text>
          </Group>
          <Button type="button">
            <Link to="/create-scrollmap" style={{color:"white",textDecoration: "none"}}>
              Create a New Scroll Map
            </Link>
          </Button>
          <Box
            p={4}
            pl={20}
            mt={10}
            sx={(theme) => ({
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
              padding: "10px",
              borderRadius: theme.radius.md,
              fontWeight: "bold",
            })}
          >
            <Grid gutter="xs">
              <Grid.Col span={2}>
                <Text mt={3} weight={500} onClick={() => handleSorting('scrollmap_name')}>
                  <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {'Scroll Map Name'}
                    {sortKeyName === 'scrollmap_name' && (
                      sortingDirection === 'asc' ? <IconCaretUpFilled /> : <IconCaretDownFilled />
                    )}
                  </span>
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text mt={3} weight={500} onClick={() => handleSorting('funnel_name')}>
                  <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {'Funnel Name'}
                    {sortKeyName === 'funnel_name' && (
                      sortingDirection === 'asc' ? <IconCaretUpFilled /> : <IconCaretDownFilled />
                    )}
                  </span>
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text mt={3} weight={500} onClick={() => handleSorting('page_url')}>
                  <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {'Page URL'}
                    {sortKeyName === 'page_url' && (
                      sortingDirection === 'asc' ? <IconCaretUpFilled /> : <IconCaretDownFilled />
                    )}
                  </span>
                </Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Text mt={3} weight={500} onClick={() => handleSorting('create_at')}>
                  <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                    {'Date Created'}
                    {sortKeyName === 'create_at' && (
                      sortingDirection === 'asc' ? <IconCaretUpFilled /> : <IconCaretDownFilled />
                    )}
                  </span>
                </Text>
              </Grid.Col>
            </Grid>
          </Box>
          {showSkeleton ? (   
            <Flex direction="column" gap={4}>  
              {Array.from({ length: 15 }).map((_, index) => (
                <Skeleton key={index} height={44} width={1246} mt={10} />
              ))}
            </Flex>
          ) : (
            sortedScrollmapList.map((scrollMap, scrollMapIndex) => (
            <Box
              key={scrollMapIndex}
              p={4}
              pl={20}
              mt={10}
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
                padding: "10px",
                borderRadius: theme.radius.md,
                border: "0.0625rem solid transparent",
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
              })}
            >
              <Grid gutter="xs">
                <Grid.Col span={2}>
                  <Text
                    mt={3}
                    onClick={() => handleScrollMapClick(scrollMap.scrollmap_id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {scrollMap.scrollmap_name}
                  </Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text mt={3} >
                    {scrollMap.funnel_name}
                  </Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text mt={3} 
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {scrollMap.page_url}
                  </Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text mt={3} >
                    {scrollMap.create_at}
                  </Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    title="Pause/Resume"
                    onClick={() => handleRecordToggle(scrollMap.pause_status, scrollMap.scrollmap_id)}
                  >
                    {scrollMap.pause_status === '1' ? (
                      <IconPlayerPlay size="1.1rem" />
                    ) : (
                      <IconPlayerPause size="1.1rem" />
                    )}
                  </ActionIcon>
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    title="Edit"
                    onClick={() => updateReport(scrollMap.scrollmap_id)}
                  >
                    <IconPencil size="1.1rem" />
                  </ActionIcon>
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    title="Delete"
                    onClick={() => deleteScrollmapConfirm(scrollMap.scrollmap_id)}
                  >
                    <IconTrash size="1.1rem" />
                  </ActionIcon>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Box>
          ))
          )}
        </Card>
      </Container>
		</>
	);
}