import React, { useState } from 'react';
import { Text, Table } from '@mantine/core';

export function TestHistoryList({
  TestHistoryList,
  SelectedThumbnail,
  SelectedThumbnailChange,
  SelectedPageIdChange,
  }:any) {

  const [isThumbnailSelected, setIsThumbnailSelected] = useState(false);

  const handleThumbnailClick = (historyId,pageId) => {
    if (isThumbnailSelected && SelectedThumbnail === historyId) {
      SelectedThumbnailChange(null);
      SelectedPageIdChange(null)
      setIsThumbnailSelected(false);
    } else {
      SelectedThumbnailChange(historyId);
      SelectedPageIdChange(pageId)
      setIsThumbnailSelected(true);
    }
  };

  return (
    <>
      <Table striped highlightOnHover withBorder withColumnBorders mb={10}>
        <thead>
          <tr>
            <th>Preview</th>
            <th>Test name</th>
            <th>URL</th>
            <th>Page Title</th>
            <th>Test Type</th>
            <th>Date Started</th>
            <th>Date Ended</th>
          </tr>
        </thead>
        <tbody>
          {TestHistoryList.map((item) => (
            <tr key={item.history_id} 
                style={{ backgroundColor: (item.selected || SelectedThumbnail === item.history_id) ? '#00b975' : '',
                        color: (item.selected || SelectedThumbnail === item.history_id) ? 'white' : 'inherit',
                         transition: 'background-color 0.1s' 
                      }} 
                onClick={item.selected ? undefined : () => handleThumbnailClick(item.history_id,item.page_id)}
            >
              {item.preview_btn !== 'hide' ?(
                <td><a href={item.preview_url} target="_blank" rel="noreferrer" style={{color: (item.selected || SelectedThumbnail === item.history_id) ? 'white' : ''}}>Preview</a></td>
              ) : (
                <td></td>
              )}
              <td><Text>{item.test_name}</Text></td>
              <td><Text><a href={item.page_url} target="_blank" rel="noreferrer" style={{color: 'inherit',textDecoration: 'none'}}>{item.page_url}</a></Text></td>
              <td><Text>{item.page_title}</Text></td>
              <td><Text>{item.taguchi_name}</Text></td>
              <td><Text>{item.test_date}</Text></td>
              <td><Text>{item.test_ended}</Text></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}