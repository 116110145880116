import React, { useState, useRef } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Container, Card, Text, Button, Group, Flex, Grid } from '@mantine/core';
import { IconHelp } from "@tabler/icons-react";
import classes from './DashBoard.module.css';
import { Link } from "react-router-dom";
import PermissionWrapper from '../../PermissionWrapper';
import { SystemUsage } from '../../components/DashBoard/SystemUsage';
import { Sources } from '../../components/DashBoard/Sources';
import { ActiveTests } from '../../components/DashBoard/ActiveTests';
import { ActiveCampaigns } from '../../components/DashBoard/ActiveCampaigns';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function DashBoard() {

  const quickIconRef = useRef(null);
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [selectedActiveTestMemberWebsite, setSelectedActiveTestMemberWebsite] = useState('');
  const [selectedGoalMemberWebsite, setSelectedGoalMemberWebsite] = useState('');
  const [selectedBoxPageThumbMemberWebsite, setSelectedBoxPageThumbMemberWebsite] = useState('');
  const [selectedPieChartMemberWebsite, setSelectedPieChartMemberWebsite] = useState('');
  const [ActiveCampaignsLoader, setActiveCampaignsLoader] = useState(false);
  const [activeTests, setActiveTests] = useState([]);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});
 
  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
    setSelectedPieChartMemberWebsite(Value);
    setSelectedActiveTestMemberWebsite(Value);
    setSelectedGoalMemberWebsite(Value);
    setSelectedBoxPageThumbMemberWebsite(Value);
    setActiveTests(Value);
  };


  useDocumentTitle("Visiopt - DashBoard");

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (quickIconRef.current) {
      const { top, left, width } = quickIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: { top: top + scrollOffset, left: left + width + 10 },
      });
    }
  };

  return (
    <>
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      {showHelpModal && Object.keys(tooltipPosition).map((key) => (
        <SelectTooltip
          isVisible={showHelpModal}
          setIsVisible={setShowHelpModal}
          id={helpTargetId}
          tooltipPosition={tooltipPosition[key]}
        />
      ))}
      <Container size="xl">
        <PermissionWrapper permission="operational_access">
          <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
            <Card.Section>
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>QUICK LINKS <IconHelp ref={quickIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(146)}/></Text>
            </Group>
            <Flex
              direction={{ base: 'column', sm: 'row' }}
              gap={{ base: 'sm', sm: 'lg' }}
              justify={{ sm: 'center' }}
            >
              <Link to="/funnels"><Button>Create a New Funnel</Button></Link>
              <Link to="/create-test"><Button>Create a New Test</Button></Link>
              <Link to="/manage-campaigns"><Button>Create a New Campaign</Button></Link>
              <Link to="/landing-pages"><Button>Create a New Landing Page</Button></Link>
            </Flex>
          </Card>
        </PermissionWrapper>
        <Grid>
          <SystemUsage
            selectedMemberWebsite={selectedMemberWebsite}
          />
          <Sources
            selectedPieChartMemberWebsite={selectedPieChartMemberWebsite}
          />
        </Grid>
        <>
          <ActiveTests
            selectedMemberWebsite={selectedMemberWebsite}
            selectedActiveTestMemberWebsite={selectedActiveTestMemberWebsite}
            activeTests={activeTests}
            setActiveTests={setActiveTests}
            selectedGoalMemberWebsite={selectedGoalMemberWebsite}
            selectedBoxPageThumbMemberWebsite={selectedBoxPageThumbMemberWebsite}
          />
        </>
        <ActiveCampaigns
          selectedMemberWebsite={selectedMemberWebsite}
          selectedActiveTestMemberWebsite={selectedActiveTestMemberWebsite}
          activeTests={activeTests}
        />
      </Container>
    </>
  );
}