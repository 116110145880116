import { Group, Text, Button, Box, Grid, ActionIcon, Flex, Container, Card, Modal, Input, Skeleton } from '@mantine/core';
import { IconHelp, IconTrash, IconPencil, IconCheck, IconX } from '@tabler/icons-react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import CampaignApi from "../../api/CampaignApi";

export function ManageCampaigns() {
  const navigate = useNavigate();
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [deleteCampaignId, setDeleteCampaignId] = useState('');
  const [deleteValue, setDeleteValue] = useState<string>('');
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [error, setError] = useState('');

  const campaignIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  useDocumentTitle("Visiopt - Manage Campaigns");

  const updateCampaign = (campaign_id:any) => {
    navigate("/create-campaign", { state: { campaign_id: campaign_id} });
  }

  const deleteCampaignConfirm = (deleteCampaignId:any) => {
    setDeleteModalIsOpen(true);
    setDeleteCampaignId(deleteCampaignId);
  };

  /* DELETE CAMPAIGN*/
  const handleDeleteCampaign = (deleteCampaignId:any) => {
    if (deleteValue.trim() === 'DELETE') {
      CampaignApi.deletecampaign({
        c_id : deleteCampaignId,
        system_website_id : selectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setDeleteModalIsOpen(false);
          setCampaignList(campaignList.filter(item => item.campaign_id !== deleteCampaignId));
          notifications.show({
            color: 'teal',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
          setDeleteValue('');
          setError('');
        } else {
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    } else {
      setError('Word does not match.');
    }
  };
/* END DELETE CAMPAIGN*/
  
  const [campaignLoader, setCampaignLoader] = useState(false);
  const [campaignList, setCampaignList] = useState([]);

  useEffect(() => {
    if(selectedMemberWebsite !== ""){
      setCampaignLoader(true);
      CampaignApi.getcampaignlist({
        system_website_id : selectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setCampaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1 && data.data && data.data.length > 0){
          setCampaignList(data.data);
        }else{
          setCampaignList([]);
        }
        setCampaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        setCampaignLoader(false);
      });
    }
  }, [selectedMemberWebsite]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (campaignIconRef.current) {
      const { top, left, width } = campaignIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

	return (
		<>
    <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
    {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
    <Container size="xl">
      <Card shadow="sm" padding="sm" radius="md" withBorder>
  			<Group position="apart" mb="xs">
          <Text weight={500}>MANAGE YOUR CAMPAIGNS <IconHelp ref={campaignIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(248)}/></Text>
        </Group>
        <Button type="button">
          <Link to="/create-campaign" style={{color:"white",textDecoration: "none"}}>
            Create a New Campaign
          </Link>
        </Button>
        {campaignLoader ? (
          <Flex direction="column" gap="sm" mt={10}>
            {Array.from({ length: 12 }).map((_, index) => (
              <Skeleton key={index} height={44} width={1262} />
            ))}
          </Flex>
        ) : (
          campaignList.map((Campaign, index) =>
            <Box
              key={index}
              p={4}
              pl={20}
              mt={10}
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
                padding: "10px",
                borderRadius: theme.radius.md,
                border: "0.0625rem solid transparent",
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
              })}
            >
              <Grid gutter="xs">
                <Grid.Col span={6}>
                  <Text mt={3}>
                    {Campaign.campaign_name}
                  </Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    title="Edit"
                    onClick={() => updateCampaign(Campaign.campaign_id)}
                  >
                    <IconPencil size="1.1rem" />
                  </ActionIcon>
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    title="Delete"
                    onClick={() => deleteCampaignConfirm(Campaign.campaign_id)}
                  >
                    <IconTrash size="1.1rem" />
                  </ActionIcon>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Box>
          )
        )}
      </Card>
      {/*Delete Modal*/}
      <Modal
        opened={deleteModalIsOpen}
        onClose={() => {
          setDeleteModalIsOpen(false);
          setDeleteValue('');
          setError('');
        }}
        title="Delete"
      >
        <Text size="sm" style={{ userSelect: 'none' }}>
          Are you sure you want to delete this campaign?<br/>
          If you want to proceed, please enter the word <b>DELETE</b> below:
        </Text>
        <Input
          mt={10}
          mb={10}
          value={deleteValue}
          onChange={(e) => {
            const value = e.currentTarget.value;
            setDeleteValue(value);
            if (value.trim() === 'DELETE') {
              setError('');
            }
          }}
        />
        {error && (
          <Text color="red" size="sm" mt={10}>
            {error}
          </Text>
        )}
        <Group position="right">
          <Button onClick={() => handleDeleteCampaign(deleteCampaignId)}>OK</Button>
        </Group>
      </Modal>
    </Container>
		</>
	);
}