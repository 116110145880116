import { Group, Text, Button, Box, Grid, Flex, ActionIcon, Modal, Input, Radio, Switch, Table, PasswordInput, Pagination, Skeleton } from '@mantine/core';
import { IconHelp, IconPencil, IconTrash, IconCheck, IconX } from '@tabler/icons-react';
import React, { useState, useEffect, useRef } from 'react';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';
import SettingsApi from "../../../api/SettingsApi";
import { SelectTooltip } from "../../GeneralComponent/GeneralComponent";

export function AccessManagement() {

  interface ActivityItem {
    date: string;
    action: string;
  }

  interface UserActivityDataType {
    total_pages: number;
    active_page_no: number;
    total_pagination_no: number[];
    activity: ActivityItem[];
  }
  const navigate = useNavigate();
  const [AddUserOpened, setAddUser] = useState(false);
  const [activeUserActivity, setActiveUserActivity] = useState('');
  const [activeUserData, setActiveUserData] = useState('User-Data');
  const [showAddButton, setShowAddButton] = useState(false);
  const [checkAddUser, setCheckAddUser] = useState(false);
  const [editUserId, setEditUserId] = useState('');

  const [UsernameValue, setUsernameValue] = useState('');
  const [PasswordValue, setPasswordValue] = useState('');
  const [RetypePasswordValue, setRetypePasswordValue] = useState('');

  const [adminChecked, setadminChecked] = useState(false);
  const [editorChecked, seteditorChecked] = useState(false);
  const [viewerChecked, setViewerChecked] = useState(false);
  const [customChecked, setCustomChecked] = useState(false);

  const [LandingPageTabchecked, setLandingPageTabchecked] = useState(false);
  const [CampaignTabchecked, setCampaignTabchecked] = useState(false);
  const [ABTestchecked, setABTestchecked] = useState(false);
  const [ModernABTestchecked, setModernABTestchecked] = useState(false);
  const [SingleVariableTestchecked, setSingleVariableTestchecked] = useState(false);
  const [MultiVariableTestchecked, setMultiVariableTestchecked] = useState(false);
  const [BehavioralDimensionschecked, setdimensionschecked] = useState(false);
  const [HeatMapschecked, setHeatMapschecked] = useState(false);
  const [ScrollMapschecked, setScrollMapschecked] = useState(false);
  const [Recordingschecked, setRecordingschecked] = useState(false);
  const [SettingsGAIntegrationchecked, setSettingsGAIntegrationchecked] = useState(false);
  const [SettingsEmailIntegrationchecked, setSettingsEmailIntegrationchecked] = useState(false);
  const [SettingsNotificationSettingschecked, setSettingsNotificationSettingschecked] = useState(false);
  const [SettingsURLBuilderCustomTagschecked, setSettingsURLBuilderCustomTagschecked] = useState(false);
  const [SettingsPostbackschecked, setSettingsPostbackschecked] = useState(false);
  const [SettingsPaymentsProcessingCartschecked, setSettingsPaymentsProcessingCartschecked] = useState(false);
  const [SettingsAccessManagementchecked, setSettingsAccessManagementchecked] = useState(false);
  const [visioptApi, setVisioptApi] = useState(false);

  const [UserData, setUserData] = useState([]);  
  const [activePage, setActivePage] = useState(1);
  const [memberId, setMemberId] = useState('');
  const [UserActivityData, setUserActivityData] = useState<UserActivityDataType>({
    total_pages: 0,
    active_page_no: 1,
    total_pagination_no: [],
    activity: [],
  });

  const accessIconref = useRef(null);
  const addAccessIconRef = useRef(null);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(false);

  const fetchAccessManagementList = () => {
    setShowSkeleton(true);
    SettingsApi.getaccessmanagementlist()
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
            setShowSkeleton(false);
          }
        }
      })
      .then(data => {
        if(data.status === 1){        
          setUserData(data.data);
          setShowSkeleton(false);
        }
      })
      .catch(error => {
        console.log(error);
        setShowSkeleton(false);
      });
  };

  const showUserActivity = (member_id:any, pageNo) => {
    SettingsApi.getrecentactivitylist({
        member_user_id: member_id,
        page_no: pageNo
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        setActiveUserData('');
        setActiveUserActivity('User-Activity');
        setUserActivityData(data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const totalActivityPages = UserActivityData.total_pages;
  const handlePageChange = (page) => {
    setActivePage(page);
    showUserActivity(memberId, page);
  }

  const handleBackBtn = () => {
    setActiveUserData('User-Data');
    setActiveUserActivity('');
  }

  const clearValues = () => {
    setEditUserId('');
    setadminChecked(false);
    setCustomChecked(false);
    setViewerChecked(false);
    seteditorChecked(false);
    setLandingPageTabchecked(false);
    setCampaignTabchecked(false);
    setABTestchecked(false);
    setModernABTestchecked(false);
    setSingleVariableTestchecked(false);
    setMultiVariableTestchecked(false);
    setdimensionschecked(false);
    setHeatMapschecked(false);
    setScrollMapschecked(false);
    setRecordingschecked(false);
    setSettingsGAIntegrationchecked(false);
    setSettingsEmailIntegrationchecked(false);
    setSettingsNotificationSettingschecked(false);
    setSettingsURLBuilderCustomTagschecked(false);
    setSettingsPostbackschecked(false);
    setSettingsPaymentsProcessingCartschecked(false);
    setSettingsAccessManagementchecked(false);
    setVisioptApi(false);
  }

  const handlePreviewClick = (member_id:any) => {
    setMemberId(member_id);
    showUserActivity(member_id, activePage);
  }

  const closeUserModal = () => {
    setAddUser(false);
    setEditUserId('');
    setUsernameValue('');
    setPasswordValue('');
    setRetypePasswordValue('');
    clearValues();
  }

  const updateUser = (member_id:any) => {
    SettingsApi.getsingleuser({
        member_user_id: member_id
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        handleEditFormData(data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const  handleEditFormData = (data) => {
    setEditUserId(data.member_user_id);
    setUsernameValue(data.member_username);
    setPasswordValue(data.member_password);
    setRetypePasswordValue(data.member_password);
    if(data.member_role === 'Admin'){
      setadminChecked(true);
    }else if(data.member_role === 'Custom'){
      setCustomChecked(true);
    }else if(data.member_role === 'Viewer'){
      setViewerChecked(true);
    }else if(data.member_role === 'Editor'){
      seteditorChecked(true);
    }
    if(data.member_role === 'Custom'){
      if(data.langing_page_tab === '1'){
        setLandingPageTabchecked(true);
      }
      if(data.campaign_tab === '1'){
        setCampaignTabchecked(true);
      }
      if(data.ab_test === '1'){
        setABTestchecked(true);
      }
      if(data.modern_ab_test === '1'){
        setModernABTestchecked(true);
      }
      if(data.single_variable_test === '1'){
        setSingleVariableTestchecked(true);
      }
      if(data.multi_variable_test === '1'){
        setMultiVariableTestchecked(true);
      }
      if(data.behavioral_dimensions === '1'){
        setdimensionschecked(true);
      }
      if(data.heat_maps === '1'){
        setHeatMapschecked(true);
      }
      if(data.scroll_maps === '1'){
        setScrollMapschecked(true);
      }
      if(data.recordings === '1'){
        setRecordingschecked(true);
      }
      if(data.ga_integration === '1'){
        setSettingsGAIntegrationchecked(true);
      }
      if(data.email_integration === '1'){
        setSettingsEmailIntegrationchecked(true);
      }
      if(data.notification_settings === '1'){
        setSettingsNotificationSettingschecked(true);
      }
      if(data.url_builder_custom_tags === '1'){
        setSettingsURLBuilderCustomTagschecked(true);
      }
      if(data.postback === '1'){
        setSettingsPostbackschecked(true);
      }
      if(data.payments_processing_and_carts === '1'){
        setSettingsPaymentsProcessingCartschecked(true);
      }
      if(data.access_management === '1'){
        setSettingsAccessManagementchecked(true);
      }
      if(data.visiopt_api === '1'){
        setVisioptApi(true);
      }
    }
    setAddUser(true);
  }

  const deleteUserConfirm = (member_id:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to delete this User?
      </Text>
    ),
    labels: { confirm: 'Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => handleUserDelete(member_id),
  });

  const handleUserDelete = (member_id:any) => {
      SettingsApi.deletememberuser({
        member_user_id: member_id,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
          setCheckAddUser(prev => !prev);
          fetchAccessManagementList();       
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const checkMemberEmail = (email) => {
    SettingsApi.checkmemberuseremail({
        username: email,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleAddUserSubmit = (event) => {
    event.preventDefault();
    let role = 0;
    if(adminChecked){
      role = 1;
    }else if(editorChecked){
      role = 2;
    }else if(viewerChecked){
      role = 3;
    }else if(customChecked){
      role = 4;
    }
    const data = ({
        member_user_id: editUserId,
        username: UsernameValue,
        password: PasswordValue,
        re_password: RetypePasswordValue,
        role: role,
        langing_page_tab: LandingPageTabchecked,
        campaign_tab: CampaignTabchecked,
        ab_test: ABTestchecked,
        modern_ab_test: ModernABTestchecked,
        single_variable_test: SingleVariableTestchecked,
        multi_variable_test: MultiVariableTestchecked,
        behavioral_dimensions: BehavioralDimensionschecked,
        heat_maps: HeatMapschecked,
        scroll_maps: ScrollMapschecked,
        recordings: Recordingschecked,
        ga_integration: SettingsGAIntegrationchecked,
        email_integration: SettingsEmailIntegrationchecked,
        notification_settings: SettingsNotificationSettingschecked,
        url_builder_custom_tags: SettingsURLBuilderCustomTagschecked,
        postback: SettingsPostbackschecked,
        payments_processing_and_carts: SettingsPaymentsProcessingCartschecked,
        access_management: SettingsAccessManagementchecked,
        visiopt_api: visioptApi,
    });
    
    if(editUserId !== ''){
      SettingsApi.addeditmemberuser(data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });  
          closeUserModal();
          setCheckAddUser(prev => !prev);
          fetchAccessManagementList();
          setEditUserId('');
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    }else{
      SettingsApi.addeditmemberuser(data)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });  
          closeUserModal();
          setCheckAddUser(prev => !prev);
          fetchAccessManagementList();
          setEditUserId('');
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    }    
  }

  useEffect(() => {
    fetchAccessManagementList();
  }, []);

  useEffect(() => {
    SettingsApi.checkaddusers()
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 0){
          setShowAddButton(false);
        }else{
          setShowAddButton(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [checkAddUser]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    const iconRef = id === 312 ? accessIconref : addAccessIconRef;
    if (iconRef.current) {
      const { top, left, width } = iconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        [id]: {top: top + scrollOffset, left: left + width + 10 },
      });
    }
  };
  
  return (
    <>
      {showHelpModal &&
        ReactDOM.createPortal(
          <div>
            {Object.keys(tooltipPosition).map((key) => (
              <SelectTooltip
                isVisible={showHelpModal}
                setIsVisible={setShowHelpModal}
                id={helpTargetId}
                tooltipPosition={tooltipPosition[key]} 
              />
            ))}
          </div>,
        document.body
      )}
      <Modal.Root size={1100} opened={AddUserOpened} onClose={closeUserModal}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title><Text weight={500}>ACCESS MANAGEMENT <IconHelp ref={addAccessIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(310)}/></Text></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddUserSubmit}>
              <Text weight={500} mb={10}>Enter new account credentials:</Text>
              <Grid>
                <Grid.Col span={2}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    <Text>Email:</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Input
                    name="username"
                    onChange={(event) => setUsernameValue(event.currentTarget.value)}
                    value={UsernameValue}
                    onBlur={(event) => checkMemberEmail(event.currentTarget.value)}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={2}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    <Text>Password:</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={3}>
                  <PasswordInput
                    name="password"
                    onChange={(event) => setPasswordValue(event.currentTarget.value)}
                    value={PasswordValue}
                  />
                </Grid.Col>
              </Grid>
              <Grid mb={5}>
                <Grid.Col span={2}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    <Text>Re-type Password:</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={3}>
                  <PasswordInput
                    name="retype_password"
                    onChange={(event) => setRetypePasswordValue(event.currentTarget.value)}
                    value={RetypePasswordValue}
                  />
                </Grid.Col>
              </Grid>
              <Text weight={500} mb={10}>Choose a role:</Text>
              <Group>
                <Radio name="role" checked={adminChecked}
                onChange={(event) => {
                  clearValues();
                  setadminChecked(event.currentTarget.checked);
                }}
                />
                <Text weight={500}>Admin</Text>
                <Text>- Full access.</Text>
              </Group>
              <Group>
                <Radio name="role" checked={editorChecked}
                onChange={(event) => {
                  clearValues();
                  seteditorChecked(event.currentTarget.checked);
                }}
                />
                <Text weight={500}>Editor</Text>
                <Text>- Full access except can't add users and can't get access to billing portal.</Text>
              </Group>
              <Group>
                <Radio name="role" checked={viewerChecked}
                onChange={(event) => {
                  clearValues();
                  setViewerChecked(event.currentTarget.checked);
                }}
                />
                <Text weight={500}>Viewer</Text>
                <Text>- Can't make any changes just view reports from dashboard, active tests, insights and campaigns.</Text>
              </Group>
              <Group mb={10}>
                <Radio name="role" checked={customChecked}
                onChange={(event) => {
                  clearValues();
                  setCustomChecked(event.currentTarget.checked);
                }}
                />
                <Text weight={500}>Custom</Text>
                <Text>- Select the options below you want to allow for the custom role.</Text>
              </Group>
              {customChecked && <div>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Landing Page tab</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={LandingPageTabchecked} onChange={(event) => setLandingPageTabchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Campaign tab</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={CampaignTabchecked} onChange={(event) => setCampaignTabchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>AB test</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={ABTestchecked} onChange={(event) => setABTestchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Modern AB test</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={ModernABTestchecked} onChange={(event) => setModernABTestchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Single Variable Test</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SingleVariableTestchecked} onChange={(event) => setSingleVariableTestchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Multi Variable Test</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={MultiVariableTestchecked} onChange={(event) => setMultiVariableTestchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Behavioral dimensions</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={BehavioralDimensionschecked} onChange={(event) => setdimensionschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Heat Maps</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={HeatMapschecked} onChange={(event) => setHeatMapschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Scroll Maps</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={ScrollMapschecked} onChange={(event) => setScrollMapschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Recordings</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={Recordingschecked} onChange={(event) => setRecordingschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Settings : GA integration</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsGAIntegrationchecked} onChange={(event) => setSettingsGAIntegrationchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Settings : Email integration</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsEmailIntegrationchecked} onChange={(event) => setSettingsEmailIntegrationchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Settings : Notification Settings</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsNotificationSettingschecked} onChange={(event) => setSettingsNotificationSettingschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Settings : URL Builder & Custom Tags</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsURLBuilderCustomTagschecked} onChange={(event) => setSettingsURLBuilderCustomTagschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Settings : Postbacks</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsPostbackschecked} onChange={(event) => setSettingsPostbackschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Settings : Payments processing & carts</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsPaymentsProcessingCartschecked} onChange={(event) => setSettingsPaymentsProcessingCartschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Settings : Access Management</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsAccessManagementchecked} onChange={(event) => setSettingsAccessManagementchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>My Account : Visiopt API</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={visioptApi} onChange={(event) => setVisioptApi(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
              </div>}
              <Group mb={10}>
                <Button type='submit'>Save</Button>
                <Button color="red" onClick={closeUserModal}>
                    Cancel
                </Button>
              </Group>              
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Group position="apart" mb="xs">
        <Text weight={500}>ACCESS MANAGEMENT <IconHelp ref={accessIconref} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(312)}/></Text>
      </Group>      
      {showAddButton && <Button onClick={() => setAddUser(true)}>Add a New User</Button>}
      <div style={{ display: activeUserData === "User-Data" ? "block" : "none"}}>
        {showSkeleton ? (
          <Flex direction="column" gap="sm" mt={10}>
            {Array.from({ length: 12 }).map((_, index) => (
              <Skeleton key={index} height={44} width={1262} />
            ))}
          </Flex>
        ) : (
          UserData.map((item,index) => (
            <Box
             key={index}
              p={4}
              pl={20}
              mt={10}
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
                padding: "10px",
                borderRadius: theme.radius.md,
                border: "0.0625rem solid transparent",
                borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
              })}
            >
              <Grid gutter="xs">
                <Grid.Col span={3}>
                  <Text mt={3}>
                    {item.member_username}
                  </Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text mt={3}>
                    {item.member_role}
                  </Text>
                </Grid.Col>
                <Grid.Col span={4}>
                <a onClick={() => handlePreviewClick(item.member_user_id)}
                  style={{
                    textDecoration: 'none',
                    color: 'blue',
                    cursor: 'pointer',
                  }}
                >
                  <Text mt={3} weight={500} c="blue">
                    Preview
                  </Text>
                </a>
                </Grid.Col>
                {item.default_member === '0' && 
                  <Grid.Col span={3}>
                    <Flex
                      justify="flex-end"
                      align="center"
                      direction="row"
                      wrap="wrap"
                    >
                      <ActionIcon
                        variant="default"
                        size="lg"
                        mx={3}
                        title="Edit"
                        onClick={() => updateUser(item.member_user_id)}
                      >
                        <IconPencil size="1.1rem" />
                      </ActionIcon>
                      <ActionIcon
                        variant="default"
                        size="lg"
                        mx={3}
                        title="Delete"
                        onClick={() => deleteUserConfirm(item.member_user_id)}
                      >
                        <IconTrash size="1.1rem" />
                      </ActionIcon>
                    </Flex>
                  </Grid.Col>
                }
              </Grid>
            </Box>
          ))
        )}
      </div>
      <div style={{ display: activeUserActivity === "User-Activity" ? "block" : "none"}}>
        <Group style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '10px' }}>
          <Text>Below you can see the date and time of the various action taken by the selected user :</Text>
          <Button color="green" onClick={handleBackBtn}>Back</Button>          
        </Group>
        <Group position="center" style={{ margin: '20px 0' }}>
          <Table>
            <thead>
              <tr>
                <th style={{ width:'200px' }}>Date and Time</th>
                <th>Action</th>
              </tr>
            </thead>
            {UserActivityData.activity && (
              <tbody>
                {UserActivityData.activity.map((item, index) => (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td dangerouslySetInnerHTML={{ __html: item.action }} />
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
          {totalActivityPages>1 && <Pagination total={totalActivityPages} value={activePage} onChange={handlePageChange} />}
        </Group>
      </div>
    </>
  );
}