import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Card, Text, Group, Grid, Select, Radio, Box, LoadingOverlay } from '@mantine/core';
import DashBoardApi from "../../api/DashBoardApi";
import { IconHelp } from "@tabler/icons-react";
import { Chart } from "react-google-charts";
import { SelectActiveTest, SelectGetGoal, BoxPageThumb, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";

export function ActiveTests({ selectedMemberWebsite, selectedActiveTestMemberWebsite, activeTests, setActiveTests, selectedGoalMemberWebsite, selectedBoxPageThumbMemberWebsite }:any) {
  const navigate = useNavigate();
  const activeTestIconRef = useRef(null);
  const [ActiveTestsLoader, setActiveTestsLoader] = useState(false);

  const [selectedActiveTest, setSelectedActiveTest] = useState('');
  const [selectedGoalActiveTest, setSelectedGoalActiveTest] = useState('');
  const [selectedBoxPageThumbActiveTest, setSelectedBoxPageThumbActiveTest] = useState('');
  const [selectedGoal, setSelectedGoal] = useState('');

  const [TestBarChartData, setTestBarChartData] = useState([]);
  const [TestBarCharColors, setTestBarCharColors] = useState([]);
  const [GoalTypevalue, setGoalTypeValue] = useState('1');
  const [ActiveTestReportTypeValue, setActiveTestReportTypeValue] = useState('1');

  const [showHelpModal, setShowHelpModal] = useState(false);
  const [helpTargetId, setHelpTargetId] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleselectedActiveTestComponentChange = (Value:any) => {
    setSelectedActiveTest(Value);
    setSelectedGoalActiveTest(Value);
    setSelectedBoxPageThumbActiveTest(Value);
  }

  const handleselectedGoalComponentChange = (Value:any) => {
    setSelectedGoal(Value);
  }

  const handleActiveTestReportTypeChange = (Value:any) => {
    setActiveTestReportTypeValue(Value);
  }

  const ChangeGoalTypeValue = (e) => {
    setGoalTypeValue(e);
    setSelectedGoal('');
  }

  useEffect(() => {
    if(selectedMemberWebsite !== '' && selectedActiveTest !== '' && selectedGoal !== '' && GoalTypevalue !== '' && ActiveTestReportTypeValue !== ''){
      setActiveTestsLoader(true);
      DashBoardApi.getactivetestlinechart({
        system_website_id : selectedMemberWebsite,
        active_test_page_id : selectedActiveTest,
        goal_type : GoalTypevalue,
        report_type : ActiveTestReportTypeValue,
        goal_id : selectedGoal
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setActiveTestsLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        let BarData = [
          [""]
        ];
        let BarStats = [];
        let BarColor = [];
        if(data.status === 1){
          if(data.data.datasets && data.data.datasets.length > 0){
            data.data.datasets.map(Bar => (
              BarData[0].push(Bar.label),
              BarStats = Object.entries(Bar.data),
              BarColor = Bar.backgroundColor
            ));
            if(BarStats.length > 0){
              BarData = BarData.concat(BarStats);
            }
            setTestBarChartData(BarData);
            setTestBarCharColors(BarColor);
            setActiveTestsLoader(false);
          }
          setActiveTestsLoader(false);
        }
        setActiveTestsLoader(false);
      })
      .catch(error => {
        console.log(error);
        setActiveTestsLoader(false);
      });
    }
  }, [selectedMemberWebsite, selectedActiveTest, selectedGoal, GoalTypevalue, ActiveTestReportTypeValue,navigate]);

  const handleHelpClick = (id:any) => {
    setShowHelpModal(true);
    setHelpTargetId(id);
    if (activeTestIconRef.current) {
      const { top, left, width } = activeTestIconRef.current.getBoundingClientRect();
      const scrollOffset = window.scrollY;
      setTooltipPosition({
        top: top + scrollOffset,
        left: left + width + 10, 
      });
    }
  };

  return (
    <>
      {showHelpModal && <SelectTooltip isVisible={showHelpModal} setIsVisible={setShowHelpModal} id={helpTargetId} tooltipPosition={tooltipPosition} />}
      {selectedActiveTestMemberWebsite && activeTests.length > 0 && (
        <Box pos="relative">
          <LoadingOverlay zIndex={9999999999} visible={ActiveTestsLoader} overlayBlur={2} loaderProps={{variant:"bars", color:"gray"}} />
          <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
            <Card.Section>
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>ACTIVE TESTS <IconHelp ref={activeTestIconRef} size="1.1rem" color="red" style={{marginBottom:-3, cursor: 'pointer'}} onClick={() => handleHelpClick(4)}/></Text>
            </Group>
            <Group mb={15}>
              <Text>Select Test: </Text>
              <SelectActiveTest SelectActiveTestValue={selectedActiveTest} SelectActiveTestOnChange={handleselectedActiveTestComponentChange} SelectActiveTestSelectedMemberWebsite={selectedActiveTestMemberWebsite} setActiveTests={setActiveTests}/>
            </Group>
            <Group mb={15}>
              <Text>Report Type: </Text>
              <Select
                data={[
                  { value: '1', label: 'Conversion Sum (#)' },
                  { value: '2', label: 'Conversion Rate (%)' }
                ]}
                value={ActiveTestReportTypeValue}
                onChange={handleActiveTestReportTypeChange}
                searchable
              />
              <Radio.Group
                value={GoalTypevalue}
                onChange={ChangeGoalTypeValue}
                name="GoalType"
              >
                <Group mt="xs">
                  <Radio value="1" label="Goal" />
                  <Radio value="2" label="Button" />
                  <Radio value="3" label="Postback" />
                </Group>
              </Radio.Group>
              <SelectGetGoal SelectGetGoalValue={selectedGoal} SelectGetGoalOnChange={handleselectedGoalComponentChange} SelectGetGoalSelectedMemberWebsite={selectedGoalMemberWebsite} SelectGetGoalSelectedActiveTestPage={selectedGoalActiveTest} SelectGetGoalSelectedGoalType={GoalTypevalue} />
            </Group>
            <Grid justify="space-between">
              <Grid.Col span={3} style={{ textAlign: 'left' }}>
                <BoxPageThumb BoxPageThumbSelectedMemberWebsite={selectedBoxPageThumbMemberWebsite} BoxPageThumbSelectedActiveTestPage={selectedBoxPageThumbActiveTest} />
              </Grid.Col>
              <Grid.Col span={9} style={{ textAlign: 'right' }}>
                {TestBarChartData.length > 1 && (
                <Chart
                  chartType="Bar"
                  data={TestBarChartData}
                  options={
                    {
                      colors: TestBarCharColors
                    }
                  }
                />
                )}
              </Grid.Col>
            </Grid>
          </Card>
        </Box>
        )}
    </>
  );
}